import { GridStyleDto } from '../../interfaces/grid/grid.style.dto';
import { ColorV2 } from '../../designsystem/models/color/colorv2.model';

export class TemplateStyle {
    // Background
    backgroundColor: string;
    backgroundColorV2: ColorV2;
    backgroundImage: string;
    backgroundImageUrl: string;
    backgroundImageLinkType: string;
    backgroundImageMediaId: string;
    backgroundAttachment: string;
    backgroundPositionX: string;
    backgroundPositionY: string;
    backgroundRepeat: string;
    backgroundSize: string;
    backgroundClip: string;
    backgroundOrigin: string;

    // Font
    font: string;
    fontSize: number;
    fontColor: string;
    fontWeight: string;

    grid: GridStyleDto = {
        gridColumns: 12,
        gridColumnSize: 60,
        gridColumnGapSize: 20,
        gridColumnColor: '#e2e2e2',
        gridRows: 12,
        gridRowSize: 60,
        gridRowGapSize: 20,
        gridRowColor: '#FF0000',
        snapStrength: 10,
        showGrid: true,
    };
}
