import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../app/common/statemanagement/state/applicationstate';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanyDto } from '../../../modules/shared/interfaces/company.dto';
import { backofficeEnvironment } from '@shared/environment';
import { InitiateUpdateBillingPackageSessionDto } from '../dto/initiate-update-billing-package-session.dto';
import { Observable } from 'rxjs';

@Injectable()
export class BillingPackageService {
    constructor(
        private store: Store<ApplicationState>,
        private http: HttpClient
    ) {}

    initiateUpdateBillingPackage(
        company: CompanyDto,
        packageName: string,
        amountOfCores: number,
        couponCode: string,
        yearly: boolean
    ): Observable<InitiateUpdateBillingPackageSessionDto> {
        let params = new HttpParams()
            .set('companyId', company.id)
            .set('packageName', packageName)
            .set('yearly', yearly)
            .set('amountOfCores', amountOfCores);

        if (couponCode) {
            params = params.set('couponCode', couponCode);
        }
        return this.http.get<InitiateUpdateBillingPackageSessionDto>(
            `${backofficeEnvironment.rest.v2.billingpackage}initiate-update-package`,
            { params: params }
        );
    }
}
