<div class="mt-4">
    <h3 class="!mb-2">{{ title }}</h3>
    <div class="designsystem-colorgrid">
        <div class="col-span-8">
            <h4 class="!mb-2">Primary colors</h4>
            <div class="flex flex-row items-center gap-1">
                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="primary">
                    <div class="colorpicker-color" [style.background-color]="theme.primary.value"></div>
                    <label class="designsystem-colorname">{{ theme.primary.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.primary.value }}</p>
                </div>
                <float-ui-content #primary>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="primaryControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="secondary">
                    <div class="colorpicker-color" [style.background-color]="theme.secondary.value"></div>
                    <label class="designsystem-colorname">{{ theme.secondary.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.secondary.value }}</p>
                </div>
                <float-ui-content #secondary>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="secondaryControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="tertiary">
                    <div class="colorpicker-color" [style.background-color]="theme.tertiary.value"></div>
                    <label class="designsystem-colorname">{{ theme.tertiary.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.tertiary.value }}</p>
                </div>
                <float-ui-content #tertiary>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="teriaryControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="alternate">
                    <div class="colorpicker-color" [style.background-color]="theme.alternate.value"></div>
                    <label class="designsystem-colorname">{{ theme.alternate.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.alternate.value }}</p>
                </div>
                <float-ui-content #alternate>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="alternateControl"></chrome-picker>
                </float-ui-content>
            </div>
        </div>
        <div class="col-span-4">
            <h4 class="!mb-2">Background colors</h4>
            <div class="flex flex-row items-center gap-1">
                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="primaryBackground">
                    <div class="colorpicker-color" [style.background-color]="theme.primaryBackground.value"></div>
                    <label class="designsystem-colorname">{{ theme.primaryBackground.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.primaryBackground.value }}</p>
                </div>
                <float-ui-content #primaryBackground>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="primaryBackgroundControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="secondaryBackground">
                    <div class="colorpicker-color" [style.background-color]="theme.secondaryBackground.value"></div>
                    <label class="designsystem-colorname">{{ theme.secondaryBackground.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.secondaryBackground.value }}</p>
                </div>
                <float-ui-content #secondaryBackground>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="secondaryBackgroundControl"></chrome-picker>
                </float-ui-content>
            </div>
        </div>
        <div class="col-span-8">
            <h4 class="!mb-2">Accent colors</h4>
            <div class="flex flex-row items-center gap-1">
                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="accentOne">
                    <div class="colorpicker-color" [style.background-color]="theme.accentOne.value"></div>
                    <label class="designsystem-colorname">{{ theme.accentOne.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.accentOne.value }}</p>
                </div>
                <float-ui-content #accentOne>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="accentOneControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="accentTwo">
                    <div class="colorpicker-color" [style.background-color]="theme.accentTwo.value"></div>
                    <label class="designsystem-colorname">{{ theme.accentTwo.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.accentTwo.value }}</p>
                </div>
                <float-ui-content #accentTwo>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="accentTwoControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="accentThree">
                    <div class="colorpicker-color" [style.background-color]="theme.accentThree.value"></div>
                    <label class="designsystem-colorname">{{ theme.accentThree.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.accentThree.value }}</p>
                </div>
                <float-ui-content #accentThree>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="accentThreeControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="accentFour">
                    <div class="colorpicker-color" [style.background-color]="theme.accentFour.value"></div>
                    <label class="designsystem-colorname">{{ theme.accentFour.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.accentFour.value }}</p>
                </div>
                <float-ui-content #accentFour>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="accentFourControl"></chrome-picker>
                </float-ui-content>
            </div>
        </div>
        <div class="col-span-4">
            <h4 class="!mb-2">Text colors</h4>
            <div class="flex flex-row items-center gap-1">
                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="primaryText">
                    <div class="colorpicker-color" [style.background-color]="theme.primaryText.value"></div>
                    <label class="designsystem-colorname">{{ theme.primaryText.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.primaryText.value }}</p>
                </div>
                <float-ui-content #primaryText>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="primaryTextControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="secondaryText">
                    <div class="colorpicker-color" [style.background-color]="theme.secondaryText.value"></div>
                    <label class="designsystem-colorname">{{ theme.secondaryText.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.secondaryText.value }}</p>
                </div>
                <float-ui-content #secondaryText>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="secondaryTextControl"></chrome-picker>
                </float-ui-content>
            </div>
        </div>
        <div class="col-span-8">
            <h4 class="!mb-2">Semantic colors</h4>
            <div class="flex flex-row items-center gap-1">
                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="success">
                    <div class="colorpicker-color" [style.background-color]="theme.success.value"></div>
                    <label class="designsystem-colorname">{{ theme.success.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.success.value }}</p>
                </div>
                <float-ui-content #success>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="successControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="info">
                    <div class="colorpicker-color" [style.background-color]="theme.info.value"></div>
                    <label class="designsystem-colorname">{{ theme.info.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.info.value }}</p>
                </div>
                <float-ui-content #info>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="infoControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="warning">
                    <div class="colorpicker-color" [style.background-color]="theme.warning.value"></div>
                    <label class="designsystem-colorname">{{ theme.warning.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.warning.value }}</p>
                </div>
                <float-ui-content #warning>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="warningControl"></chrome-picker>
                </float-ui-content>

                <div class="designsystem-colorpicker" [showTrigger]="NgxFloatUiTriggers.click" [floatUi]="error">
                    <div class="colorpicker-color" [style.background-color]="theme.error.value"></div>
                    <label class="designsystem-colorname">{{ theme.error.name | titlecase }}</label>
                    <p class="designsystem-colorvalue">{{ theme.error.value }}</p>
                </div>
                <float-ui-content #error>
                    <chrome-picker class="designsystem-colorpicker-popup" [control]="errorControl"></chrome-picker>
                </float-ui-content>
            </div>
        </div>
    </div>
</div>
