<div class="clear-both flex justify-between mb-2">
    <label class="small-input-label">{{ 'v2.part.style.shadow.color' | translate }}</label>
    <nocodex-color-picker-v2
        [hex]="_shadowStyle.color"
        [colorV2]="!!_shadowStyle.colorV2 ? _shadowStyle.colorV2 : null"
        (colorChangedV2)="onChangeColor($event)"></nocodex-color-picker-v2>
</div>

<codex-pixel-selector
    [label]="'v2.part.style.shadow.offset.x' | translate"
    [value]="_shadowStyle.offsetX"
    (changePixels)="onChangeOffsetX($event)"></codex-pixel-selector>
<codex-pixel-selector
    [label]="'v2.part.style.shadow.offset.y' | translate"
    [value]="_shadowStyle.offsetY"
    (changePixels)="onChangeOffsetY($event)"></codex-pixel-selector>
<codex-pixel-selector
    [label]="'v2.part.style.shadow.blurradius' | translate"
    [value]="_shadowStyle.blurRadius"
    (changePixels)="onChangeBlurRadius($event)"></codex-pixel-selector>
<codex-pixel-selector
    [label]="'v2.part.style.shadow.spreadradius' | translate"
    [value]="_shadowStyle.spreadRadius"
    (changePixels)="onChangeSpreadRadius($event)"></codex-pixel-selector>
<label class="toggle-line">
    Inset
    <input
        type="checkbox"
        class="sr-only peer"
        [(ngModel)]="_shadowStyle.inset"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="onChangeShadow()"
        (click)="$event.stopPropagation()" />
    <div
        class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
</label>
