<form [formGroup]="formGroup" novalidate cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="1 1 auto">
            <label for="application-name" class="small-input-label" data-name="application-name">{{
                'v2.application.edit.name' | translate
            }}</label>
            <input
                class="small-input"
                type="text"
                id="application-name"
                name="application-name"
                autocomplete="off"
                cdkFocusInitial
                required
                formControlName="name" />
            <p class="small-input-error" *ngIf="this.formGroup.get('name').invalid">
                {{ 'v2.application.edit.name.error' | translate }}
            </p>

            <label for="application-description" class="small-input-label" data-name="application-description">{{
                'v2.application.edit.description' | translate
            }}</label>
            <textarea
                class="small-input"
                type="text"
                id="application-description"
                name="application-description"
                autocomplete="off"
                formControlName="description"></textarea>
        </div>
        <div fxFlex="1 1 170px">
            <codex-icon-picker [icon]="application.iconName" (changeIcon)="onChangeIconName($event)"></codex-icon-picker>
        </div>
    </div>
    <hr class="seperator" />
    <label for="application-domain" class="small-input-label" data-name="application-domain">{{
        'v2.application.edit.domain' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="application-domain"
        name="application-domain"
        autocomplete="off"
        required
        slug
        formControlName="slug" />
    <p class="small-input-hint">
        https://{{ formGroup.controls['slug'].value }}{{ application.applicationDomainName.replace(application.applicationSlug, '') }}
        .no-code-x.com
    </p>
    <p class="small-input-error" *ngIf="this.formGroup.get('slug').invalid && this.formGroup.get('slug').errors.required">
        {{ 'v2.application.edit.endpoint.error' | translate }}
    </p>
    <p class="small-input-error" *ngIf="this.formGroup.get('slug').invalid && this.formGroup.get('slug').errors.pattern">
        Please only use alphanumerical characters & '-', '_'.
    </p>
    <p class="small-input-hint" *ngIf="this.formGroup.get('slug').invalid"></p>

    <hr class="seperator" />
    <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'v2.application.edit.custom-domain.title' | translate }}</h4>

    <label for="custom-domain" class="small-input-label" data-name="part-code"
        >{{ 'v2.application.edit.custom-domain' | translate }}
        <mat-icon
            class="help-icon"
            color="primary"
            matSuffix
            [showTrigger]="NgxFloatUiTriggers.hover"
            [appendTo]="'body'"
            [boundariesElement]="'body'"
            [preventOverflow]="false"
            [positionFixed]="true"
            [applyClass]="'!max-w-[500px]'"
            [floatUi]="customDomainNameHelpPopover"
            >help</mat-icon
        >
    </label>
    <input class="small-input" type="text" id="custom-domain" name="customDomain" cdkFocusInitial formControlName="customDomain" />
    <p class="small-input-error" *ngIf="this.formGroup.get('customDomain').invalid">
        {{ 'v2.application.edit.customDomain.error' | translate }}
    </p>
    <float-ui-content #customDomainNameHelpPopover>
        <div class="popover">
            <p>
                The Custom Domain field allows you to serve your application from a unique, domain name of your choice, rather than a
                subdomain of nocode-x.com. This feature allows you to personalize your application's URL, making it more professional and
                aligned with your brand identity.
            </p>
            <p>
                <strong>Important Note:</strong> To get started, the first step is to purchase a domain name of your choice from a reputable
                DNS registrar. Once you have acquired your domain, you can then utilize our Custom Domain feature to associate it with your
                application. For detailed instructions on how to configure DNS settings for your custom domain, please refer to our
                <a class="text-accenttext underline font-bold" href="https://www.nocode-x.com/docs/application-platform/custom-domains/"
                    >documentation</a
                >.
            </p>
        </div>
    </float-ui-content>
    <ng-container *ngIf="this.formGroup.get('customDomain').value && this.formGroup.get('customDomain').value !== ''">
        <div class="flex justify-between items-center mb-2">
            <h4 class="!text-sm !font-bold !mb-1 !mt-1" fxLayout="row">{{ 'v2.application.edit.custom-domain.configure' | translate }}</h4>
            <button
                class="h-9 mt-1 mb-1"
                data-name="delete"
                type="button"
                color="primary"
                (click)="onValidateDomains()"
                mat-button
                mat-raised-button>
                <mat-icon>check_outline</mat-icon>
                {{ 'v2.application.edit.custom-domain.validate' | translate }}
            </button>
        </div>
        <p class="!text-xs">{{ 'v2.application.edit.custom-domain.configure.description' | translate }}</p>
        <div>
            <ng-container *ngFor="let customDomainCheck of customDomainChecks">
                <div class="flex justify-between">
                    <div>
                        <div class="flex">
                            <div class="text-xxs font-bold">Type:&nbsp;</div>
                            <div class="text-xxs">A</div>
                        </div>
                        <div class="flex">
                            <div class="text-xxs font-bold">Name:&nbsp;</div>
                            <div class="text-xxs">{{ customDomainCheck.name }}</div>
                        </div>
                        <div class="flex">
                            <div class="text-xxs font-bold">IPv4 address:&nbsp;</div>
                            <div class="text-xxs">{{ customDomainCheck.ipv4 }}</div>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <mat-spinner diameter="20" *ngIf="customDomainCheck.status === 'pending'"></mat-spinner>
                        <mat-icon class="success" style="color: #326e1a" *ngIf="customDomainCheck.status === 'success'">
                            check_circle
                        </mat-icon>
                        <mat-icon class="failed" style="color: #ff5733" *ngIf="customDomainCheck.status === 'failed'"> error </mat-icon>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <hr class="seperator" />
    <button class="destructive-button button-medium" data-name="delete" type="button" (click)="onDeleteApplication()">
        <mat-icon>delete</mat-icon>
        {{ 'v2.application.edit.delete' | translate }}
    </button>
    <hr class="seperator" />
    <div class="flex gap-1 justify-end mt-2 mb-2">
        <button class="primary-button button-large" data-name="save" type="submit" (click)="onUpdateApplication()">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-large" data-name="cancel" type="button" (click)="onCancel()">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
