import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { LoggerService } from '@backoffice/utils';
import { AddPropertyDialogComponent } from '../add-property-dialog/add-property-dialog.component';
import { backofficeEnvironment } from '@shared/environment';
import { DataFormatAttribute } from '../../../../../../../../data-access/editor/src/lib/dataformat/models/data-format-attribute';

@Component({
    selector: 'codex-json-schema-property',
    templateUrl: './json-schema-property.component.html',
})
export class JsonSchemaPropertyComponent {
    @Input() index: number;
    @Input() attributeAmount: number;
    @Input() attribute: DataFormatAttribute;
    @Input() required: string[] = [];

    @Output() propertyChanged = new EventEmitter<{ attribute: DataFormatAttribute }>();

    constructor() {}
}
