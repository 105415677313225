import { PartDetail } from '../../../part-detail.model';

export class ButtonPartDetail extends PartDetail {
    code: string;
    label: string;
    type: string;
    enabled: boolean;
    tooltip: string;
    icon: string;
    iconPosition: string;

    isValid(): boolean {
        return this.isCodeValid() && this.isLabelValid() && this.isTypeValid();
    }

    getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.button.error.code');
        }
        if (!this.isLabelValid()) {
            validationErrors.push('v2.part.button.error.label');
        }
        if (!this.isTypeValid()) {
            validationErrors.push('v2.part.button.error.type');
        }
        return validationErrors;
    }

    isTypeValid(): boolean {
        return !!this.type && this.type !== '';
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isLabelValid(): boolean {
        return !!this.label && this.label !== '';
    }
}
