import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import {
    DataFormatAttribute,
    setTypeBasedPropertiesOfAttribute,
} from '../../../../../../../../../data-access/editor/src/lib/dataformat/models/data-format-attribute';
import { AddPropertyDialogComponent } from '../../add-property-dialog/add-property-dialog.component';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoggerService } from '@backoffice/utils';
import { GUIDFunctions } from '@shared/utils';

@Component({
    selector: 'codex-array-type-settings',
    templateUrl: './array-type-settings.component.html',
})
export class ArrayTypeSettingsComponent {
    @Input() attribute: DataFormatAttribute;

    @Output() change: EventEmitter<void> = new EventEmitter();

    constructor(
        private readonly dialog: MatDialog,
        private readonly logger: LoggerService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}
    onDeleteItem($event: { attribute: DataFormatAttribute }) {
        this.change.emit();
    }

    onDeclareArrayTypeClicked(): void {
        const dialogRef = this.dialog.open(
            AddPropertyDialogComponent,
            Object.assign({ restoreFocus: true }, backofficeEnvironment.dialogConfig.extrasmall)
        );

        dialogRef.afterClosed().subscribe((result: { type: any }) => {
            if (result) {
                this.logger.info(`Received: ${JSON.stringify(result)}`);

                if (!this.attribute.arrayDefinition) {
                    const { type } = result;

                    let attribute = {
                        id: this.attribute.id + '.type',
                        name: this.attribute.id + '.type',
                        noCodeXType: type,
                        types: [type],
                        attributes: [],
                        nullable: true,
                        required: false,
                    };
                    setTypeBasedPropertiesOfAttribute(attribute);
                    this.attribute.arrayDefinition = attribute;
                }

                this.changeDetectorRef.markForCheck();
                this.change.emit();
            }
        });
    }

    onArrayItemChanged($event) {
        this.change.emit();
    }
}
