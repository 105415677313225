<div class="flex gap-1 mt-3 mb-3 justify-end h-9">
    <button class="primary-button button-medium" data-name="create-new-role" type="button" (click)="openCreateUserDialog()">
        <mat-icon>add_circle</mat-icon>
        Create user
    </button>
</div>
<div class="flex gap-3 mt-3 mb-3 items-center" [formGroup]="form">
    <label for="filter" class="small-input-label" data-name="filter">{{ 'v2.usermanagement.users.filter' | translate }}</label>
    <input class="small-input" type="text" id="filter" name="filter" autocomplete="off" cdkFocusInitial formControlName="filter" />

    <label for="select-environment" class="small-input-label">{{ 'v2.usermanagement.users.environment.title' | translate }}</label>
    <select id="select-environment" class="small-input" formControlName="environment" data-name="select-environment">
        <option [value]="'DEV'">{{ 'v2.usermanagement.users.environment.dev' | translate }}</option>
        <option [value]="'TEST'">{{ 'v2.usermanagement.users.environment.test' | translate }}</option>
        <option [value]="'ACCEPT'">{{ 'v2.usermanagement.users.environment.accept' | translate }}</option>
        <option [value]="'PRODUCTION'">{{ 'v2.usermanagement.users.environment.production' | translate }}</option>
    </select>
</div>
<div class="mat-elevation-z2" *ngIf="{ usersContent: usersContent$ | async } as observables">
    <table
        class="w-[100%]"
        mat-table
        [dataSource]="observables?.usersContent?.content"
        matSort
        matSortActive="email"
        matSortDisableClear
        matSortDirection="desc">
        <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'v2.company.users.email' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user.email }}</td>
        </ng-container>

        <ng-container matColumnDef="firstName" class="mat-elevation-z8">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'v2.company.users.firstname' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'v2.company.users.lastname' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="w-4">{{ 'v2.company.users.actions' | translate }}</th>
            <td mat-cell *matCellDef="let user" class="w-4">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    class="icon icon-button mx-auto !h-5 !w-5 !text-xl !leading-5 z-10 cursor-pointer"
                    data-name="controls"
                    [attr.data-dropdown-toggle]="'userActionsDropdown-' + user.id">
                    <use href="#more-vertical" class="ui-element"></use>
                </svg>
                <div [attr.id]="'userActionsDropdown-' + user.id" class="dropdown-items hidden">
                    <ul class="dropdown-items-wrapper" aria-labelledby="dropdownDelayButton">
                        <li>
                            <a (click)="openRolesAndPermissionsDialog(user.userId)" class="dropdown-item">
                                <svg class="icon">
                                    >
                                    <use href="#authentication" class="ui-element"></use>
                                </svg>
                                {{ 'v2.company.users.permissions' | translate }}</a
                            >
                        </li>
                        <li>
                            <a (click)="deleteUser(user.id)" class="dropdown-item">
                                <svg class="icon">
                                    >
                                    <use href="#trash" class="ui-element"></use>
                                </svg>
                                {{ 'v2.usermanagement.users.delete.user' | translate }}</a
                            >
                        </li>
                    </ul>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="observables?.usersContent?.count" [pageSizeOptions]="[5, 10, 25]" [pageSize]="10"></mat-paginator>
</div>
