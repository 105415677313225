import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplicationVersionDiffComponent } from '../application-version-diff/application-version-diff.component';
import { backofficeEnvironment } from '@shared/environment';
import { ApplicationVersion, ApplicationVersionFacade } from '@backoffice/editor/data-access/application-version';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-application-version',
    templateUrl: './application-version-page.component.html',
    styleUrls: ['./application-version-page.component.scss'],
})
export class ApplicationVersionPageComponent implements OnInit, AfterViewInit {
    applicationVersions$ = new BehaviorSubject<ApplicationVersion[]>([]);
    addNewApplicationVersion = false;

    constructor(
        private readonly appVersionFacade: ApplicationVersionFacade,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<ApplicationVersionPageComponent>
    ) {}

    ngOnInit() {
        this.loadApplicationVersions();
    }

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    loadApplicationVersions() {
        timer(0, 1000)
            .pipe(
                switchMap(() => this.appVersionFacade.findAll(0, 10)),
                tap(response => this.applicationVersions$.next(response.content)),
                tap(() => setTimeout(() => initFlowbite())),
                takeWhile(
                    response =>
                        !!response.content.find(
                            applicationVersion => applicationVersion.status !== 'READY' && applicationVersion.status !== 'FAILED'
                        )
                )
            )
            .subscribe(() => {
                setTimeout(() => initFlowbite(), 1500);
            });
    }

    onPromoteToTest(applicationVersion: ApplicationVersion) {
        applicationVersion.setTestVersion();
        const applicationVersions = this.applicationVersions$.value;
        applicationVersions.forEach(otherApplicationVersion => {
            if (otherApplicationVersion.id !== applicationVersion.id && otherApplicationVersion.environments.indexOf('TEST') > -1) {
                otherApplicationVersion.environments.splice(otherApplicationVersion.environments.indexOf('TEST'), 1);
            }
        });
        applicationVersion.environment = 'TEST';
        this.applicationVersions$.next(applicationVersions);
        this.appVersionFacade.update(applicationVersion).subscribe();
    }

    onPromoteToAcceptance(applicationVersion: ApplicationVersion) {
        applicationVersion.setAcceptanceVersion();
        const applicationVersions = this.applicationVersions$.value;
        applicationVersions.forEach(otherApplicationVersion => {
            if (otherApplicationVersion.id !== applicationVersion.id && otherApplicationVersion.environments.indexOf('ACCEPT') > -1) {
                otherApplicationVersion.environments.splice(otherApplicationVersion.environments.indexOf('ACCEPT'), 1);
            }
        });
        applicationVersion.environment = 'ACCEPT';
        this.applicationVersions$.next(applicationVersions);
        this.appVersionFacade.update(applicationVersion).subscribe();
    }

    onPromoteToProduction(applicationVersion: ApplicationVersion) {
        applicationVersion.setCurrentVersion();
        const applicationVersions = this.applicationVersions$.value;
        applicationVersions.forEach(otherApplicationVersion => {
            if (otherApplicationVersion.id !== applicationVersion.id && otherApplicationVersion.environments.indexOf('PRODUCTION') > -1) {
                otherApplicationVersion.environments.splice(otherApplicationVersion.environments.indexOf('PRODUCTION'), 1);
            }
        });
        applicationVersion.environment = 'PRODUCTION';
        this.applicationVersions$.next(applicationVersions);
        this.appVersionFacade.update(applicationVersion).subscribe();
    }

    onCreateApplicationVersion() {
        this.addNewApplicationVersion = true;
    }

    onHideNewApplicationVersion($event: { created: boolean }) {
        this.addNewApplicationVersion = false;
        if ($event.created) {
            this.loadApplicationVersions();
        }
    }

    onHandleCompare(sourceVersionId: string): void {
        this.appVersionFacade.development().subscribe(({ id: currentVersionId }) => {
            this.dialogRef.close();
            this.dialog.open(
                ApplicationVersionDiffComponent,
                Object.assign(
                    {
                        data: {
                            sourceVersionId,
                            currentVersionId,
                        },
                    },
                    backofficeEnvironment.dialogConfig.normal
                )
            );
        });
    }

    protected readonly environment = backofficeEnvironment;
}
