import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyIdentityProvider } from '@shared/interfaces/company-identity-provider.dto';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-openidconnect',
    templateUrl: './openidconnect.component.html',
})
export class OpenidconnectComponent implements OnInit {
    formGroup: FormGroup;

    @Input()
    identityProvider: CompanyIdentityProvider;

    @Input()
    company: CompanyDto;

    @Input()
    type: string;

    @Output()
    onSave: EventEmitter<{ identityProvider: CompanyIdentityProvider }> = new EventEmitter<{
        identityProvider: CompanyIdentityProvider;
    }>();

    @Output()
    cancel: EventEmitter<void> = new EventEmitter<void>();

    urlReg = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:%/?#[\]@!\$&'\(\)\*\+,;=.]+$/);

    constructor(public fb: FormBuilder) {}

    ngOnInit(): void {
        this.initFormGroup();
    }

    initFormGroup() {
        if (this.identityProvider) {
            this.formGroup = this.fb.group({
                name: [this.identityProvider.name, Validators.required],
                displayName: [this.identityProvider.displayName, Validators.required],
                discoveryendpoint: [this.identityProvider.discoveryendpoint, [Validators.pattern(this.urlReg)]],
                clientid: [this.identityProvider.clientId, Validators.required],
                clientsecret: [this.identityProvider.clientSecret, Validators.required],
                forceIdp: [this.identityProvider.forceIdp],
            });
            if (this.identityProvider.type === 'openidconnect') {
                this.formGroup.get('discoveryendpoint')?.addValidators([Validators.required]);
            }
        } else {
            this.formGroup = this.fb.group({
                name: ['', Validators.required],
                displayName: ['', Validators.required],
                discoveryendpoint: ['', [Validators.pattern(this.urlReg)]],
                clientid: ['', Validators.required],
                clientsecret: ['', Validators.required],
                forceIdp: [false],
            });
            if (this.type === 'openidconnect') {
                this.formGroup.get('discoveryendpoint')?.addValidators([Validators.required]);
            }
        }
    }

    onCancel() {
        this.cancel.emit();
    }

    onSaveConfiguration() {
        this.onSave.emit({
            identityProvider: {
                id: this.identityProvider?.id,
                type: this.type ? this.type : 'openidconnect',
                name: this.formGroup.get('name').value,
                displayName: this.formGroup.get('displayName').value,
                discoveryendpoint: this.formGroup.get('discoveryendpoint').value,
                clientId: this.formGroup.get('clientid').value,
                clientSecret: this.formGroup.get('clientsecret').value,
                forceIdp: this.formGroup.get('forceIdp').value,
            },
        });
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;
}
