<mat-dialog-content>
    <div>
        <h1 class="!text-xxl !font-bold !text-black !mb-4">Rename property</h1>
        <div class="mb-2">
            <label for="property-name" class="small-input-label" data-name="property-name">Name</label>
            <input
                class="small-input"
                type="text"
                id="property-name"
                name="property-name"
                autocomplete="off"
                cdkFocusInitial
                required
                [formControl]="this.form.controls.name"
                cd-code />
        </div>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" (click)="handleRenameClicked()">
                <mat-icon>edit</mat-icon>
                {{ 'general.ok' | translate }}
            </button>
            <button class="secondary-button button-large" (click)="handleCancelClick()">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-content>
