import { BackgroundStyleDto } from '../../../../../interfaces/style/background.style.dto';
import { BorderStyleDto } from '../../../../../interfaces/style/border.style.dto';
import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';
import { MarginStyleDto } from '../../../../../interfaces/style/margin.style.dto';
import { PaddingStyleDto } from '../../../../../interfaces/style/padding.style.dto';

export interface UploaderDropzonePartStyle {
    backgroundStyle: BackgroundStyleDto;

    borderStyle: BorderStyleDto;
    borderTopStyle: BorderStyleDto;
    borderLeftStyle: BorderStyleDto;
    borderRightStyle: BorderStyleDto;
    borderBottomStyle: BorderStyleDto;

    paddingStyle: PaddingStyleDto;
    paddingTopStyle: PaddingStyleDto;
    paddingLeftStyle: PaddingStyleDto;
    paddingRightStyle: PaddingStyleDto;
    paddingBottomStyle: PaddingStyleDto;

    fontStyle: FontStyleDto;

    marginStyle: MarginStyleDto;
    marginTopStyle: MarginStyleDto;
    marginLeftStyle: MarginStyleDto;
    marginRightStyle: MarginStyleDto;
    marginBottomStyle: MarginStyleDto;
}
