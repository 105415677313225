import { Injectable } from '@angular/core';
import { LastUsedApplication, LastUsedApplications } from '../../../app/authenticated/dto/last-used-application.dto';
import { backofficeEnvironment } from '@shared/environment';
import { CompanyDto } from '../../shared/interfaces/company.dto';
import { ApplicationDto } from '../../../app/v2-application/dto/application.dto';

@Injectable()
export class WorkspaceCookieFacade {
    getRecentApplicationsCookie(userId: string): LastUsedApplications {
        const value = '; ' + document.cookie;
        const valueToParse = value.split('; ' + backofficeEnvironment.recentSubWorkspaceCookieName + '-' + userId + '=');
        let json = valueToParse.pop();
        if (json.indexOf(';') > -1) {
            json = json.split(';')[0];
        }
        if (json) {
            return JSON.parse(json, this.reviver);
        } else {
            return { subworkspaces: new Map() };
        }
    }

    getRecentCompaniesCookie(userId: string): string[] {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + backofficeEnvironment.recentWorkspaceCookieName + '-' + userId + '=');

        if (parts.length === 2) {
            // @ts-ignore
            return parts.pop().split(';').shift().split(',');
        }

        return [];
    }

    recentCompanies(userId: string): string[] {
        return this.getRecentCompaniesCookie(userId);
    }

    recentApplications(userId: string, companyId: string): LastUsedApplication[] {
        return this.getRecentApplicationsCookie(userId).subworkspaces.get(companyId) ?? [];
    }

    registerCompany(userId: string, company: CompanyDto): void {
        const { id } = company;
        const lastUsedCompanies: string[] = this.getRecentCompaniesCookie(userId) ?? [];

        const index = lastUsedCompanies.findIndex(c => c !== id);
        let updatedLastUsed = [];
        if (index !== 1) {
            updatedLastUsed = lastUsedCompanies.filter(c => c !== id);
        } else {
            updatedLastUsed = [...lastUsedCompanies];
        }
        updatedLastUsed.unshift(id);

        this.registerRecentCompanies(userId, [...new Set(updatedLastUsed.slice(0, 5))]);
    }

    registerRecentCompanies(userId: string, recentCompanyIds: string[]) {
        const date = new Date();
        const value = recentCompanyIds.toString();

        // Set it expire in 7 days
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        // Set it
        document.cookie =
            backofficeEnvironment.recentWorkspaceCookieName + '-' + userId + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
    }

    registerApplication(userId: string, application: ApplicationDto): void {
        const { id, name, companyId } = application;
        const lastUsedApplications: LastUsedApplication[] = this.recentApplications(userId, companyId);

        const index = lastUsedApplications.findIndex(a => a.id === application.id);
        let updatedLastUsed = [];
        if (index !== -1) {
            updatedLastUsed = lastUsedApplications.filter(a => a.id !== application.id);
        } else {
            updatedLastUsed = [...lastUsedApplications];
        }
        updatedLastUsed.unshift({ id, name });
        this.registerRecentApplications(userId, companyId, updatedLastUsed.slice(0, 5));
    }

    registerRecentApplications(userId: string, companyId: string, lastUsedApplications: LastUsedApplication[]) {
        let lastUsedSubWorkspaceDto: LastUsedApplications = this.getRecentApplicationsCookie(userId);
        if (!lastUsedSubWorkspaceDto) {
            lastUsedSubWorkspaceDto = { subworkspaces: new Map<string, LastUsedApplication[]>() };
        }

        lastUsedSubWorkspaceDto.subworkspaces.set(companyId, lastUsedApplications);

        const date = new Date();
        // Set it expire in 7 days
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        // Set it
        document.cookie =
            backofficeEnvironment.recentSubWorkspaceCookieName +
            '-' +
            userId +
            '=' +
            JSON.stringify(lastUsedSubWorkspaceDto, this.replacer) +
            '; expires=' +
            date.toUTCString() +
            '; path=/';
    }

    removeApplication(userId: string, companyId: string, applicationId: string): void {
        const lastUsedApplications: LastUsedApplication[] = this.getRecentApplicationsCookie(userId).subworkspaces.get(companyId) ?? [];
        const filtered = lastUsedApplications.filter(a => a.id !== applicationId);
        this.registerRecentApplications(userId, companyId, filtered);
    }

    cleanupRecentApplications(userId: string, companyId: string, existingApplications: ApplicationDto[]) {
        const lastUsedApplications: LastUsedApplication[] = this.getRecentApplicationsCookie(userId).subworkspaces.get(companyId) ?? [];
        const existingIds = existingApplications.map(app => app.id);
        const filtered = lastUsedApplications.filter(a => existingIds.includes(a.id));
        this.registerRecentApplications(userId, companyId, filtered);
        const updated: LastUsedApplication[] = this.getRecentApplicationsCookie(userId).subworkspaces.get(companyId) ?? [];
    }

    private reviver(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value);
            }
        }
        return value;
    }

    private replacer(key, value) {
        if (value instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(value.entries()), // or with spread: value: [...value]
            };
        } else {
            return value;
        }
    }
}
