<ul class="paging">
    <li>
        <a (click)="changePageParam(pages[0])" [class.disabled]="currentPage === firstPage"><i class="material-icons">first_page</i></a>
    </li>
    <li>
        <a (click)="changePageParam(pages[currentPage - 1])" [class.disabled]="currentPage === firstPage"
            ><i class="material-icons">chevron_left</i></a
        >
    </li>
    <li class="pages" *ngFor="let page of pages | slice: cappedMinimum : cappedMaximum" [class.active]="currentPage === page.page">
        <a (click)="changePageParam(page)">{{ page.pageRepresentation }}</a>
    </li>
    <li>
        <a (click)="changePageParam(pages[currentPage + 1])" [class.disabled]="currentPage === lastPageIndex"
            ><i class="material-icons">chevron_right</i></a
        >
    </li>
    <li>
        <a (click)="changePageParam(pages[lastPageIndex])" [class.disabled]="currentPage === lastPageIndex"
            ><i class="material-icons">last_page</i></a
        >
    </li>
</ul>
