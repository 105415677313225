import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Data } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/model/data';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const dataAdapter = createEntityAdapter<Data>();

const { selectIds, selectAll, selectTotal, selectEntities } = dataAdapter.getSelectors();

export const dataEditorSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.data)),
    dialogData: createSelector(selectState, state => state.dataDialog.result),
    dialogFilter: createSelector(selectState, state => {
        const { page, maxResults, searchTerm, facets, filterPlugins } = state.dataDialog;
        return {
            page,
            maxResults,
            searchTerm,
            facets,
            filterPlugins,
        };
    }),
    searchTerm: createSelector(selectState, state => state.dataDialog.searchTerm),
    pagination: createSelector(selectState, state => {
        const { page, maxResults } = state.dataDialog;
        return { page, maxResults };
    }),
    facets: createSelector(selectState, state => state.dataDialog.facets),
    filterPlugins: createSelector(selectState, state => state.dataDialog.filterPlugins),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<Data> = selectEntities(state.data);
            if (id in dictionary) {
                return dictionary[id];
            }
            return undefined;
        }),
};
