<codex-border-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_genericPartStyle"
    [allowedTypes]="['normal', 'hover', 'focus', 'active']"
    (wrapperStyleUpdated)="this.genericPartStyleUpdated.emit({ genericPartStyle: this._genericPartStyle })"
    [hoverWrapperStyle]="_genericHoverPartStyle"
    (hoverWrapperStyleUpdated)="this.genericHoverPartStyleUpdated.emit({ genericPartStyle: this._genericHoverPartStyle })"
    [focusWrapperStyle]="_genericFocusPartStyle"
    (focusWrapperStyleUpdated)="this.genericFocusPartStyleUpdated.emit({ genericPartStyle: this._genericFocusPartStyle })"
    [activeWrapperStyle]="_genericActivePartStyle"
    (activeWrapperStyleUpdated)="this.genericActivePartStyleUpdated.emit({ genericPartStyle: this._genericActivePartStyle })">
</codex-border-picker-wrapper>
<codex-padding-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_genericPartStyle"
    [allowedTypes]="['normal', 'hover', 'focus', 'active']"
    (wrapperStyleUpdated)="this.genericPartStyleUpdated.emit({ genericPartStyle: this._genericPartStyle })"
    [hoverWrapperStyle]="_genericHoverPartStyle"
    (hoverWrapperStyleUpdated)="this.genericHoverPartStyleUpdated.emit({ genericPartStyle: this._genericHoverPartStyle })"
    [focusWrapperStyle]="_genericFocusPartStyle"
    (focusWrapperStyleUpdated)="this.genericFocusPartStyleUpdated.emit({ genericPartStyle: this._genericFocusPartStyle })"
    [activeWrapperStyle]="_genericActivePartStyle"
    (activeWrapperStyleUpdated)="this.genericActivePartStyleUpdated.emit({ genericPartStyle: this._genericActivePartStyle })">
</codex-padding-picker-wrapper>
<codex-background-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_genericPartStyle"
    [allowedTypes]="['normal', 'hover', 'focus', 'active']"
    (wrapperStyleUpdated)="this.genericPartStyleUpdated.emit({ genericPartStyle: this._genericPartStyle })"
    [hoverWrapperStyle]="_genericHoverPartStyle"
    (hoverWrapperStyleUpdated)="this.genericHoverPartStyleUpdated.emit({ genericPartStyle: this._genericHoverPartStyle })"
    [focusWrapperStyle]="_genericFocusPartStyle"
    (focusWrapperStyleUpdated)="this.genericFocusPartStyleUpdated.emit({ genericPartStyle: this._genericFocusPartStyle })"
    [activeWrapperStyle]="_genericActivePartStyle"
    (activeWrapperStyleUpdated)="this.genericActivePartStyleUpdated.emit({ genericPartStyle: this._genericActivePartStyle })">
</codex-background-picker-wrapper>
<codex-shadow-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_genericPartStyle"
    [allowedTypes]="['normal', 'hover', 'focus', 'active']"
    (wrapperStyleUpdated)="this.genericPartStyleUpdated.emit({ genericPartStyle: this._genericPartStyle })"
    [hoverWrapperStyle]="_genericHoverPartStyle"
    (hoverWrapperStyleUpdated)="this.genericHoverPartStyleUpdated.emit({ genericPartStyle: this._genericHoverPartStyle })"
    [focusWrapperStyle]="_genericFocusPartStyle"
    (focusWrapperStyleUpdated)="this.genericFocusPartStyleUpdated.emit({ genericPartStyle: this._genericFocusPartStyle })"
    [activeWrapperStyle]="_genericActivePartStyle"
    (activeWrapperStyleUpdated)="this.genericActivePartStyleUpdated.emit({ genericPartStyle: this._genericActivePartStyle })">
</codex-shadow-picker-wrapper>
<codex-cursor-picker-wrapper
    [identifier]="part.id"
    [wrapperStyle]="_genericPartStyle"
    [allowedTypes]="['normal', 'hover', 'focus', 'active']"
    (wrapperStyleUpdated)="this.genericPartStyleUpdated.emit({ genericPartStyle: this._genericPartStyle })"
    [hoverWrapperStyle]="_genericHoverPartStyle"
    (hoverWrapperStyleUpdated)="this.genericHoverPartStyleUpdated.emit({ genericPartStyle: this._genericHoverPartStyle })"
    [focusWrapperStyle]="_genericFocusPartStyle"
    (focusWrapperStyleUpdated)="this.genericFocusPartStyleUpdated.emit({ genericPartStyle: this._genericFocusPartStyle })"
    [activeWrapperStyle]="_genericActivePartStyle"
    (activeWrapperStyleUpdated)="this.genericActivePartStyleUpdated.emit({ genericPartStyle: this._genericActivePartStyle })">
</codex-cursor-picker-wrapper>
