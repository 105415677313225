import { Type } from 'class-transformer';
import { Part } from '../../../part.model';

export class StepperPartStep {
    id?: string;
    code?: string;
    name?: string;
    icon?: string;
    nextButtonLabel?: string;
    saveButtonLabel?: string;
    previousButtonLabel?: string;

    //transient
    @Type(() => Part)
    parts?: Part[];
}
