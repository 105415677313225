<div
    class="color-clicker"
    [showTrigger]="NgxFloatUiTriggers.click"
    [floatUi]="colorPopover"
    [preventOverflow]="false"
    [boundariesElement]="'rootBoundary'"
    [appendTo]="'body'"
    [positionFixed]="true"
    [placement]="NgxFloatUiPlacements.LEFT"
    (onShown)="onShowColorPicker()">
    <span [style.background-color]="color"></span>
</div>
<float-ui-content #colorPopover>
    <span
        [(colorPicker)]="color"
        [style.background]="color"
        [cpToggle]="colorPickerToggle"
        [cpEyeDropper]="true"
        [cpDialogDisplay]="'inline'"
        (colorPickerChange)="onChangeColor($event)">
    </span>
</float-ui-content>
