import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { ApplicationDto } from '../v2-application/dto/application.dto';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { companyUserRightsReducer, companyUserRolesReducer } from './data/authenticated.reducer';
import { RouterState } from './router-store/router.state';
import { routerReducer } from './router-store/router.reducer';
import { contextReducer } from './context/context.reducers';
import { ActionState } from './data/datastate';
import { actionStateReducer } from './data/action.reducer';
import { CompanyUserRightDto } from '../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.user.right.dto';
import { CompanyUserRoleDto } from '../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.user.role.dto';
import { CompanyLanguageDto } from '../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.language.dto';
import { CompanyBillingDto } from '../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.billing.dto';
import {
    selectedCompanyBillingReducer,
    selectedCompanyLanguageReducer,
} from '../../../../../libs/backoffice/feature/company/edit-company/src/lib/statemanagement/reducer/company.reducer';

export interface State {
    data?: {
        authenticationCompanyUserRights?: CompanyUserRightDto[];
        authenticationCompanyUserRoles?: CompanyUserRoleDto[];
        selectedCompanyLanguages?: CompanyLanguageDto[];
        selectedCompanyBilling?: CompanyBillingDto;
        actionState?: ActionState;
    };
    context?: {
        user?: any;
        companies?: CompanyDto[];
        applications?: ApplicationDto[];
        selectedCompany?: CompanyDto;
        selectedApplication?: ApplicationDto;
    };
    router: RouterState;
}

const dataReducers = combineReducers({
    authenticationCompanyUserRights: companyUserRightsReducer,
    authenticationCompanyUserRoles: companyUserRolesReducer,
    selectedCompanyLanguages: selectedCompanyLanguageReducer,
    selectedCompanyBilling: selectedCompanyBillingReducer,
    actionState: actionStateReducer,
});

const containersReducers = combineReducers({});

export const reducerToken = new InjectionToken<ActionReducerMap<State>>('Reducers');

export function getReducers() {
    return {
        data: dataReducers,
        containers: containersReducers,
        router: routerReducer,
        context: contextReducer,
    };
}

export const reducerProvider = [{ provide: reducerToken, useFactory: getReducers }];
