<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSaveConfiguration()" novalidate cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <mat-form-field appearance="outline" data-name="identityprovider-name">
        <mat-label>{{ 'v2.company.edit.identityproviders.openidconnect.input.name' | translate }}</mat-label>
        <input matInput name="name" align="end" required cdkFocusInitial formControlName="name" />
        <mat-error *ngIf="this.formGroup.get('name').touched && this.formGroup.get('name').invalid">{{
            'v2.company.edit.identityproviders.openidconnect.input.name-required' | translate
        }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" data-name="identityprovider-displayName">
        <mat-label>{{ 'v2.company.edit.identityproviders.openidconnect.input.displayName' | translate }}</mat-label>
        <input matInput name="displayName" align="end" required cdkFocusInitial formControlName="displayName" />
        <mat-error *ngIf="this.formGroup.get('displayName').touched && this.formGroup.get('displayName').invalid">{{
            'v2.company.edit.identityproviders.openidconnect.input.displayName-required' | translate
        }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" data-name="identityprovider-discovery-endpoint">
        <mat-label>{{ 'v2.company.edit.identityproviders.openidconnect.input.discovery-endpoint' | translate }}</mat-label>
        <input matInput name="discoveryendpoint" type="url" align="end" required cdkFocusInitial formControlName="discoveryendpoint" />
        <mat-error
            *ngIf="
                formGroup.get('discoveryendpoint').touched &&
                this.formGroup.get('discoveryendpoint').invalid &&
                formGroup.get('discoveryendpoint').hasError('required')
            "
            >{{ 'v2.company.edit.identityproviders.openidconnect.input.discovery-endpoint-required' | translate }}</mat-error
        >
        <mat-error
            *ngIf="
                formGroup.get('discoveryendpoint').touched &&
                formGroup.get('discoveryendpoint').invalid &&
                formGroup.get('discoveryendpoint').hasError('pattern')
            "
            >{{ 'v2.company.edit.identityproviders.openidconnect.input.discovery-endpoint-pattern' | translate }}</mat-error
        >
    </mat-form-field>
    <mat-form-field appearance="outline" data-name="identityprovider-clientid">
        <mat-label>{{ 'v2.company.edit.identityproviders.openidconnect.input.clientid' | translate }}</mat-label>
        <input matInput name="clientid" align="end" required cdkFocusInitial formControlName="clientid" />
        <mat-error *ngIf="this.formGroup.get('clientid').touched && this.formGroup.get('clientid').invalid">{{
            'v2.company.edit.identityproviders.openidconnect.input.clientid-required' | translate
        }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" data-name="identityprovider-clientsecret">
        <mat-label>{{ 'v2.company.edit.identityproviders.openidconnect.input.clientsecret' | translate }}</mat-label>
        <input matInput name="clientsecret" type="password" align="end" required cdkFocusInitial formControlName="clientsecret" />
        <mat-error *ngIf="this.formGroup.get('clientsecret').touched && this.formGroup.get('clientsecret').invalid">{{
            'v2.company.edit.identityproviders.openidconnect.input.clientsecret-required' | translate
        }}</mat-error>
    </mat-form-field>
    <mat-slide-toggle color="primary" formControlName="forceIdp">{{
        'v2.company.edit.identityproviders.openidconnect.input.forceidp' | translate
    }}</mat-slide-toggle>
    <div class="flex justify-end gap-1">
        <button data-name="save" class="h-9" [disabled]="!formGroup.valid" type="submit" color="primary" mat-button mat-raised-button>
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="h-9" data-name="cancel" (click)="this.onCancel()" type="button" mat-button mat-raised-button>
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
