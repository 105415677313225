import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MethodService } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-actions/services/method.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { Page } from '@shared/data-access';
import { DataFormatEditorFacade, LayerService, Part, PartService, TemplateService } from '@backoffice/data-access/editor';
import { TagService } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-actions/services/tag.service';
import { OverviewTagDto } from '../../dto/overview-tag.dto';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application.dto';
import { selectCurrentContext } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { DataFormatService } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-actions/services/data-format.service';
import { ActionCreatedDto } from '../../dto/action-created.dto';
import { LoggerService } from '@backoffice/utils';
import { ActionService } from '@backoffice/editor/data-access/action';
import { selectSelectedCompanyId } from '../../../../../../../feature/company/edit-company/src/lib/statemanagement/selectors/company.selector';

@Injectable({
    providedIn: 'root',
})
export class ActionEditFacade {
    selectedCompanyId$: Observable<string> = this.store.select(selectSelectedCompanyId);

    currentContext$: Observable<{
        userLanguage: string;
        selectedCompany: CompanyDto;
        selectedApplication: ApplicationDto;
    }> = this.store.select(selectCurrentContext);

    constructor(
        private actionService: ActionService,
        private methodService: MethodService,
        private partService: PartService,
        private layerService: LayerService,
        private templateService: TemplateService,
        private dataFormatService: DataFormatService,
        private dataFormatEditorFacade: DataFormatEditorFacade,
        private tagService: TagService,
        private store: Store<ApplicationState>,
        private log: LoggerService
    ) {}

    public getTags(
        keyword: string,
        filters: string[],
        orderBy: string,
        page: number,
        maxResults: number
    ): Observable<Page<OverviewTagDto>> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.tagService.getTags(
                    currentContext.selectedCompany.id,
                    currentContext.selectedApplication.id,
                    keyword,
                    orderBy,
                    filters,
                    page,
                    maxResults
                );
            })
        );
    }

    public getParts(templateId: string): Observable<Part[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.partService.getPartsByTemplate(
                    templateId,
                    currentContext.selectedCompany.id,
                    currentContext.selectedApplication.id
                );
            })
        );
    }

    public createAction(): Observable<ActionCreatedDto> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionService.create({
                    companyId: currentContext.selectedCompany.id,
                    applicationId: currentContext.selectedApplication.id,
                });
            })
        );
    }
}
