import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { authenticatedRoutingModule } from './authenticated.routing.module';
import { AuthenticatedComponent } from './pages/authenticated/authenticated.component';
import { CommonsModule } from '../common/common.module';
import { HttpLoaderInterceptorProvider } from '../common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../common/http/interceptor/http-authentication.interceptor';
import { CodexInfoComponent } from './components/codex-info/codex-info.component';
import { ApplicationOnboardingComponent } from './components/application-onboarding/application-onboarding.component';
import { AddCompanyModule } from '@add-company/add-company.module';
import { V2SharedModule } from '../v2-shared/v2-shared.module';
import { EditApplicationModule } from '../../features/edit-application/edit-application.module';
import { WorkspaceSelectionModule } from '../../features/workspace-selection/workspace-selection.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BackofficeUiAssistantModule } from '@backoffice/ui/assistant';
import { ApplicationVersionFeatureModule } from '@backoffice/feature/application/application-version';
import { BackofficeFeaturePluginsModule } from '../../../../../libs/backoffice/feature/plugins/src/lib/backoffice-feature-plugins.module';
import { BackofficeFeatureApplicationLogsModule } from '../../../../../libs/backoffice/feature/application-logs/src';
import { BackofficeFeatureAuditLogsModule } from '../../../../../libs/backoffice/feature/audit-logs/src';
import { BackofficeFeatureCompanyUsersModule } from '@backoffice/feature/company/users';
import { BackofficeFeatureMarketplaceModule } from '../../../../../libs/backoffice/feature/company/marketplace/src';
import { BackofficeFeatureEditCompanyModule } from '@backoffice/feature/edit-company';
import { TopRightNavigationComponent } from './components/top-right-navigation/top-right-navigation.component';
import { BackofficeFeatureDesignsystemModule } from 'backoffice/feature/designsystem';

@NgModule({
    declarations: [AuthenticatedComponent, CodexInfoComponent, ApplicationOnboardingComponent, TopRightNavigationComponent],
    imports: [
        authenticatedRoutingModule,
        RouterModule,
        CommonsModule,
        AddCompanyModule,
        V2SharedModule,
        EditApplicationModule,
        WorkspaceSelectionModule,
        BrowserAnimationsModule,
        BackofficeUiAssistantModule,
        ApplicationVersionFeatureModule,
        BackofficeFeaturePluginsModule,
        BackofficeFeatureApplicationLogsModule,
        BackofficeFeatureAuditLogsModule,
        BackofficeFeatureCompanyUsersModule,
        BackofficeFeatureMarketplaceModule,
        BackofficeFeatureEditCompanyModule,
        BackofficeFeatureDesignsystemModule,
    ],
    providers: [HttpLoaderInterceptorProvider, HttpErrorInterceptorProvider, HttpAuthenticationInterceptorProvider],
})
export class AuthenticatedModule {}
