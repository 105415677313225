import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { TemplateInheritanceDto } from '../../interfaces/template/template.inheritance.dto';

@Injectable()
export class TemplateInheritanceService {
    constructor(private httpClient: HttpClient) {}

    public getTemplateInheritance(templateId: string, companyId: string, applicationId: string): Observable<TemplateInheritanceDto> {
        const params = new HttpParams().set('templateId', templateId);

        return this.httpClient.get<TemplateInheritanceDto>(
            `${backofficeEnvironment.rest.v2.templateInheritances}company/${companyId}/application/${applicationId}`,
            { params: params }
        );
    }
}
