<label for="degrees" class="small-input-label">{{ label }}</label>
<input
    class="small-input"
    type="text"
    id="degrees"
    name="degrees"
    autocomplete="off"
    [min]="minDegrees"
    [max]="maxDegrees"
    [readonly]="readonly"
    [disabled]="readonly"
    [(ngModel)]="degrees"
    (ngModelChange)="onChangeDegrees($event)" />
