import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../../../store/editor.state';
import {
    clearDataFormatDialogFilter,
    createDataFormatSuccess,
    dataFormatDialogFacetsChanged,
    dataFormatDialogFilterPluginsChanged,
    dataFormatDialogPaginationChanged,
    dataFormatDialogSearchTermChanged,
    deleteDataFormat,
    deleteDataFormats,
    deleteDataFormatsSuccess,
    deleteDataFormatSuccess,
    fetchDataFormatError,
    fetchDataFormatSuccess,
    findDataFormat,
    loadDataFormatDialogData,
    loadDataFormatDialogSuccess,
    updateDataFormat,
    updateDataFormatSuccess,
} from '../actions/dataformat-editor.actions';
import { dataFormatEditorSelectors } from '../selectors/dataformat-editor.selectors';
import { closeTab, closeTabs, registerTab, updateTab } from '../../../store/actions/editor.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { DataFormat } from '../../models/data-format';
import { DataformatService, toFilter } from '@backoffice/data-access/editor';
import { DataFormatEditorFacade } from '../facades/data-format-editor.facade';
import { selectApplicationSuccess } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { Page } from '@shared/data-access';
import { OverviewDataFormatDto } from '../../interfaces/overview-data-format.dto';

@Injectable()
export class DataFormatEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly editorFacade: DataFormatEditorFacade,
        private readonly dataFormatService: DataformatService,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearDataFormatDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDataFormats),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.dataFormatService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteDataFormatsSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDataFormatsSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'data-format' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDataFormat),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.dataFormatService.delete(id, companyId, applicationId).pipe(map(() => deleteDataFormatSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findDataFormat),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(dataFormatEditorSelectors.byId(id)),
            ]),
            filter(([_dataformat, company, application, detail]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }, detail]) => {
                if (detail) {
                    return of(fetchDataFormatSuccess({ dataFormat: detail }));
                } else {
                    return this.dataFormatService.findById(id, companyId, applicationId).pipe(
                        map(result => fetchDataFormatSuccess({ dataFormat: result })),
                        catchError(() => of(fetchDataFormatError({ dataFormat: DataFormat.createDeleted(id) })))
                    );
                }
            })
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteDataFormatsSuccess,
                loadDataFormatDialogData,
                dataFormatDialogPaginationChanged,
                dataFormatDialogSearchTermChanged,
                dataFormatDialogFacetsChanged,
                dataFormatDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.dataFormatService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewDataFormatDto>) => loadDataFormatDialogSuccess({ data }))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDataFormat),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ dataFormat, openTab }, { id: companyId }, { id: applicationId }]) =>
                this.dataFormatService
                    .update(dataFormat, companyId, applicationId)
                    .pipe(map(() => updateDataFormatSuccess({ id: dataFormat.id, dataFormat, openTab })))
            )
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDataFormatSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'data-format' }))
        )
    );

    openSnack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateDataFormatSuccess),
                tap(() => {
                    this.snackBar.open(this.translate.instant('v2.data-format.edit.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createDataFormatSuccess, updateDataFormatSuccess),
            filter(({ id, openTab }) => openTab),
            map(({ id }) => registerTab({ definition: { type: 'data-format', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDataFormatSuccess, fetchDataFormatSuccess),
            map(({ dataFormat }) => {
                const { id: typeId, name, iconName: icon } = dataFormat;
                return updateTab({ definition: { type: 'data-format', typeId, name, icon } });
            })
        )
    );
}
