import { ContainerStyleDto } from '../../../../../interfaces/style/container.style.dto';
import { BackgroundStyleDto } from '../../../../../interfaces/style/background.style.dto';
import { ColorV2 } from '@shared/data-access';
import { FontStyle } from '../../../../../designsystem/models/font/font-style.model';
import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';

export interface TabsStyle extends ContainerStyleDto {
    // DEPRECATED
    tabColor?: string;
    // DEPRECATED
    tabActiveAccentColor?: string;

    tabPanelBackground?: BackgroundStyleDto | null;
    tabBackground?: BackgroundStyleDto | null;
    tabFontStyle?: FontStyleDto | null;
    tabActiveAccentColorV2?: ColorV2;
}
