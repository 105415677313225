import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppFacade } from '@core/facades/app.facade';
import { filter, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { LoggerService } from '@backoffice/utils';

@Injectable()
export class RoleGuard extends KeycloakAuthGuard {
    constructor(
        private readonly appFacade: AppFacade,
        private readonly logger: LoggerService,
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService
    ) {
        super(router, keycloak);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        this.logger.info('Checking role guard ...');
        const roles: string[] = route.data.roles ?? [];
        return this.appFacade.userRights
            .pipe(
                filter(rights => !!rights && !!rights.calculatedRightNames),
                map(rights => {
                    const { calculatedRightNames } = rights;
                    if (roles.length === 0) {
                        return true;
                    } else {
                        return calculatedRightNames.some(r => roles.includes(r));
                    }
                }),
                take(1)
            )
            .toPromise()
            .then(value => {
                if (!!value) {
                    return super.canActivate(route, state);
                } else {
                    return Promise.resolve(this.router.createUrlTree(['/404']));
                }
            });
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return Promise.resolve(true);
    }
}
