import { Injectable } from '@angular/core';
import { filter, Observable, switchMap, take } from 'rxjs';
import { CurrentContext } from '@backoffice/utils';
import { selectCurrentContext } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { Store } from '@ngrx/store';
import { EditorState } from '../../../../editor/src/lib/store/editor.state';
import { ApplicationState } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { GenerateTestDataResponse } from '../dto/generate-test-data-response.dto';
import { AssistantService } from '../services/assistant.service';
import { GenerateDataFormatResponse } from '../dto/generate-data-format-response.dto';

@Injectable()
export class AssistantFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.applicationStateStore.select(selectCurrentContext);

    constructor(
        private readonly store: Store<EditorState>,
        private readonly applicationStateStore: Store<ApplicationState>,
        private readonly assistantService: AssistantService
    ) {}

    public generateTestData(prompt: string, dataFormatIds: string[]): Observable<GenerateTestDataResponse> {
        return this.currentContext$.pipe(
            take(1),
            filter(context => !!context),
            switchMap(context =>
                this.assistantService.generateTestData(context.selectedCompany.id, context.selectedApplication.id, prompt, dataFormatIds)
            )
        );
    }

    public generateDataFormat(prompt: string): Observable<GenerateDataFormatResponse> {
        return this.currentContext$.pipe(
            take(1),
            filter(context => !!context),
            switchMap(context =>
                this.assistantService.generateDataFormat(context.selectedCompany.id, context.selectedApplication.id, prompt)
            )
        );
    }

    public generateApis(dataFormatIds: string[], prompt: string): Observable<any> {
        return this.currentContext$.pipe(
            take(1),
            filter(context => !!context),
            switchMap(context =>
                this.assistantService.generateApis(context.selectedCompany.id, context.selectedApplication.id, prompt, dataFormatIds)
            )
        );
    }

    public generateRootPage(type: string, prompt: string): Observable<any> {
        return this.currentContext$.pipe(
            take(1),
            filter(context => !!context),
            switchMap(context =>
                this.assistantService.generateRootPage(context.selectedCompany.id, context.selectedApplication.id, prompt, type)
            )
        );
    }

    public generateGlobalTheme(prompt: string): Observable<any> {
        return this.currentContext$.pipe(
            take(1),
            filter(context => !!context),
            switchMap(context =>
                this.assistantService.generateGlobalTheme(context.selectedCompany.id, context.selectedApplication.id, prompt, '')
            )
        );
    }
}
