export class Layer {
    public readonly id: string;
    public readonly applicationId: string;
    public name: string;
    public sequence: number;
    public hidden: boolean;

    public languageCode: string;
    public languageSyncStatus: any;
    public inheritFromLayerId: string;
    public copiedFromLayerId: string;
    public hiddenOnTemplate: boolean;

    setSequence(sequence: number) {
        if (sequence > 0) {
            this.sequence = sequence;
        }
    }

    isValid() {
        return true;
    }
}
