import { GenericStyleDto } from '../../../../../interfaces/style/generic-style.dto';
import { ColorV2 } from '@shared/data-access';
import { generateColorStyleV2 } from '../../../../../interfaces/style/color.style';

export interface ImagePartGenericStyleDto extends GenericStyleDto {
    objectFit?: string;
    colorV2: ColorV2;
}

export function generateImageStyleWithVars(type: string, imageStyle: ImagePartGenericStyleDto) {
    let style = '';
    if (imageStyle?.objectFit) {
        style += '--image' + type + '-object-fit:' + imageStyle.objectFit + ';';
    }
    if (imageStyle?.colorV2) {
        style += '--image' + type + '-color:' + generateColorStyleV2(imageStyle.colorV2) + ';';
    }
    return style;
}

export function generateImageStyle(objectFit: string) {
    let style = '';
    if (objectFit) {
        style += 'object-fit:' + objectFit + ';';
    }
    return style;
}
