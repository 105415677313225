import { Argument } from '../../arguments/argument';
import { Parameter } from '../../parameters/parameter';

export class NextInvocation {
    public id?: string;
    public invocationId: string;
    public conditional?: string;
    conditionalLeftArgumentInputSelectionType?: string[];
    conditionalRightArgumentInputSelectionType?: string[];
    points: { x: number; y: number }[] = [];

    static determineExpressionParts(expression: string) {
        const expressionMatches: string[] = expression.match(/(.*)(== NULL|!= NULL|==|!=|<=|>=|<|>|!~|~=)(?![^{]*})(.*)/m);
        const leftArgument = new Argument();
        const rightArgument = new Argument();
        leftArgument.parameter = new Parameter();
        rightArgument.parameter = new Parameter();
        leftArgument.parameter.type = 'CONDITIONAL';
        rightArgument.parameter.type = 'CONDITIONAL';
        let equalityOperator = '==';

        if (Boolean(expressionMatches)) {
            if (expressionMatches.length > 1) {
                leftArgument.value = expressionMatches[1];
            } else {
                leftArgument.value = '';
            }
            if (expressionMatches.length > 2) {
                equalityOperator = expressionMatches[2];
            } else {
                equalityOperator = '==';
            }
            if (expressionMatches.length > 3) {
                rightArgument.value = expressionMatches[3];
            } else {
                rightArgument.value = '';
            }
        }
        return { leftArgument, rightArgument, equalityOperator };
    }

    determineConditionalExpressions() {
        if (!!this.conditional || this.conditional === '') {
            let expressions = this.conditional.split(/(?![^)(?![^{]*})&&|\|\|(?![^\[]*\])/gm);
            if (!!expressions) {
                expressions = expressions.map(str => str.trim());
            } else {
                expressions = new Array();
                expressions.push('');
            }
            let expressionOperators = this.conditional.match(/(?![^)(?![^{]*})(&&|\|\|)(?![^\[]*\])/gm);
            if (Boolean(expressionOperators)) {
                // @ts-ignore
                expressionOperators = expressionOperators.map(str => str.trim());
            } else {
                // @ts-ignore
                expressionOperators = new Array();
            }

            if (expressions.length === 0) {
                expressions.push('');
            }
            return {
                expressions,
                expressionOperators,
            };
        }
    }
}
