import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';
import { GenericStyleV2Dto } from '../../../../../interfaces/style/generic-style-v2.dto';
import { GenericMarginStyleDto } from '../../../../../interfaces/style/generic-margin.style.dto';
import { ColorV2 } from '@shared/data-access';

export interface SliderFieldStyle extends GenericStyleV2Dto {
    labelFontStyle: FontStyleDto;
    labelMarginStyle: GenericMarginStyleDto;

    // deprecated
    sliderColor: string;

    sliderColorV2: ColorV2;
}
