export interface PartStyleReferenceDto {
    reference?: Reference;
    constant?: Constant;
}

interface Reference {
    selectorId: SelectorId;
    variable: Variable;
}

interface Constant {
    value: string;
    unit?: string;
}

export interface SelectorId {
    value: string;
}

interface Variable {
    name: string;
    css: string;
}

export function generatePartStyleReferenceCss(partyStyle: PartStyleReferenceDto | undefined) {
    let style = '';
    if (partyStyle?.reference) {
        style += partyStyle.reference.variable.css;
    } else if (partyStyle?.constant) {
        let unit = '';
        if (partyStyle.constant.unit) {
            unit = partyStyle.constant.unit === 'pixels' ? 'px' : partyStyle.constant.unit;
        }
        style += partyStyle.constant.value + unit;
    }
    return style;
}
