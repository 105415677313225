import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { JobEditorState } from '../state/job-editor.state';
import {
    clearJobDialogData,
    clearJobDialogFilter,
    deleteJobSuccess,
    fetchJobSuccess,
    jobDialogFacetsChanged,
    jobDialogFilterPluginsChanged,
    jobDialogPaginationChanged,
    jobDialogSearchTermChanged,
    loadJobDialogSuccess,
    updateJobSuccess,
} from '../actions/job-editor.actions';
import { Job } from '../../dto/job.dto';
import { SelectedFacets } from '../../dto/overview/facets.dto';
import { Page } from '@shared/data-access';
import { JobDialogState } from '../state/job-dialog.state';
import { OverviewJobDto } from '../../dto/overview/overview-job.dto';
import {
    templateDialogFacetsChanged,
    templateDialogFilterPluginsChanged,
    templateDialogSearchTermChanged,
} from '../actions/templates-editor.actions';

const adapterAction = createEntityAdapter<Job>();
export const jobInitialState: JobEditorState = adapterAction.getInitialState();
export const jobDialogState: JobDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const jobReducer = (initialData: JobEditorState) =>
    createReducer(
        initialData,
        on(fetchJobSuccess, (state, { job }) => adapterAction.addOne(job, state)),
        on(deleteJobSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(updateJobSuccess, (state, { job }) => adapterAction.updateOne({ id: job.id, changes: job }, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );

const facetsReducer = (initialData: SelectedFacets) =>
    createReducer(
        initialData,
        on(jobDialogFacetsChanged, (state, { facets }) => facets),
        on(clearJobDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(jobDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearJobDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(jobDialogPaginationChanged, (_, { page }) => page),
        on(jobDialogSearchTermChanged, () => 0),
        on(clearJobDialogFilter, () => 0),
        on(jobDialogFacetsChanged, () => 0),
        on(jobDialogFilterPluginsChanged, () => 0),
        on(jobDialogSearchTermChanged, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(jobDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearJobDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(jobDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearJobDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewJobDto> | undefined) =>
    createReducer(
        initialData,
        on(loadJobDialogSuccess, (_, { data }) => data),
        on(clearJobDialogData, () => undefined)
    );

export const jobDialogReducers = combineReducers({
    facets: facetsReducer(jobDialogState.facets),
    maxResults: maxResultsReducer(jobDialogState.maxResults),
    page: pageReducer(jobDialogState.page),
    result: resultReducer(jobDialogState.result),
    searchTerm: searchTermReducer(jobDialogState.searchTerm),
    filterPlugins: filterPluginsReducer(jobDialogState.filterPlugins),
});
