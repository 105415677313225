<mat-dialog-content class="!p-4 scrollbar-primary scrollbar">
    <ng-container *ngIf="{ currentContext: billingFacade.currentContext$ | async } as observables">
        <div class="flex flex-col justify-center items-center text-center gap-2">
            <h1>Wow! Looks like your workspace is growing faster than expected!</h1>
            <img class="max-w-[350px]" src="/src/images/theme/resource-warning.gif" />
            <span class="text-text_2"
                >You are hitting your resource limit. If you surpass your resources applications can stop working. Check your usage to add
                more resources to your workspace.</span
            >
            <button class="primary-button button-medium" (click)="checkUsage()" color="primary" mat-button mat-raised-button>
                <mat-icon>fact_check</mat-icon>
                {{ 'v2.billing.usage.alert.check' | translate }}
            </button>
        </div>
    </ng-container>
</mat-dialog-content>
