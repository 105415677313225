import { FontSize } from '../models/font/font-size.model';
import { FontWeight } from '../models/font/font-weight.model';
import { LetterSpacing } from '../models/font/letter-spacing.model';
import { FontFamily } from '../models/font/font-family.model';
import { FontStyle } from '../models/font/font-style.model';
import { ColorV2 } from '../models/color/colorv2.model';

export interface UpdateTypographyDto {
    applicationId: string;
    companyId: string;
    designSystemId: string;
    color: ColorV2;
    fontFamily: FontFamily;
    fontSize: FontSize;
    fontWeight: FontWeight;
    italic: FontStyle;
    letterSpacing: LetterSpacing;
    type:
        | 'display_large'
        | 'display_medium'
        | 'display_small'
        | 'headline_large'
        | 'headline_medium'
        | 'headline_small'
        | 'title_large'
        | 'title_medium'
        | 'title_small'
        | 'label_large'
        | 'label_medium'
        | 'label_small'
        | 'body_large'
        | 'body_medium'
        | 'body_small';
    unit: string;
}
