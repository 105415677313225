import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { BorderStyleDto, ContainerStyleDto, FontStyleDto, InputPartStyleDto, Part } from '@backoffice/data-access/editor';
import { GenericStyleComponent } from '../generic-style-component/generic-style.component';
import { GenericStyleDto } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/interfaces/style/generic-style.dto';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-generic-input-field-part-style',
    templateUrl: './generic-input-field-part-style.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericInputFieldPartStyleComponent implements AfterViewInit {
    @Input()
    part: Part;

    type: string = 'normal';

    @Input()
    set inputPartStyle(inputPartStyle: InputPartStyleDto) {
        if (inputPartStyle) {
            this._inputPartStyle = inputPartStyle;
        } else {
            this._inputPartStyle = this.defaultStyle() as InputPartStyleDto;
        }
    }

    _inputPartStyle: InputPartStyleDto;

    @Output()
    inputPartStyleUpdated: EventEmitter<{ inputPartStyle: InputPartStyleDto }> = new EventEmitter<{
        inputPartStyle: InputPartStyleDto;
    }>();

    @Input()
    set hoverInputPartStyle(inputPartStyle: InputPartStyleDto) {
        if (inputPartStyle) {
            this._hoverInputPartStyle = inputPartStyle;
        } else {
            this._hoverInputPartStyle = this.defaultStyle() as InputPartStyleDto;
        }
    }

    _hoverInputPartStyle: InputPartStyleDto;

    @Output()
    hoverInputPartStyleUpdated: EventEmitter<{ inputPartStyle: InputPartStyleDto }> = new EventEmitter<{
        inputPartStyle: InputPartStyleDto;
    }>();

    @Input()
    set focusInputPartStyle(inputPartStyle: InputPartStyleDto) {
        if (inputPartStyle) {
            this._focusInputPartStyle = inputPartStyle;
        } else {
            this._focusInputPartStyle = this.defaultStyle() as InputPartStyleDto;
        }
    }

    _focusInputPartStyle: InputPartStyleDto;

    @Output()
    focusInputPartStyleUpdated: EventEmitter<{ inputPartStyle: InputPartStyleDto }> = new EventEmitter<{
        inputPartStyle: InputPartStyleDto;
    }>();

    constructor(public changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        setTimeout(() => initFlowbite());
    }

    onChangeInputBorder($event: {
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }) {
        this._inputPartStyle.inputBorderStyle = $event.border.borderStyle;
        this.inputPartStyleUpdated.emit({ inputPartStyle: this._inputPartStyle });
    }

    onChangeFocusInputBorder($event: {
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }) {
        this._focusInputPartStyle.inputBorderStyle = $event.border.borderStyle;
        this.focusInputPartStyleUpdated.emit({ inputPartStyle: this._focusInputPartStyle });
    }

    onChangeHoverInputBorder($event: {
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }) {
        this._hoverInputPartStyle.inputBorderStyle = $event.border.borderStyle;
        this.hoverInputPartStyleUpdated.emit({ inputPartStyle: this._hoverInputPartStyle });
    }

    resetInputBorderStyle() {
        if (this.type === 'normal') {
            this._inputPartStyle.inputBorderStyle = null;
            this.inputPartStyleUpdated.emit({ inputPartStyle: this._inputPartStyle });
        } else if (this.type === 'focus') {
            this._focusInputPartStyle.inputBorderStyle = null;
            this.focusInputPartStyleUpdated.emit({ inputPartStyle: this._focusInputPartStyle });
        } else if (this.type === 'hover') {
            this._hoverInputPartStyle.inputBorderStyle = null;
            this.hoverInputPartStyleUpdated.emit({ inputPartStyle: this._hoverInputPartStyle });
        }
    }

    getPartStyle(): GenericStyleDto {
        return this._inputPartStyle;
    }

    defaultStyle(): GenericStyleDto {
        return {} as GenericStyleDto;
    }
}
