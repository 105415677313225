import { Category, Script, Variant } from './google-font.types';

export class Font {
    // Fields used by font-picker
    family: string;
    id: string;
    category: Category;
    subsets: Script[]; // Called "subsets" in Google Fonts API
    variants: Variant[];

    // Other fields specified by the API
    kind?: string; // Usually "webfonts#webfont"
    version?: string; // Version number
    lastModified?: string; // Date of last modification (yyyy-MM-dd)
    files?: Record<Variant, string>; // Font file for each variant

    constructor(base?: Partial<Font>) {
        Object.assign(this, base);
        this.id = this.calculateId(this.family);
        if (!this.subsets) {
            this.subsets = [];
        }
        if (!this.variants) {
            this.variants = [];
        }
    }

    private calculateId(family: string): string {
        return `${family.replace(/\s+/g, '-').toLowerCase()}`;
    }
}
