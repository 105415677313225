import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger as MatMenuTrigger } from '@angular/material/menu';
import { UserDto } from '../../../../../../../feature/company/edit-company/src/lib/dto/deprecated/user.dto';
import { Subscription } from 'rxjs';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { backofficeEnvironment } from '@shared/environment';
import { take } from 'rxjs/operators';
import { InviteUserComponent } from '../../../../../../../feature/company/edit-company/src/lib/components/invite-user/invite-user.component';
import { Page } from '@shared/data-access';
import { EditDeveloperPermissionsComponent } from '../edit-developer-permissions/edit-developer-permissions.component';
import { BillingPackage } from '@billing/dto/billingpackage';

@Component({
    selector: 'codex-developer-overview',
    templateUrl: './developer-overview.component.html',
})
export class DeveloperOverviewComponent implements OnDestroy {
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    @Input() developers: Page<UserDto>;
    @Input() billing: BillingPackage;

    @Output() userInvited = new EventEmitter();

    readonly displayedColumns: string[] = ['email', 'firstname', 'lastname', 'actions'];

    private readonly _subscriptions = new Subscription();

    constructor(private readonly dialog: MatDialog) {}

    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    openRolesAndPermissionsDialog(userId: string) {
        const dialogRef = this.dialog.open(
            EditDeveloperPermissionsComponent,
            Object.assign(
                {
                    restoreFocus: false,
                    data: {
                        userId,
                    },
                },
                backofficeEnvironment.dialogConfig.big
            )
        );
    }

    openInviteUserDialog() {
        const dialogRef = this.dialog.open(InviteUserComponent, backofficeEnvironment.dialogConfig.normal);
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(mail => {
                if (mail) {
                    this.userInvited.emit(mail);
                }
            });
    }
}
