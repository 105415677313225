import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { filter, Observable, switchMap, take } from 'rxjs';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { CurrentContext } from '@backoffice/utils';
import { Page } from '@shared/data-access';
import { ApplicationVersion } from '../models/application-version.model';
import { ApplicationVersionService } from '../services/application-version.service';
import { CreateApplicationVersionDto } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/create-application-version.dto';
import { ApplicationVersionCreatedDto } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application-version-created.dto';

@Injectable()
export class ApplicationVersionFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.store.select(selectCurrentContext);

    constructor(
        private readonly store: Store<ApplicationState>,
        private readonly applicationVersionService: ApplicationVersionService
    ) {}

    findAll(page: number, maxResults: number): Observable<Page<ApplicationVersion>> {
        return this.currentContext$.pipe(
            filter((context: CurrentContext | undefined): context is CurrentContext => !!this.currentContext$),
            take(1),
            switchMap(({ selectedApplication, selectedCompany }) =>
                this.applicationVersionService.findAll(selectedApplication.id, selectedCompany.id, {
                    page,
                    maxResults,
                })
            )
        );
    }

    findFull(id: string): Observable<ApplicationVersion> {
        return this.currentContext$.pipe(
            filter((context: CurrentContext | undefined): context is CurrentContext => !!this.currentContext$),
            take(1),
            switchMap(({ selectedApplication, selectedCompany }) =>
                this.applicationVersionService.findFull(id, selectedApplication.id, selectedCompany.id)
            )
        );
    }

    findById(id: string): Observable<ApplicationVersion> {
        return this.currentContext$.pipe(
            filter((context: CurrentContext | undefined): context is CurrentContext => !!this.currentContext$),
            take(1),
            switchMap(({ selectedApplication, selectedCompany }) =>
                this.applicationVersionService.findById(id, selectedApplication.id, selectedCompany.id)
            )
        );
    }

    create(dto: CreateApplicationVersionDto): Observable<ApplicationVersionCreatedDto> {
        return this.currentContext$.pipe(
            filter((context: CurrentContext | undefined): context is CurrentContext => !!this.currentContext$),
            take(1),
            switchMap(({ selectedApplication, selectedCompany }) =>
                this.applicationVersionService.create(selectedApplication.id, selectedCompany.id, {
                    ...dto,
                    applicationId: selectedApplication.id,
                })
            )
        );
    }

    createDev(): Observable<ApplicationVersionCreatedDto> {
        return this.currentContext$.pipe(
            filter((context: CurrentContext | undefined): context is CurrentContext => !!this.currentContext$),
            take(1),
            switchMap(({ selectedApplication, selectedCompany }) =>
                this.applicationVersionService.create(selectedApplication.id, selectedCompany.id, {
                    name: '_development',
                    description: 'Development version',
                    applicationId: selectedApplication.id,
                    overwriteEnabled: true,
                })
            )
        );
    }

    development(): Observable<ApplicationVersion> {
        return this.currentContext$.pipe(
            filter((context: CurrentContext | undefined): context is CurrentContext => !!this.currentContext$),
            take(1),
            switchMap(({ selectedApplication, selectedCompany }) =>
                this.applicationVersionService.findDevelopment(selectedApplication.id, selectedCompany.id)
            )
        );
    }

    compare(sourceVersionId: string, currentVersionId: string): Observable<any> {
        return this.currentContext$.pipe(
            filter((context: CurrentContext | undefined): context is CurrentContext => !!this.currentContext$),
            take(1),
            switchMap(({ selectedApplication, selectedCompany }) =>
                this.applicationVersionService.compare(selectedApplication.id, selectedCompany.id, sourceVersionId, currentVersionId)
            )
        );
    }

    update(dto: ApplicationVersion): Observable<void> {
        return this.currentContext$.pipe(
            filter((context: CurrentContext | undefined): context is CurrentContext => !!this.currentContext$),
            take(1),
            switchMap(({ selectedApplication, selectedCompany }) =>
                this.applicationVersionService.update(selectedApplication.id, selectedCompany.id, dto)
            )
        );
    }
}
