import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState, routerStateKey } from './router.state';

const selectState = createFeatureSelector<RouterState>(routerStateKey);

export const routerSelectors = {
    params: createSelector(selectState, state => state.route?.params),
    queryParams: createSelector(selectState, state => state.route?.queryParams),
    companyId: createSelector(selectState, state => state.route?.params?.companyId),
    selectedApplication: createSelector(selectState, state => state.route?.params?.applicationId),
};
