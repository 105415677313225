import { CompanyDto } from '@shared/interfaces/company.dto';
import {
    CONTEXT_SET_COMPANIES_SUCCESS,
    DATA_COMPANY_ADD_USAGE_TO_BILLING,
    DATA_COMPANY_SET_SEEN_COMPANY_BILLING_WARNING,
    DATA_COMPANY_SET_SELECTED_COMPANY,
    DATA_COMPANY_SET_SELECTED_COMPANY_BILLING,
    DATA_COMPANY_SET_SELECTED_COMPANY_LANGUAGES,
} from '../actioncreator/company.actioncreator';
import { CompanyLanguageDto } from '../../dto/deprecated/company.language.dto';
import { CompanyBillingDto } from '../../dto/deprecated/company.billing.dto';
import { PayloadAction } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/statemanagement/action/payload.action';
import { DataMapState } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/datastate';

export function selectedCompanyReducer(state: CompanyDto, action: PayloadAction = null): DataMapState {
    switch (action.type) {
        case DATA_COMPANY_SET_SELECTED_COMPANY:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
}

export function selectedCompanyBillingReducer(state: CompanyBillingDto, action: PayloadAction = null): DataMapState {
    switch (action.type) {
        case DATA_COMPANY_SET_SELECTED_COMPANY_BILLING:
            return action.payload;
        case DATA_COMPANY_SET_SEEN_COMPANY_BILLING_WARNING:
            state.seenWarning = true;
            return { ...state };
        case DATA_COMPANY_ADD_USAGE_TO_BILLING:
            if (state) {
                if (action.payload === 'suppliers') {
                    state.supplierAmount++;
                    return { ...state };
                } else if (action.payload === 'users') {
                    state.userAmount++;
                    return { ...state };
                } else if (action.payload === 'incident') {
                    state.incidentAmount++;
                    return { ...state };
                } else if (action.payload === 'inventory') {
                    state.inventoryAmount++;
                    return { ...state };
                } else if (action.payload === 'verwerking') {
                    state.processingActivityAmount++;
                    return { ...state };
                } else if (action.payload === 'policy') {
                    state.policyAmount++;
                    return { ...state };
                } else if (action.payload === 'assessment-auto') {
                    state.assessmentAutoAmount++;
                    return { ...state };
                } else if (action.payload === 'assessment-manual') {
                    state.assessmentManAmount++;
                    return { ...state };
                } else if (action.payload === 'dsar') {
                    state.dsarAmount++;
                    return { ...state };
                }
            }
            return { ...state };
        default:
            return state;
    }
}

export function selectedCompanyLanguageReducer(state: CompanyLanguageDto[], action: PayloadAction = null): DataMapState {
    switch (action.type) {
        case DATA_COMPANY_SET_SELECTED_COMPANY_LANGUAGES:
            return action.payload;
        default:
            return state;
    }
}

export function companyReducer(state: CompanyDto[] = null, action: PayloadAction = null): DataMapState {
    switch (action.type) {
        case CONTEXT_SET_COMPANIES_SUCCESS:
            return action.payload ? [...action.payload] : null;
        default:
            return state;
    }
}
