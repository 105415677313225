import { NgModule } from '@angular/core';
import { StringTypeSettingsComponent } from './components/json-schema/properties/string-type-settings/string-type-settings.component';
import { NumberTypeSettingsComponent } from './components/json-schema/properties/number-type-settings/number-type-settings.component';
import { ObjectTypeSettingsComponent } from './components/json-schema/properties/object-type-settings/object-type-settings.component';
import { ArrayTypeSettingsComponent } from './components/json-schema/properties/array-type-settings/array-type-settings.component';
import { BooleanTypeSettingsComponent } from './components/json-schema/properties/boolean-type-settings/boolean-type-settings.component';
import { IntegerTypeSettingsComponent } from './components/json-schema/properties/integer-type-settings/integer-type-settings.component';
import { JsonSchemaPropertyComponent } from './components/json-schema/json-schema-property/json-schema-property.component';
import { AddPropertyDialogComponent } from './components/json-schema/add-property-dialog/add-property-dialog.component';
import { AddItemDialogComponent } from './components/json-schema/add-item-dialog/add-item-dialog.component';
import { JsonSchemaArrayItemComponent } from './components/json-schema/json-schema-array-item/json-schema-array-item.component';
import { CommonsModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { V2TagModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-tag/v2-tag.module';
import { V2SharedModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-shared/v2-shared.module';
import { JsonSchemaEditorComponent } from './components/json-schema/json-schema-editor/json-schema-editor.component';
import { RenameProperyDialogComponent } from './components/json-schema/rename-propery-dialog/rename-propery-dialog.component';

@NgModule({
    declarations: [
        JsonSchemaEditorComponent,
        JsonSchemaPropertyComponent,
        StringTypeSettingsComponent,
        AddPropertyDialogComponent,
        NumberTypeSettingsComponent,
        ObjectTypeSettingsComponent,
        ArrayTypeSettingsComponent,
        BooleanTypeSettingsComponent,
        IntegerTypeSettingsComponent,
        AddItemDialogComponent,
        JsonSchemaArrayItemComponent,
        RenameProperyDialogComponent,
    ],
    imports: [CommonsModule, MonacoEditorModule, V2TagModule, V2SharedModule],
    exports: [JsonSchemaEditorComponent],
})
export class DataformatUiModule {}
