import { PartDetail } from '../../../part-detail.model';

export class DateTimeFieldPartDetail extends PartDetail {
    code: string;
    label: string;

    required: boolean;
    requiredMessage: string;

    help: boolean;
    helpInformation: string;

    maxDate: string;
    minDate: string;

    answer: string;

    enabled: boolean;

    isValid(): boolean {
        return this.isCodeValid() && this.isLabelValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isLabelValid(): boolean {
        return !!this.label && this.label !== '';
    }

    getMaxDate(): Date {
        return new Date(this.maxDate);
    }

    getMinDate(): Date {
        return new Date(this.minDate);
    }
}
