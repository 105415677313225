export class Data {
    public readonly id: string;
    public name: string;
    public description: string;
    public iconName: string;
    public companyId: string;
    public applicationId: string;
    public body: any;
    public reference: string;
    public dataFormatId: string;

    public installFromHub: boolean;

    public isValid(): boolean {
        return !!this.companyId && !!this.id && !!this.applicationId && !!this.name && this.name !== '';
    }
}
