import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ProductService } from '@core/services/product.service';
import { map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { LoggerService } from '@backoffice/utils';
import { RouterFacade } from '../../../../../../utils-routing/src';

@Injectable()
export class ProductAdminGuard extends KeycloakAuthGuard {
    constructor(
        private readonly routerFacade: RouterFacade,
        private readonly productService: ProductService,
        private readonly logger: LoggerService,
        protected readonly router: Router,
        protected readonly keycloak: KeycloakService
    ) {
        super(router, keycloak);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        this.logger.info('Checking product admin guard ...');
        return combineLatest([
            this.routerFacade.companyId,
            this.routerFacade.params.pipe(
                map(({ productId }) => productId),
                switchMap(productId => this.productService.findById(productId))
            ),
        ])
            .pipe(take(1))
            .toPromise()
            .then(([companyId, product]) => {
                if (companyId === product.companyId) {
                    return super.canActivate(route, state);
                } else {
                    return Promise.resolve(this.router.createUrlTree(['/404']));
                }
            });
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return Promise.resolve(true);
    }
}
