<div class="flex flex-row items-center gap-2">
    <label for="viewport-input" class="w-[35px] small-input-label !mb-0 !pr-0 p-[2px]" *ngIf="label && !this.indicateResponsiveSettings">{{
        label
    }}</label>
    <label
        for="viewport-input"
        *ngIf="label && this.indicateResponsiveSettings"
        class="small-input-label-with-override-options"
        [class]="'responsive'"
        [showTrigger]="NgxFloatUiTriggers.hover"
        [floatUi]="unitValuePositionPopover"
        [appendTo]="'body'"
        [boundariesElement]="'body'"
        [preventOverflow]="false"
        [positionFixed]="true"
        [applyClass]="'!max-w-[450px]'"
        [placement]="NgxFloatUiPlacements.BOTTOM"
        >{{ label }}</label
    >
    <div
        class="flex w-full max-w-[125px] items-center border-dark-input-border_color rounded-lg border"
        *ngIf="formGoup.controls.unit.value !== 'fit-content' && formGoup.controls.unit.value !== 'grow'">
        <div class="relative grow shrink h-[32px]">
            <input
                id="viewport-input"
                class="small-input-left-part small-number-input h-[32px]"
                type="number"
                [formControl]="formGoup.controls.value"
                required />
        </div>
        <select
            [attr.id]="'select-unit-' + identifier"
            class="small-input-right-part min-w-[55px] h-[32px]"
            [formControl]="formGoup.controls.unit">
            <option *ngFor="let unit of _allowedUnits | keyvalue" [value]="unit.value.value">{{ unit.value.display }}</option>
        </select>
    </div>
    <select
        [attr.id]="'select-unit-' + identifier"
        *ngIf="formGoup.controls.unit.value === 'fit-content'"
        class="small-input min-w-[55px] !w-[125px] h-[32px] !mb-0"
        [formControl]="formGoup.controls.unit">
        <option *ngFor="let unit of _allowedUnits | keyvalue" [value]="unit.value.value">{{ unit.value.display }}</option>
    </select>
    <div class="flex max-w-[125px] items-center" *ngIf="formGoup.controls.unit.value === 'grow'">
        <div class="relative grow shrink h-[32px]">
            <input
                id="viewport-input"
                class="small-input small-number-input h-[32px]"
                type="number"
                [formControl]="formGoup.controls.value"
                required />
        </div>
    </div>
</div>
<float-ui-content #unitValuePositionPopover>
    <div class="popover w-[260px]">
        <ng-container [ngTemplateOutlet]="partPositioningSettings"></ng-container>
    </div>
</float-ui-content>
