<mat-dialog-content class="!p-4">
    <div>
        <label for="type" class="small-input-label">Levels</label>
        <select id="type" class="small-input" [(ngModel)]="type" (ngModelChange)="onChangeType()">
            <option value="CREATE">Create</option>
            <option value="Read">Read</option>
            <option value="Update">Update</option>
            <option value="Delete">Delete</option>
        </select>
        <div class="flex gap-1">
            <div>
                <label for="from-date" class="small-input-label">{{ 'v2.logs.from.date' | translate }}</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <svg
                            class="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20">
                            <path
                                d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                    </div>
                    <input
                        datepicker
                        type="text"
                        class="small-input min-w-[400px] !pl-9"
                        id="from-date"
                        placeholder="Select date"
                        [(ngModel)]="from"
                        (ngModelChange)="onChangeFromDate()" />
                </div>
            </div>
            <div>
                <label for="from-time" class="small-input-label">{{ 'v2.logs.to.date' | translate }}</label>
                <div class="flex">
                    <div class="relative mb-2">
                        <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-2.5 pointer-events-none">
                            <svg
                                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24">
                                <path
                                    fill-rule="evenodd"
                                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                    clip-rule="evenodd" />
                            </svg>
                        </div>
                        <input
                            type="time"
                            id="from-time"
                            class="small-input !mb-0"
                            required
                            [(ngModel)]="fromTime"
                            (ngModelChange)="onChangeFromTime()" />
                    </div>
                </div>
            </div>
            <div>
                <label for="to-date" class="small-input-label">{{ 'v2.logs.from.date' | translate }}</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <svg
                            class="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20">
                            <path
                                d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                        </svg>
                    </div>
                    <input
                        datepicker
                        type="text"
                        class="small-input min-w-[400px] !pl-9"
                        id="to-date"
                        placeholder="Select date"
                        [(ngModel)]="to"
                        (ngModelChange)="onChangeToDate()" />
                </div>
            </div>
            <div>
                <label for="to-time" class="small-input-label">{{ 'v2.logs.to.datetime' | translate }}</label>
                <div class="relative">
                    <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-2.5 pointer-events-none">
                        <svg
                            class="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 24 24">
                            <path
                                fill-rule="evenodd"
                                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <input type="time" id="to-time" class="small-input" required [(ngModel)]="toTime" (ngModelChange)="onChangeToTime()" />
                </div>
            </div>
        </div>
        <app-template-picker class="flex-auto" [value]="templateId" (valueUpdated)="onTemplateChanged($event)"> </app-template-picker>
    </div>
    <div>
        <div class="flex justify-end gap-1 mt-3">
            <button class="primary-button button-large" [disabled]="streaming" (click)="refreshLogs()" type="button">
                <mat-icon>refresh</mat-icon>
                {{ 'v2.logs.refresh.logs' | translate }}
            </button>
            <button *ngIf="streaming" class="secondary-button button-large" (click)="streamLogs()" type="button">
                <mat-icon>stop</mat-icon>
                {{ 'v2.logs.stopstream.logs' | translate }}
            </button>

            <button *ngIf="!streaming" class="secondary-button button-large" (click)="streamLogs()" type="button">
                <mat-icon>refresh</mat-icon>
                {{ 'v2.logs.stream.logs' | translate }}
            </button>
        </div>
    </div>
    <mat-list>
        <mat-list-item *ngIf="(logs | async).length == 0">
            <div class="text-xxs dark:text-white mb-1 whitespace-normal">{{ 'v2.logs.none.found' | translate }}</div>
        </mat-list-item>
        <mat-list-item class="!p-0 border-b !h-fit" *ngFor="let logLine of logs | async">
            <div class="text-xxs flex gap-1 dark:text-white">
                <mat-icon
                    class="!h-5 !w-5 !min-w-5 !text-[20px] cursor-pointer"
                    *ngIf="expandedLogLines.indexOf(logLine.id) > -1"
                    (click)="expandToggle(logLine)">
                    expand_more
                </mat-icon>
                <mat-icon
                    class="!h-5 !w-5 !min-w-5 !text-[20px] cursor-pointer"
                    *ngIf="expandedLogLines.indexOf(logLine.id) === -1"
                    (click)="expandToggle(logLine)">
                    expand_less
                </mat-icon>
                <mat-icon
                    class="!h-5 !w-5 !min-w-5 !text-[20px] cursor-pointer"
                    [ngClass]="logLine.type.toString()"
                    [matTooltip]="getTooltip(logLine.type)"
                    >{{ getIcon(logLine.type) }}
                </mat-icon>
                <mat-icon
                    *ngIf="logLine.templateId"
                    class="!h-5 !w-5 !text-[20px] cursor-pointer"
                    [ngClass]="logLine.actionId"
                    (click)="gotoAction(logLine.actionId)"
                    matTooltip="Goto action"
                    >bolt
                </mat-icon>
                <mat-icon
                    *ngIf="logLine.templateId"
                    class="!h-5 !w-5 !min-w-5 !text-[20px] cursor-pointer"
                    [ngClass]="logLine.actionId"
                    (click)="gotoTemplate(logLine.templateId)"
                    matTooltip="Goto template"
                    >web
                </mat-icon>
                <div class="align-middle">{{ logLine.date | date: 'yyyy-MM-dd HH:mm:ss' }}</div>
                <div class="align-middle">{{ logLine.message | truncate: 150 }}</div>
            </div>
            <div class="text-xxs mb-1 whitespace-normal pl-6" *ngIf="expandedLogLines.indexOf(logLine.id) > -1">{{ logLine.message }}</div>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>
