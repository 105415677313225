import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { AuditLogDto } from '../interfaces/audit-log.dto';

@Injectable({
    providedIn: 'root',
})
export class AuditLogService {
    constructor(private httpClient: HttpClient) {}

    public findAuditLogs(
        companyId: string,
        applicationId: string,
        templateId: string | undefined,
        actionId: string | undefined,
        from: Date,
        to: Date,
        type: string
    ): Observable<AuditLogDto[]> {
        let params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('from', from.toISOString())
            .set('to', to.toISOString());

        if (!!type) {
            params = params.set('type', type);
        }
        if (!!templateId) {
            params = params.set('templateId', templateId);
        }
        if (!!actionId) {
            params = params.set('actionId', actionId);
        }
        return this.httpClient.get<AuditLogDto[]>(`${backofficeEnvironment.rest.v2.auditLogs}`, { params: params });
    }
}
