import { PartDetail } from '../../../part-detail.model';

export class ChipsPartDetail extends PartDetail {
    code: string;
    title?: string;
    icon?: string;

    isValid(): boolean {
        return this.isCodeValid() && this.isTitleValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isTitleValid(): boolean {
        return !!this.title && this.title !== '';
    }

    getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.chips.error.code');
        }
        if (!this.isTitleValid()) {
            validationErrors.push('v2.chips.error.title');
        }
        return validationErrors;
    }
}
