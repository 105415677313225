<h3
    [attr.id]="'style-accordion-' + identifier + '-padding-heading'"
    [attr.data-name]="'style-accordion-padding-header-' + identifier"
    class="p-0">
    <button
        type="button"
        class="accordion-heading"
        [class.accordion-heading-first]="firstPanel"
        [class.accordion-heading-last]="lastPanel"
        [attr.data-accordion-target]="'#style-accordion-' + identifier + '-padding'"
        [attr.aria-controls]="'style-accordion-' + identifier + '-padding'"
        aria-expanded="true">
        <div class="flex gap-1 justify-center items-center w-full">
            <span class="leading-7 grow text-left">{{ title ? title : ('v2.template.style.padding' | translate) }}</span>
            <button
                class="text-button"
                type="button"
                (click)="resetStyle(); $event.stopPropagation()"
                [matTooltip]="'v2.template.style.reset' | translate">
                Reset
            </button>
        </div>
    </button>
</h3>
<div
    [attr.id]="'style-accordion-' + identifier + '-padding'"
    [attr.data-name]="'padding-' + identifier"
    [attr.aria-labelledby]="'style-accordion-' + identifier + '-padding'"
    [class.accordion-panel-last]="lastPanel"
    [class.accordion-panel]="true"
    class="hidden">
    <div class="button-group" role="group">
        <button
            *ngIf="allowedTypes.indexOf('normal') > -1"
            type="button"
            class="button-group-button-left"
            autofocus
            (click)="type = 'normal'"
            [ngClass]="type === 'normal' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.normal' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('hover') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'hover'"
            [ngClass]="type === 'hover' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.hover' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('focus') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'focus'"
            [ngClass]="type === 'focus' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.focus' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('active') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'active'"
            [ngClass]="type === 'active' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.active' | translate }}
        </button>
    </div>
    <codex-padding-picker
        *ngIf="type === 'normal'"
        [paddingStyle]="wrapperStyle?.paddingStyle"
        [paddingTopStyle]="wrapperStyle?.paddingTopStyle"
        [paddingLeftStyle]="wrapperStyle?.paddingLeftStyle"
        [paddingBottomStyle]="wrapperStyle?.paddingBottomStyle"
        [paddingRightStyle]="wrapperStyle?.paddingRightStyle"
        (changePadding)="onChangeStyle($event)"></codex-padding-picker>
    <codex-padding-picker
        *ngIf="type === 'hover'"
        [paddingStyle]="hoverWrapperStyle?.paddingStyle"
        [paddingTopStyle]="hoverWrapperStyle?.paddingTopStyle"
        [paddingLeftStyle]="hoverWrapperStyle?.paddingLeftStyle"
        [paddingBottomStyle]="hoverWrapperStyle?.paddingBottomStyle"
        [paddingRightStyle]="hoverWrapperStyle?.paddingRightStyle"
        (changePadding)="onChangeHoverStyle($event)"></codex-padding-picker>
    <codex-padding-picker
        *ngIf="type === 'focus'"
        [paddingStyle]="focusWrapperStyle?.paddingStyle"
        [paddingTopStyle]="focusWrapperStyle?.paddingTopStyle"
        [paddingLeftStyle]="focusWrapperStyle?.paddingLeftStyle"
        [paddingBottomStyle]="focusWrapperStyle?.paddingBottomStyle"
        [paddingRightStyle]="focusWrapperStyle?.paddingRightStyle"
        (changePadding)="onChangeFocusStyle($event)"></codex-padding-picker>
    <codex-padding-picker
        *ngIf="type === 'active'"
        [paddingStyle]="activeWrapperStyle?.paddingStyle"
        [paddingTopStyle]="activeWrapperStyle?.paddingTopStyle"
        [paddingLeftStyle]="activeWrapperStyle?.paddingLeftStyle"
        [paddingBottomStyle]="activeWrapperStyle?.paddingBottomStyle"
        [paddingRightStyle]="activeWrapperStyle?.paddingRightStyle"
        (changePadding)="onChangeActiveStyle($event)"></codex-padding-picker>
</div>
