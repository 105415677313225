import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { ActionContext } from '../../interfaces/action-context.interface';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const actionAdapter = createEntityAdapter<ActionContext>();

const { selectIds, selectAll, selectTotal, selectEntities } = actionAdapter.getSelectors();

export const actionContextSelectors = {
    outdated: id =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<ActionContext> = selectEntities(state.actionContexts);
            if (id in dictionary) {
                return dictionary[id].scopeOutdated;
            }
            return undefined;
        }),
    scope: id =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<ActionContext> = selectEntities(state.actionContexts);
            if (dictionary && id in dictionary) {
                return dictionary[id].scope;
            }
            return undefined;
        }),
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.actionContexts)),
    byId: id =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<ActionContext> = selectEntities(state.actionContexts);
            if (id in dictionary) {
                return dictionary[id];
            }
            return undefined;
        }),
};
