import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { KeycloakService } from 'keycloak-angular';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    closeEditApplicationDialog,
    closeOnboardingDialog,
    createApplication,
    createApplicationError,
    createApplicationSuccess,
    deleteApplication,
    deleteApplicationError,
    deleteApplicationSuccess,
    editApplication,
    editApplicationError,
    editApplicationSuccess,
    editCompany,
    editCompanyError,
    editCompanySuccess,
    openEditApplicationDialog,
    requestApplications,
    requestApplicationsError,
    requestApplicationsSuccess,
    requestBillingPackage,
    requestBillingPackageError,
    requestBillingPackageSuccess,
    requestCompanies,
    requestCompaniesError,
    requestCompaniesSuccess,
    requestUserInformation,
    requestUserInformationError,
    requestUserInformationSuccess,
    requestUserRights,
    requestUserRightsError,
    requestUserRightsSuccess,
    resetSelectedApplication,
    selectApplication,
    selectApplicationError,
    selectApplicationSuccess,
    selectCompany,
    selectCompanyError,
    selectCompanySuccess,
} from './context.actions';
import { UserContext } from '@shared/interfaces/user-context.interface';
import { RouterFacade } from '../../../../../../libs/backoffice/utils-routing/src/lib/facade/router.facade';
import { CompanyService } from '@core/services/company.service';
import { AppFacade } from '@core/facades/app.facade';
import { of } from 'rxjs';
import { routerNavigated } from '../router-store/router.actions';
import { ApplicationService } from '@core/services/application.service';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { WorkspaceCookieFacade } from '../../../modules/core/facades/workspace-cookie.facade';
import { ApplicationCreatedDto } from '../../v2-application/dto/application-created.dto';
import { Application } from '../../v2-application/model/application';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationUsageAlertComponent } from '../../../features/billing/components/application-usage-alert/application-usage-alert.component';
import { backofficeEnvironment } from '@shared/environment';
import { ApplicationDto } from '../../v2-application/dto/application.dto';
import { ApplicationOnboardingComponent } from '../../authenticated/components/application-onboarding/application-onboarding.component';
import { ApplicationEditComponent } from '../../../features/edit-application/components/application-edit/application-edit.component';
import { CompanyEditComponent } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/page/my-company-edit.component';

@Injectable()
export class Effects {
    requestUserInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestUserInformation),
            switchMap(() =>
                this.keyCloakService
                    .loadUserProfile()
                    .then(keycloak => {
                        const { id, firstName: firstname, lastName: name, email } = keycloak;
                        const info: UserContext = {
                            id: keycloak['userProfileMetadata']['attributes'].id ? keycloak['userProfileMetadata']['attributes'].id[0] : id,
                            firstname,
                            name,
                            language: 'EN',
                            email,
                        };
                        return requestUserInformationSuccess({ info });
                    })
                    .catch(e => {
                        console.log(e);
                        return requestUserInformationError();
                    })
            )
        )
    );

    requestUserInformationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestUserInformationSuccess),
            map(({ info }) => requestCompanies({ userId: info.id }))
        )
    );

    requestCompanies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestCompanies),
            switchMap(({ userId }) =>
                this.companyService.getUserCompanies(userId).pipe(
                    map(companies => {
                        const recentCompanyIds = this.workspaceCookieFacade.recentCompanies(userId);
                        const recentCompanies = this.appFacade.determineRecentCompanies(companies, recentCompanyIds);
                        return requestCompaniesSuccess({ companies, recentCompanies });
                    }),
                    catchError(() => of(requestCompaniesError))
                )
            )
        )
    );

    determineCompanyToBeSelected$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestCompaniesSuccess),
            filter(({ companies }) => companies && companies.length > 0),
            withLatestFrom(this.routerFacade.companyId),
            map(([{ companies, recentCompanies }, companyId]) => {
                if (!companyId) {
                    return selectCompany({ companyId: recentCompanies[0].id });
                } else {
                    const company = companies.find(c => c.id === companyId);
                    return selectCompany({ companyId: company.id });
                }
            })
        )
    );

    selectCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompany),
            withLatestFrom(this.appFacade.companies, this.appFacade.user),
            map(([{ companyId }, companies, user]) => {
                const company = companies.find(c => c.id === companyId);
                if (!!company) {
                    this.workspaceCookieFacade.registerCompany(user.id, company);
                    return selectCompanySuccess({ company });
                } else {
                    return selectCompanyError();
                }
            })
        )
    );

    routeToCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompanySuccess),
            withLatestFrom(this.routerFacade.companyId),
            tap(([{ company }, companyId]) => {
                if (companyId !== company.id) {
                    this.routerFacade.navigate([`/company/${company.id}`]);
                }
            }),
            map(([{ company }, _]) => requestApplications({ companyId: company.id }))
        )
    );

    selectCompanySuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompanySuccess),
            map(({ company }) => requestApplications({ companyId: company.id }))
        )
    );

    editCompany$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editCompany),
            switchMap(({ company }) =>
                this.companyService.update(company).pipe(
                    map(() => editCompanySuccess({ company })),
                    catchError(() => of(editCompanyError()))
                )
            )
        )
    );

    editCompanySuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editCompanySuccess),
                tap(() => {
                    this.snackbar.open(this.translate.instant('my.company.edit.success'), null, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    requestApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestApplications),
            switchMap(({ companyId }) =>
                this.applicationService.getApplications(companyId).pipe(
                    withLatestFrom(this.appFacade.user),
                    map(([{ content }, user]) => {
                        this.workspaceCookieFacade.cleanupRecentApplications(user.id, companyId, content);
                        const lastUsedApps = this.workspaceCookieFacade.recentApplications(user.id, companyId);
                        const recentApps = this.appFacade.determineRecentApplications(content, lastUsedApps);
                        this.workspaceCookieFacade.registerRecentApplications(
                            user.id,
                            companyId,
                            recentApps.map(r => ({
                                id: r.id,
                                name: r.name,
                            }))
                        );
                        return requestApplicationsSuccess({ applications: content, recentApplications: recentApps });
                    }),
                    catchError(error => {
                        return of(requestApplicationsError());
                    })
                )
            )
        )
    );

    determineApplicationToBeSelected$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestApplicationsSuccess),
            filter(({ applications }) => applications && applications.length > 0),
            withLatestFrom(this.routerFacade.applicationId),
            map(([{ applications, recentApplications }, applicationId]) => {
                if (!applicationId) {
                    return selectApplication({ applicationId: recentApplications[0].id });
                } else {
                    const application = applications.find(a => a.id === applicationId);
                    if (!!application) {
                        return selectApplication({ applicationId: application.id });
                    } else {
                        return selectApplication({ applicationId: recentApplications[0].id });
                    }
                }
            })
        )
    );

    selectApplication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplication),
            withLatestFrom(this.appFacade.applications, this.appFacade.user),
            map(([{ applicationId }, applications, user]) => {
                const selectedApplication = applications.find(a => a.id === applicationId);
                if (!!selectedApplication) {
                    this.workspaceCookieFacade.registerApplication(user.id, selectedApplication);
                    return selectApplicationSuccess({ application: selectedApplication });
                } else {
                    return selectApplicationError();
                }
            })
        )
    );

    createApplications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createApplication),
            withLatestFrom(this.appFacade.selectedCompany),
            switchMap(([_, company]) =>
                this.applicationService.createApplication({ companyId: company.id }, company.id).pipe(
                    switchMap((response: ApplicationCreatedDto) => this.applicationService.getApplication(response.id, company.id)),
                    map((application: ApplicationDto) =>
                        createApplicationSuccess({
                            application: application,
                        })
                    ),
                    catchError(() => of(createApplicationError()))
                )
            )
        )
    );

    selectApplicationAfterCreation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createApplicationSuccess),
            map(({ application }) => selectApplication({ applicationId: application.id }))
        )
    );

    openEditApplicationDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(openEditApplicationDialog),
                switchMap(({ application }) =>
                    this.dialog
                        .open(
                            ApplicationEditComponent,
                            Object.assign(
                                {
                                    data: {
                                        application,
                                    },
                                },
                                backofficeEnvironment.dialogConfig.normal
                            )
                        )
                        .afterClosed()
                )
            ),
        { dispatch: false }
    );

    closeEditApplicationDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(closeEditApplicationDialog),
            filter(({ application }) => !!application),
            map(({ action, application }) => {
                const { id: applicationId, companyId } = application;
                if (action === 'UPDATE') {
                    return editApplication({ application });
                } else if (action === 'DELETE') {
                    return deleteApplication({ applicationId, companyId });
                }
            })
        )
    );

    editApplicationAfterCreation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createApplicationSuccess),
            map(({ application }) => openEditApplicationDialog({ application }))
        )
    );

    editApplication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editApplication),
            switchMap(({ application }) =>
                this.applicationService.updateApplication(new Application(application), application.companyId).pipe(
                    map(() => editApplicationSuccess({ application })),
                    catchError(() => of(editApplicationError()))
                )
            )
        )
    );

    editApplicationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(editApplicationSuccess),
                withLatestFrom(this.appFacade.selectedApplication),
                tap(() =>
                    this.snackbar.open(this.translate.instant('v2.application.edit.success'), null, {
                        panelClass: ['success'],
                    })
                ),
                map(([{ application }, selectedApplication]) =>
                    selectedApplication?.id != application.id ? selectApplication({ applicationId: application.id }) : null
                )
            ),
        { dispatch: false }
    );

    deleteApplication$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApplication),
            switchMap(({ applicationId, companyId }) =>
                this.applicationService.deleteApplication(applicationId, companyId).pipe(
                    withLatestFrom(this.appFacade.applications, this.appFacade.user),
                    map(([_, applications, user]) => {
                        const updatedApplicationList = applications.filter(application => application.id !== applicationId);
                        this.workspaceCookieFacade.removeApplication(user.id, companyId, applicationId);
                        const lastUsedApps = this.workspaceCookieFacade.recentApplications(user.id, companyId);
                        const recentApplications = this.appFacade.determineRecentApplications(updatedApplicationList, lastUsedApps);
                        return deleteApplicationSuccess({ applicationId, recentApplications });
                    }),
                    catchError(() => of(deleteApplicationError()))
                )
            )
        )
    );

    deleteApplicationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApplicationSuccess),
            withLatestFrom(this.appFacade.selectedApplication),
            filter(([{ applicationId }, { id }]) => id === applicationId),
            switchMap(([{ applicationId, recentApplications }, { companyId }]) => {
                if (recentApplications.length > 0) {
                    return of(selectApplication({ applicationId: recentApplications[0].id }));
                } else {
                    return of(resetSelectedApplication());
                }
            })
        )
    );

    resetSelectedApp$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(resetSelectedApplication),
                withLatestFrom(this.appFacade.selectedCompany),
                tap(([_, { id }]) => this.routerFacade.navigate([`/company/${id}`]))
            ),
        { dispatch: false }
    );

    routeToApplication$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(selectApplicationSuccess),
                withLatestFrom(this.routerFacade.applicationId),
                tap(([{ application }, applicationId]) => {
                    if (applicationId !== application.id) {
                        this.routerFacade.navigate([`/company/${application.companyId}/application/${application.id}`]);
                    }
                })
            ),
        { dispatch: false }
    );

    companyIdRouteParamChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigated),
            switchMap(() => this.routerFacade.companyId),
            withLatestFrom(this.appFacade.selectedCompany, this.appFacade.companies),
            filter(([companyId, selectedCompany, companies]) => !!companyId && companyId !== selectedCompany?.id && !!companies),
            map(([companyId]) => selectCompany({ companyId }))
        )
    );

    applicationIdRouteParamChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(routerNavigated),
            switchMap(() => this.routerFacade.applicationId),
            withLatestFrom(this.appFacade.selectedApplication, this.appFacade.applications),
            filter(
                ([applicationId, selectedApplications, applications]) =>
                    !!applicationId && applicationId !== selectedApplications?.id && !!applications
            ),
            map(([applicationId]) => selectApplication({ applicationId }))
        )
    );

    initiateRights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => requestUserRights())
        )
    );

    requestUserRights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestUserRights),
            switchMap(() =>
                this.appFacade.getCalculatedRights().pipe(
                    map(calculatedRightSet => {
                        return requestUserRightsSuccess({ calculatedRightSet });
                    }),
                    catchError(() => of(requestUserRightsError))
                )
            )
        )
    );

    initiateRequestBillingPackage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCompanySuccess),
            map(({ company }) => requestBillingPackage({ companyId: company.id }))
        )
    );

    requestBillingPackage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestBillingPackage),
            switchMap(({ companyId }) =>
                this.appFacade.getBillingPackage(companyId).pipe(
                    map(billingPackage => {
                        return requestBillingPackageSuccess({ billingPackage });
                    }),
                    catchError(() => of(requestBillingPackageError))
                )
            )
        )
    );

    requestBillingPackageSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(requestBillingPackageSuccess),
            map(({ billingPackage }) => {
                if (billingPackage.flag === 'RED' || billingPackage.flag === 'YELLOW') {
                    this.dialog
                        .open(ApplicationUsageAlertComponent, backofficeEnvironment.dialogConfig.small)
                        .afterClosed()
                        .pipe(take(1))
                        .subscribe((result: { openUsage: boolean }) => {
                            if (result.openUsage) {
                                this.dialog.open(CompanyEditComponent, { minWidth: '80vw', maxHeight: '95vh' });
                            }
                        });
                }
                return billingPackage;
            })
        )
    );

    openOnBoardingDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(deleteApplicationSuccess, requestApplicationsSuccess),
                withLatestFrom(this.appFacade.applications),
                map(([, applications]) => applications),
                filter(applications => applications && applications.length == 0),
                switchMap(() => this.dialog.open(ApplicationOnboardingComponent, { disableClose: true }).afterClosed())
            ),
        {
            dispatch: false,
        }
    );

    closeOnboardingDialog$ = createEffect(() => this.actions$.pipe(ofType(closeOnboardingDialog), map(createApplication)));

    constructor(
        protected readonly actions$: Actions,
        private readonly dialog: MatDialog,
        private readonly appFacade: AppFacade,
        private readonly routerFacade: RouterFacade,
        private readonly companyService: CompanyService,
        private readonly applicationService: ApplicationService,
        private readonly keyCloakService: KeycloakService,
        private readonly workspaceCookieFacade: WorkspaceCookieFacade,
        private readonly snackbar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}
}
