import { createAction, createAction as createNgRxAction, props } from '@ngrx/store';
import { Action } from '../../action/model/action';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '@backoffice/data-access/editor';
import { OverviewActionDto } from '../../dto/overview/overview-action.dto';

export const createActionSuccess = createNgRxAction('[EFFECT] create new action success', props<{ id: string }>());

export const findAction = createNgRxAction('[ACTION] fetch action', props<{ id: string }>());
export const fetchActionSuccess = createNgRxAction('[EFFECT] fetch action success', props<{ action: Action }>());
export const fetchActionError = createNgRxAction('[ACTION] fetch action error', props<{ action: Action }>());

export const fetchActionFromScopeSuccess = createNgRxAction('[EFFECT] fetch action from scope success', props<{ action: Action }>());
export const deleteAction = createNgRxAction('[ACTION] delete action', props<{ id: string }>());
export const deleteActionSuccess = createNgRxAction('[EFFECT] delete action success', props<{ id: string }>());

export const updateAction = createNgRxAction('[ACTION] update action', props<{ action: Action }>());
export const updateActionSuccess = createNgRxAction('[EFFECT] update action success', props<{ action: Action }>());

// Editor Dialog Actions

export const clearActionDialogData = createAction('[ACTION] action editor dialog data cleared');
export const clearActionDialogFilter = createAction('[ACTION] action editor dialog filter cleared');

export const deleteActions = createAction('[ACTION] delete actions', props<{ ids: string[] }>());
export const deleteActionsSuccess = createAction('[EFFECT] delete actions success', props<{ ids: string[] }>());

export const loadActionDialogData = createAction('[ACTION] load action editor dialog data');
export const loadActionDialogSuccess = createAction(
    '[EFFECT] load action editor dialog data success',
    props<{ data: Page<OverviewActionDto> }>()
);

export const actionDialogPaginationChanged = createAction(
    '[ACTION] action editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const actionDialogSearchTermChanged = createAction(
    '[ACTION] action editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const actionDialogFacetsChanged = createAction('[ACTION] action editor dialog facets changed', props<{ facets: SelectedFacets }>());

export const actionDialogFilterPluginsChanged = createAction(
    '[ACTION] action editor dialog filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
