import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, map, startWith, switchMap, take } from 'rxjs/operators';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable as MatTable } from '@angular/material/table';
import { LoggerService } from '@backoffice/utils';
import { backofficeEnvironment } from '@shared/environment';
import { EditorFacade, isOverviewActionDto, OverviewActionDto } from '@backoffice/data-access/editor';
import { ActionLink } from '../../../../../../data-access/editor/src/lib/action/model/action-link';
import { TabDefinition } from '../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';
import { ActionPickerFacade } from '../../../../../../data-access/editor/src/lib/action/store/facades/action-picker.facade';

@Component({
    selector: 'codex-action-picker',
    templateUrl: './action-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionPickerComponent implements OnInit, OnDestroy {
    @Input()
    required: boolean = false;

    @Input()
    referenceId: string;

    @Input()
    subReferenceId: string;

    @Input()
    applicationId: string;

    @Input()
    type: string;

    @ViewChild('table') table: MatTable<ActionLink>;

    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    actionLinks$: BehaviorSubject<ActionLink[]> = new BehaviorSubject<ActionLink[]>([]);

    displayedColumns: string[] = ['name', 'actions'];

    formGroup: FormGroup;

    subscriptions: Subscription = new Subscription();

    selectedAction: OverviewActionDto;

    filteredActions$: Observable<OverviewActionDto[]>;

    constructor(
        private actionPickerFacade: ActionPickerFacade,
        private fb: FormBuilder,
        public dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private readonly consoleFacade: EditorFacade,
        private readonly logger: LoggerService
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.initCurrentValues();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            actionId: [null],
            actionIdFilter: [],
        });

        this.filteredActions$ = this.formGroup.get('actionIdFilter')?.valueChanges.pipe(
            startWith(''),
            debounceTime(backofficeEnvironment.inputdebounce),
            switchMap(actionName => {
                return this.actionPickerFacade
                    .getActions(actionName, this.applicationId)
                    .pipe(map(overviewActionPage => overviewActionPage.content));
            })
        );

        this.subscriptions.add(
            this.formGroup
                .get('actionId')
                ?.valueChanges.pipe(debounceTime(1000))
                .subscribe(value => this.onSelectAction(value))
        );
    }

    displayAction = (actionValue: string | OverviewActionDto) => {
        if (isOverviewActionDto(actionValue)) {
            return actionValue.name;
        } else {
            return '';
        }
    };

    initCurrentValues() {
        this.subscriptions.add(
            this.actionPickerFacade
                .getActionLinksByReferenceIdAndType(this.referenceId, this.type, this.applicationId)
                .pipe(take(1))
                .subscribe(actionLinks => {
                    this.actionLinks$.next(actionLinks);
                })
        );
    }

    createNewAction() {
        this.subscriptions.add(
            this.actionPickerFacade
                .createAction(this.applicationId)
                .pipe(
                    switchMap(actionCreated => {
                        return this.actionPickerFacade
                            .createActionLink(actionCreated.id, this.referenceId, this.subReferenceId, this.type, this.applicationId)
                            .pipe(
                                switchMap(() => {
                                    return this.actionPickerFacade.getAction(actionCreated.id, this.applicationId);
                                })
                            );
                    }),
                    switchMap(action => {
                        return this.actionPickerFacade
                            .getActionLinksByReferenceIdAndType(this.referenceId, this.type, this.applicationId)
                            .pipe(
                                take(1),
                                map(actionLinks => {
                                    this.actionLinks$.next(actionLinks);
                                    this.cd.detectChanges();
                                    // this.onEditAction(action.id);
                                })
                            );
                    })
                )
                .subscribe()
        );
    }

    onSelectAction(actionId: string) {
        this.actionPickerFacade
            .getAction(actionId, this.applicationId)
            .pipe(
                take(1),
                switchMap(action =>
                    this.actionPickerFacade
                        .createActionLink(action.id, this.referenceId, this.subReferenceId, this.type, this.applicationId)
                        .pipe(
                            switchMap(() =>
                                this.actionPickerFacade
                                    .getActionLinksByReferenceIdAndType(this.referenceId, this.type, this.applicationId)
                                    .pipe(take(1))
                            )
                        )
                )
            )
            .subscribe((actionLinks: ActionLink[]) => {
                this.actionLinks$.next(actionLinks);
                this.formGroup.get('actionIdFilter').reset();
                this.cd.detectChanges();
            });
    }

    onEditAction(actionLink: ActionLink) {
        // TODO Replace consoleFacade call with openTab for all callers of this component.
        this.openTab.emit({ type: 'action', typeId: actionLink.actionId, name: actionLink.actionName });
        this.consoleFacade.registerTab({ type: 'action', typeId: actionLink.actionId, name: actionLink.actionName });
    }

    clearAction(elementId: string) {
        this.actionPickerFacade
            .deleteActionLink(elementId, this.applicationId)
            .pipe(
                switchMap(() =>
                    this.actionPickerFacade
                        .getActionLinksByReferenceIdAndType(this.referenceId, this.type, this.applicationId)
                        .pipe(take(1))
                )
            )
            .subscribe((actionLinks: ActionLink[]) => {
                this.actionLinks$.next(actionLinks);
                this.formGroup.get('actionId').reset(null, { emitEvent: false, onlySelf: true });
                this.formGroup.get('actionIdFilter').reset(null, { emitEvent: false, onlySelf: true });
                this.cd.detectChanges();
            });
    }

    onListDrop($event: CdkDragDrop<ActionLink, any>) {
        const actionLinks = this.actionLinks$.value;
        moveItemInArray(actionLinks, $event.previousIndex, $event.currentIndex);
        for (let i = 0; i < actionLinks.length; i++) {
            const actionLink = actionLinks[i];
            if (actionLink.sequence !== i) {
                actionLink.sequence = i + 1;
                this.actionPickerFacade.updateActionLink(actionLink, this.applicationId).subscribe();
            }
        }
        this.actionLinks$.next(actionLinks);
        this.logger.info(this.actionLinks$.value);
        this.table.renderRows();
    }
}
