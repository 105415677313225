import { PartDetail } from '../../../part-detail.model';

export class HtmlPartDetail extends PartDetail {
    code: string;
    html: string;
    javascriptUrls: JavascriptUrl[];
    cssUrls: CssUrl[];
    css: string;
    preJavascript: string;
    postJavascript: string;

    override isValid(): boolean {
        return this.isCodeValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }
}

export interface JavascriptUrl {
    id: string;
    name: string;
    url: string;
    async: boolean;
    module: boolean;
}

export interface CssUrl {
    id: string;
    name: string;
    url: string;
    async: boolean;
}
