<div
    *ngIf="{ currentContext: currentContext$ | async } as observables"
    (click)="onSearchMedia()"
    class="image hover:bg-dark-button-background rounded-lg border border-light-border dark:border-dark-border bg-dark-input-background_color">
    <i
        class="material-icons close-button accordion-heading-button destructive-accordion-heading-button absolute right-2 top-2"
        (click)="onRemoveMedia(); $event.stopPropagation()"
        >close</i
    >
    <img *ngIf="!mediaId" src="/images/theme/image_placeholder.svg" />
    <img
        *ngIf="!!mediaId"
        [src]="
            environment.rest.v2.media +
            'file/company/' +
            observables.currentContext.selectedCompany.id +
            '/application/' +
            observables.currentContext.selectedApplication.id +
            '/' +
            mediaId
        "
        width="200" />
    <span class="bg-dark-background_level_0 text-text_2 text-center absolute font-bold w-full top-[50%]">{{
        'v2.part.image.media.select' | translate
    }}</span>
</div>
