import { PartDetail } from '../../../part-detail.model';
import { DatasourceConfiguration } from '../line-graph-part/line-chart-part.detail';

export class RadarChartPartDetail extends PartDetail {
    id: string;
    code = 'code';
    title: string;
    indicators: RadarChartIndicatorConfiguration = new RadarChartIndicatorConfiguration();
    datasources: DatasourceConfiguration[] = [];

    constructor(base?: Partial<RadarChartPartDetail>) {
        super();
        if (!!base) {
            this.id = base.id ?? this.id;
            this.code = base.code ?? this.code;
            this.title = base.title ?? this.title;
            this.indicators = base.indicators ?? this.indicators;
            this.datasources = base.datasources ?? this.datasources;
        }
    }

    public override isValid(): boolean {
        return true;
    }

    public override hasActions(): boolean {
        return false;
    }
}

export class RadarChartIndicatorConfiguration {
    id: string;
    values: string;

    constructor(base?: Partial<RadarChartIndicatorConfiguration>) {
        if (!!base) {
            this.values = base.values ?? this.values;
        }
    }
}
