import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Job } from '../../dto/job.dto';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const jobAdapter = createEntityAdapter<Job>();

const { selectIds, selectAll, selectTotal, selectEntities } = jobAdapter.getSelectors();

export const jobEditorSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.job)),
    dialogData: createSelector(selectState, state => state.jobDialog.result),
    dialogFilter: createSelector(selectState, state => {
        const { page, maxResults, searchTerm, facets, filterPlugins } = state.jobDialog;
        return {
            page,
            maxResults,
            searchTerm,
            facets,
            filterPlugins,
        };
    }),

    searchTerm: createSelector(selectState, state => state.jobDialog.searchTerm),
    pagination: createSelector(selectState, state => {
        const { page, maxResults } = state.jobDialog;
        return { page, maxResults };
    }),
    facets: createSelector(selectState, state => state.jobDialog.facets),
    filterPlugins: createSelector(selectState, state => state.jobDialog.filterPlugins),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<Job> = selectEntities(state.job);
            if (id in dictionary) {
                return dictionary[id];
            }
            return undefined;
        }),
};
