<mat-card *ngIf="shouldShowFacets(facets)" class="p-2">
    <div class="flex flex-col">
        <ng-container *ngFor="let facet of facets | keyvalue">
            <ng-container *ngIf="facet.value?.length > 0">
                <section>
                    <h4 class="!text-sm !font-bold !mb-1 !mt-1">
                        {{ prefix ? ('facets.' + prefix + '.' + facet.key + '.label' | translate) : facet.key }}
                    </h4>
                    <li *ngFor="let field of facet.value" class="list-none">
                        <mat-checkbox
                            color="primary"
                            [checked]="selectedValues.has(facet.key) && selectedValues.get(facet.key) === field.name"
                            class="w-full"
                            (change)="onCheckboxChanged($event, facet.key, field.name)">
                            <div
                                *ngIf="!field.displayName"
                                class="whitespace-nowrap text-xs"
                                [matTooltip]="'facets.' + prefix + '.' + facet.key + '.options.' + field.name | translate">
                                {{
                                    prefix
                                        ? ('facets.' + prefix + '.' + facet.key + '.options.' + field.name | translate)
                                        : (field.name | truncate: 23)
                                }}
                                ({{ field.count }})
                            </div>
                            <div
                                *ngIf="field.displayName"
                                class="w-60 text-xs overflow-hidden whitespace-nowrap text-ellipsis"
                                [matTooltip]="field.displayName">
                                {{ field.displayName | truncate: 23 }} ({{ field.count }})
                            </div>
                        </mat-checkbox>
                    </li>
                </section>
            </ng-container>
        </ng-container>
    </div>
</mat-card>
