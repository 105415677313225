import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import {
    isOverviewTemplateDto,
    OverviewTemplateDto,
} from '../../../../../../../libs/backoffice/data-access/editor/src/lib/dto/overview/overview-template.dto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { catchError, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { SharedFacade } from '../../facade/shared.facade';

@Component({
    selector: 'codex-template-picker',
    templateUrl: './template-picker.component.html',
})
export class TemplatePickerComponent implements OnInit, OnChanges {
    @Input()
    currentTemplateId: string;

    @Input()
    label: string;

    @Input()
    required: boolean = false;

    @Output()
    templateChanged: EventEmitter<{ template: OverviewTemplateDto }> = new EventEmitter<{
        template: OverviewTemplateDto;
    }>();

    @Input()
    selectedApplicationId: string;

    subscriptions: Subscription = new Subscription();

    formGroup: FormGroup;

    templates: Observable<OverviewTemplateDto[]>;

    currentTemplateFound: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private fb: FormBuilder,
        private sharedFacade: SharedFacade,
        public changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.initForm();
        this.initTemplateAutoComplete();
    }

    ngAfterViewInit(): void {
        this.initializeSelectedTemplate();
    }

    ngOnChanges() {
        this.initTemplateAutoComplete();
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            template: [null, this.required],
        });
    }

    initTemplateAutoComplete() {
        if (this.formGroup) {
            this.templates = this.formGroup.get('template').valueChanges.pipe(
                startWith(''),
                switchMap(templateName => {
                    if (!isOverviewTemplateDto(templateName)) {
                        return this.sharedFacade
                            .getTemplates(this.selectedApplicationId, templateName, [], 'score desc', 0, 10)
                            .pipe(map(overviewTemplatePage => overviewTemplatePage.content));
                    } else {
                        return of([]);
                    }
                })
            );
        }
    }

    initializeSelectedTemplate(): void {
        if (this.currentTemplateId && this.currentTemplateId !== '') {
            this.sharedFacade
                .getTemplate(this.currentTemplateId, this.selectedApplicationId)
                .pipe(
                    take(1),
                    catchError(() => {
                        this.currentTemplateFound.next(false);
                        return of({ id: null, name: 'Template not found' });
                    }),
                    filter(template => !!template)
                )
                .subscribe(template => {
                    this.currentTemplateFound.next(true);
                    this.formGroup.get('template').setValue(template);
                    this.changeDetectorRef.detectChanges();
                });
        }
    }

    displayTemplate = (templateArgumentValue: string | OverviewTemplateDto) => {
        if (templateArgumentValue) {
            if (isOverviewTemplateDto(templateArgumentValue)) {
                return templateArgumentValue.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    onChange(value: any) {
        if (value.id !== this.currentTemplateId) {
            if (value?.id) {
                this.currentTemplateId = value.id;
            } else {
                this.currentTemplateId = null;
            }
            this.templateChanged.emit({ template: value });
        }
    }

    clearTemplate(): void {
        this.formGroup.get('template').setValue(null);
        this.currentTemplateId = null;
        this.currentTemplateFound.next(false);
        this.templateChanged.emit({ template: null });
    }
}
