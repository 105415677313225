import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { Page } from '@shared/data-access';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { OverviewDataFormatDto } from '@backoffice/data-access/editor';
import { map, switchMap, tap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { DataFormat } from '../models/data-format';
import { CreateDataFormatDto } from '../interfaces/create-data-format.dto';
import { DataFormatCreatedDto } from '../interfaces/data-format-created.dto';
import { DataCreatedDto } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/dto/data-created.dto';
import { UploadInput } from 'ngx-uploader';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class DataformatService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public create(dto: CreateDataFormatDto, companyId: string, applicationId: string): Observable<DataFormatCreatedDto> {
        this.log.debug('Creating data format', [dto]);
        if (dto.type === 'GENERATE_FROM_CSV' && !!dto.file && !!dto.file.nativeFile) {
            let formData: FormData = new FormData();
            formData.append('file', dto.file.nativeFile);
            formData.append(
                'data',
                JSON.stringify({
                    companyId: companyId,
                    applicationId: applicationId,
                    name: dto.name,
                })
            );

            return this.httpClient.post<DataFormatCreatedDto>(
                `${backofficeEnvironment.rest.v2.dataFormat}company/${companyId}/application/${applicationId}/from-csv`,
                formData
            );
        } else if (dto.type === 'GENERATE_FROM_JSON' && !!dto.dataformatdescription) {
            return this.httpClient.post<DataFormatCreatedDto>(
                `${backofficeEnvironment.rest.v2.dataFormat}company/${companyId}/application/${applicationId}/from-json`,
                dto
            );
        } else {
            return this.httpClient.post<DataFormatCreatedDto>(
                `${backofficeEnvironment.rest.v2.dataFormat}company/${companyId}/application/${applicationId}`,
                dto
            );
        }
    }

    public delete(id: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Deleting data format <${id}>`);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.dataFormat}company/${companyId}/application/${applicationId}/${id}`
        );
    }

    public findById(id: string, companyId: string, applicationId: string): Observable<DataFormat> {
        this.log.debug(`Find data format by id <${id}>`);
        return this.httpClient
            .get<DataFormat>(`${backofficeEnvironment.rest.v2.dataFormat}company/${companyId}/application/${applicationId}/${id}`, {
                headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
            })
            .pipe(map(dataFormat => plainToClass(DataFormat, dataFormat)));
    }

    public findAll(
        companyId: string,
        applicationId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<OverviewDataFormatDto>> {
        this.log.debug('Getting dataformat overview');
        let params = new HttpParams().set('companyId', companyId).set('applicationId', applicationId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('keyword', keyword);
            }

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }
        }

        return this.httpClient.get<Page<OverviewDataFormatDto>>(`${backofficeEnvironment.rest.v2.dataFormat}`, { params });
    }

    public update(dataFormat: DataFormat, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Updating dataformat`, [dataFormat]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.dataFormat}company/${companyId}/application/${applicationId}/${dataFormat.id}`,
            dataFormat
        );
    }
}
