import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridStyleDto } from '../../../../../../data-access/editor/src/lib/interfaces/grid/grid.style.dto';
import { ColorV2 } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-grid-config',
    templateUrl: './grid-config.component.html',
})
export class GridConfigComponent {
    @Input()
    set grid(grid: GridStyleDto) {
        if (!grid) {
            this._grid = {
                gridColumns: 12,
                gridColumnColor: '#fe645a',
                gridColumnGapSize: 20,
                gridRows: 12,
                gridRowColor: '#ffe6e4',
                gridRowGapSize: 20,
                showGrid: false,
                snapStrength: 0,
            };
        } else {
            this._grid = grid;
        }
    }

    _grid: GridStyleDto;

    @Input()
    showToggleGrid: boolean = true;

    @Output()
    gridUpdated: EventEmitter<{ grid: GridStyleDto }> = new EventEmitter<{ grid: GridStyleDto }>();

    onGridStyleChanged() {
        this.gridUpdated.emit({ grid: this._grid });
    }

    onChangeGridColumns() {
        this.onGridStyleChanged();
    }

    onChangeGridColumnGapSize($event) {
        if ($event.viewPort || $event.viewPort === 0) {
            this._grid.gridColumnGapSize = $event.viewPort;
            this.onGridStyleChanged();
        }
    }

    onChangeGridColumnColor($event: ColorV2) {
        this._grid.gridColumnColorV2 = $event;
        if (!!$event.hex) {
            this._grid.gridColumnColor = $event.hex.value;
        }

        this.onGridStyleChanged();
    }

    onChangeGridRows() {
        this.onGridStyleChanged();
    }

    onChangeGridRowGapSize($event) {
        if ($event.viewPort || $event.viewPort === 0) {
            this._grid.gridRowGapSize = $event.viewPort;
            this.onGridStyleChanged();
        }
    }

    onChangeGridRowColor($event: ColorV2) {
        this._grid.gridRowColorV2 = $event;
        if (!!$event.hex) {
            this._grid.gridRowColor = $event.hex.value;
        }

        this.onGridStyleChanged();
    }

    onChangeSnapStrength() {
        this.onGridStyleChanged();
    }
}
