<label class="small-input-label ng-star-inserted">Icon</label>
<div
    class="alt-small-input !h-10 text-center cursor-pointer"
    [matTooltip]="'v2.template.properties.icon' | translate"
    [showTrigger]="NgxFloatUiTriggers.click"
    [floatUi]="iconPopover"
    [preventOverflow]="false"
    [boundariesElement]="'rootBoundary'"
    [appendTo]="'body'"
    [positionFixed]="true"
    [placement]="NgxFloatUiPlacements.LEFT"
    (onShown)="onShowIconContainer($event)">
    <mat-icon>{{ icon ? icon : 'image_not_supported' }}</mat-icon>
</div>
<float-ui-content #iconPopover>
    <div class="popover">
        <label for="icon-name" class="small-input-label">Filter icons</label>
        <input
            class="alt-small-input"
            type="text"
            id="icon-name"
            name="icon-name"
            autocomplete="off"
            cdkFocusInitial
            [(ngModel)]="iconFilter"
            (ngModelChange)="filterIcons($event)" />
        <div class="icon-grid">
            <ng-container *ngFor="let icon of filteredIcons | async">
                <div [attr.id]="'tooltip-icon-' + icon.name" role="tooltip" class="nocodex-tooltip tooltip invisible opacity-0">
                    {{ icon.name }}
                    <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
                <div [attr.data-tooltip-target]="'tooltip-icon-' + icon.name" (click)="onChangeIcon(icon.name)" class="icon-card">
                    <mat-icon>{{ icon.name }}</mat-icon>
                </div>
            </ng-container>
        </div>
    </div>
</float-ui-content>
