import { PartDetail } from '../../../part-detail.model';

export class ButtonGroupPartDetail extends PartDetail {
    code: string;
    label: string;
    enabled: boolean;

    override isValid(): boolean {
        return this.isCodeValid() && this.isLabelValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isLabelValid(): boolean {
        return !!this.label && this.label !== '';
    }
}
