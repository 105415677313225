import { Component, OnInit } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'codex-action-create-form',
    templateUrl: './action-create-form.component.html',
})
export class ActionCreateFormComponent implements OnInit {
    selectedType = 'BLANK';

    environment = backofficeEnvironment;

    public formGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ActionCreateFormComponent>,
        public fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: ['Unnamed Action', Validators.required],
            authenticationLogicInPlace: [false],
            authenticationLogic: [null],
            apiDocumentation: [null],
            apiAuthentication: ['NONE'],
            apiHost: [null],
            apiAuthenticationDocumentation: [null],
        });
    }

    onSelectType(type: string) {
        this.selectedType = type;
    }

    onCreate() {
        const {
            name,
            authenticationLogicInPlace,
            authenticationLogic,
            apiDocumentation,
            apiAuthentication,
            apiHost,
            apiAuthenticationDocumentation,
        } = this.formGroup.value;
        this.dialogRef.close({
            name,
            type: this.selectedType,
            authenticationLogicInPlace,
            authenticationLogic,
            apiDocumentation,
            apiAuthentication,
            apiHost,
            apiAuthenticationDocumentation,
        });
    }
}
