import { Component, OnInit } from '@angular/core';
import { BillingFacade } from '@billing/facade/billing.facade';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'codex-application-usage-exceeded-alert',
    templateUrl: './application-usage-exceeded-alert.component.html',
    styleUrls: ['./application-usage-exceeded-alert.component.scss'],
})
export class ApplicationUsageExceededAlertComponent implements OnInit {
    constructor(
        public billingFacade: BillingFacade,
        public readonly dialogRef: MatDialogRef<ApplicationUsageExceededAlertComponent>
    ) {}

    ngOnInit(): void {}
}
