export class Application {
    public id: string;
    public name: string;
    public description: string;
    public iconName: string;
    public applicationSlug: string;
    public applicationDomainName: string;
    public companyId: string;
    public createdBy?: string;
    public creationDate?: string;
    public modificationDate?: string;
    public modifiedBy?: string;

    metaTitle: string;
    metaDescription: string;
    faviconLinkType: string;
    faviconUrl: string;
    faviconMediaId: string;
    customDomain: string;

    homeTemplateId: string;
    notFoundTemplateId: string;
    errorTemplateId: string;
    unAuthorizedTemplateId: string;
    loginTemplateId: string;

    constructor(base?: Partial<Application>) {
        this.id = base.id ?? this.id;
        this.name = base.name ?? this.name;
        this.description = base.description ?? this.description;
        this.iconName = base.iconName ?? this.iconName;
        this.applicationSlug = base.applicationSlug ?? this.applicationSlug;
        this.applicationDomainName = base.applicationDomainName ?? this.applicationDomainName;
        this.companyId = base.companyId ?? this.companyId;
        this.createdBy = base.createdBy ?? this.createdBy;
        this.creationDate = base.creationDate ?? this.creationDate;
        this.modificationDate = base.modificationDate ?? this.modificationDate;
        this.modifiedBy = base.modifiedBy ?? this.modifiedBy;

        this.metaTitle = base.metaTitle ?? this.metaTitle;
        this.metaDescription = base.metaDescription ?? this.metaDescription;
        this.faviconLinkType = base.faviconLinkType ?? this.faviconLinkType;
        this.faviconUrl = base.faviconUrl ?? this.faviconUrl;
        this.faviconMediaId = base.faviconMediaId ?? this.faviconMediaId;
        this.customDomain = base.customDomain ?? this.customDomain;
        this.homeTemplateId = base.homeTemplateId ?? this.homeTemplateId;
        this.notFoundTemplateId = base.notFoundTemplateId ?? this.notFoundTemplateId;
        this.errorTemplateId = base.errorTemplateId ?? this.errorTemplateId;
        this.unAuthorizedTemplateId = base.unAuthorizedTemplateId ?? this.unAuthorizedTemplateId;
        this.loginTemplateId = base.loginTemplateId ?? this.loginTemplateId;
    }

    public isValid(): boolean {
        return (
            !!this.id &&
            !!this.name &&
            this.name !== '' &&
            !!this.iconName &&
            this.iconName !== '' &&
            !!this.applicationSlug &&
            this.applicationSlug !== ''
        );
    }
}
