import { ContainerStyleDto } from '../../../../../interfaces/style/container.style.dto';
import { ColorV2 } from '@shared/data-access';

export interface StepperStyle extends ContainerStyleDto {
    stepColor?: string | null;
    stepColorV2?: ColorV2 | null;

    stepIconColor?: string | null;
    stepIconColorV2?: ColorV2 | null;

    stepBackgroundColor?: string | null;
    stepBackgroundColorV2?: ColorV2 | null;

    currentStepAccentColor?: string | null;
    currentStepAccentColorV2?: ColorV2 | null;

    currentStepBackgroundColor?: string | null;
    currentStepBackgroundColorV2?: ColorV2 | null;

    currentStepIconColor?: string | null;
    currentStepIconColorV2?: ColorV2 | null;
}
