import { PartDetail } from '../../../part-detail.model';

export class NumberInputFieldPartDetail extends PartDetail {
    code: string;
    label: string;

    required: boolean;
    requiredMessage: string;

    help: boolean;
    helpInformation: string;

    answer: string;

    enabled: boolean;

    isValid(): boolean {
        return this.isCodeValid() && this.isLabelValid() && this.isRequiredValid() && this.isHelpValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isLabelValid(): boolean {
        return !!this.label && this.label !== '';
    }

    isHelpValid(): boolean {
        return !this.help || (!!this.helpInformation && this.helpInformation !== '');
    }

    isRequiredValid(): boolean {
        return !this.required || (!!this.requiredMessage && this.requiredMessage !== '');
    }

    getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.number-input-field.error.code');
        }
        if (!this.isLabelValid()) {
            validationErrors.push('v2.part.number-input-field.error.label');
        }
        if (!this.isRequiredValid()) {
            validationErrors.push('v2.part.number-input-field.error.required');
        }
        if (!this.isHelpValid()) {
            validationErrors.push('v2.part.number-input-field.error.help');
        }
        return validationErrors;
    }
}
