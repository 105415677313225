<mat-dialog-content class="scrollbar scrollbar-primary">
    <div class="tabs-header-wrapper">
        <ul
            class="tabs-header"
            data-tabs-toggle="#users-tab-content"
            role="tablist"
            data-tabs-inactive-classes="tabs-tab-inactive"
            data-tabs-active-classes="tabs-tab-active">
            <li class="tabs-tab">
                <button
                    id="developers-tab"
                    class="tabs-tab-button"
                    aria-current="page"
                    data-tabs-target="#developers-mgmt"
                    role="tab"
                    aria-controls="developers"
                    aria-selected="true">
                    <mat-icon class="mr-1">supervised_user_circle</mat-icon>
                    {{ 'v2.usermanagement.developers.title' | translate }}
                </button>
            </li>
            <li class="tabs-tab">
                <button
                    id="users-tab"
                    class="tabs-tab-button"
                    data-tabs-target="#users-mgmt"
                    role="tab"
                    aria-controls="users"
                    aria-selected="false">
                    <mat-icon class="mr-1">people</mat-icon>
                    {{ 'v2.usermanagement.users.title' | translate }}
                </button>
            </li>
        </ul>
    </div>
    <div id="users-tab-content" class="p-2">
        <div id="developers-mgmt" class="hidden" role="tabpanel" aria-labelledby="developers-tab">
            <ng-container *ngIf="developers$ | async as developers">
                <codex-developer-overview
                    *ngIf="!!developers"
                    [developers]="developers"
                    [billing]="billing$ | async"
                    (userInvited)="onUserInvited($event)">
                </codex-developer-overview>
            </ng-container>
        </div>
        <div id="users-mgmt" class="hidden" role="tabpanel" aria-labelledby="users-tab">
            <codex-user-overview (userInvited)="onUserInvited($event)"></codex-user-overview>
        </div>
    </div>
</mat-dialog-content>
