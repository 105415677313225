<ng-container *ngIf="identityProviders && identityProviders.length > 0">
    <div class="flex gap-1 justify-end mt-2">
        <button class="h-9" mat-raised-button color="primary" (click)="onCreateIdentityProvider()">
            <mat-icon>add</mat-icon>
            {{ 'v2.company.edit.identityproviders.none.found.configure' | translate }}
        </button>
    </div>
    <table mat-table [dataSource]="identityProviders">
        <ng-container matColumnDef="name" class="mat-elevation-z8">
            <th mat-header-cell *matHeaderCellDef>{{ 'v2.company.edit.identityproviders.name' | translate }}</th>
            <td mat-cell *matCellDef="let identityProvider">{{ identityProvider.name }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ 'v2.company.edit.identityproviders.actions.title' | translate }}</th>
            <td class="actions-column" mat-cell *matCellDef="let identityProvider">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onEditIdentityProvider(identityProvider)">
                        <mat-icon>edit</mat-icon>
                        {{ 'v2.company.edit.identityproviders.actions.edit' | translate }}
                    </button>
                    <button mat-menu-item (click)="onDeleteIdentityProvider(identityProvider)">
                        <mat-icon>delete</mat-icon>
                        {{ 'v2.company.edit.identityproviders.actions.delete' | translate }}
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</ng-container>
<div class="no-identity-providers flex justify-center items-center flex-col" *ngIf="!identityProviders || identityProviders.length === 0">
    <img class="w-[350px] h-[250px] object-contain" src="/images/theme/no_identityproviders.gif" />
    <h1 class="text-center !mb-2">We couldn't find any identity providers for this company</h1>
    <p class="!mb-8 text-white">
        Allow your users to authenticate with external accounts such as microsoft accounts, google accounts, etc... by configuring an
        identity provider.
    </p>
    <button type="button" class="primary-button button-extra-large !mb-8" (click)="onCreateIdentityProvider()">
        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
        </svg>
        {{ 'v2.company.edit.identityproviders.none.found.configure' | translate }}
    </button>
</div>
