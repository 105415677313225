import { UploadFile } from 'ngx-uploader';

export interface CreateDataFormatDto {
    applicationId: string;
    companyId: string;
    type: string;
    name: string;
    dataformatdescription?: string;
    file?: UploadFile;
}
