import { createAction, props } from '@ngrx/store';
import {
    ROUTER_CANCEL,
    ROUTER_ERROR,
    ROUTER_NAVIGATED,
    ROUTER_NAVIGATION,
    ROUTER_REQUEST,
    RouterCancelPayload,
    RouterErrorPayload,
    RouterNavigationPayload,
    RouterRequestPayload,
} from '@ngrx/router-store';
import { RouterStateUrl } from './router.state';

export const routerRequest = createAction(ROUTER_REQUEST, props<{ payload: RouterRequestPayload<RouterStateUrl> }>());

export const routerNavigation = createAction(ROUTER_NAVIGATION, props<{ payload: RouterNavigationPayload<RouterStateUrl> }>());

export const routerCancel = createAction(ROUTER_CANCEL, props<{ payload: RouterCancelPayload<RouterStateUrl, RouterStateUrl> }>());

export const routerError = createAction(ROUTER_ERROR, props<{ payload: RouterErrorPayload<RouterStateUrl, RouterStateUrl> }>());

export const routerNavigated = createAction(ROUTER_NAVIGATED, props<{ payload: RouterNavigationPayload<RouterStateUrl> }>());
