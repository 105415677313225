import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '@shared/data-access';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Media } from '../models/media';
import { CreateMediaDto } from '../interfaces/media/create-media.dto';
import { MediaCreatedDto } from '../interfaces/media/media-created.dto';

@Injectable()
export class MediaService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public create(dto: CreateMediaDto, companyId: string, applicationId: string): Observable<MediaCreatedDto> {
        this.log.debug('Creating media', [dto]);
        return this.httpClient.post<MediaCreatedDto>(
            `${backofficeEnvironment.rest.v2.media}company/${companyId}/application/${applicationId}`,
            dto
        );
    }

    public delete(id: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Deleting media <{$id}`);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.media}company/${companyId}/application/${applicationId}/${id}`
        );
    }

    public findById(id: string, companyId: string, applicationId: string): Observable<Media> {
        this.log.debug(`Find media by id <${id}>`);
        return this.httpClient
            .get<Media>(`${backofficeEnvironment.rest.v2.media}company/${companyId}/application/${applicationId}/${id}`, {
                headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
            })
            .pipe(map(media => plainToClass(Media, media)));
    }

    public findAll(
        companyId: string,
        applicationId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<Media>> {
        this.log.debug('Getting media overview');
        let params = new HttpParams().set('companyId', companyId).set('applicationId', applicationId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('keyword', keyword);
            }

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }
        }

        return this.httpClient.get<Page<Media>>(`${backofficeEnvironment.rest.v2.media}`, { params }).pipe(
            map(result => {
                const { content, facetFields, count } = result;
                return {
                    content: content ? content.map(i => new Media(i)) : [],
                    count,
                    facetFields,
                };
            })
        );
    }

    public update(media: Media, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Updating media`, [media]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.media}company/${companyId}/application/${applicationId}/${media.id}`,
            media
        );
    }
}
