import { NgModule } from '@angular/core';
import { ApplicationVersionDetailComponent } from './components/application-version-detail/application-version-detail.component';
import { CommonsModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [ApplicationVersionDetailComponent],
    imports: [CommonsModule, MatFormFieldModule, MatButtonModule],
    exports: [ApplicationVersionDetailComponent],
})
export class ApplicationVersionUiModule {}
