import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DesignSystem } from '@backoffice/data-access/editor';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-design-system-general',
    templateUrl: './design-system-general.component.html',
    styleUrls: ['./design-system-general.component.scss'],
})
export class DesignSystemGeneralComponent implements OnInit, OnDestroy {
    @Input() designSystem: DesignSystem;

    @Output() changed = new EventEmitter<{ name: string; active: boolean }>();

    form = this.fb.group({
        name: ['', [Validators.required]],
        active: [false, [Validators.required]],
    });

    subscriptions = new Subscription();

    constructor(private readonly fb: NonNullableFormBuilder) {}

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.form.patchValue({
            ...this.designSystem,
        });
        this.subscriptions.add(this.form.valueChanges.subscribe(() => this.changed.emit(this.form.getRawValue())));
    }
}
