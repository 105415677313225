<div *ngIf="showAssistantDialog" class="!absolute left-4 bottom-20 !z-40 toolpane">
    <mat-card class="w-96 p-1 min-h-[500px] mat-elevation-z5 flex flex-col">
        <ul *ngIf="chooseType" cass="flex-grow overflow-scroll scrollbar scrollbar-primary">
            <li
                class="p-2 mb-2 h-16 hover:bg-primary hover:text-white cursor-pointer rounded flex flex-col"
                (click)="startTypedChat('generate-data-format')">
                <h4 class="!text-sm !font-bold !mb-1 !mt-1">Create data-formats</h4>
                <span class="text-xxs leading-4">Have your assistant to create your data-formats for you! </span>
            </li>
            <hr class="seperator" />
            <li
                class="p-2 mb-2 h-16 hover:bg-primary hover:text-white cursor-pointer rounded flex flex-col"
                (click)="startTypedChat('generate-test-data')">
                <h4 class="!text-sm !font-bold !mb-1 !mt-1">Create test data</h4>
                <span class="text-xxs leading-4">Create test data for your existing dataformats </span>
            </li>
            <hr class="seperator" />
            <li
                class="p-2 mb-2 h-16 hover:bg-primary hover:text-white cursor-pointer rounded flex flex-col"
                (click)="startTypedChat('generate-global-theme')">
                <h4 class="!text-sm !font-bold !mb-1 !mt-1">Generate global theme</h4>
                <span class="text-xxs leading-4">Create a global theme for your application</span>
            </li>
            <hr class="seperator" />
            <li
                class="p-2 mb-2 h-16 hover:bg-primary hover:text-white cursor-pointer rounded flex flex-col"
                (click)="startTypedChat('generate-api-endpoints')">
                <h4 class="!text-sm !font-bold !mb-1 !mt-1">Generate api endpoints for a dataformat</h4>
                <span class="text-xxs leading-4">Create an series of api endpoints for a dataformat</span>
            </li>
            <li
                class="p-2 mb-2 h-16 hover:bg-primary hover:text-white cursor-pointer rounded flex flex-col"
                (click)="startTypedChat('generate-rootpage')">
                <h4 class="!text-sm !font-bold !mb-1 !mt-1">Generate root page</h4>
                <span class="text-xxs leading-4">Create a root page for your application</span>
            </li>
        </ul>
        <ng-container *ngIf="showChat">
            <ul *ngIf="showChat" class="h-[450px] h-[425px] overflow-scroll scrollbar scrollbar-primary">
                <li *ngFor="let message of messages">
                    <ng-container *ngIf="!message.user">
                        <div class="text-left">
                            <h4 class="!text-xxs !font-bold !mb-1 !mt-1">Assistant:</h4>
                            <span class="inline-block max-w-[320px] text-xxs leading-4 p-2 mb-2 bg-primary text-white rounded">{{
                                message.text
                            }}</span>
                            <span
                                class="inline-block max-w-[320px] text-xxs leading-4 p-2 mb-2 bg-primary text-white rounded cursor-pointer underline"
                                *ngIf="message.dialoglink"
                                (click)="openValidationDialog(message.dialogClass, message)"
                                >Click here to validate & proceed</span
                            >
                        </div>
                    </ng-container>
                    <ng-container *ngIf="message.user">
                        <div class="text-right">
                            <h4 class="!text-xxs !font-bold !mb-1 !mt-1">{{ userName }}:</h4>
                            <span class="text-left inline-block max-w-[320px] text-xxs leading-4 p-2 mb-2 bg-primarylight rounded">{{
                                message.text
                            }}</span>
                        </div>
                    </ng-container>
                </li>
                <li (click)="startTypedChat('rest')"></li>
            </ul>
            <app-assistant-generate-test-data-input *ngIf="this.type === 'generate-test-data'" (addMessage)="onAddMessage($event)">
            </app-assistant-generate-test-data-input>

            <app-assistant-generate-dataformat-input *ngIf="this.type === 'generate-data-format'" (addMessage)="onAddMessage($event)">
            </app-assistant-generate-dataformat-input>

            <app-assistant-generate-api-endpoints-input *ngIf="this.type === 'generate-api-endpoints'" (addMessage)="onAddMessage($event)">
            </app-assistant-generate-api-endpoints-input>

            <app-assistant-generate-rootpage-input *ngIf="this.type === 'generate-rootpage'" (addMessage)="onAddMessage($event)">
            </app-assistant-generate-rootpage-input>
        </ng-container>
    </mat-card>
</div>
<button color="primary" class="!absolute left-4 bottom-4 !z-40" data-name="assistant" mat-fab (click)="toggleAssistant()">
    <mat-icon>smart_toy</mat-icon>
</button>
