import { Invocation } from './invocation';
import { Method } from './method';
import { Exclude, Type } from 'class-transformer';
import { NextInvocation } from './next-invocation';
import { Parameter } from '../../parameters/parameter';
import { createArguments, Output } from '@backoffice/data-access/editor';
import { InvocationOutput } from './invocation-output';
import { Action } from './action';
import { GUIDFunctions } from '@shared/utils';

export class Program {
    @Type(() => Invocation)
    invocations: Invocation[] = [];

    @Type(() => Parameter)
    public parameters: Parameter[];

    @Type(() => Output)
    public outputs: Output[];

    //transient
    @Exclude()
    invocationMap: Map<string, Invocation>;

    //transient
    @Exclude()
    nextInvocationMap: Map<string, NextInvocation> = new Map();

    //transient
    @Exclude()
    previousInvocationMap: Map<string, Invocation[]> = new Map();

    public prepareForSave() {
        if (this.parameters && this.parameters.length) {
            this.parameters.forEach(parameter => {
                if (parameter.defaultValue) {
                    parameter.defaultValue.parameter = undefined;
                }
            });
        }
    }

    public isValid(): boolean {
        return this.areParametersValid();
    }

    areParametersValid() {
        if (!!this.parameters && this.parameters.length > 0) {
            for (const parameter of this.parameters) {
                if (!this.isParameterValid(parameter)) {
                    return false;
                }
            }
        }
        if (!!this.outputs && this.outputs.length > 0) {
            for (const output of this.outputs) {
                if (!this.isOutputValid(output)) {
                    return false;
                }
            }
        }

        return true;
    }

    isParameterValid(parameter: Parameter) {
        return !!parameter.name && parameter.name !== '' && !!parameter.type && parameter.type !== '';
    }

    isOutputValid(output: Output) {
        return !!output.names && !!output.names['en'] && output.names['en'] !== '' && !!output.type && output.names !== '';
    }

    public initProgram(methodMap: Map<string, Method>, actionMap: Map<string, Action>) {
        this.initInvocationMap();
        this.initNextInvocationMap();
        this.initPreviousInvocationMap();
        if (!!this.invocations) {
            this.invocations.forEach(invocation => invocation.initInvocation(methodMap, actionMap));
        }
    }

    public initInvocationMap() {
        if (!this.invocationMap) {
            this.invocationMap = new Map<string, Invocation>();
            this.invocations.forEach(invocation => this.invocationMap.set(invocation.id, invocation));
        }
    }

    public initNextInvocationMap() {
        this.invocations.forEach(invocation => {
            if (invocation.nextInvocations) {
                invocation.nextInvocations.forEach(nextInvocation => {
                    this.nextInvocationMap.set(nextInvocation.id, nextInvocation);
                });
            }
        });
    }

    public initPreviousInvocationMap() {
        this.invocations.forEach(invocation => {
            if (invocation.nextInvocations) {
                invocation.nextInvocations.forEach(nextInvocation => {
                    if (!this.previousInvocationMap.has(nextInvocation.invocationId)) {
                        this.previousInvocationMap.set(nextInvocation.invocationId, []);
                    }
                    this.previousInvocationMap.get(nextInvocation.invocationId).push(invocation);
                });
            }
        });
    }

    private removePreviousInvocationFromMap(targetId: string, sourceId: string) {
        let previousInvocations = this.previousInvocationMap.get(targetId);
        previousInvocations = previousInvocations.splice(
            previousInvocations.findIndex(invocation => invocation.id === sourceId),
            1
        );
        this.previousInvocationMap.set(targetId, previousInvocations);
    }

    private deleteFromInvocationMaps(invocationId: string) {
        this.previousInvocationMap.delete(invocationId);
        if (this.invocationMap.has(invocationId) && this.invocationMap.get(invocationId).nextInvocations !== null) {
            this.invocationMap.get(invocationId).nextInvocations.forEach(nextInvocation => {
                this.previousInvocationMap.get(nextInvocation.invocationId).splice(
                    this.previousInvocationMap
                        .get(nextInvocation.invocationId)
                        .findIndex(previousInvocation => previousInvocation.id === invocationId),
                    1
                );
            });
        }
    }

    private addToPreviousInvocationMap(targetId: string, sourceId: string) {
        if (!this.previousInvocationMap.has(targetId)) {
            this.previousInvocationMap.set(targetId, []);
        }
        this.previousInvocationMap.get(targetId).push(this.invocationMap.get(sourceId));
    }

    copyInvocation(invocation: Invocation): Invocation {
        const copiedInvocation = invocation.copy();
        this.invocations.push(copiedInvocation);
        this.invocationMap.set(copiedInvocation.id, copiedInvocation);
        return copiedInvocation;
    }

    removeInvocation(invocation: Invocation) {
        if (!!invocation) {
            this.deleteFromInvocationMaps(invocation.id);
            this.invocations.splice(this.invocations.indexOf(invocation), 1);
            this.invocationMap.delete(invocation.id);
            for (let i = 0; i < this.invocations.length; i++) {
                const invocationToStay = this.invocations[i];
                const nextInvocationsToRemove = invocationToStay.nextInvocations.filter(
                    nextInvocation => invocation.id === nextInvocation.invocationId
                );
                nextInvocationsToRemove.forEach(nextInvocationToRemove =>
                    invocationToStay.nextInvocations.splice(invocationToStay.nextInvocations.indexOf(nextInvocationToRemove), 1)
                );
            }
        }
    }

    addInvocation(method: Method, x: number, y: number, language: string): Invocation {
        const invocation: Invocation = this.createInvocation(x, y, language, method);
        this.invocations.push(invocation);
        this.invocationMap.set(invocation.id, invocation);
        return invocation;
    }

    createInvocation(x: number, y: number, language: string, method: Method): Invocation {
        const invocation: Invocation = new Invocation();
        invocation.name = method.names[language ? language.toLowerCase() : 'en'];
        invocation.description = method.descriptions[language ? language.toLowerCase() : 'en'];
        invocation.iconName = method.iconName;
        invocation.arguments = createArguments(method.parameters);
        invocation.invocationOutputs = this.createOutputs(method);
        invocation.methodKey = method.key;
        invocation.method = method;
        invocation.id = new GUIDFunctions().newGuid();
        invocation.x = x;
        invocation.y = y;
        return invocation;
    }

    createOutputs(method: Method): InvocationOutput[] {
        const invocationOutputs: InvocationOutput[] = [];
        if (!!method.outputs) {
            method.outputs.forEach(output => {
                const invocationOutput: InvocationOutput = new InvocationOutput();
                invocationOutput.outputId = output.id;
                if (output.defaultValue) {
                    invocationOutput.value = output.defaultValue.value;
                }
                invocationOutput.type = output.type;
                invocationOutput.output = output;
                invocationOutputs.push(invocationOutput);
            });
        }
        return invocationOutputs;
    }

    changeSourceInvocation(previousSourceId: string, newSourceId: string, targetId: string) {
        if (
            !!previousSourceId &&
            !!newSourceId &&
            !!targetId &&
            this.invocationMap.has(previousSourceId) &&
            this.invocationMap.has(newSourceId) &&
            this.invocationMap.has(targetId)
        ) {
            this.removePreviousInvocationFromMap(targetId, previousSourceId);
            this.addToPreviousInvocationMap(targetId, newSourceId);
            const nextInvocationToRemove = this.invocationMap.get(previousSourceId).getNextInvocation(targetId);
            if (nextInvocationToRemove) {
                this.nextInvocationMap.delete(this.invocationMap.get(previousSourceId).getNextInvocation(targetId).id);
                this.invocationMap.get(previousSourceId).removeNextInvocation(targetId);
            }
            return this.setNextInvocation(newSourceId, targetId);
        } else {
            console.error('Next invocation could not be set correctly because of a null value');
            console.error('previousSourceId', previousSourceId);
            console.error('newSourceId', newSourceId);
            console.error('targetId', targetId);
            console.error('invocationMap has previousSourceId', this.invocationMap.has(previousSourceId));
            console.error('invocationMap has newSourceId', this.invocationMap.has(newSourceId));
            console.error('invocationMap has targetId', this.invocationMap.has(targetId));
        }
    }

    changeNextInvocation(sourceId: string, previousTargetId: string, newTargetId: string): NextInvocation {
        if (
            !!sourceId &&
            !!previousTargetId &&
            !!newTargetId &&
            this.invocationMap.has(sourceId) &&
            this.invocationMap.has(previousTargetId) &&
            this.invocationMap.has(newTargetId)
        ) {
            this.removePreviousInvocationFromMap(previousTargetId, sourceId);
            this.addToPreviousInvocationMap(newTargetId, sourceId);
            const nextInvocationToRemove = this.invocationMap.get(sourceId).getNextInvocation(previousTargetId);
            if (nextInvocationToRemove) {
                this.nextInvocationMap.delete(nextInvocationToRemove.id);
                this.invocationMap.get(sourceId).removeNextInvocation(previousTargetId);
            }
            return this.setNextInvocation(sourceId, newTargetId, nextInvocationToRemove.conditional);
        } else {
            console.error('Next invocation could not be set correctly because of a null value');
            console.error('sourceId', sourceId);
            console.error('previousTargetId', previousTargetId);
            console.error('newTargetId', newTargetId);
            console.error('invocationMap has sourceId', this.invocationMap.has(sourceId));
            console.error('invocationMap has previousTargetId', this.invocationMap.has(previousTargetId));
            console.error('invocationMap has newTargetId', this.invocationMap.has(newTargetId));
        }
    }

    setNextInvocation(sourceId: string, targetId: string, conditional?: string): NextInvocation {
        if (!!sourceId && !!targetId && this.invocationMap.has(sourceId) && this.invocationMap.has(targetId)) {
            this.addToPreviousInvocationMap(targetId, targetId);
            const sourceInvocation: Invocation = this.invocationMap.get(sourceId);
            const nextInvocation: NextInvocation = sourceInvocation.addNextInvocation(targetId);
            nextInvocation.conditional = conditional;
            this.nextInvocationMap.set(nextInvocation.id, nextInvocation);
            return nextInvocation;
        } else {
            console.error('Next invocation could not be set correctly because of a null value');
            console.error('sourceId', sourceId);
            console.error('targetId', targetId);
            console.error('invocationMap has sourceId', this.invocationMap.has(sourceId));
            console.error('invocationMap has targetId', this.invocationMap.has(targetId));
        }
    }

    public createProgramOutputs(): InvocationOutput[] {
        const invocationOutputs: InvocationOutput[] = [];
        if (!!this.outputs) {
            this.outputs.forEach(output => {
                const invocationOutput: InvocationOutput = new InvocationOutput();
                invocationOutput.outputId = output.id;
                if (output.defaultValue) {
                    invocationOutput.value = output.defaultValue.value;
                }
                invocationOutput.output = output;
                invocationOutput.type = output.type;
                invocationOutputs.push(invocationOutput);
            });
        }
        return invocationOutputs;
    }
}

export function mergeOutputs(newOutputs: InvocationOutput[], output: InvocationOutput) {
    newOutputs.forEach(newOutput => {
        const existingOutput: InvocationOutput = output.subOutputs.find(subOutput => newOutput.outputId === subOutput.outputId);
        if (existingOutput) {
            existingOutput.updateOutput(newOutput);
        } else {
            output.subOutputs.push(newOutput);
        }
    });
    output.subOutputs = output.subOutputs.filter(subOutput => newOutputs.find(newOutput => newOutput.outputId === subOutput.outputId));
}
