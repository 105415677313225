import { Component, Input } from '@angular/core';

@Component({
    selector: 'nocodex-typography-picker-v2',
    templateUrl: 'typography-picker-v2.component.html',
})
export class TypographyPickerV2Component {
    @Input() typography: { color: string; name: string; size: string; bold: string } = {
        color: '#14181b',
        name: 'Headline Medium',
        size: '24px',
        bold: 'Normal',
    };

    presets: { color: string; name: string; size: string; bold: string }[] = [
        { color: '#14181b', name: 'Display Large', size: '64px', bold: 'Normal' },
        { color: '#14181b', name: 'Display Medium', size: '44px', bold: 'Normal' },
        { color: '#14181b', name: 'Display Small', size: '36px', bold: 'Semi Bold' },
        { color: '#14181b', name: 'Headline Large', size: '32px', bold: 'Semi Bold' },
        { color: '#14181b', name: 'Headline Medium', size: '24px', bold: 'Normal' },
        { color: '#14181b', name: 'Headline Small', size: '24px', bold: 'Medium' },
        { color: '#14181b', name: 'Title Large', size: '22px', bold: 'Medium' },
        { color: '#ffffff', name: 'Title Medium', size: '18px', bold: 'Normal' },
        { color: '#ffffff', name: 'Title Small', size: '16px', bold: 'Medium' },
        { color: '#57636c', name: 'Label Large', size: '16px', bold: 'Normal' },
        { color: '#57636c', name: 'Label Medium', size: '14px', bold: 'Normal' },
        { color: '#57636c', name: 'Label Small', size: '12px', bold: 'Normal' },
        { color: '#14181b', name: 'Body Large', size: '16px', bold: 'Normal' },
        { color: '#14181b', name: 'Body Medium', size: '14px', bold: 'Normal' },
        { color: '#14181b', name: 'Body Small', size: '12px', bold: 'Normal' },
    ];

    handleTypographySelected(preset: { color: string; name: string; size: string; bold: string }): void {
        this.typography = preset;
    }
}
