export class ApplicationVersion {
    public readonly id: string;
    public name: string;
    public description: string;
    public versionNumber: string;
    public environment: string;
    public environments: string[] = [];
    public releaseNotes: string;
    public status: string;

    public canPromote(): boolean {
        return this.status === 'READY';
    }

    public failed(): boolean {
        return this.status === 'FAILED';
    }

    public building(): boolean {
        return this.status === 'BUILDING' || this.status === 'BUILDING_SUBVERSIONS';
    }

    public canPromoteToTest() {
        return this.environments?.indexOf('TEST') === -1;
    }

    public canPromoteToAccept() {
        return this.environments?.indexOf('ACCEPT') === -1;
    }

    public canPromoteToProduction() {
        return this.environments?.indexOf('PRODUCTION') === -1;
    }

    public setCurrentVersion(): void {
        this.environments.push('PRODUCTION');
    }

    public setTestVersion(): void {
        this.environments.push('TEST');
    }

    public setAcceptanceVersion(): void {
        this.environments.push('ACCEPT');
    }
}
