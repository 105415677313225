import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Color, ColorPickerControl } from '@iplab/ngx-color-picker';
import { debounceTime, Subscription } from 'rxjs';
import { Theme, Variable } from '@backoffice/data-access/editor';
import { NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'app-design-system-theme',
    templateUrl: './design-system-theme.component.html',
    styleUrls: ['./design-system-theme.component.scss'],
})
export class DesignSystemThemeComponent implements OnInit, OnDestroy {
    @Input() theme: Theme;
    @Input() title: string;

    @Output() colorChanged = new EventEmitter<{ value: string; variable: Variable }>();

    private readonly subscriptions = new Subscription();

    primaryControl: ColorPickerControl;
    secondaryControl: ColorPickerControl;
    teriaryControl: ColorPickerControl;
    alternateControl: ColorPickerControl;
    primaryTextControl: ColorPickerControl;
    primaryBackgroundControl: ColorPickerControl;
    secondaryTextControl: ColorPickerControl;
    secondaryBackgroundControl: ColorPickerControl;
    successControl: ColorPickerControl;
    infoControl: ColorPickerControl;
    warningControl: ColorPickerControl;
    errorControl: ColorPickerControl;
    accentOneControl: ColorPickerControl;
    accentTwoControl: ColorPickerControl;
    accentThreeControl: ColorPickerControl;
    accentFourControl: ColorPickerControl;

    ngOnInit(): void {
        this.initializePrimaryColors();
        this.initializeTextColors();
        this.initializeBackgroundColors();
        this.initializeSemanticColors();
        this.initializeAccentColors();
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    private handleColorChanged(value: Color, variable: Variable): void {
        const hexValue = value.toHexString();
        const colorDefinition = this.theme.findColorByVariable(variable);
        colorDefinition.value = hexValue;

        this.colorChanged.emit({ value: hexValue, variable });
    }

    private initializePrimaryColors(): void {
        this.primaryControl = new ColorPickerControl().setValueFrom(this.theme.primary.value).hidePresets();
        this.secondaryControl = new ColorPickerControl().setValueFrom(this.theme.secondary.value).hidePresets();
        this.teriaryControl = new ColorPickerControl().setValueFrom(this.theme.tertiary.value).hidePresets();
        this.alternateControl = new ColorPickerControl().setValueFrom(this.theme.alternate.value).hidePresets();

        this.subscriptions.add(
            this.primaryControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.primary.variable))
        );
        this.subscriptions.add(
            this.secondaryControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.secondary.variable))
        );
        this.subscriptions.add(
            this.teriaryControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.tertiary.variable))
        );
        this.subscriptions.add(
            this.alternateControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.alternate.variable))
        );
    }

    private initializeTextColors(): void {
        this.primaryTextControl = new ColorPickerControl().setValueFrom(this.theme.primaryText.value).hidePresets();
        this.secondaryTextControl = new ColorPickerControl().setValueFrom(this.theme.secondaryText.value).hidePresets();

        this.subscriptions.add(
            this.primaryTextControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.primaryText.variable))
        );
        this.subscriptions.add(
            this.secondaryTextControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.secondaryText.variable))
        );
    }

    private initializeBackgroundColors(): void {
        this.primaryBackgroundControl = new ColorPickerControl().setValueFrom(this.theme.primaryBackground.value).hidePresets();
        this.secondaryBackgroundControl = new ColorPickerControl().setValueFrom(this.theme.secondaryBackground.value).hidePresets();

        this.subscriptions.add(
            this.primaryBackgroundControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.primaryBackground.variable))
        );
        this.subscriptions.add(
            this.secondaryBackgroundControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.secondaryBackground.variable))
        );
    }

    private initializeSemanticColors(): void {
        this.successControl = new ColorPickerControl().setValueFrom(this.theme.success.value).hidePresets();
        this.infoControl = new ColorPickerControl().setValueFrom(this.theme.info.value).hidePresets();
        this.warningControl = new ColorPickerControl().setValueFrom(this.theme.warning.value).hidePresets();
        this.errorControl = new ColorPickerControl().setValueFrom(this.theme.error.value).hidePresets();

        this.subscriptions.add(
            this.successControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.success.variable))
        );
        this.subscriptions.add(
            this.infoControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.info.variable))
        );
        this.subscriptions.add(
            this.warningControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.warning.variable))
        );
        this.subscriptions.add(
            this.errorControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.error.variable))
        );
    }

    private initializeAccentColors(): void {
        this.accentOneControl = new ColorPickerControl().setValueFrom(this.theme.accentOne.value).hidePresets();
        this.accentTwoControl = new ColorPickerControl().setValueFrom(this.theme.accentTwo.value).hidePresets();
        this.accentThreeControl = new ColorPickerControl().setValueFrom(this.theme.accentThree.value).hidePresets();
        this.accentFourControl = new ColorPickerControl().setValueFrom(this.theme.accentFour.value).hidePresets();

        this.subscriptions.add(
            this.accentOneControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.accentOne.variable))
        );
        this.subscriptions.add(
            this.accentTwoControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.accentTwo.variable))
        );
        this.subscriptions.add(
            this.accentThreeControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.accentThree.variable))
        );
        this.subscriptions.add(
            this.accentFourControl.valueChanges
                .pipe(debounceTime(300))
                .subscribe(value => this.handleColorChanged(value, this.theme.accentFour.variable))
        );
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
