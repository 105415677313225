import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { Api } from '../../../api/model/api';
import { ApiEditorState } from '../../../store/state/api-editor.state';
import {
    apiDialogFacetsChanged,
    apiDialogFilterPluginsChanged,
    apiDialogPaginationChanged,
    apiDialogSearchTermChanged,
    clearApiDialogData,
    clearApiDialogFilter,
    deleteApiSuccess,
    fetchApiSuccess,
    loadApiDialogSuccess,
    updateApiSuccess,
} from '../../../store/actions/api-editor.actions';
import { selectApplicationSuccess } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { SelectedFacets } from '../../../dto/overview/facets.dto';
import { Page } from '@shared/data-access';
import { ApiDialogState } from '../../../store/state/api-dialog.state';
import { OverviewApiDto } from '../../../dto/overview/overview-api.dto';
import { GroupDto } from 'libs/backoffice/data-access/editor/src/lib/group/dto/group.dto';
import {
    clearGroupDialogData,
    clearGroupDialogFilter,
    deleteGroupSuccess,
    fetchGroupSuccess,
    groupDialogFacetsChanged,
    groupDialogFilterPluginsChanged,
    groupDialogPaginationChanged,
    groupDialogSearchTermChanged,
    loadGroupDialogSuccess,
    updateGroupSuccess,
} from '../actions/group-editor.actions';
import { GroupEditorState } from '../state/group-editor.state';
import { GroupDialogState } from '../state/group-dialog.state';
import { OverviewGroupDto } from '../../dto/overview-group.dto';

const adapterAction = createEntityAdapter<GroupDto>();
export const groupInitialState: GroupEditorState = adapterAction.getInitialState();
export const groupDialogState: GroupDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const groupReducer = (initialData: GroupEditorState) =>
    createReducer(
        initialData,
        on(fetchGroupSuccess, (state, { group }) => adapterAction.addOne(group, state)),
        on(deleteGroupSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(updateGroupSuccess, (state, { group }) => adapterAction.updateOne({ id: group.id, changes: group }, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );

const facetsReducer = (initialData: SelectedFacets | undefined) =>
    createReducer(
        initialData,
        on(groupDialogFacetsChanged, (state, { facets }) => facets),
        on(clearGroupDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(groupDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearGroupDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(groupDialogPaginationChanged, (_, { page }) => page),
        on(groupDialogSearchTermChanged, () => 0),
        on(clearGroupDialogFilter, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(groupDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearGroupDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(groupDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearGroupDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewGroupDto> | undefined) =>
    createReducer(
        initialData,
        on(loadGroupDialogSuccess, (_, { data }) => data),
        on(clearGroupDialogData, () => undefined)
    );

export const groupDialogReducers = combineReducers({
    page: pageReducer(groupDialogState.page),
    maxResults: maxResultsReducer(groupDialogState.maxResults),
    searchTerm: searchTermReducer(groupDialogState.searchTerm),
    result: resultReducer(groupDialogState.result),
    facets: facetsReducer(groupDialogState.facets),
    filterPlugins: filterPluginsReducer(groupDialogState.filterPlugins),
});
