import { createAction, createAction as createNgRxAction, props } from '@ngrx/store';
import { Scope } from '../../action/model/scope';
import { ActionCtx as ActionCtx } from '../../action/model/action-ctx';
import { Action } from '../../action/model/action';
import { Method } from '../../action/model/method';

export const findActionContext = createAction('[ACTION] fetch action context', props<{ action: Action }>());
export const fetchActionContextSuccess = createNgRxAction(
    '[EFFECT] fetch action context success',
    props<{
        action: Action;
        actionContext: ActionCtx[];
    }>()
);

export const fetchActionContextFromScopeSuccess = createNgRxAction(
    '[EFFECT] fetch action context from scope success',
    props<{
        id: string;
        actionContext: ActionCtx[];
    }>()
);

export const findActionMethod = createNgRxAction('[ACTION] fetch action methods', props<{ action: Action }>());
export const fetchActionMethodsSuccess = createNgRxAction(
    '[EFFECT] fetch action methods success',
    props<{
        action: Action;
        methods: Method[];
    }>()
);
export const fetchActionContextError = createNgRxAction('[ACTION] fetch action context error', props<{ error: any }>());

export const updateActionScope = createNgRxAction(
    '[ACTION] update action scope',
    props<{
        id: string;
        scope: Scope;
    }>()
);

export const findActionCalledActions = createNgRxAction('[ACTION] fetch action called actions', props<{ action: Action }>());
export const updateCalledActions = createNgRxAction(
    '[ACTION] update called actions',
    props<{
        id: string;
        calledActions: string[];
    }>()
);

export const markActionScopeOutdated = createNgRxAction('[ACTION] mark action scope outdated', props<{ action: Action }>());

export const markCalledActionsOutdated = createNgRxAction('[ACTION] mark called actions scope outdated', props<{ action: Action }>());

export const calculateScope = createNgRxAction('[ACTION] recalculate scope', props<{ action: Action }>());
