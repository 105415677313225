import { PartStyle } from '../../../part-style.model';
import { PagingButtonPartStyle } from './paging-button-part-style';
import { PagingTextPartStyle } from './paging-text-part-style';
import { GenericStyleDto } from '../../../../../interfaces/style/generic-style.dto';

export class PagingPartStyle extends PartStyle {
    normalStyle: GenericStyleDto;
    activeStyle: GenericStyleDto;
    focusStyle: GenericStyleDto;
    hoverStyle: GenericStyleDto;

    normalButtonStyle: PagingButtonPartStyle;
    activeButtonStyle: PagingButtonPartStyle;
    focusButtonStyle: PagingButtonPartStyle;
    hoverButtonStyle: PagingButtonPartStyle;

    normalTextStyle: PagingTextPartStyle;
    activeTextStyle: PagingTextPartStyle;
    focusTextStyle: PagingTextPartStyle;
    hoverTextStyle: PagingTextPartStyle;
}
