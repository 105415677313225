<div class="usage-information-wrapper" *ngIf="billingPackage">
    <div class="usage-info">
        <h2>{{ 'v2.billing.usage.data.title' | translate }}</h2>
        <div
            class="usage-circle"
            [attr.data-progress]="math.round((billingPackage?.dataUsage?.amount / billingPackage?.dataUsage?.maxAmount) * 100)"
            [attr.style]="'--progress: ' + (billingPackage?.dataUsage?.amount / billingPackage?.dataUsage?.maxAmount) * 365 + 'deg;'">
            {{ math.round((billingPackage?.dataUsage?.amount / billingPackage?.dataUsage?.maxAmount) * 100) }}
            %
        </div>
        <mat-list>
            <mat-list-item>
                <span>Current month:&nbsp;</span>{{ (billingPackage?.dataUsage?.amount / (1024 * 1024 * 1024)).toFixed(4) }} /&nbsp;<span
                    class="total-amount"
                    >{{ billingPackage?.dataUsage?.maxAmount / (1024 * 1024 * 1024) }} GB</span
                >
            </mat-list-item>
            <mat-list-item>
                <span>Last month:&nbsp;</span
                >{{ (billingPackage?.dataUsage?.lastMonthAmount / (1024 * 1024 * 1024)).toFixed(4) }} /&nbsp;<span class="total-amount"
                    >{{ billingPackage?.dataUsage?.maxAmount / (1024 * 1024 * 1024) }} GB</span
                >
            </mat-list-item>
        </mat-list>
    </div>

    <div class="usage-info">
        <h2>{{ 'v2.billing.usage.execution.title' | translate }}</h2>
        <div
            class="usage-circle"
            [attr.data-progress]="math.round((billingPackage?.executionUsage?.amount / billingPackage?.executionUsage?.maxAmount) * 100)"
            [attr.style]="
                '--progress: ' + (billingPackage?.executionUsage?.amount / billingPackage?.executionUsage?.maxAmount) * 365 + 'deg;'
            ">
            {{ math.round((billingPackage?.executionUsage?.amount / billingPackage?.executionUsage?.maxAmount) * 100) }}
            %
        </div>
        <mat-list>
            <mat-list-item>
                <span>Current month:&nbsp;</span>{{ (billingPackage?.executionUsage?.amount / 60000).toFixed(4) }} /&nbsp;<span
                    class="total-amount"
                    >{{ (billingPackage?.executionUsage?.maxAmount / 60000).toFixed(0) }} minutes</span
                >
            </mat-list-item>
            <mat-list-item>
                <span>Last month:&nbsp;</span>{{ (billingPackage?.executionUsage?.lastMonthAmount / 60000).toFixed(4) }} /&nbsp;<span
                    class="total-amount"
                    >{{ (billingPackage?.executionUsage?.maxAmount / 60000).toFixed(0) }} minutes</span
                ></mat-list-item
            >
        </mat-list>
    </div>

    <div class="usage-info">
        <h2>{{ 'v2.billing.usage.bandwidth.title' | translate }}</h2>
        <div
            class="usage-circle"
            [attr.data-progress]="math.round((billingPackage?.bandwidthUsage?.amount / billingPackage?.bandwidthUsage?.maxAmount) * 100)"
            [attr.style]="
                '--progress: ' + (billingPackage?.bandwidthUsage?.amount / billingPackage?.bandwidthUsage?.maxAmount) * 365 + 'deg;'
            ">
            {{ math.round((billingPackage?.bandwidthUsage?.amount / billingPackage?.bandwidthUsage?.maxAmount) * 100) }}
            %
        </div>
        <mat-list>
            <mat-list-item>
                <span>Current month:&nbsp;</span
                >{{ (billingPackage?.bandwidthUsage?.amount / (1024 * 1024 * 1024)).toFixed(4) }} /&nbsp;<span class="total-amount"
                    >{{ billingPackage?.bandwidthUsage?.maxAmount / (1024 * 1024 * 1024) }} GB</span
                >
            </mat-list-item>
            <mat-list-item>
                <span>Last month:&nbsp;</span
                >{{ (billingPackage?.bandwidthUsage?.lastMonthAmount / (1024 * 1024 * 1024)).toFixed(4) }} /&nbsp;<span class="total-amount"
                    >{{ billingPackage?.bandwidthUsage?.maxAmount / (1024 * 1024 * 1024) }} GB</span
                >
            </mat-list-item>
        </mat-list>
    </div>

    <div class="usage-info">
        <h2>{{ 'v2.billing.usage.user.title' | translate }}</h2>
        <div
            class="usage-circle"
            [attr.data-progress]="math.round((billingPackage?.userUsage?.amount / billingPackage?.userUsage?.maxAmount) * 100)"
            [attr.style]="'--progress: ' + (billingPackage?.userUsage?.amount / billingPackage?.userUsage?.maxAmount) * 365 + 'deg;'">
            {{ math.round((billingPackage?.userUsage?.amount / billingPackage?.userUsage?.maxAmount) * 100) }}
            %
        </div>
        <mat-list>
            <mat-list-item
                ><span>Current month:&nbsp;</span>{{ billingPackage?.userUsage?.amount }} /&nbsp;<span class="total-amount"
                    >{{ billingPackage?.userUsage?.maxAmount }} developers</span
                ></mat-list-item
            >
            <mat-list-item
                ><span>Last month:&nbsp;</span>{{ billingPackage?.userUsage?.lastMonthAmount }} /&nbsp;<span class="total-amount"
                    >{{ billingPackage?.userUsage?.maxAmount }} developers</span
                ></mat-list-item
            >
        </mat-list>
    </div>

    <div class="usage-info">
        <h2>AI Credits</h2>
        <div
            *ngIf="billingPackage?.aiCreditsUsage?.maxAmount > 0"
            class="usage-circle"
            [attr.data-progress]="math.round((billingPackage?.aiCreditsUsage?.amount / billingPackage?.aiCreditsUsage?.maxAmount) * 100)"
            [attr.style]="
                '--progress: ' + (billingPackage?.aiCreditsUsage?.amount / billingPackage?.aiCreditsUsage?.maxAmount) * 365 + 'deg;'
            ">
            {{ math.round((billingPackage?.aiCreditsUsage?.amount / billingPackage?.aiCreditsUsage?.maxAmount) * 100) }}
            %
        </div>
        <div
            *ngIf="billingPackage?.aiCreditsUsage?.maxAmount === 0"
            class="usage-circle"
            [attr.data-progress]="100"
            [attr.style]="'--progress: 365deg;'">
            {{ math.round((billingPackage?.aiCreditsUsage?.amount / billingPackage?.aiCreditsUsage?.maxAmount) * 100) }}
            %
        </div>
        <mat-list>
            <mat-list-item
                ><span>Credits used:&nbsp;</span>{{ billingPackage?.aiCreditsUsage?.amount }} /&nbsp;<span class="total-amount">{{
                    billingPackage?.aiCreditsUsage?.maxAmount
                }}</span></mat-list-item
            >
        </mat-list>
    </div>
</div>
