<div class="overview-item" (click)="onClicked()">
    <div class="icon">
        <input (click)="$event.stopPropagation(); onCheckboxSelected()" id="default-checkbox" type="checkbox" value="" class="checkbox" />
        <i class="material-icons">
            {{ data.iconName ? data.iconName : 'content_paste' }}
        </i>
    </div>
    <div class="name-data">
        <div class="name">
            <span>{{ data.name }}</span>
        </div>
        <div class="product-reference" *ngIf="data.productReference">
            <mat-icon class="text-lg leading-6 !h-5 !w-5">electrical_services</mat-icon>
            <div class="leading-6">{{ data.productReferenceName }}</div>
        </div>
    </div>
    <div class="audit-data">
        <div>
            {{ 'general.search.result.created.by' | translate }}
            {{ data.createdBy }}
            {{ 'general.search.result.on' | translate }} {{ data.creationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
        <div>
            {{ 'general.search.result.updated.by' | translate }} {{ data.modifiedBy }} {{ 'general.search.result.on' | translate }}
            {{ data.modificationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
    </div>
    <div class="attribute-icons">
        <mat-chip-listbox *ngIf="data.environment">
            <mat-chip>{{ data.environment }}</mat-chip>
        </mat-chip-listbox>
    </div>
</div>
