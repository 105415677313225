import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from '../../models/product.model';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';

@Component({
    selector: 'codex-product-overview-row',
    templateUrl: './product-overview-row.component.html',
    styleUrls: ['./product-overview-row.component.scss'],
})
export class ProductOverviewRowComponent {
    @Input() data: Product;
    @Output() installedClicked = new EventEmitter<Product>();
    @Output() uninstallClicked = new EventEmitter<string>();

    environment: BackofficeEnvironmentDto = backofficeEnvironment;

    handleInstallLatestVersion(data: Product): void {
        this.installedClicked.emit(data);
    }

    handleUninstallLatestVersion(id: string): void {
        this.uninstallClicked.emit(id);
    }

    protected readonly window = window;
}
