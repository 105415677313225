import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, delay, retryWhen, take, tap } from 'rxjs/operators';
import { clearErrors, setErrors } from '../../statemanagement/actioncreator/error.actioncreator';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../store/application.state';
import { backofficeEnvironment } from '@shared/environment';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { Profile } from '../../../dto/profile.dto';
import { LoggerService } from '@backoffice/utils';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { ApplicationUsageExceededAlertComponent } from '@billing/components/application-usage-exceeded-alert/application-usage-exceeded-alert.component';
import { TranslateService } from '@ngx-translate/core';

export const InterceptorSkipHeader = 'X-Skip-Error-Interceptor';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private readonly USER_PROFILE: string = 'user.profile';

    constructor(
        private router: Router,
        private store: Store<ApplicationState>,
        private httpClient: HttpClient,
        private snackBar: MatSnackBar,
        private readonly dialog: MatDialog,
        private readonly logger: LoggerService,
        private readonly translateService: TranslateService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            withCredentials: true,
        });
        if (req.headers.has(InterceptorSkipHeader)) {
            return next.handle(req);
        } else {
            return next.handle(req).pipe(
                catchError(error => this.handleErrorsObservable(error, req, next)),
                retryWhen(errors => this.shouldRetry(errors))
            );
        }
    }

    shouldRetry(errors: Observable<any>): Observable<any> {
        return errors.pipe(
            delay(500),
            tap(errorStatus => {
                if (!errorStatus.message.startsWith('token.refreshed')) {
                    throw errorStatus;
                } else {
                    const profile: Profile = Object.assign({}, JSON.parse(sessionStorage.getItem(this.USER_PROFILE)));
                    this.logger.info('Token was refreshed retrying now ' + profile.token);
                }
            }),
            take(3)
        );
    }

    handleErrorsObservable(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (this.isCaptchaRequired(error)) {
            this.store.dispatch(setErrors(error.error));
        } else if (error.status === 404) {
            return this.handleResourceNotFoundErrors();
        } else if (error.status === 403) {
            return this.handleForbiddenError(error);
        } else if (error.status === 500) {
            return this.handleUnknownError(req, error);
        } else if (error.status === 509) {
            return this.handleUsageError(error);
        } else if (error.status === 400) {
            return this.handleUnknownError(req, error);
        } else if (error.status !== 200) {
            return this.handleUnknownError(req, error);
        } else {
            this.store.dispatch(clearErrors());
            return EMPTY;
        }
    }

    handleResourceNotFoundErrors() {
        this.router.navigateByUrl('status/404', { replaceUrl: true });
        return EMPTY;
    }

    handleUnknownError(req: HttpRequest<any>, error: HttpErrorResponse): any {
        if (req.headers.has('handle-errors')) {
            throw error;
        } else {
            const response = this.translateService.instant(error.error.message);
            let text;
            if (response === `???${error.error.message}???`) {
                text = this.translateService.instant('error.unknown.error');
            } else {
                text = response;
            }
            this.snackBar.open(text, null, {
                duration: 10000,
                panelClass: 'error',
            });
        }
        return EMPTY;
    }

    handleForbiddenError(error: HttpErrorResponse): any {
        if (!backofficeEnvironment.production) {
            this.snackBar.open('error.forbidden', null, {
                duration: 10000,
                panelClass: 'error',
            });
        }
        return EMPTY;
    }

    handleUsageError(error: HttpErrorResponse): any {
        this.dialog.open(ApplicationUsageExceededAlertComponent, backofficeEnvironment.dialogConfig.normal);
        return EMPTY;
    }

    getHeader(error: HttpErrorResponse, headerName: string): string {
        if (error && error.headers) {
            return error.headers.get(headerName);
        } else {
            return null;
        }
    }

    isCaptchaRequired(error: HttpErrorResponse): boolean {
        const failedRequests: string = this.getHeader(error, 'x-failed-requests');
        return failedRequests && !isNaN(Number(failedRequests)) && Number(failedRequests) >= 5;
    }
}

export const HttpErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
};
