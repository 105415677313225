import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { backofficeEnvironment } from '@shared/environment';
import { AddIdentityproviderComponent } from '../add-identityprovider/add-identityprovider.component';
import { CompanyIdentityProvider } from '@shared/interfaces/company-identity-provider.dto';
import { ConfirmDialog } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';

@Component({
    selector: 'codex-company-edit-authentication-identityproviders',
    templateUrl: './company-edit-authentication-identityproviders.component.html',
    styleUrls: ['./company-edit-authentication-identityproviders.component.scss'],
})
export class CompanyEditAuthenticationIdentityprovidersComponent implements OnInit {
    @Input()
    company: CompanyDto;

    @Input()
    identityProviders: CompanyIdentityProvider[];

    @Output()
    createIdentityProvider: EventEmitter<{ companyIdentityProvider: CompanyIdentityProvider }> = new EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }>();

    @Output()
    updateIdentityProvider: EventEmitter<{ companyIdentityProvider: CompanyIdentityProvider }> = new EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }>();

    @Output()
    deleteIdentityProvider: EventEmitter<{ companyIdentityProvider: CompanyIdentityProvider }> = new EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }>();

    displayedColumns: string[] = ['name', 'actions'];

    constructor(
        private dialog: MatDialog,
        private confirmDialog: ConfirmDialog
    ) {}

    ngOnInit(): void {}

    onDeleteIdentityProvider(identityProvider: CompanyIdentityProvider): void {
        this.confirmDialog.showConfirmDialog(
            'v2.company.edit.identityproviders.delete.title',
            'v2.company.edit.identityproviders.delete.description',
            'v2.company.edit.identityproviders.delete.ok',
            'v2.company.edit.identityproviders.delete.cancel',
            'v2.company.edit.identityproviders.delete.success.title',
            'v2.company.edit.identityproviders.delete.success.description',
            () => {
                this.deleteIdentityProvider.emit({ companyIdentityProvider: identityProvider });
            }
        );
    }

    onEditIdentityProvider(identityProvider: CompanyIdentityProvider): void {
        this.dialog
            .open(
                AddIdentityproviderComponent,
                Object.assign(
                    {
                        data: {
                            identityProvider: identityProvider,
                            company: this.company,
                        },
                    },
                    backofficeEnvironment.dialogConfig.big
                )
            )
            .afterClosed()
            .subscribe(result => {
                if (!!result && !!result.idpToSave) {
                    this.updateIdentityProvider.emit({ companyIdentityProvider: result.idpToSave });
                }
            });
    }

    onCreateIdentityProvider() {
        this.dialog
            .open(AddIdentityproviderComponent, Object.assign({ data: { company: this.company } }, backofficeEnvironment.dialogConfig.big))
            .afterClosed()
            .subscribe(result => {
                if (!!result && !!result.idpToSave) {
                    this.createIdentityProvider.emit({ companyIdentityProvider: result.idpToSave });
                }
            });
    }
}
