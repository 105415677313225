<search-results-paging
    class="mat-elevation-z2"
    *ngIf="includePaging()"
    [currentPage]="currentPage"
    [firstPage]="firstPage"
    [lastPage]="pages.length"
    [pages]="pages">
</search-results-paging>
<div class="searchResults clearfix">
    <div class="results">
        <div class="no-results" *ngIf="count === 0">
            <img src="/src/images/theme/no_results_found.svg" />
            <h1>{{ noResultsMessage | translate }}</h1>
        </div>
        <ng-container *ngFor="let searchResult of searchResults">
            <mat-card *ngIf="searchResult" class="search-result">
                <mat-card-content>
                    <dynamic-component (select)="onSelect($event)" [selected]="allSelected" [type]="rowComponent" [data]="searchResult">
                    </dynamic-component>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </div>
    <div class="facets" *ngIf="_facetFields && _facetFields.size > 0 && hasEnoughFacets()">
        <search-facets
            [facetFields]="_facetFields"
            [filterFacets]="_filterFacets"
            [type]="type"
            [openedFacetList]="openedFacetList"></search-facets>
    </div>
</div>
