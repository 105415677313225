import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { Api } from '../../api/model/api';
import { ApiEditorState } from '../state/api-editor.state';
import {
    apiDialogFacetsChanged,
    apiDialogFilterPluginsChanged,
    apiDialogPaginationChanged,
    apiDialogSearchTermChanged,
    clearApiDialogData,
    clearApiDialogFilter,
    deleteApiSuccess,
    fetchApiSuccess,
    loadApiDialogSuccess,
    updateApiSuccess,
} from '../actions/api-editor.actions';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { SelectedFacets } from '../../dto/overview/facets.dto';
import { Page } from '@shared/data-access';
import { ApiDialogState } from '../state/api-dialog.state';
import { OverviewApiDto } from '../../dto/overview/overview-api.dto';
import { dataDialogFacetsChanged, dataDialogFilterPluginsChanged, dataDialogSearchTermChanged } from '../actions/data-editor.actions';

const adapterAction = createEntityAdapter<Api>();
export const apiInitialState: ApiEditorState = adapterAction.getInitialState();
export const apiDialogState: ApiDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const apiReducer = (initialData: ApiEditorState) =>
    createReducer(
        initialData,
        on(fetchApiSuccess, (state, { api }) => adapterAction.addOne(api, state)),
        on(deleteApiSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(updateApiSuccess, (state, { api }) => adapterAction.updateOne({ id: api.id, changes: api }, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );

const facetsReducer = (initialData: SelectedFacets | undefined) =>
    createReducer(
        initialData,
        on(apiDialogFacetsChanged, (state, { facets }) => facets),
        on(clearApiDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(apiDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearApiDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(apiDialogPaginationChanged, (_, { page }) => page),
        on(apiDialogSearchTermChanged, () => 0),
        on(clearApiDialogFilter, () => 0),
        on(apiDialogFacetsChanged, () => 0),
        on(apiDialogFilterPluginsChanged, () => 0),
        on(apiDialogSearchTermChanged, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(apiDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearApiDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(apiDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearApiDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewApiDto> | undefined) =>
    createReducer(
        initialData,
        on(loadApiDialogSuccess, (_, { data }) => data),
        on(clearApiDialogData, () => undefined)
    );

export const apiDialogReducers = combineReducers({
    page: pageReducer(apiDialogState.page),
    maxResults: maxResultsReducer(apiDialogState.maxResults),
    searchTerm: searchTermReducer(apiDialogState.searchTerm),
    result: resultReducer(apiDialogState.result),
    facets: facetsReducer(apiDialogState.facets),
    filterPlugins: filterPluginsReducer(apiDialogState.filterPlugins),
});
