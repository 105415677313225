import { Variable } from './util/variable.model';

export class ColorDefinition {
    name: string;
    type: 'RGB' | 'HSV' | 'HSL' | 'REFERENCE';
    value: string;
    variable: Variable;

    constructor(base?: Partial<ColorDefinition>) {
        Object.assign(this, base);
    }

    public update(type: 'RGB' | 'HSV' | 'HSL' | 'REFERENCE', value: string): void {
        this.type = type;
        this.value = value;
    }
}
