import { JsonSchema } from './json-schema';
import { Type } from 'class-transformer';
import { JsonProperty } from './json-property';
import { DataFormatAttribute } from './data-format-attribute';
import { JsonArrayItem } from './json-array-item';
import { GUIDFunctions } from '@shared/utils';
import { data } from 'autoprefixer';

export class DataFormat {
    public id: string;
    public name: string;
    public description: string;
    public iconName: string;
    public companyId: string;
    public applicationId: string;

    @Type(() => JsonSchema)
    public jsonSchema: JsonSchema | null = new JsonSchema();

    //Transient
    public attributes: DataFormatAttribute[] = [];

    public reference: string;
    public deleted: boolean;

    public static createDeleted(id: string): DataFormat {
        const dataFormat = new DataFormat();
        dataFormat.id = id;
        dataFormat.deleted = true;
        dataFormat.jsonSchema = null;
        return dataFormat;
    }

    public isValid(): boolean {
        return !!this.companyId && !!this.id && !!this.applicationId && !!this.name && this.name !== '';
    }

    public getSubFormats(): string[] {
        if (!this.jsonSchema.properties) {
            return [];
        } else {
            return this.getSubFormatsFromProperties('', this.jsonSchema.properties);
        }
    }

    public getSubFormatsFromProperties(
        prefix: string,
        properties:
            | {
                  [key: string]: JsonProperty;
              }
            | undefined
    ): string[] {
        let subTypes: any[] = [];
        if (!!properties) {
            for (const key of Object.keys(properties)) {
                const property = properties[key];
                if (property && property.type.indexOf('object') !== -1) {
                    subTypes.push(prefix + key);
                    const subTypeSubTypes = this.getSubFormatsFromProperties(prefix + key + '.', property.properties);
                    if (subTypeSubTypes && subTypeSubTypes.length > 0) {
                        subTypes = subTypes.concat(subTypeSubTypes);
                    }
                } else if (property && property.type.indexOf('array') !== -1) {
                    subTypes.push(prefix + key);
                    subTypes.push(prefix + key + '.arrayitem');
                    if (property.items) {
                        const subTypeSubTypes = this.getSubFormatsFromProperties(prefix + key + '.', property.items.properties);
                        if (subTypeSubTypes && subTypeSubTypes.length > 0) {
                            subTypes = subTypes.concat(subTypeSubTypes);
                        }
                    }
                }
            }
        }
        return subTypes;
    }
}

export function attributesToJsonSchema(dataFormat: DataFormat, attributes: DataFormatAttribute[]) {
    const jsonSchema = new JsonSchema();
    jsonSchema.title = dataFormat.name;
    jsonSchema.description = dataFormat.description;
    jsonSchema.required = attributeChildrenToRequired(attributes);
    attributes.forEach(attribute => {
        jsonSchema.properties[attribute.name] = attributeToProperty(attribute);
    });
    return jsonSchema;
}

function attributeToProperty(attribute: DataFormatAttribute) {
    const jsonSchemaProperty: JsonProperty = {
        description: attribute.description,
        type: attributeToTypes(attribute),
        noCodeXType: attribute.noCodeXType,
        required: attributeChildrenToRequired(attribute.attributes),
        multipleOf: attribute.multipleOf,
        minimum: attribute.minimum,
        exclusiveMinimum: attribute.exclusiveMinimum,
        maximum: attribute.maximum,
        exclusiveMaximum: attribute.exclusiveMaximum,
        minimumLength: attribute.minimumLength,
        maximumLength: attribute.maximumLength,
        pattern: attribute.pattern,
        enum: attribute.enum,
        format: attribute.format,
        dataClassification: attribute.dataClassification,
        properties: {},
    };
    if (attribute.attributes) {
        attribute.attributes.forEach(attribute => {
            if (jsonSchemaProperty.properties) {
                jsonSchemaProperty.properties[attribute.name] = attributeToProperty(attribute);
            }
        });
    }

    if (attribute.arrayDefinition) {
        const jsonSchemaArrayItem: JsonArrayItem = {
            noCodeXType: attribute.arrayDefinition.noCodeXType,
            type: attribute.arrayDefinition.types,
            required: attributeChildrenToRequired(attribute.arrayDefinition.attributes),
            properties: {},
        };
        if (attribute.arrayDefinition.attributes) {
            attribute.arrayDefinition.attributes.forEach(item => {
                if (jsonSchemaArrayItem.properties) {
                    jsonSchemaArrayItem.properties[item.name] = attributeToProperty(item);
                }
            });
        }
        jsonSchemaProperty.items = jsonSchemaArrayItem;
    }

    return jsonSchemaProperty;
}

function attributeToTypes(attribute: DataFormatAttribute) {
    const types = attribute.types;
    if (attribute.nullable && !types.find(type => type === 'null')) {
        types.push('null');
    }
    return types;
}
function attributeChildrenToRequired(attributes: DataFormatAttribute[] | undefined): string[] {
    return attributes ? attributes.filter(attribute => attribute.required).map(attribute => attribute.name) : [];
}

export function jsonSchemaToAttributes(jsonSchema: JsonSchema): DataFormatAttribute[] {
    const dataFormatAttributes: DataFormatAttribute[] = [];
    if (jsonSchema.properties) {
        Object.keys(jsonSchema.properties).forEach(propertyKey => {
            if (jsonSchema.properties) {
                const property = jsonSchema.properties[propertyKey];
                dataFormatAttributes.push(jsonPropertyToAttribute(propertyKey, property, jsonSchema.required));
            }
        });
    }
    return dataFormatAttributes;
}

function jsonPropertyToAttribute(propertyKey: string, jsonProperty: JsonProperty, required: string[] | undefined): DataFormatAttribute {
    return {
        id: new GUIDFunctions().newGuid(),
        name: propertyKey,
        description: jsonProperty.description,
        required: required ? required?.indexOf(propertyKey) > -1 : false,
        nullable: jsonProperty.type.indexOf('null') > -1,
        noCodeXType: jsonProperty.noCodeXType,
        types: typeof jsonProperty.type === 'string' ? [jsonProperty.type] : jsonProperty.type.filter(type => type !== 'null'),
        attributes: jsonProperty.properties
            ? Object.keys(jsonProperty.properties).map(propertyKey =>
                  jsonPropertyToAttribute(propertyKey, jsonProperty.properties[propertyKey], jsonProperty.required)
              )
            : [],
        arrayDefinition: jsonItemToAttributes(jsonProperty.items),
        dataClassification: jsonProperty.dataClassification,
        multipleOf: jsonProperty.multipleOf,
        minimum: jsonProperty.minimum,
        exclusiveMinimum: jsonProperty.exclusiveMinimum,
        maximum: jsonProperty.maximum,
        exclusiveMaximum: jsonProperty.exclusiveMaximum,
        minimumLength: jsonProperty.minimumLength,
        maximumLength: jsonProperty.maximumLength,
        pattern: jsonProperty.pattern,
        enum: jsonProperty.enum,
        domain: jsonProperty.domain,
        format: jsonProperty.format,
        minItems: jsonProperty.minItems,
        uniqueItems: jsonProperty.uniqueItems,
    };
}

function jsonItemToAttributes(jsonArrayItem: JsonArrayItem | undefined): DataFormatAttribute | undefined {
    if (jsonArrayItem) {
        const dataFormatAttribute: DataFormatAttribute = {
            noCodeXType: jsonArrayItem.noCodeXType,
            types: jsonArrayItem.type,
        };
        const dataFormatAttributes: DataFormatAttribute[] = [];
        if (jsonArrayItem && jsonArrayItem.properties) {
            Object.keys(jsonArrayItem.properties).forEach(propertyKey => {
                if (jsonArrayItem.properties) {
                    const property = jsonArrayItem.properties[propertyKey];
                    dataFormatAttributes.push(jsonPropertyToAttribute(propertyKey, property, jsonArrayItem.required));
                }
            });
        }
        dataFormatAttribute.attributes = dataFormatAttributes;
        return dataFormatAttribute;
    } else {
        return undefined;
    }
}
