<div class="overview-item" (click)="onClicked()">
    <div class="icon">
        <input (click)="$event.stopPropagation(); onCheckboxSelected()" id="default-checkbox" type="checkbox" value="" class="checkbox" />
        <mat-icon *ngIf="media.extension === 'default'" fontIcon="content_paste"></mat-icon>
        <img class="thumbnail" *ngIf="media.extension === 'pdf'" src="/src/images/theme/pdf.png" />
        <img class="thumbnail" *ngIf="media.extension === 'word'" src="/src/images/theme/doc.png" />
        <img class="thumbnail" *ngIf="media.extension === 'excel'" src="/src/images/theme/xls.png" />
        <img class="thumbnail" *ngIf="media.extension === 'csv'" src="/src/images/theme/csv.png" />
        <img class="thumbnail" *ngIf="media.extension === 'zip'" src="/src/images/theme/zip.png" />
        <img class="thumbnail" *ngIf="media.extension === 'xml'" src="/src/images/theme/xml.png" />
        <img class="thumbnail" *ngIf="media.extension === 'json'" src="/src/images/theme/json.png" />
        <img class="thumbnail" *ngIf="media.extension === 'text'" src="/src/images/theme/txt.png" />
        <img class="thumbnail" *ngIf="media.extension === 'mp4'" src="/src/images/theme/mp4.png" />
        <img class="thumbnail" *ngIf="media.extension === 'mp3'" src="/src/images/theme/mp3.png" />
        <img class="thumbnail" *ngIf="media.extension === 'md'" src="/src/images/theme/md.png" />
        <img
            class="thumbnail"
            *ngIf="media.extension === 'image'"
            [src]="
                environment.rest.v2.media + 'file/company/' + media.companyId + '/application/' + media.applicationId + '/' + media.id
            " />
    </div>
    <div class="name-data">
        <div class="name">
            <span>{{ media.title }}</span>
        </div>
        <div class="product-reference" *ngIf="media.productReference">
            <mat-icon class="text-lg leading-6 !h-5 !w-5">electrical_services</mat-icon>
            <div class="leading-6">{{ media.productReferenceName }}</div>
        </div>
    </div>
    <div class="audit-data">
        <div>
            {{ 'general.search.result.created.by' | translate }}
            {{ media.createdBy }}
            {{ 'general.search.result.on' | translate }} {{ media.creationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
        <div>
            {{ 'general.search.result.updated.by' | translate }} {{ media.modifiedBy }} {{ 'general.search.result.on' | translate }}
            {{ media.modificationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
    </div>
</div>
