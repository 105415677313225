export class ChartTitleOptions {
    id?: string;
    show?: boolean;
    text?: string;
    textStyle?: TextStyle;
    link?: string;
    target?: 'self' | 'blank';
    left?: 'center';

    constructor(base?: Partial<ChartTitleOptions>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.show = base.show ?? this.show;
            this.text = base.text ?? this.text;
            this.link = base.link ?? this.link;
            this.target = base.target ?? this.target;
            this.left = base.left ?? this.left;
        }
    }
}

export interface TextStyle {
    color?: string;
    fontStyle?: string;
    fontWeight?: string;
    fontFamily?: string;
    fontSize?: number;
}
