<div class="relative group mb-2">
    <button #toggleButton (click)="toggleDropdown()" id="dropdown-button" class="small-dropdown-button">
        <span class="mr-2 grow text-xs text-left">{{ value ? getLabel(value) : placeHolder }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
                fill-rule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd" />
        </svg>
    </button>
    <div #menu *ngIf="openedDropdown" id="dropdown-menu" class="small-dropdown-items">
        <!-- Search input -->
        <input
            *ngIf="showSearch"
            id="search-input"
            class="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
            type="text"
            placeholder="Search items"
            autocomplete="off"
            [ngModel]="filter$ | async"
            (ngModelChange)="filter$.next($event)" />
        <!-- Dropdown content goes here -->
        <a *ngFor="let item of items" (click)="this.select.emit(item); openedDropdown = false" class="small-dropdown-item">{{
            item.label
        }}</a>
    </div>
</div>
