import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggerService } from '@backoffice/utils';
import { backofficeEnvironment } from '@shared/environment';
import { Page, PageDto } from '@shared/data-access';
import { NgHttpCachingHeaders } from 'ng-http-caching';
import { Installation } from '../../../../../../libs/backoffice/feature/company/marketplace/src/lib/models/installation.model';

@Injectable()
export class InstallationService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly logger: LoggerService
    ) {}

    findAll(options: { searchTerm?: string; page?: number; maxResults?: number; applicationId?: string }): Observable<Page<Installation>> {
        this.logger.debug('[GET] requesting all installation');

        let params = new HttpParams();
        if (options) {
            const { page, maxResults, applicationId, searchTerm } = options;
            if (page) {
                params = params.append('page', page);
            }
            if (maxResults) {
                params = params.append('maxResults', maxResults);
            }
            if (applicationId) {
                params = params.append('applicationId', applicationId);
            }
            if (searchTerm) {
                params = params.append('searchTerm', searchTerm);
            }
        }
        return this.httpClient.get<PageDto<Installation>>(`${backofficeEnvironment.rest.v2.installations}`, {
            params,
            headers: {
                [NgHttpCachingHeaders.DISALLOW_CACHE]: '1',
            },
        });
    }

    findByProductAndApplication(productId: string, applicationId: string): Observable<Installation> {
        this.logger.debug('[GET] requesting installation by product and application');
        return this.httpClient.get<Installation>(`${backofficeEnvironment.rest.v2.installations}byproductid`, {
            params: new HttpParams().append('productId', productId).append('applicationId', applicationId),
            headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
        });
    }
    findById(installationId: string): Observable<Installation> {
        this.logger.debug('[GET] requesting installation by id');
        return this.httpClient.get<Installation>(`${backofficeEnvironment.rest.v2.installations}${installationId}`, {
            headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
        });
    }
}
