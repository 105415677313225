import { createAction, props } from '@ngrx/store';
import { Data } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/model/data';
import { Page } from '@shared/data-access';
import { OverviewDataDto } from '../../dto/overview/overview-data.dto';
import { SelectedFacets } from '@backoffice/data-access/editor';

export const createDataSuccess = createAction('[EFFECT] create new data success', props<{ id: string; name: string; openTab: boolean }>());

export const findData = createAction('[ACTION] fetch data', props<{ id: string }>());
export const fetchDataSuccess = createAction('[EFFECT] fetch data success', props<{ data: Data }>());
export const fetchDataError = createAction('[ACTION] fetch data error', props<{ error: any }>());

export const deleteDataFromStore = createAction('[ACTION] delete data from store', props<{ id: string }>());
export const deleteData = createAction('[ACTION] delete data', props<{ id: string }>());
export const deleteDataSuccess = createAction('[EFFECT] delete format success', props<{ id: string }>());

export const updateData = createAction('[ACTION] update data', props<{ data: Data }>());
export const updateDataSuccess = createAction('[EFFECT] update data success', props<{ data: Data }>());

// Editor Dialog Actions

export const clearDataDialogData = createAction('[ACTION] data editor dialog data cleared');
export const clearDataDialogFilter = createAction('[ACTION] data editor dialog filter cleared');

export const deleteDatas = createAction('[ACTION] delete datad', props<{ ids: string[] }>());
export const deleteDatasSuccess = createAction('[EFFECT] delete datas success', props<{ ids: string[] }>());

export const loadDataDialogData = createAction('[ACTION] load data editor dialog data');
export const loadDataDialogSuccess = createAction(
    '[EFFECT] load data editor dialog data success',
    props<{ data: Page<OverviewDataDto> }>()
);

export const dataDialogPaginationChanged = createAction(
    '[ACTION] data editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const dataDialogSearchTermChanged = createAction(
    '[ACTION] data editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const dataDialogFacetsChanged = createAction('[ACTION] data editor dialog facets changed', props<{ facets: SelectedFacets }>());

export const dataDialogFilterPluginsChanged = createAction(
    '[ACTION] data editor filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
