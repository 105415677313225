import { Injectable } from '@angular/core';
import { LoggerService } from '@backoffice/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '@shared/data-access';

import { backofficeEnvironment } from '@shared/environment';
import { CreateJobDto } from '../dto/create-job.dto';
import { JobCreatedDto } from '../dto/job-created.dto';
import { Job } from '../dto/job.dto';
import { OverviewJobDto } from '../dto/overview/overview-job.dto';

@Injectable({
    providedIn: 'root',
})
export class JobService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public create(dto: CreateJobDto, companyId: string, applicationId: string): Observable<JobCreatedDto> {
        this.log.debug('Creating job', [dto]);
        return this.httpClient.post<JobCreatedDto>(
            `${backofficeEnvironment.rest.v2.job}company/${companyId}/application/${applicationId}`,
            dto
        );
    }

    public delete(id: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Deleting job <${id}>`);
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.job}company/${companyId}/application/${applicationId}/${id}`);
    }

    public findById(id: string, companyId: string, applicationId: string): Observable<Job> {
        this.log.debug(`Find job by id <${id}>`);
        return this.httpClient.get<Job>(`${backofficeEnvironment.rest.v2.job}company/${companyId}/application/${applicationId}/${id}`);
    }

    public update(job: Job, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Updating job`, [job]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.job}company/${companyId}/application/${applicationId}/${job.id}`,
            job
        );
    }

    public findAll(
        companyId: string,
        applicationId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<OverviewJobDto>> {
        this.log.debug('Getting job overview');
        let params = new HttpParams().set('companyId', companyId).set('applicationId', applicationId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('keyword', keyword);
            }

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }
        }

        return this.httpClient.get<Page<OverviewJobDto>>(`${backofficeEnvironment.rest.v2.job}`, { params });
    }
}
