import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { JobService } from '../../services/job.service';
import {
    clearJobDialogFilter,
    createJobSuccess,
    deleteJob,
    deleteJobs,
    deleteJobsSuccess,
    deleteJobSuccess,
    fetchJobSuccess,
    findJob,
    jobDialogFacetsChanged,
    jobDialogFilterPluginsChanged,
    jobDialogPaginationChanged,
    jobDialogSearchTermChanged,
    loadJobDialogData,
    loadJobDialogSuccess,
    updateJob,
    updateJobSuccess,
} from '../actions/job-editor.actions';
import { jobEditorSelectors } from '../selectors/job-editor.selectors';
import { toFilter } from '@backoffice/data-access/editor';
import { JobEditorFacade } from '../facades/job-editor.facade';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { forkJoin } from 'rxjs';
import { Page } from '@shared/data-access';
import { OverviewJobDto } from '../../dto/overview/overview-job.dto';

@Injectable()
export class JobEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly jobService: JobService,
        private readonly editorFacade: JobEditorFacade,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearJobDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobs),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.jobService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteJobsSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobsSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'job' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJob),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.jobService.delete(id, companyId, applicationId).pipe(map(() => deleteJobSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findJob),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(jobEditorSelectors.byId(id)),
            ]),
            filter(([_job, company, application, detail]) => !detail && !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.jobService.findById(id, companyId, applicationId).pipe(map(result => fetchJobSuccess({ job: result })))
            )
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteJobsSuccess,
                loadJobDialogData,
                jobDialogPaginationChanged,
                jobDialogSearchTermChanged,
                jobDialogFacetsChanged,
                jobDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.jobService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewJobDto>) => loadJobDialogSuccess({ data }))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJob),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ job }, { id: companyId }, { id: applicationId }]) =>
                this.jobService.update(job, companyId, applicationId).pipe(map(response => updateJobSuccess({ job })))
            )
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteJobSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'job' }))
        )
    );

    openSnack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateJobSuccess),
                tap(() => {
                    this.snackBar.open(this.translate.instant('v2.job.edit.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createJobSuccess),
            map(({ id }) => registerTab({ definition: { type: 'job', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateJobSuccess),
            map(({ job }) => {
                const { id: typeId, name, iconName: icon } = job;
                return updateTab({ definition: { type: 'job', typeId, name, icon } });
            })
        )
    );
}
