import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CurrentContext, LoggerService } from '@backoffice/utils';
import { JobService } from '../../services/job.service';
import {
    clearJobDialogData,
    createJobSuccess,
    deleteJobs,
    findJob,
    jobDialogFacetsChanged,
    jobDialogFilterPluginsChanged,
    jobDialogPaginationChanged,
    jobDialogSearchTermChanged,
    loadJobDialogData,
    updateJob,
} from '../actions/job-editor.actions';
import { jobEditorSelectors } from '../selectors/job-editor.selectors';
import { Job } from '../../dto/job.dto';
import { SelectedFacets } from '../../dto/overview/facets.dto';
import { Page } from '@shared/data-access';
import { OverviewJobDto } from '../../dto/overview/overview-job.dto';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';

@Injectable()
export class JobEditorFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.applicationStateStore.select(selectCurrentContext);

    get dialogData(): Observable<Page<OverviewJobDto> | undefined> {
        return this.store.select(jobEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(jobEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(jobEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(jobEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(jobEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{
        searchTerm: string | undefined;
        page: number;
        maxResults: number;
        facets: SelectedFacets;
        filterPlugins: boolean;
    }> {
        return this.store.select(jobEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly jobService: JobService,
        private readonly log: LoggerService,
        private readonly applicationStateStore: Store<ApplicationState>
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearJobDialogData());
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(jobDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(jobDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(jobDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(jobDialogFilterPluginsChanged({ filterPlugins }));
    }

    create(): Observable<string> {
        this.log.info('Create new job');
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext =>
                this.jobService
                    .create(
                        {
                            companyId: currentContext.selectedCompany.id,
                            applicationId: currentContext.selectedApplication.id,
                        },
                        currentContext.selectedCompany.id,
                        currentContext.selectedApplication.id
                    )
                    .pipe(
                        tap(({ id }) => this.store.dispatch(createJobSuccess({ id }))),
                        map(({ id }) => id)
                    )
            )
        );
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting job`, [ids]);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteJobs({ ids }));
        }
    }

    findById(id: string): Observable<Job> {
        this.log.info(`Find job by id ${id}`);
        this.store.dispatch(findJob({ id }));
        return this.store.select(jobEditorSelectors.byId(id)).pipe(filter((job: Job | undefined): job is Job => !!job));
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadJobDialogData());
    }

    update(job: Job): void {
        this.log.info(`Update job`, [job]);
        this.store.dispatch(updateJob({ job }));
    }
}
