import { Argument } from '../arguments/argument';

export class Output {
    public id?: string;
    public selectorId?: string;
    public names?: any;
    public descriptions?: any;
    public type?: string;
    public inputType?: string;
    public defaultValue: Argument;
    public subTypeParameterId: string;
    public subTypePathParameterId: string;
    public linkedDataFormatParameterId?: string;
    public linkedActionParameterId?: string;
    public linkedArrayParameterId?: string;
    subTypePath: string;

    public getName(language?: string) {
        if (language && this.names && this.names[language]) {
            return this.names[language];
        } else if (this.names) {
            return this.names['en'];
        } else {
            return null;
        }
    }

    public getDescription(language: string) {
        if (language && this.descriptions && this.descriptions[language]) {
            return this.descriptions[language];
        } else if (this.descriptions) {
            return this.descriptions['en'];
        } else {
            return null;
        }
    }
}
