import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressComponent } from '../progress';
import { DynamicComponent } from './dynamic-component/dynamic-component.component';
import { CodexMaterialModule } from '../material/codex.module';
import { AvatarModule } from 'ngx-avatars';

@NgModule({
    declarations: [ProgressComponent, DynamicComponent],
    imports: [FormsModule, ReactiveFormsModule, CommonModule, CodexMaterialModule, RouterModule, TranslateModule, AvatarModule],
    exports: [ProgressComponent, DynamicComponent],
})
export class LayoutModule {}
