import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Media, MediaEditorFacade, SelectedFacets } from '@backoffice/data-access/editor';
import { map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmDialog } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';
import { GroupEditorFacade } from '../../../../../../../data-access/editor/src/lib/group/store/facades/group-editor.facade';
import { OverviewGroupDto } from '../../../../../../../data-access/editor/src/lib/group/dto/overview-group.dto';

@Component({
    selector: 'codex-search-group-dialog',
    templateUrl: './search-group-dialog.component.html',
})
export class SearchGroupDialogComponent implements OnInit, OnDestroy {
    facets$ = this.editFacade.facets;
    page$ = this.editFacade.dialogData;
    pagination$ = this.editFacade.pagination;
    filterPlugins$ = this.editFacade.filterPlugins;
    searchTerm$ = this.editFacade.searchTerm.pipe(map(term => (!!term ? term : '')));
    selectedRows: string[] = [];

    @HostListener('document:keyup', ['$event'])
    handleFastKeysForConsole(event: KeyboardEvent) {
        if (event.altKey) {
            event.preventDefault();
            switch (event.key) {
                case 'n':
                    this.handleCreateClicked();
                    break;
                case 'r':
                    this.handleDeleteClicked();
                    break;
            }
        }
    }

    constructor(
        private readonly confirmDialog: ConfirmDialog,
        private readonly dialogRef: MatDialogRef<SearchGroupDialogComponent>,
        private readonly editFacade: GroupEditorFacade
    ) {}

    ngOnInit() {
        this.editFacade.initialiseDialogData();
    }

    ngOnDestroy() {
        this.editFacade.clearDialogData();
    }

    handleCreateClicked(): void {
        this.dialogRef.close({
            action: 'create',
            content: {
                type: 'group',
            },
        });
    }

    handleDeleteClicked(): void {
        if (this.selectedRows && this.selectedRows.length > 0) {
            this.confirmDialog.showConfirmDialog(
                `v2.group.overview.delete.title`,
                `v2.group.overview.delete.description`,
                `v2.group.overview.delete.ok`,
                `v2.group.overview.delete.cancel`,
                `v2.group.overview.delete.success.title`,
                `v2.group.overview.delete.success.description`,
                () => {
                    this.editFacade.delete(this.selectedRows);
                    this.selectedRows = [];
                }
            );
        }
    }

    handleFacetsChanged(facets: SelectedFacets): void {
        this.editFacade.changeFacets(facets);
    }

    handleFilterPluginsChanged(filterPlugins: boolean): void {
        this.editFacade.changeFilterPlugins(filterPlugins);
    }

    handleItemClicked(item: OverviewGroupDto): void {
        this.dialogRef.close({
            action: 'open',
            content: {
                type: 'group',
                name: item.name,
                typeId: item.id,
            },
        });
    }

    handlePaginationChanged(event: PageEvent): void {
        const { pageIndex: page, pageSize: maxResults } = event;
        this.editFacade.changePagination(page, maxResults);
    }

    handleRowSelectionChanged(selected: boolean, id: string): void {
        if (selected) {
            this.selectedRows.push(id);
        } else {
            this.selectedRows = this.selectedRows.filter(entry => entry !== id);
        }
    }

    handleSearchTermChanged(searchTerm: string): void {
        this.editFacade.changeSearchTerm(searchTerm);
    }
}
