import { NgModule } from '@angular/core';
import { ApplicationLogsPage } from './pages/application-logs/application-logs.page';
import { MatListModule } from '@angular/material/list';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { ApplicationLogsFacade } from './facade/application-logs.facade';
import { BackofficeUtilsRoutingModule } from '@backoffice/utils-routing';
import { BackofficeUiArgumentsModule } from '@backoffice/ui-arguments';
import { CommonsModule } from '../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { HttpLoaderInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-authentication.interceptor';
import { ApplicationLogService } from '@backoffice/data-access/application-logs';
import { BackofficeFeatureEditorPickersModule } from '../../../editor/pickers/src';
import { ApplicationLogLinesComponent } from './components/application-log-lines/application-log-lines.component';

@NgModule({
    declarations: [ApplicationLogsPage, ApplicationLogLinesComponent],
    imports: [
        CommonsModule,
        MatListModule,
        BackofficeUtilsRoutingModule,
        BackofficeUiArgumentsModule,
        NgxMatTimepickerModule,
        BackofficeFeatureEditorPickersModule,
    ],
    providers: [
        HttpLoaderInterceptorProvider,
        HttpErrorInterceptorProvider,
        HttpAuthenticationInterceptorProvider,
        ApplicationLogsFacade,
        ApplicationLogService,
    ],
    exports: [ApplicationLogLinesComponent],
})
export class BackofficeFeatureApplicationLogsModule {}
