import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../../../store/editor.state';
import { closeTab, closeTabs, registerTab, updateTab } from '../../../store/actions/editor.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';

import { toFilter } from '@backoffice/data-access/editor';
import { selectApplicationSuccess } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { Page } from '@shared/data-access';
import { RightsService } from '../../services/roles.service';
import { RightEditorFacade } from '../facades/right-editor.facade';
import {
    clearRightDialogFilter,
    createRightSuccess,
    deleteRight,
    deleteRights,
    deleteRightsSuccess,
    deleteRightSuccess,
    fetchRightError,
    fetchRightSuccess,
    findRight,
    loadRightDialogData,
    loadRightDialogSuccess,
    rightDialogFacetsChanged,
    rightDialogFilterPluginsChanged,
    rightDialogPaginationChanged,
    rightDialogSearchTermChanged,
    updateRight,
    updateRightSuccess,
} from '../actions/right-editor.actions';
import { rightEditorSelectors } from '../selectors/right-editor.selectors';
import { OverviewRightDto } from '../../dto/overview-right.dto';
import { createDeletedRight } from '../../dto/right.dto';

@Injectable()
export class RightEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly editorFacade: RightEditorFacade,
        private readonly rightsService: RightsService,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearRightDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteRights),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.rightsService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteRightsSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteRightsSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'right' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteRight),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.rightsService.delete(id, companyId, applicationId).pipe(map(() => deleteRightSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findRight),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(rightEditorSelectors.byId(id)),
            ]),
            filter(([_dataformat, company, application, detail]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }, detail]) => {
                if (detail) {
                    return of(fetchRightSuccess({ right: detail }));
                } else {
                    return this.rightsService.findById(id, companyId, applicationId).pipe(
                        map(result => fetchRightSuccess({ right: result })),
                        catchError(() => of(fetchRightError({ right: createDeletedRight(id) })))
                    );
                }
            })
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteRightsSuccess,
                loadRightDialogData,
                rightDialogPaginationChanged,
                rightDialogSearchTermChanged,
                rightDialogFacetsChanged,
                rightDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.rightsService.findAll(applicationId, companyId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewRightDto>) => loadRightDialogSuccess({ data }))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRight),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ right }, { id: companyId }, { id: applicationId }]) =>
                this.rightsService.update(right, companyId, applicationId).pipe(map(() => updateRightSuccess({ right })))
            )
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteRightSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'right' }))
        )
    );

    openSnack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateRightSuccess),
                tap(() => {
                    this.snackBar.open(this.translate.instant('v2.usermanagement.roles.edit.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createRightSuccess),
            filter(({ id, openTab }) => openTab),
            map(({ id }) => registerTab({ definition: { type: 'right', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateRightSuccess, fetchRightSuccess),
            map(({ right }) => {
                const { id: typeId, name, iconName: icon } = right;
                return updateTab({ definition: { type: 'right', typeId, name, icon } });
            })
        )
    );
}
