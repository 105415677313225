import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../../../store/editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { GroupDto } from 'libs/backoffice/data-access/editor/src/lib/group/dto/group.dto';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const designSystemAdapter = createEntityAdapter<GroupDto>();

const { selectIds, selectAll, selectTotal, selectEntities } = designSystemAdapter.getSelectors();

export const groupEditorSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.group)),
    dialogData: createSelector(selectState, state => state.groupDialog.result),
    dialogFilter: createSelector(selectState, state => {
        const { page, maxResults, searchTerm, facets, filterPlugins } = state.groupDialog;
        return {
            page,
            maxResults,
            searchTerm,
            facets,
            filterPlugins,
        };
    }),
    searchTerm: createSelector(selectState, state => state.groupDialog.searchTerm),
    pagination: createSelector(selectState, state => {
        const { page, maxResults } = state.groupDialog;
        return { page, maxResults };
    }),
    facets: createSelector(selectState, state => state.groupDialog.facets),
    filterPlugins: createSelector(selectState, state => state.groupDialog.filterPlugins),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<GroupDto> = selectEntities(state.group);
            if (id in dictionary) {
                return dictionary[id];
            }
            return undefined;
        }),
};
