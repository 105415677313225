import { PartDetail } from '../../../part-detail.model';

export class LinkPartDetail extends PartDetail {
    code: string;
    url: string;
    text: string;
    target: string;

    isValid(): boolean {
        return this.isCodeValid() && this.isUrlValid() && this.isTextValid() && this.isTargetValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isUrlValid(): boolean {
        return !!this.url && this.url !== '';
    }

    isTextValid(): boolean {
        return !!this.text && this.text !== '';
    }

    isTargetValid(): boolean {
        return !!this.target && this.target !== '';
    }

    getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.link.error.code');
        }
        if (!this.isUrlValid()) {
            validationErrors.push('v2.part.link.error.url');
        }
        if (!this.isTextValid()) {
            validationErrors.push('v2.part.link.error.text');
        }
        if (!this.isTargetValid()) {
            validationErrors.push('v2.part.link.error.target');
        }
        return validationErrors;
    }
}
