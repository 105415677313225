import { createAction, props } from '@ngrx/store';
import { DataFormat } from '../../models/data-format';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '@backoffice/data-access/editor';
import { OverviewDataFormatDto } from '../../interfaces/overview-data-format.dto';

export const createDataFormatSuccess = createAction('[EFFECT] create new data format success', props<{ id: string; openTab: boolean }>());

export const findDataFormat = createAction('[ACTION] fetch data format', props<{ id: string }>());
export const fetchDataFormatSuccess = createAction('[EFFECT] fetch data format success', props<{ dataFormat: DataFormat }>());
export const fetchDataFormatError = createAction('[ACTION] fetch data format error', props<{ dataFormat: DataFormat }>());

export const deleteDataFormat = createAction('[ACTION] delete data format', props<{ id: string }>());
export const deleteDataFormatSuccess = createAction('[EFFECT] delete data format success', props<{ id: string }>());

export const updateDataFormat = createAction('[ACTION] update data format', props<{ dataFormat: DataFormat; openTab: boolean }>());
export const updateDataFormatSuccess = createAction(
    '[EFFECT] update data format success',
    props<{ id: string; dataFormat: DataFormat; openTab: boolean }>()
);

// Editor Dialog Actions

export const clearDataFormatDialogData = createAction('[ACTION] data format editor dialog data cleared');
export const clearDataFormatDialogFilter = createAction('[ACTION] data format editor dialog filter cleared');

export const deleteDataFormats = createAction('[ACTION] delete data formats', props<{ ids: string[] }>());
export const deleteDataFormatsSuccess = createAction('[EFFECT] delete data formats success', props<{ ids: string[] }>());

export const loadDataFormatDialogData = createAction('[ACTION] load data format editor dialog data');
export const loadDataFormatDialogSuccess = createAction(
    '[EFFECT] load data format editor dialog data success',
    props<{ data: Page<OverviewDataFormatDto> }>()
);

export const dataFormatDialogPaginationChanged = createAction(
    '[ACTION] data format editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const dataFormatDialogSearchTermChanged = createAction(
    '[ACTION] data format editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const dataFormatDialogFacetsChanged = createAction(
    '[ACTION] data format editor dialog facets changed',
    props<{ facets: SelectedFacets }>()
);

export const dataFormatDialogFilterPluginsChanged = createAction(
    '[ACTION] data format editor filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
