import { PartStyle } from '../../../part-style.model';
import { ColorV2 } from '../../../../../designsystem/models/color/colorv2.model';

export class PieChartPartStyle extends PartStyle {
    series: PieChartDataSourceStyle[] = [];
    legend = new PieChartLegendStyle();
    centerX: string;
    centerY: string;
    radiusInner: string;
    radiusOuter: string;

    constructor(base?: Partial<PieChartPartStyle>) {
        super();

        if (!!base) {
            this.series = base.series ?? this.series;
            this.legend = base.legend ?? this.legend;
            this.centerX = base.centerX ?? this.centerX;
            this.centerY = base.centerY ?? this.centerY;
            this.radiusInner = base.radiusInner ?? this.radiusInner;
            this.radiusOuter = base.radiusOuter ?? this.radiusOuter;
        }
    }
}

export class PieChartDataSourceStyle {
    id: string;
    name: string;
    itemStyle = new PieChartSeriesItemStyleStyle();

    constructor(base?: Partial<PieChartDataSourceStyle>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.itemStyle = base.itemStyle ? new PieChartSeriesItemStyleStyle(base.itemStyle) : this.itemStyle;
        }
    }
}

export class PieChartSeriesItemStyleStyle {
    color?: string;
    colorV2?: ColorV2;

    constructor(base?: Partial<PieChartSeriesItemStyleStyle>) {
        if (!!base) {
            this.color = base.color ?? this.color;
            this.colorV2 = base.colorV2 ?? this.colorV2;
        }
    }
}

export class PieChartLegendStyle {
    show = true;
    orient?: 'vertical' | 'horizontal' = 'horizontal';
    left?: 'auto' | 'left' | 'center' | 'right';
    top?: 'auto' | 'top' | 'middle' | 'bottom';
}
