import { GridStyleDto } from '../../interfaces/grid/grid.style.dto';

export class PartType {
    public readonly id?: string;
    public readonly name?: string;
    public readonly key?: string;
    public readonly description?: string;
    public readonly iconName?: string;
    public readonly initialSizeX: number;
    public readonly initialSizeXUnit: string;
    public readonly initialSizeY: number;
    public readonly initialSizeYUnit: string;

    public readonly initialPositionX?: number;
    public readonly initialPositionXUnit?: string;
    public readonly initialPositionY?: number;
    public readonly initialPositionYUnit?: string;
    public readonly category: string;
    public readonly arguments?: any;
    // transient
    public grid: GridStyleDto;
    // transient
    public containerOffsetLeft = 0;
    // transient
    public containerOffsetTop = 0;
    // transient
    public visible: boolean = true;
}
