import { PartDetail } from '../../../part-detail.model';
import { Type } from 'class-transformer';
import { Argument } from '../../../../../arguments/argument';

export class DataTablePartDetail extends PartDetail {
    code: string;
    columns: DataTableColumn[];
    rows: DataTableRow[];
    addFilter: boolean;
    filterLabel: string;
    enablePaging: boolean;
    pageSize: number;
    showNoResults: boolean;
    noResultsText: string;

    override isValid(): boolean {
        return this.isCodeValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }
}

export class DataTableColumn {
    id: string;
    code: string;
    label: string;
    enableSort?: boolean;
    visible?: boolean;
    contentType: string;
    templateId?: string | null;
    @Type(() => Argument)
    arguments?: Argument[];
}

export class DataTableRow {
    id: string;
    code: string;
    columnData: {
        [key: string]: DataTableColumnData;
    };
    footerRow?: boolean;
    headerRow?: boolean;
}

export class DataTableColumnData {
    value?: string;
}
