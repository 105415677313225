import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { LoggerService } from '@backoffice/utils';
import { dataEditorSelectors } from '../selectors/data-editor.selectors';
import { Data } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-data/model/data';
import {
    clearDataDialogData,
    createDataSuccess,
    dataDialogFacetsChanged,
    dataDialogFilterPluginsChanged,
    dataDialogPaginationChanged,
    dataDialogSearchTermChanged,
    deleteDataFromStore,
    deleteDatas,
    findData,
    loadDataDialogData,
    updateData,
} from '../actions/data-editor.actions';
import { DataService } from '../../services/data.service';
import { AppFacade } from '@core/facades/app.facade';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialog } from '@angular/material/dialog';
import { DataFormatEditorFacade, SelectedFacets } from '@backoffice/data-access/editor';
import { Page } from '@shared/data-access';
import { OverviewDataDto } from '../../dto/overview/overview-data.dto';
import { ComponentType } from '@angular/cdk/overlay';

@Injectable()
export class DataEditorFacade {
    get dialogData(): Observable<Page<OverviewDataDto> | undefined> {
        return this.store.select(dataEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(dataEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(dataEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(dataEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(dataEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{ searchTerm: string | undefined; page: number; maxResults: number; facets: SelectedFacets; filterPlugins }> {
        return this.store.select(dataEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly dataService: DataService,
        private readonly dataFormatEditorFacade: DataFormatEditorFacade,
        private readonly appFacade: AppFacade,
        private readonly dialog: MatDialog,
        private readonly log: LoggerService
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearDataDialogData());
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(dataDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(dataDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(dataDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(dataDialogFilterPluginsChanged({ filterPlugins }));
    }

    create(dialogType: ComponentType<any>): Observable<string> {
        this.log.info('Create new data');
        return combineLatest([
            this.appFacade.selectedCompany,
            this.appFacade.selectedApplication,
            this.dialog.open(dialogType, Object.assign({ disableClose: true }, backofficeEnvironment.dialogConfig.normal)).afterClosed(),
        ]).pipe(
            take(1),
            filter(([company, application, result]) => !!company && !!application && !!result),
            switchMap(
                ([
                    { id: companyId },
                    { id: applicationId },
                    { dataName, dataFormatId, dataBody, environment: env, type, dataFormatIds, dataDescription, file, createDataFormat },
                ]) => {
                    if (type === 'CREATE_DATA_FROM_CSV' && createDataFormat) {
                        return this.dataFormatEditorFacade
                            .createDataFormat(companyId, applicationId, 'GENERATE_FROM_CSV', null, null, file, true)
                            .pipe(
                                switchMap(createdDataFormat => {
                                    return this.dataService
                                        .create(
                                            {
                                                companyId,
                                                applicationId,
                                                dataName,
                                                dataFormatId: createdDataFormat[0],
                                                dataBody,
                                                environment: env,
                                                type,
                                                dataFormatIds,
                                                dataDescription,
                                                file,
                                            },
                                            companyId,
                                            applicationId
                                        )
                                        .pipe(
                                            tap(({ id }) =>
                                                this.store.dispatch(
                                                    createDataSuccess({
                                                        id,
                                                        name: dataName,
                                                        openTab: type === 'BLANK',
                                                    })
                                                )
                                            ),
                                            map(({ id }) => id)
                                        );
                                })
                            );
                    } else {
                        return this.dataService
                            .create(
                                {
                                    companyId,
                                    applicationId,
                                    dataName,
                                    dataFormatId,
                                    dataBody,
                                    environment: env,
                                    type,
                                    dataFormatIds,
                                    dataDescription,
                                    file,
                                },
                                companyId,
                                applicationId
                            )
                            .pipe(
                                tap(({ id }) =>
                                    this.store.dispatch(
                                        createDataSuccess({
                                            id,
                                            name: dataName,
                                            openTab: type === 'BLANK',
                                        })
                                    )
                                ),
                                map(({ id }) => id)
                            );
                    }
                }
            )
        );
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting data <${ids}>`);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteDatas({ ids }));
        }
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadDataDialogData());
    }

    findById(id: string): Observable<Data> {
        this.log.info(`Find data by id ${id}`);
        this.store.dispatch(findData({ id }));
        return this.store.select(dataEditorSelectors.byId(id)).pipe(
            filter(data => !!data),
            map(data => data as Data)
        );
    }

    refresh(id: string) {
        this.store.dispatch(deleteDataFromStore({ id }));
        this.findById(id);
    }

    update(data: Data): void {
        this.log.info(`Update data`, [data]);
        this.store.dispatch(updateData({ data }));
    }
}
