import { combineReducers, createReducer, on } from '@ngrx/store';
import { routerCancel, routerError, routerNavigated, routerNavigation, routerRequest } from './router.actions';
import { routerInitialState, RouterStateUrl } from './router.state';

const rootReducer = (initialRoot: boolean) =>
    createReducer(
        initialRoot,
        on(
            routerRequest,
            (_, { payload }) => payload.event.id === 1 || (!!payload.event.restoredState && payload.event.restoredState.navigationId === 1)
        )
    );

const routeReducer = (initialRoute: RouterStateUrl) =>
    createReducer(
        initialRoute,
        on(routerRequest, routerNavigation, routerCancel, routerError, routerNavigated, (_, { payload }) => payload.routerState)
    );

export const routerReducer = combineReducers({
    isRoot: rootReducer(routerInitialState.isRoot),
    route: routeReducer(routerInitialState.route),
});
