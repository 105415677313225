<ng-container *ngIf="comparison$ | async as comparison">
    <mat-card>
        <div class="ml-3 mt-3 mr-3 mb-1">
            <h1 class="grow !text-xl pt-1 !m-0">Comparing development against {{ comparison.baseVersionName }}</h1>
            <p>{{ comparison.totals.total }} changes detected</p>
        </div>
        <ng-container *ngIf="comparison.totals.total > 0; else noChanges">
            <div class="ml-3 mb-3 mr-3 mt-1 flex-col flex" *ngIf="comparison.actions.totals.total !== 0">
                <h2 class="!mt-4">Actions</h2>
                <mat-expansion-panel class="!mt-1" *ngFor="let change of comparison.actions.changes" togglePosition="before">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ change.name }}</mat-panel-title>
                        <mat-icon *ngIf="change.type === 'INSERTION'" class="icon--inserted">add_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'UPDATE'" class="icon--updated">edit_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'DELETION'" class="icon--deleted">delete_circle</mat-icon>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </div>
            <div class="ml-3 mb-3 mr-3 mt-1 flex-col flex" *ngIf="comparison.apis.totals.total !== 0">
                <h2 class="!mt-4">Apis</h2>
                <mat-expansion-panel class="!mt-1" *ngFor="let change of comparison.apis.changes" togglePosition="before">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ change.name }}</mat-panel-title>
                        <mat-icon *ngIf="change.type === 'INSERTION'" class="icon--inserted">add_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'UPDATE'" class="icon--updated">edit_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'DELETION'" class="icon--deleted">delete_circle</mat-icon>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </div>
            <div class="ml-3 mb-3 mr-3 mt-1 flex-col flex" *ngIf="comparison.dataFormats.totals.total !== 0">
                <h2 class="!mt-4">Dataformats</h2>
                <mat-expansion-panel class="!mt-1" *ngFor="let change of comparison.dataFormats.changes" togglePosition="before">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ change.name }}</mat-panel-title>
                        <mat-icon *ngIf="change.type === 'INSERTION'" class="icon--inserted">add_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'UPDATE'" class="icon--updated">edit_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'DELETION'" class="icon--deleted">delete_circle</mat-icon>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </div>
            <div class="ml-3 mb-3 mr-3 mt-1 flex-col flex" *ngIf="comparison.jobs.totals.total !== 0">
                <h2 class="!mt-4">Jobs</h2>
                <mat-expansion-panel class="!mt-1" *ngFor="let change of comparison.jobs.changes" togglePosition="before">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ change.name }}</mat-panel-title>
                        <mat-icon *ngIf="change.type === 'INSERTION'" class="icon--inserted">add_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'UPDATE'" class="icon--updated">edit_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'DELETION'" class="icon--deleted">delete_circle</mat-icon>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </div>
            <div class="ml-3 mb-3 mr-3 mt-1 flex-col flex" *ngIf="comparison.templates.totals.total !== 0">
                <h2 class="!mt-4">Templates</h2>
                <mat-expansion-panel class="!mt-1" *ngFor="let change of comparison.templates.changes" togglePosition="before">
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{ change.name }}</mat-panel-title>
                        <mat-icon *ngIf="change.type === 'INSERTION'" class="icon--inserted">add_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'UPDATE'" class="icon--updated">edit_circle</mat-icon>
                        <mat-icon *ngIf="change.type === 'DELETION'" class="icon--deleted">delete_circle</mat-icon>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </div>
        </ng-container>
        <ng-template #noChanges>
            <div class="pt-2 flex-auto w-[70%] gap-1 flex flex-col h-[500px] scrollbar scrollbar-primary !overflow-x-visible self-center">
                <div class="flex-col flex justify-center items-center">
                    <img class="w-[400px]" src="/src/images/theme/no_results_found.svg" />
                    <h1 class="!text-lg text-center">no difference between versions</h1>
                </div>
            </div>
        </ng-template>
    </mat-card>
</ng-container>
