import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../../../store/editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { DesignSystem } from '@backoffice/data-access/editor';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const designSystemAdapter = createEntityAdapter<DesignSystem>();

const { selectIds, selectAll, selectTotal, selectEntities } = designSystemAdapter.getSelectors();

export const designSystemEditorSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.designSystem)),
    active: createSelector(selectState, state => {
        const dictionary: Dictionary<DesignSystem> = selectEntities(state.designSystem);
        if (dictionary) {
            Object.entries(dictionary).forEach(([k, v]) => {
                if (v && v.active) {
                    return v;
                }
                return undefined;
            });
        }
        return undefined;
    }),
    dialogData: createSelector(selectState, state => state.designSystemDialog.result),
    dialogFilter: createSelector(selectState, state => {
        const { page, maxResults, searchTerm, facets, filterPlugins } = state.designSystemDialog;
        return {
            page,
            maxResults,
            searchTerm,
            facets,
            filterPlugins,
        };
    }),
    searchTerm: createSelector(selectState, state => state.designSystemDialog.searchTerm),
    pagination: createSelector(selectState, state => {
        const { page, maxResults } = state.designSystemDialog;
        return { page, maxResults };
    }),
    facets: createSelector(selectState, state => state.designSystemDialog.facets),
    filterPlugins: createSelector(selectState, state => state.designSystemDialog.filterPlugins),
    designsystem: createSelector(selectState, state => state.designSystem),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<DesignSystem> = selectEntities(state.designSystem);
            return dictionary[id];
        }),
};
