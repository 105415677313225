<ng-container>
    <h1 class="grow !text-xl pt-1 !mb-2">API Security</h1>
    <label class="toggle-line">
        Enable API Security
        <input type="checkbox" class="peer hidden" [(ngModel)]="companySecurity.apiAccessEnabled" (change)="onCompanySecurityChanged()" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>

    <ng-container *ngIf="companySecurity.apiAccessEnabled">
        <h2 class="grow !text-xl pt-1 !mb-2">Retrieve an accesstoken</h2>
        <p class="text-white">Retrieve an access token to authenticate in the following way:</p>
        <codex-select-search
            [items]="[
                { label: 'Development', value: 'DEV' },
                { label: 'Test', value: 'TEST' },
                { label: 'Accept', value: 'ACCEPT' },
                { label: 'Production', value: 'PRODUCTION' }
            ]"
            [showSearch]="false"
            [value]="this.chosenEnvironment"
            [placeHolder]="'Choose environment'"
            (select)="onChangeEnvironment($event)"></codex-select-search>
        <ng-container *ngIf="this.chosenEnvironment === 'DEV'">
            <div class="text-white flex gap-2 items-center mb-2">
                <span class="http-method">POST</span>
                <span class="flex gap-1 flex-row items-center"
                    >https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.co-dex.eu/auth/realms/dev-{{
                        company.id
                    }}/protocol/openid-connect/token
                    <svg
                        class="icon icon-button"
                        [matTooltip]="'Copy'"
                        [cdkCopyToClipboard]="
                            'https://' +
                            (backofficeEnvironment.production ? '' : 'dev-') +
                            'login.nocode-x.com/auth/realms/dev-' +
                            company.id +
                            '/protocol/openid-connect/token'
                        ">
                        <use href="#copy" class="ui-element"></use>
                    </svg>
                </span>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Curl command:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" (click)="onViewSecret('DEV')">Show secret</button>
                        <button class="code primary-button button-medium" [cdkCopyToClipboard]="'testje'">Copy</button>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <pre id="code" class="text-white text-left whitespace-pre-line">
                    <code>
                        curl -X POST "https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.nocode-x.com/auth/realms/dev-{{ company.id }}/protocol/openid-connect/token" \
                            -H "Content-Type: application/x-www-form-urlencoded" \
                            -d "username=[service user username]" \
                            -d "password=[service user password]" \
                            -d "grant_type=password" \
                            -d "client_id=nocodex-service-account" \
                            -d "client_secret={{ apiSecret ? apiSecret : '***************************'}}"
                    </code>
                    </pre>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="this.chosenEnvironment === 'TEST'">
            <div class="text-white flex gap-2 items-center mb-2">
                <span class="http-method">POST</span>
                <span class="flex gap-1 flex-row items-center"
                    >https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.co-dex.eu/auth/realms/test-{{
                        company.id
                    }}/protocol/openid-connect/token
                    <svg
                        class="icon icon-button"
                        [matTooltip]="'Copy'"
                        [cdkCopyToClipboard]="
                            'https://' +
                            (backofficeEnvironment.production ? '' : 'dev-') +
                            'login.nocode-x.com/auth/realms/test-' +
                            company.id +
                            '/protocol/openid-connect/token'
                        ">
                        <use href="#copy" class="ui-element"></use>
                    </svg>
                </span>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Curl command:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" (click)="onViewSecret('TEST')">Show secret</button>
                        <button class="code primary-button button-medium" [cdkCopyToClipboard]="'testje'">Copy</button>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <pre id="code" class="text-white text-left whitespace-pre-line">
                    <code>
                        curl -X POST "https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.nocode-x.com/auth/realms/test-{{ company.id }}/protocol/openid-connect/token" \
                            -H "Content-Type: application/x-www-form-urlencoded" \
                            -d "username=[service user username]" \
                            -d "password=[service user password]" \
                            -d "grant_type=password" \
                            -d "client_id=nocodex-service-account" \
                            -d "client_secret={{ apiSecret ? apiSecret : '***************************'}}"
                    </code>
                    </pre>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="this.chosenEnvironment === 'ACCEPT'">
            <div class="text-white flex gap-2 items-center mb-2">
                <span class="http-method">POST</span>
                <span class="flex gap-1 flex-row items-center"
                    >https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.co-dex.eu/auth/realms/accept-{{
                        company.id
                    }}/protocol/openid-connect/token
                    <svg
                        class="icon icon-button"
                        [matTooltip]="'Copy'"
                        [cdkCopyToClipboard]="
                            'https://' +
                            (backofficeEnvironment.production ? '' : 'dev-') +
                            'login.nocode-x.com/auth/realms/accept-' +
                            company.id +
                            '/protocol/openid-connect/token'
                        ">
                        <use href="#copy" class="ui-element"></use>
                    </svg>
                </span>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Curl command:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" (click)="onViewSecret('ACCEPT')">Show secret</button>
                        <button class="code primary-button button-medium" [cdkCopyToClipboard]="'testje'">Copy</button>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <pre id="code" class="text-white text-left whitespace-pre-line">
                    <code>
                        curl -X POST "https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.nocode-x.com/auth/realms/accept-{{ company.id }}/protocol/openid-connect/token" \
                            -H "Content-Type: application/x-www-form-urlencoded" \
                            -d "username=[service user username]" \
                            -d "password=[service user password]" \
                            -d "grant_type=password" \
                            -d "client_id=nocodex-service-account" \
                            -d "client_secret={{ apiSecret ? apiSecret : '***************************'}}"
                    </code>
                    </pre>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="this.chosenEnvironment === 'PRODUCTION'">
            <div class="text-white flex gap-2 items-center mb-2">
                <span class="http-method">POST</span>
                <span class="flex gap-1 flex-row items-center"
                    >https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.co-dex.eu/auth/realms/{{
                        company.id
                    }}/protocol/openid-connect/token
                    <svg
                        class="icon icon-button"
                        [matTooltip]="'Copy'"
                        [cdkCopyToClipboard]="
                            'https://' +
                            (backofficeEnvironment.production ? '' : 'dev-') +
                            'login.nocode-x.com/auth/realms/' +
                            company.id +
                            '/protocol/openid-connect/token'
                        ">
                        <use href="#copy" class="ui-element"></use>
                    </svg>
                </span>
            </div>
            <div class="bg-dark-background_level_1 text-white p-4 rounded-lg border border-dark-border mb-2">
                <div class="flex justify-between items-center mb-2">
                    <span class="text-gray-400">Curl command:</span>
                    <div class="flex flex-row gap-2">
                        <button class="code primary-button button-medium" (click)="onViewSecret('PRODUCTION')">Show secret</button>
                        <button class="code primary-button button-medium" [cdkCopyToClipboard]="'testje'">Copy</button>
                    </div>
                </div>
                <div class="overflow-x-auto">
                    <pre id="code" class="text-white text-left whitespace-pre-line">
                    <code>
                        curl -X POST "https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.nocode-x.com/auth/realms/{{ company.id }}/protocol/openid-connect/token" \
                            -H "Content-Type: application/x-www-form-urlencoded" \
                            -d "username=[service user username]" \
                            -d "password=[service user password]" \
                            -d "grant_type=password" \
                            -d "client_id=nocodex-service-account" \
                            -d "client_secret={{ apiSecret ? apiSecret : '***************************'}}"
                    </code>
                    </pre>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>
