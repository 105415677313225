<div [formGroup]="formGroup" class="absolute p-2 bottom-2 left-1 right-1 flex flex-col">
    <textarea
        (keydown.enter)="sendInput($event)"
        class="p-2 w-full !text-xxs border border-primary rounded !focus:border !focus:border-primary"
        matInput
        fxFlex="100%"
        name="input"
        align="end"
        required
        cdkFocusInitial
        formControlName="input">
    </textarea>
</div>
