<div
    class="relative p-2.5 flex flex-row items-center border rounded-lg border-2 dark:border-gray-600 dark:!bg-gray-800 dark:text-gray-300 cursor-pointer">
    <div class="h-4 w-4 border-[1px] rounded border-black dark:border-white" [style.background]="typography.color"></div>
    <div class="w-full ml-3 flex items-center justify-between text-sm">
        <label class="font-bold">{{ typography.name }}</label>
        <label class="block ml-auto">{{ typography.bold }}, {{ typography.size }}</label>
    </div>
</div>
<div class="absolute z-10 w-80 bg-white border border-blue-600 rounded-lg dark:border-gray-600 dark:!bg-gray-800 !mr-4 mt-2">
    <div class="max-w-4xl">
        <ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-300 pt-2 pb-2">
            <li
                *ngFor="let preset of presets"
                class="flex items-center cursor-pointer p-4 pt-2 pb-2 focus:ring-2 dark:focus:bg-gray-600 dark:hover:bg-gray-500"
                (click)="handleTypographySelected(preset)">
                <div class="h-4 w-4 border-[1px] rounded border-black dark:border-white" [style.background]="preset.color"></div>
                <span class="mx-2 font-bold">{{ preset.name }}</span>
                <span class="block ml-auto">{{ preset.bold }}, {{ preset.size }}</span>
            </li>
        </ul>
    </div>
</div>
