<mat-dialog-content class="!p-4 scrollbar-primary scrollbar">
    <div class="choice-grid-10" *ngIf="!type">
        <div class="choice-card-big" (click)="onChooseType('openidconnect')">
            <img src="/images/theme/openidconnect.png" />
            <span class="choice-card-title">Open Id Connect</span>
            <span class="choice-card-description">{{ 'v2.company.edit.identityproviders.openidconnect.description' | translate }}</span>
        </div>
        <div class="choice-card-big" (click)="onChooseType('azuread')">
            <img src="/images/theme/azuread.svg" />
            <span class="choice-card-title">Azure AD</span>
            <span class="choice-card-description">{{ 'v2.company.edit.identityproviders.azuread.description' | translate }}</span>
        </div>
        <div class="choice-card-big" (click)="onChooseType('google')">
            <img src="/images/theme/googleidp.png" width="80" style="width: 80px" />
            <span class="choice-card-title">Google IDP</span>
            <span class="choice-card-description">Connect to your Google identity provider.</span>
        </div>
        <div class="choice-card-big" (click)="onChooseType('trustelem')">
            <img src="/images/theme/trustelem.png" />
            <span class="choice-card-title">Wallix Trustelem</span>
            <span class="choice-card-description">{{ 'v2.company.edit.identityproviders.trustelem.description' | translate }}</span>
        </div>
    </div>
    <codex-openidconnect
        *ngIf="type === 'openidconnect'"
        (cancel)="onCancel()"
        [type]="'openidconnect'"
        [identityProvider]="identityProvider"
        [company]="this.company"
        (onSave)="this.dialogRef.close({ idpToSave: $event.identityProvider })"></codex-openidconnect>
    <codex-openidconnect
        *ngIf="type === 'google'"
        (cancel)="onCancel()"
        [type]="'google'"
        [identityProvider]="identityProvider"
        [company]="this.company"
        (onSave)="this.dialogRef.close({ idpToSave: $event.identityProvider })"></codex-openidconnect>
    <codex-trustelem
        *ngIf="type === 'trustelem'"
        (cancel)="onCancel()"
        [identityProvider]="identityProvider"
        (onSave)="this.dialogRef.close({ idpToSave: $event.identityProvider })"></codex-trustelem>
    <codex-azuread
        *ngIf="type === 'azuread'"
        (cancel)="onCancel()"
        [identityProvider]="identityProvider"
        [company]="this.company"
        (onSave)="this.dialogRef.close({ idpToSave: $event.identityProvider })"></codex-azuread>
</mat-dialog-content>
