import { PayloadAction } from '../../common/statemanagement/action/payload.action';
import { CompanyUserRightDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.user.right.dto';
import { CompanyUserRoleDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.user.role.dto';

export const DATA_ROLES_SET_USER_RIGHTS = 'DATA_ROLES_SET_USER_RIGHTS';
export const DATA_ROLES_SET_USER_ROLES = 'DATA_ROLES_SET_USER_ROLES';

export function setCompanyUserRights(rights: Array<CompanyUserRightDto>): PayloadAction {
    return {
        type: DATA_ROLES_SET_USER_RIGHTS,
        payload: rights,
    };
}

export function setCompanyUserRoles(roles: Array<CompanyUserRoleDto>): PayloadAction {
    return {
        type: DATA_ROLES_SET_USER_ROLES,
        payload: roles,
    };
}
