import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutModule } from '../layout/layout.module';
import { CodexMaterialModule } from '../material/codex.module';
import { NgxUploaderModule } from 'ngx-uploader';
import { CdCodeDirective } from './cd-code/cd-code.directive';
import { CdEditorDirective } from './cd-editor/cd-editor.directive';

@NgModule({
    declarations: [CdCodeDirective, CdEditorDirective],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CodexMaterialModule,
        CommonModule,
        LayoutModule,
        RouterModule,
        TranslateModule,
        NgxUploaderModule,
    ],
    exports: [CdCodeDirective, CdEditorDirective, NgxUploaderModule],
})
export class FormModule {}
