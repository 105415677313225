import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoggerService } from '@backoffice/utils';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { CreateAuthorizationLinkDto } from '../../interfaces/authorizationlink/create-authorization-link.dto';
import { AuthorizationLinkCreatedDto } from '../../interfaces/authorizationlink/authorization-link-created.dto';
import { AuthorizationLinkDto } from '../../interfaces/authorizationlink/authorization-link.dto';

@Injectable()
export class AuthorizationLinkService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    createAuthorizationLink(
        authorizationLinkDto: CreateAuthorizationLinkDto,
        companyId: string,
        applicationId: string
    ): Observable<AuthorizationLinkCreatedDto> {
        this.log.debug('Creating authorization link', [authorizationLinkDto]);
        return this.httpClient.post<AuthorizationLinkCreatedDto>(
            `${backofficeEnvironment.rest.v2.authorizationLinks}company/${companyId}/application/${applicationId}`,
            authorizationLinkDto
        );
    }

    updateAuthorizationLink(authorizationLink: AuthorizationLinkDto, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Updating authorizationLink', [authorizationLink]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.authorizationLinks}company/${companyId}/application/${applicationId}/${authorizationLink.id}`,
            authorizationLink
        );
    }

    getAuthorizationLink(authorizationLinkId: string, companyId: string, applicationId: string): Observable<AuthorizationLinkDto> {
        this.log.debug('Getting authorizationLink', [authorizationLinkId]);
        return this.httpClient.get<AuthorizationLinkDto>(
            `${backofficeEnvironment.rest.v2.authorizationLinks}company/${companyId}/application/${applicationId}/${authorizationLinkId}`
        );
    }

    deleteAuthorizationLinks(authorizationLinkId: string, companyId: string, applicationId: string, type: string): Observable<void> {
        this.log.debug('Deleting authorizationLink', [authorizationLinkId]);
        const params = new HttpParams().set('type', type);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.authorizationLinks}company/${companyId}/application/${applicationId}/${authorizationLinkId}`,
            { params }
        );
    }

    getAuthorizationLinksByReferenceId(
        referenceId: string,
        companyId: string,
        applicationId: string,
        type: string
    ): Observable<AuthorizationLinkDto[]> {
        this.log.debug('Getting authorizationLinks by referenceId', [referenceId]);
        const params = new HttpParams().set('type', type);

        return this.httpClient.get<AuthorizationLinkDto[]>(
            `${backofficeEnvironment.rest.v2.authorizationLinks}company/${companyId}/application/${applicationId}?referenceId=${referenceId}`,
            { params }
        );
    }
}
