import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { Argument } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/arguments/argument';
import { TabDefinition } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/interfaces/tab-definition.interface';
import { RouterFacade } from '@backoffice/utils-routing';

@Component({
    selector: 'codex-application-edit-meta',
    templateUrl: './application-edit-meta.component.html',
    styleUrls: ['./application-edit-meta.component.scss'],
})
export class ApplicationEditMetaComponent implements OnInit {
    @Input()
    application: ApplicationDto;

    @Output()
    updateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{
        application: ApplicationDto;
    }>();

    @Output()
    cancelUpdateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{
        application: ApplicationDto;
    }>();

    formGroup: FormGroup;

    get metaTitleControl(): FormControl {
        return this.formGroup.get('metaTitle') as FormControl;
    }

    get metaDescriptionControl(): FormControl {
        return this.formGroup.get('metaDescription') as FormControl;
    }

    get faviconUrlControl(): FormControl {
        return this.formGroup.get('faviconUrl') as FormControl;
    }

    get faviconMediaIdControl(): FormControl {
        return this.formGroup.get('faviconMediaId') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly routerFacade: RouterFacade
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            metaTitle: [this.application.metaTitle],
            metaDescription: [this.application.metaDescription],
            faviconLinkType: [this.application.faviconLinkType ? this.application.faviconLinkType : 'url'],
            faviconUrl: [this.application.faviconUrl],
        });
    }

    onHomeTemplateChanged($event: { value: string | undefined; arguments?: Argument[] | undefined }) {
        this.application.homeTemplateId = $event.value;
    }

    onFaviconMediaIdChanged($event: { mediaId: string }): void {
        if (this.application.faviconMediaId !== $event.mediaId) {
            this.application.faviconMediaId = $event.mediaId;
        }
    }

    onUpdateApplication() {
        this.application.metaTitle = this.metaTitleControl.value;
        this.application.metaDescription = this.metaDescriptionControl.value;
        this.application.faviconUrl = this.faviconUrlControl.value;
        this.updateApplication.emit({ application: this.application });
    }

    onCancel() {
        this.cancelUpdateApplication.emit({ application: this.application });
    }

    onOpenTemplate($event: TabDefinition) {
        this.routerFacade.navigate([`/company/${this.application.companyId}/application/${this.application.id}/editor`], {
            queryParams: {
                type: 'template',
                id: $event.typeId,
            },
        });
        this.onCancel();
    }
}
