import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var swal: any;

@Injectable()
export class ConfirmDialog {
    constructor(private translateService: TranslateService) {}

    public showConfirmDialog(
        title: string,
        description: string,
        confirm: string,
        cancel: string,
        successTitle: string,
        successDescription: string,
        callback: Function,
        translationParams: any = {}
    ) {
        this.translateService
            .get([title, description, confirm, cancel, successTitle, successDescription], translationParams)
            .subscribe((translation: string) => {
                swal({
                    title: translation[title],
                    text: translation[description],
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: translation[confirm],
                    confirmButtonClass: 'confirm',
                    cancelButtonText: translation[cancel],
                    cancelButtonClass: 'cancel',
                }).then(
                    () => {
                        swal(translation[successTitle], translation[successDescription], 'success');
                        callback();
                    },
                    () => {}
                );
            });
    }

    public showConfirmDialogWithoutSuccess(title: string, description: string, confirm: string, cancel: string, callback: Function) {
        this.translateService.get([title, description, confirm, cancel]).subscribe((translation: string) => {
            swal({
                title: translation[title],
                text: translation[description],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: translation[confirm],
                confirmButtonClass: 'confirm',
                cancelButtonText: translation[cancel],
                cancelButtonClass: 'cancel',
            }).then(
                () => {
                    callback();
                },
                () => {}
            );
        });
    }

    public showConfirmPaymentDialog(
        title: string,
        description: string,
        confirm: string,
        cancel: string,
        callback: Function,
        companyId: string
    ) {
        this.translateService.get([title, description, confirm, cancel], { companyId: companyId }).subscribe((translation: string) => {
            swal({
                title: translation[title],
                html: translation[description],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: translation[confirm],
                confirmButtonClass: 'confirm',
                cancelButtonText: translation[cancel],
                cancelButtonClass: 'cancel',
            }).then(
                () => {
                    callback();
                },
                () => {}
            );
        });
    }
}
