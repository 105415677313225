export interface GroupDto {
    id: string;
    name?: string;
    iconName?: string;
    description?: string;
    applicationId?: string;
    companyId?: string;
    deleted: boolean;
}

export function createDeletedGroup(id: string): GroupDto {
    return {
        id: id,
        deleted: true,
    };
}
