<ng-container *ngIf="selectedApplication">
    <div class="workspace-selector showcurrentcontext" (click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <div class="workspace-selector-container">
            <div class="workspace-selector-icon-wrapper">
                <mat-icon>{{ selectedApplication?.iconName }}</mat-icon>
            </div>
            <div class="workspace-name-container">
                <span class="workspace-name">{{ selectedApplication?.name }}</span>
                <div *ngIf="!isOpen" class="material-icons">expand_more</div>
                <div *ngIf="isOpen" class="material-icons">expand_less</div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isOpen"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="isOpen = false">
        <mat-card data-name="recent-applications" class="workspace-selector-last-used">
            <mat-card-content>
                <mat-card-header>
                    <mat-card-title>
                        <span class="recent-workspaces-title">{{ 'workspace.selector.recent.applications' | translate }}</span>
                        <span (click)="onAllApplicationsClicked()" class="see-all-workspaces"
                            >{{ 'workspace.selector.all.applications' | translate }} ></span
                        >
                    </mat-card-title>
                </mat-card-header>
                <ul class="workspace-list">
                    <ng-container *ngFor="let application of recentApplications">
                        <li
                            (click)="onApplicationSelected(application)"
                            [attr.data-name]="application.name"
                            [class.selected]="application.id === selectedApplication.id">
                            <div class="workspace-selector-icon-wrapper">
                                <mat-icon matListItemIcon>{{ application.iconName }}</mat-icon>
                            </div>
                            <div class="workspace-name grow">{{ application.name }}</div>
                            <button
                                (click)="onEditApplication(application.id); $event.stopPropagation()"
                                class="primary-button button-extra-small">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </li>
                    </ng-container>
                </ul>

                <button class="mt-2 text-button ml-[23px]" type="button" (click)="onAddApplication()">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#plus" class="ui-element"></use>
                    </svg>
                    {{ 'workspace.selector.add.application' | translate }}
                </button>
            </mat-card-content>
        </mat-card>
    </ng-template>
</ng-container>
<ng-container *ngIf="!selectedApplication">
    <button class="mt-[7px] primary-button button-medium w-full" (click)="onAddApplication()">
        <mat-icon>add_circle</mat-icon>
        {{ 'workspace.selector.add.application' | translate }}
    </button>
</ng-container>
