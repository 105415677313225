import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemDetailComponent } from './pages/design-system-detail/design-system-detail.component';
import { DesignSystemThemeComponent } from './components/design-system-theme/design-system-theme.component';
import { DesignSystemTypographyComponent } from './components/design-system-typography/design-system-typography.component';
import { BackofficeFeatureEditorPickersModule } from 'backoffice/feature/editor/pickers';
import { BackofficeUiArgumentsModule } from '@backoffice/ui-arguments';
import { CommonsModule } from '../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { DesignSystemGeneralComponent } from './components/design-system-general/design-system-general.component';

@NgModule({
    declarations: [DesignSystemDetailComponent, DesignSystemThemeComponent, DesignSystemTypographyComponent, DesignSystemGeneralComponent],
    imports: [CommonModule, BackofficeFeatureEditorPickersModule, BackofficeUiArgumentsModule, CommonsModule],
    exports: [DesignSystemDetailComponent],
})
export class BackofficeFeatureDesignsystemModule {}
