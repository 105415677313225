import { PartStyle } from '../../../part-style.model';
import { GenericMarginStyleDto } from '../../../../../interfaces/style/generic-margin.style.dto';
import { ColorV2 } from '../../../../../designsystem/models/color/colorv2.model';

export class BarChartPartStyle extends PartStyle {
    series: BarChartSeriesStyle[] = [];
    legend = new BarChartLegendStyle();
    axisLabelInside: boolean;
    axisLabelColor: string;
    axisLabelRotation: number;
    axisLabelColorV2: ColorV2;

    horizontalBars: boolean;

    gridMarginStyle: GenericMarginStyleDto;

    animation: boolean;
    animationDuration: number;

    constructor(base?: Partial<BarChartPartStyle>) {
        super();

        if (!!base) {
            this.series = base.series ?? this.series;
            this.legend = base.legend ?? this.legend;
            this.axisLabelInside = base.axisLabelInside ?? this.axisLabelInside;
            this.axisLabelColor = base.axisLabelColor ?? this.axisLabelColor;
            this.axisLabelRotation = base.axisLabelRotation ?? this.axisLabelRotation;
            this.axisLabelColorV2 = base.axisLabelColorV2 ?? this.axisLabelColorV2;
            this.gridMarginStyle = base.gridMarginStyle ?? this.gridMarginStyle;
            this.horizontalBars = base.horizontalBars ?? this.horizontalBars;
            this.animation = base.animation ?? this.animation;
            this.animationDuration = base.animationDuration ?? this.animationDuration;
        }
    }
}

export class BarChartSeriesStyle {
    id: string;
    code: string;
    name: string;
    itemStyle = new BarChartSeriesItemStyleStyle();

    animation: boolean;
    animationDuration: number;

    constructor(base?: Partial<BarChartSeriesStyle>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.code = base.code ?? this.code;
            this.name = base.name ?? this.name;
            this.itemStyle = base.itemStyle ? new BarChartSeriesItemStyleStyle(base.itemStyle) : this.itemStyle;
        }
    }
}

export class BarChartSeriesItemStyleStyle {
    color?: string;
    colorV2?: ColorV2;
    borderColor?: string;
    borderColorV2?: ColorV2;
    borderWidth?: number;
    borderRadius?: number;
    borderType?: string;

    showBackground?: boolean;
    backgroundColor?: string;
    backgroundColorV2?: ColorV2;
    backgroundBorderColor?: string;
    backgroundBorderColorV2?: ColorV2;
    backgroundBorderWidth?: number;
    backgroundBorderRadius?: number;
    backgroundBorderType?: string;

    stack?: string;

    constructor(base?: Partial<BarChartSeriesItemStyleStyle>) {
        if (!!base) {
            this.color = base.color ?? this.color;
            this.colorV2 = base.colorV2 ?? this.colorV2;
            this.borderColor = base.borderColor ?? this.borderColor;
            this.borderColorV2 = base.borderColorV2 ?? this.borderColorV2;
            this.borderWidth = base.borderWidth ?? this.borderWidth;
            this.borderRadius = base.borderRadius ?? this.borderRadius;
            this.borderType = base.borderType ?? this.borderType;
            this.showBackground = base.showBackground ?? this.showBackground;
            this.backgroundColor = base.backgroundColor ?? this.backgroundColor;
            this.backgroundColorV2 = base.backgroundColorV2 ?? this.backgroundColorV2;
            this.backgroundBorderColor = base.backgroundBorderColor ?? this.backgroundBorderColor;
            this.backgroundBorderColorV2 = base.backgroundBorderColorV2 ?? this.backgroundBorderColorV2;
            this.backgroundBorderWidth = base.backgroundBorderWidth ?? this.backgroundBorderWidth;
            this.backgroundBorderRadius = base.backgroundBorderRadius ?? this.backgroundBorderRadius;
            this.backgroundBorderType = base.backgroundBorderType ?? this.backgroundBorderType;
            this.stack = base.stack ?? this.stack;
        }
    }
}

export class BarChartLegendStyle {
    show = true;
    orient?: 'vertical' | 'horizontal' = 'horizontal';
    left?: 'auto' | 'left' | 'center' | 'right';
    top?: 'auto' | 'top' | 'middle' | 'bottom';
}
