export interface OverviewTemplateDto {
    applicationId: string;
    applicationName: string;
    companyId: string;
    createdBy: string;
    creationDate: string;
    global: boolean;
    iconName: string;
    id: string;
    inheritedByHierarchy: string;
    authenticatedAccess: boolean;
    component: boolean;
    languageCode: string;
    modificationDate: string;
    modifiedBy: string;
    name: string;
    productReference: string;
    productReferenceName?: string;
}

export function isOverviewTemplateDto(object: any): object is OverviewTemplateDto {
    return object && object['name'];
}
