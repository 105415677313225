<mat-dialog-content class="!p-4 scrollbar-primary scrollbar">
    <h1>{{ 'v2.hub.edit.title' | translate }}</h1>
    <div *ngIf="product$ | async as product">
        <label for="product-name" class="small-input-label" data-name="product-name">{{ 'v2.hub.edit.labels.name' | translate }}</label>
        <input class="small-input" type="text" id="product-name" name="name" autocomplete="off" [formControl]="nameControl" />

        <label for="product-description" class="small-input-label" data-name="product-description">{{
            'v2.hub.edit.labels.description' | translate
        }}</label>
        <textarea
            class="small-input"
            type="text"
            id="product-description"
            name="description"
            autocomplete="off"
            [formControl]="descriptionControl">
        </textarea>

        <codex-tag-input
            type="PRODUCT"
            [linkedEntityId]="product.id"
            [applicationId]="product.applicationId"
            [companyId]="product.companyId">
        </codex-tag-input>
        <h4>{{ 'v2.hub.edit.labels.icon.title' | translate }}</h4>
        <div class="w-[172px] m-auto relative image-picker cursor-pointer" *ngIf="!showUploadIcon" (click)="onChangeIcon()">
            <img class="w-full m-auto p-4 border rounded-md block" *ngIf="!product.iconUrl" src="/images/theme/image_placeholder.svg" />
            <img
                class="w-full m-auto p-4 border rounded-md block"
                *ngIf="!!product.iconUrl"
                [src]="
                    'https://' +
                    environment.backEndHost +
                    '/cdn-cgi/image/width=118,height=118,fit=scale-down,quality=100,format=auto/marketplace/products/' +
                    product.id +
                    '/icon?v=' +
                    product.iconUrl
                "
                width="200" />
            <span class="cursor-pointer absolute top-[50%] w-full m-auto text-xxs opacity-50 text-white" *ngIf="!product.iconUrl">{{
                'v2.hub.edit.labels.icon.select' | translate
            }}</span>
            <span class="cursor-pointer absolute top-[50%] w-full m-auto text-xxs opacity-50 text-white" *ngIf="!!product.iconUrl">{{
                'v2.hub.edit.labels.icon.delete' | translate
            }}</span>
        </div>

        <div
            *ngIf="showUploadIcon"
            [style.visibility]="showUploadIcon ? 'visible' : 'hidden'"
            class="drop-container"
            ngFileDrop
            [options]="options"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
            [ngClass]="{ 'is-drop-over': dragOverIconUpload }">
            <h1>{{ 'v2.hub.edit.labels.icon.drop' | translate }}</h1>
        </div>

        <label for="product-readme" class="small-input-label" data-name="application-meta-description">Readme</label>
        <textarea
            class="small-input"
            type="text"
            id="product-readme"
            name="product-readme"
            autocomplete="off"
            [formControl]="readmeControl"></textarea>
    </div>
    <div class="buttonContainer">
        <button class="primary-button button-medium" [disabled]="uploading" type="button" (click)="onEditClicked()">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-medium" (click)="onCancelClick()" type="button">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</mat-dialog-content>
