<mat-accordion class="example-headers-align" multi>
    <ng-container *ngFor="let facetList of filteredFacetFields | keyvalue">
        <mat-expansion-panel
            *ngIf="facetList.value && facetList.value.length > 0"
            (opened)="openedFacetList = facetList.key"
            (closed)="closeFacetList(facetList.key)"
            [expanded]="facetList.key === openedFacetList">
            <mat-expansion-panel-header>
                <mat-panel-title>{{
                    (type ? 'facet.filters.title.' + type + '.' + facetList.key : 'facet.filters.title.' + facetList.key) | translate
                }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <ul>
                    <li *ngFor="let facetField of facetList.value">
                        <mat-checkbox
                            [matBadge]="facetField.count"
                            matBadgeOverlap="false"
                            [checked]="facetField.selected"
                            (change)="onSelect($event.checked, facetList.key, facetField)"
                            color="primary"
                            >{{ facetField.displayName }}</mat-checkbox
                        >
                    </li>
                </ul>
            </ng-template>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
