export interface InnerSizeStyleDto {
    sizeX?: number;
    sizeY?: number;
    overflowX?: string;
    overflowY?: string;
}

export function generateInnerSize(innerSizeStyle: InnerSizeStyleDto | null | undefined) {
    let style = '';
    if (innerSizeStyle) {
        if (innerSizeStyle.sizeY) {
            style += `height: calc((100vw / 100) * ${innerSizeStyle.sizeY} - 1px);`;
        }

        if (innerSizeStyle.sizeX) {
            style += `width: calc((100vw / 100) * ${innerSizeStyle.sizeX} - 1px);`;
        }

        if (innerSizeStyle.overflowX) {
            style += 'overflow-x: ' + innerSizeStyle.overflowX + ';';
        }

        if (innerSizeStyle.overflowY) {
            style += 'overflow-y: ' + innerSizeStyle.overflowY + ';';
        }
    }
    return style;
}
