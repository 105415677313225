import { AxisConfiguration, DatasourceConfiguration } from '../line-graph-part/line-chart-part.detail';
import { PartDetail } from '../../../part-detail.model';

export class BarChartPartDetail extends PartDetail {
    id: string;
    code = 'code';
    title: string;
    xaxis: AxisConfiguration = new AxisConfiguration();
    datasources: DatasourceConfiguration[] = [];

    constructor(base?: Partial<BarChartPartDetail>) {
        super();
        if (!!base) {
            this.id = base.id ?? this.id;
            this.code = base.code ?? this.code;
            this.title = base.title ?? this.title;
            this.xaxis = base.xaxis ?? this.xaxis;
            this.datasources = base.datasources ?? this.datasources;
        }
    }

    public override hasActions(): boolean {
        return false;
    }

    public override isValid(): boolean {
        return true;
    }
}
