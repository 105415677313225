<select
    id="select-application"
    class="small-input"
    data-name="select-application"
    [(ngModel)]="selectedApplicationId"
    (ngModelChange)="onApplicationSelect($event)">
    <option selected [value]="">
        {{ label | translate }}
    </option>
    <option *ngFor="let application of applications$ | async" [value]="application.id">
        {{ application.name }}
    </option>
</select>
