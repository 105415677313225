import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MarginStyleDto, PaddingStyleDto } from '@backoffice/data-access/editor';

interface WrapperStyle {
    marginStyle?: MarginStyleDto | null;
    marginTopStyle?: MarginStyleDto | null;
    marginLeftStyle?: MarginStyleDto | null;
    marginRightStyle?: MarginStyleDto | null;
    marginBottomStyle?: MarginStyleDto | null;
}

@Component({
    selector: 'codex-margin-picker-wrapper',
    templateUrl: './margin-picker-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarginPickerWrapperComponent {
    @Input()
    title: string;

    @Input()
    identifier: string;

    @Output()
    wrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    wrapperStyle: WrapperStyle;

    @Output()
    hoverWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    hoverWrapperStyle: WrapperStyle;

    @Output()
    focusWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    focusWrapperStyle: WrapperStyle;

    @Input()
    allowedTypes: string[] = ['normal', 'hover', 'focus'];

    type: string = 'normal';

    resetStyle() {
        if (this.type === 'normal') {
            this.resetWrapperStyle();
        } else if (this.type === 'hover') {
            this.resetHoverWrapperStyle();
        } else if (this.type === 'focus') {
            this.resetFocusWrapperStyle();
        }
    }

    onChangeStyle($event: { margin: WrapperStyle }) {
        this.wrapperStyle.marginStyle = $event.margin.marginStyle;
        this.wrapperStyle.marginTopStyle = $event.margin.marginTopStyle;
        this.wrapperStyle.marginLeftStyle = $event.margin.marginLeftStyle;
        this.wrapperStyle.marginBottomStyle = $event.margin.marginBottomStyle;
        this.wrapperStyle.marginRightStyle = $event.margin.marginRightStyle;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    onChangeHoverStyle($event: { margin: WrapperStyle }) {
        this.hoverWrapperStyle.marginStyle = $event.margin.marginStyle;
        this.hoverWrapperStyle.marginTopStyle = $event.margin.marginTopStyle;
        this.hoverWrapperStyle.marginLeftStyle = $event.margin.marginLeftStyle;
        this.hoverWrapperStyle.marginBottomStyle = $event.margin.marginBottomStyle;
        this.hoverWrapperStyle.marginRightStyle = $event.margin.marginRightStyle;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    onChangeFocusStyle($event: { margin: WrapperStyle }) {
        this.focusWrapperStyle.marginStyle = $event.margin.marginStyle;
        this.focusWrapperStyle.marginTopStyle = $event.margin.marginTopStyle;
        this.focusWrapperStyle.marginLeftStyle = $event.margin.marginLeftStyle;
        this.focusWrapperStyle.marginBottomStyle = $event.margin.marginBottomStyle;
        this.focusWrapperStyle.marginRightStyle = $event.margin.marginRightStyle;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    resetWrapperStyle() {
        this.wrapperStyle.marginStyle = null;
        this.wrapperStyle.marginTopStyle = null;
        this.wrapperStyle.marginLeftStyle = null;
        this.wrapperStyle.marginBottomStyle = null;
        this.wrapperStyle.marginRightStyle = null;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    resetHoverWrapperStyle() {
        this.hoverWrapperStyle.marginStyle = null;
        this.hoverWrapperStyle.marginTopStyle = null;
        this.hoverWrapperStyle.marginLeftStyle = null;
        this.hoverWrapperStyle.marginBottomStyle = null;
        this.hoverWrapperStyle.marginRightStyle = null;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    resetFocusWrapperStyle() {
        this.focusWrapperStyle.marginStyle = null;
        this.focusWrapperStyle.marginTopStyle = null;
        this.focusWrapperStyle.marginLeftStyle = null;
        this.focusWrapperStyle.marginBottomStyle = null;
        this.focusWrapperStyle.marginRightStyle = null;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }
}
