import { ColorV2 } from '@backoffice/data-access/editor';

export interface Color {
    reference?: string;
    type: 'RGB' | 'HSV' | 'HSL';
    value: string;
}

export function generateColorStyle(color: Color | undefined) {
    let style = '';
    if (color?.reference) {
        style += `var(${color.reference})`;
    } else if (color && color.value) {
        style += color.value;
    }
    return style;
}

export function generateColorStyleV2(color: ColorV2 | undefined) {
    let style = '';
    if (color?.reference) {
        style += `var(${color.reference.variable.name})`;
    } else if (color?.hex) {
        style += color?.hex.value;
    }
    return style;
}
