import { NgModule } from '@angular/core';
import { CommonsModule } from '../common/common.module';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { statusRouting } from './status.routing';

@NgModule({
    declarations: [NotFoundPageComponent],
    imports: [CommonsModule, statusRouting],
})
export class StatusModule {}
