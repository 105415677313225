<h3
    [attr.id]="'style-accordion-' + identifier + '-font-answer-heading'"
    [attr.data-name]="'style-accordion-font-answer-header-' + identifier"
    class="p-0">
    <button
        type="button"
        class="accordion-heading"
        [class.accordion-heading-first]="false"
        [class.accordion-heading-last]="lastPanel"
        [attr.data-accordion-target]="'#style-accordion-' + identifier + '-font-answer'"
        [attr.aria-controls]="'style-accordion-' + identifier + '-font-answer'"
        aria-expanded="true">
        <div class="flex gap-1 justify-center items-center w-full">
            <span class="leading-7 grow text-left">{{ 'v2.part.style.font.answer' | translate }}</span>
            <button
                class="text-button"
                type="button"
                (click)="resetStyle(); $event.stopPropagation()"
                [matTooltip]="'v2.template.style.reset' | translate">
                Reset
            </button>
        </div>
    </button>
</h3>
<div
    [attr.id]="'style-accordion-' + identifier + '-font-answer'"
    [attr.data-name]="'font-answer-' + identifier"
    [attr.aria-labelledby]="'style-accordion-' + identifier + '-font-answer'"
    [class.accordion-panel-last]="lastPanel"
    [class.accordion-panel]="true"
    class="hidden">
    <div class="button-group" role="group">
        <button
            *ngIf="allowedTypes.indexOf('normal') > -1"
            type="button"
            class="button-group-button-left"
            autofocus
            (click)="type = 'normal'"
            [ngClass]="type === 'normal' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.normal' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('hover') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'hover'"
            [ngClass]="type === 'hover' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.hover' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('focus') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'focus'"
            [ngClass]="type === 'focus' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.focus' | translate }}
        </button>
    </div>
    <codex-font-picker *ngIf="type === 'normal'" [fontStyle]="wrapperStyle.answerFontStyleDto" (changeFont)="onChangeStyle($event)">
    </codex-font-picker>
    <codex-font-picker
        *ngIf="type === 'hover'"
        [fontStyle]="hoverWrapperStyle.answerFontStyleDto"
        (changeFont)="onChangeHoverStyle($event)">
    </codex-font-picker>
    <codex-font-picker
        *ngIf="type === 'focus'"
        [fontStyle]="focusWrapperStyle.answerFontStyleDto"
        (changeFont)="onChangeFocusStyle($event)">
    </codex-font-picker>
</div>
