<ng-container>
    <codex-border-picker-wrapper
        [identifier]="part.id"
        [wrapperStyle]="_inputPartStyle"
        (wrapperStyleUpdated)="this.inputPartStyleUpdated.emit({ inputPartStyle: this._inputPartStyle })"
        [hoverWrapperStyle]="_hoverInputPartStyle"
        (hoverWrapperStyleUpdated)="this.hoverInputPartStyleUpdated.emit({ inputPartStyle: this._hoverInputPartStyle })"
        [focusWrapperStyle]="_focusInputPartStyle"
        (focusWrapperStyleUpdated)="this.focusInputPartStyleUpdated.emit({ inputPartStyle: this._focusInputPartStyle })"
        [allowedTypes]="['normal', 'hover', 'focus']">
    </codex-border-picker-wrapper>
    <codex-padding-picker-wrapper
        [identifier]="part.id"
        [wrapperStyle]="_inputPartStyle"
        (wrapperStyleUpdated)="this.inputPartStyleUpdated.emit({ inputPartStyle: this._inputPartStyle })"
        [hoverWrapperStyle]="_hoverInputPartStyle"
        (hoverWrapperStyleUpdated)="this.hoverInputPartStyleUpdated.emit({ inputPartStyle: this._hoverInputPartStyle })"
        [focusWrapperStyle]="_focusInputPartStyle"
        (focusWrapperStyleUpdated)="this.focusInputPartStyleUpdated.emit({ inputPartStyle: this._focusInputPartStyle })"
        [allowedTypes]="['normal', 'hover', 'focus']">
    </codex-padding-picker-wrapper>
    <codex-background-picker-wrapper
        [identifier]="part.id"
        [wrapperStyle]="_inputPartStyle"
        (wrapperStyleUpdated)="this.inputPartStyleUpdated.emit({ inputPartStyle: this._inputPartStyle })"
        [hoverWrapperStyle]="_hoverInputPartStyle"
        (hoverWrapperStyleUpdated)="this.hoverInputPartStyleUpdated.emit({ inputPartStyle: this._hoverInputPartStyle })"
        [focusWrapperStyle]="_focusInputPartStyle"
        (focusWrapperStyleUpdated)="this.focusInputPartStyleUpdated.emit({ inputPartStyle: this._focusInputPartStyle })"
        [allowedTypes]="['normal', 'hover', 'focus']">
    </codex-background-picker-wrapper>
    <codex-font-dto-label-picker-wrapper
        [identifier]="part.id"
        [wrapperStyle]="_inputPartStyle"
        (wrapperStyleUpdated)="this.inputPartStyleUpdated.emit({ inputPartStyle: this._inputPartStyle })"
        [hoverWrapperStyle]="_hoverInputPartStyle"
        (hoverWrapperStyleUpdated)="this.hoverInputPartStyleUpdated.emit({ inputPartStyle: this._hoverInputPartStyle })"
        [focusWrapperStyle]="_focusInputPartStyle"
        (focusWrapperStyleUpdated)="this.focusInputPartStyleUpdated.emit({ inputPartStyle: this._focusInputPartStyle })"
        [allowedTypes]="['normal', 'hover', 'focus']"></codex-font-dto-label-picker-wrapper>
    <codex-font-answer-picker-wrapper
        [identifier]="part.id"
        [wrapperStyle]="_inputPartStyle"
        (wrapperStyleUpdated)="this.inputPartStyleUpdated.emit({ inputPartStyle: this._inputPartStyle })"
        [hoverWrapperStyle]="_hoverInputPartStyle"
        (hoverWrapperStyleUpdated)="this.hoverInputPartStyleUpdated.emit({ inputPartStyle: this._hoverInputPartStyle })"
        [focusWrapperStyle]="_focusInputPartStyle"
        (focusWrapperStyleUpdated)="this.focusInputPartStyleUpdated.emit({ inputPartStyle: this._focusInputPartStyle })"
        [allowedTypes]="['normal', 'hover', 'focus']"></codex-font-answer-picker-wrapper>
    <h3
        [attr.id]="'partstyle-generic-' + part.id + '-inputborder-heading'"
        [attr.data-name]="'partstyle-generic-inputborder-header-' + part.id"
        class="p-0">
        <button
            type="button"
            class="accordion-heading"
            [class.accordion-heading-first]="false"
            [class.accordion-heading-last]="true"
            [attr.data-accordion-target]="'#partstyle-generic-accordion-' + part.id + '-inputborder'"
            [attr.aria-controls]="'partstyle-generic-accordion-' + part.id + '-inputborder'"
            aria-expanded="true">
            <div class="flex gap-1 justify-center items-center w-full">
                <span class="leading-7 grow text-left">{{ 'v2.part.style.input.border' | translate }}</span>
                <button
                    class="text-button"
                    type="button"
                    (click)="resetInputBorderStyle(); $event.stopPropagation()"
                    [matTooltip]="'v2.template.style.reset' | translate">
                    Reset
                </button>
            </div>
        </button>
    </h3>
    <div
        [attr.id]="'partstyle-generic-accordion-' + part.id + '-inputborder'"
        [attr.data-name]="'inputborder-' + part.id"
        [attr.aria-labelledby]="'partstyle-generic-accordion-' + part.id + '-inputborder'"
        [class.accordion-panel-last]="true"
        [class.accordion-panel]="true"
        class="hidden">
        <div class="button-group" role="group">
            <button
                type="button"
                class="button-group-button-left"
                autofocus
                (click)="type = 'normal'"
                [ngClass]="type === 'normal' ? 'button-group-button-selected' : ''">
                {{ 'v2.part.style.type.normal' | translate }}
            </button>
            <button
                type="button"
                class="button-group-button-middle"
                autofocus
                (click)="type = 'hover'"
                [ngClass]="type === 'hover' ? 'button-group-button-selected' : ''">
                {{ 'v2.part.style.type.hover' | translate }}
            </button>
            <button
                type="button"
                class="button-group-button-middle"
                autofocus
                (click)="type = 'focus'"
                [ngClass]="type === 'focus' ? 'button-group-button-selected' : ''">
                {{ 'v2.part.style.type.focus' | translate }}
            </button>
        </div>
        <codex-border-picker
            *ngIf="type === 'normal'"
            [borderStyle]="_inputPartStyle.inputBorderStyle"
            [borderTopStyle]="_inputPartStyle.inputBorderStyle"
            [borderLeftStyle]="_inputPartStyle.inputBorderStyle"
            [borderBottomStyle]="_inputPartStyle.inputBorderStyle"
            [borderRightStyle]="_inputPartStyle.inputBorderStyle"
            [allowUnlock]="false"
            (changeBorder)="onChangeInputBorder($event)"></codex-border-picker>
        <codex-border-picker
            *ngIf="type === 'focus'"
            [borderStyle]="_focusInputPartStyle.inputBorderStyle"
            [borderTopStyle]="_focusInputPartStyle.inputBorderStyle"
            [borderLeftStyle]="_focusInputPartStyle.inputBorderStyle"
            [borderBottomStyle]="_focusInputPartStyle.inputBorderStyle"
            [borderRightStyle]="_focusInputPartStyle.inputBorderStyle"
            [allowUnlock]="false"
            (changeBorder)="onChangeFocusInputBorder($event)"></codex-border-picker>
        <codex-border-picker
            *ngIf="type === 'hover'"
            [borderStyle]="_hoverInputPartStyle.inputBorderStyle"
            [borderTopStyle]="_hoverInputPartStyle.inputBorderStyle"
            [borderLeftStyle]="_hoverInputPartStyle.inputBorderStyle"
            [borderBottomStyle]="_hoverInputPartStyle.inputBorderStyle"
            [borderRightStyle]="_hoverInputPartStyle.inputBorderStyle"
            [allowUnlock]="false"
            (changeBorder)="onChangeHoverInputBorder($event)"></codex-border-picker>
    </div>
</ng-container>
