import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { DataFormatEditorState } from '../state/data-format-editor.state';
import {
    clearDataFormatDialogData,
    clearDataFormatDialogFilter,
    dataFormatDialogFacetsChanged,
    dataFormatDialogFilterPluginsChanged,
    dataFormatDialogPaginationChanged,
    dataFormatDialogSearchTermChanged,
    deleteDataFormatSuccess,
    fetchDataFormatError,
    fetchDataFormatSuccess,
    loadDataFormatDialogSuccess,
    updateDataFormatSuccess,
} from '../actions/dataformat-editor.actions';
import { DataFormat } from '../../models/data-format';
import { selectApplicationSuccess } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { SelectedFacets } from '../../../dto/overview/facets.dto';
import { DataFormatDialogState } from '../state/data-format-dialog.state';
import { Page } from '@shared/data-access';
import { OverviewDataFormatDto } from '../../interfaces/overview-data-format.dto';

const adapterAction = createEntityAdapter<DataFormat>();
export const dataFormatInitialState: DataFormatEditorState = adapterAction.getInitialState();
export const dataFormatDialogState: DataFormatDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const dataFormatReducer = (initialData: DataFormatEditorState) =>
    createReducer(
        initialData,
        on(fetchDataFormatSuccess, fetchDataFormatError, (state, { dataFormat }) => adapterAction.addOne(dataFormat, state)),
        on(updateDataFormatSuccess, (state, { dataFormat }) =>
            adapterAction.updateOne(
                {
                    id: dataFormat.id,
                    changes: dataFormat,
                },
                state
            )
        ),
        on(deleteDataFormatSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );

const facetsReducer = (initialData: SelectedFacets | undefined) =>
    createReducer(
        initialData,
        on(dataFormatDialogFacetsChanged, (state, { facets }) => facets),
        on(clearDataFormatDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(dataFormatDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearDataFormatDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(dataFormatDialogPaginationChanged, (_, { page }) => page),
        on(dataFormatDialogSearchTermChanged, () => 0),
        on(clearDataFormatDialogFilter, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(dataFormatDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearDataFormatDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(dataFormatDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearDataFormatDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewDataFormatDto> | undefined) =>
    createReducer(
        initialData,
        on(loadDataFormatDialogSuccess, (_, { data }) => data),
        on(clearDataFormatDialogData, () => undefined)
    );

export const dataFormatDialogReducers = combineReducers({
    page: pageReducer(dataFormatDialogState.page),
    maxResults: maxResultsReducer(dataFormatDialogState.maxResults),
    searchTerm: searchTermReducer(dataFormatDialogState.searchTerm),
    result: resultReducer(dataFormatDialogState.result),
    facets: facetsReducer(dataFormatDialogState.facets),
    filterPlugins: filterPluginsReducer(dataFormatDialogState.filterPlugins),
});
