import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MarginStyleDto } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-margin-picker',
    templateUrl: './margin-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarginPickerComponent implements OnInit {
    marginType: string = 'all';

    @Input()
    set marginStyle(marginStyle: MarginStyleDto) {
        if (marginStyle) {
            this._marginStyle = marginStyle;
        } else {
            this._marginStyle = this.defaultMarginStyle();
        }
    }

    _marginStyle: MarginStyleDto;

    @Input()
    set marginTopStyle(marginStyle: MarginStyleDto) {
        if (marginStyle) {
            this._marginTopStyle = marginStyle;
        } else if (!this._marginTopStyle) {
            this._marginTopStyle = this.defaultMarginStyle();
        }
    }

    _marginTopStyle: MarginStyleDto;

    @Input()
    set marginLeftStyle(marginStyle: MarginStyleDto) {
        if (marginStyle) {
            this._marginLeftStyle = marginStyle;
        } else if (!this._marginLeftStyle) {
            this._marginLeftStyle = this.defaultMarginStyle();
        }
    }

    _marginLeftStyle: MarginStyleDto;

    @Input()
    set marginBottomStyle(marginStyle: MarginStyleDto) {
        if (marginStyle) {
            this._marginBottomStyle = marginStyle;
        } else if (!this._marginBottomStyle) {
            this._marginBottomStyle = this.defaultMarginStyle();
        }
    }

    _marginBottomStyle: MarginStyleDto;

    @Input()
    set marginRightStyle(marginStyle: MarginStyleDto) {
        if (marginStyle) {
            this._marginRightStyle = marginStyle;
        } else if (!this._marginRightStyle) {
            this._marginRightStyle = this.defaultMarginStyle();
        }
    }

    _marginRightStyle: MarginStyleDto;

    @Output()
    changeMargin: EventEmitter<{
        margin: {
            marginStyle: MarginStyleDto;
            marginTopStyle: MarginStyleDto;
            marginLeftStyle: MarginStyleDto;
            marginBottomStyle: MarginStyleDto;
            marginRightStyle: MarginStyleDto;
        };
    }> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    defaultMarginStyle() {
        return {
            margin: null,
        };
    }

    onChangeAllMargin() {
        this.changeMargin.emit({
            margin: {
                marginStyle: this._marginStyle,
                marginTopStyle: this._marginTopStyle,
                marginLeftStyle: this._marginLeftStyle,
                marginBottomStyle: this._marginBottomStyle,
                marginRightStyle: this._marginRightStyle,
            },
        });
    }
}
