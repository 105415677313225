import { classToPlain, plainToClass, Type } from 'class-transformer';
import { Part } from '../../../part.model';
import { PartDetail } from '../../../part-detail.model';

export class VerticalListPartDetail extends PartDetail {
    code: string;

    //transient
    @Type(() => Part)
    parts?: Part[];

    partsFetched = false;

    partSelectorIds: string[] = [];

    public removeContainerPart(partSelectorId: string): void {
        this.partSelectorIds.splice(this.partSelectorIds.indexOf(partSelectorId), 1);
        this.parts.splice(
            this.parts.findIndex(part => part.selectorId === partSelectorId),
            1
        );
    }

    public updateContainerPart(partToUpdate: Part) {
        let currentPartIndex = this.partSelectorIds.findIndex(partSelectorId => partSelectorId === partToUpdate.selectorId);
        const currentPart = this.parts.find(part => part.selectorId === partToUpdate.selectorId);
        if (currentPart) {
            this.removeContainerPart(currentPart.selectorId);
        }

        if (currentPartIndex === -1) {
            currentPartIndex = this.parts.length;
        }

        const plainPartToUpdate = classToPlain(partToUpdate);
        const objectUpdatedPart: Part = plainToClass(Part, plainPartToUpdate);
        this.addContainerPart(objectUpdatedPart, currentPartIndex);
        return objectUpdatedPart;
    }

    public addContainerPart(part: Part, index: number): void {
        this.parts.push(part);
        this.partSelectorIds.splice(index, 0, part.selectorId);
        this.sortParts();
    }

    public sortParts() {
        if (this.parts && this.parts.length > 0) {
            this.parts.sort((a, b) => this.partSelectorIds.indexOf(a.selectorId) - this.partSelectorIds.indexOf(b.selectorId));
        }
    }

    getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.vertical-list.error.code');
        }
        return validationErrors;
    }

    isValid(): boolean {
        return this.isCodeValid() /* && this.isPartSelectorListValid()*/;
    }

    isPartSelectorListValid(): boolean {
        const sizeTheSame: boolean = this.partSelectorIds.length === this.parts.length;
        if (!sizeTheSame) {
            return false;
        } else {
            for (let i = 0; i < this.parts.length; i++) {
                const part = this.parts[i];
                if (this.partSelectorIds.indexOf(part.selectorId) < 0) {
                    return false;
                }
            }
        }
        return true;
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    public getChildParts(): Part[] {
        return this.parts;
    }

    public shouldInitializeChildParts(): boolean {
        return !this.initialized;
    }
}
