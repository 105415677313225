import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActionLink } from '../../../../../../libs/backoffice/data-access/editor/src/lib/action/model/action-link';
import { ActionLinkCreatedDto } from '../../../../../../libs/backoffice/data-access/editor/src/lib/action/dto/action-link-created.dto';
import { CreateActionLinkDto } from '../../../../../../libs/backoffice/data-access/editor/src/lib/action/dto/create-action-link.dto';
import { LoggerService } from '@backoffice/utils';
import { NgHttpCachingHeaders } from 'ng-http-caching';

@Injectable({
    providedIn: 'root',
})
export class ActionLinkService {
    constructor(
        private httpClient: HttpClient,
        private log: LoggerService
    ) {}

    public createActionLink(
        createActionLinkDto: CreateActionLinkDto,
        companyId: string,
        applicationId: string
    ): Observable<ActionLinkCreatedDto> {
        this.log.debug('Creating action link', [createActionLinkDto]);
        return this.httpClient.post<ActionLinkCreatedDto>(
            `${backofficeEnvironment.rest.v2.actionLinks}company/${companyId}/application/${applicationId}`,
            createActionLinkDto
        );
    }

    public updateActionLink(actionLink: ActionLink, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Updating action link', [actionLink]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.actionLinks}company/${companyId}/application/${applicationId}/${actionLink.id}`,
            actionLink
        );
    }

    public deleteActionLink(actionLinkId: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Deleting action link', [actionLinkId]);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.actionLinks}company/${companyId}/application/${applicationId}/${actionLinkId}`
        );
    }

    public getActionLinksByReferenceIdAndType(
        referenceId: string,
        type: string,
        companyId: string,
        applicationId: string
    ): Observable<ActionLink[]> {
        this.log.debug('Getting action links by reference {} and type {}', [referenceId, type]);

        const params = new HttpParams().set('referenceId', referenceId).set('type', type);

        return this.httpClient
            .get<ActionLink[]>(`${backofficeEnvironment.rest.v2.actionLinks}company/${companyId}/application/${applicationId}`, {
                params: params,
                headers: {
                    [NgHttpCachingHeaders.DISALLOW_CACHE]: '1',
                },
            })
            .pipe(map(actionLink => plainToClass(ActionLink, actionLink)));
    }
}
