import { PayloadAction } from '../../common/statemanagement/action/payload.action';
import { ActionState } from './datastate';
import { ACTION_ADD_TEMPLATE, ACTION_CLEAR_STATE } from './action.actioncreator';

export function actionStateReducer(state: ActionState, action: PayloadAction = null): ActionState {
    switch (action.type) {
        case ACTION_ADD_TEMPLATE:
            return state;
        case ACTION_CLEAR_STATE:
            return {
                templates: [],
                components: [],
                layers: [],
            };
        default:
            return state;
    }
}
