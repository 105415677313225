import { Variable } from '../util/variable.model';

export class ColorV2 {
    hex: Hex | undefined;
    reference: Reference | undefined;

    constructor(hex: Hex | undefined, reference: Reference | undefined) {
        this.hex = hex;
        this.reference = reference;
    }

    static ofHex(hex: Hex): ColorV2 {
        return new ColorV2(hex, undefined);
    }

    static ofReference(reference: Reference): ColorV2 {
        return new ColorV2(undefined, reference);
    }
}

export class Hex {
    value: string;
    rgb: number[];

    constructor(value: string, rgb: number[]) {
        this.value = value;
        this.rgb = rgb;
    }
}

export class Reference {
    variable: Variable;

    constructor(variable: Variable) {
        this.variable = variable;
    }
}
