import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { LoggerService } from '@backoffice/utils';
import { backofficeEnvironment } from '@shared/environment';
import { Part } from '../../models/template/part.model';
import { CreatePartDto } from '../../interfaces/parts/create-part.dto';
import { PartCreatedDto } from '../../interfaces/parts/part-created.dto';

@Injectable()
export class PartService {
    constructor(
        private httpClient: HttpClient,
        private log: LoggerService
    ) {}

    getPart(partId: string, companyId: string, applicationId: string): Observable<Part> {
        this.log.debug('Getting part', [partId]);
        return this.httpClient
            .get<Part>(`${backofficeEnvironment.rest.v2.parts}company/${companyId}/application/${applicationId}/${partId}`)
            .pipe(map(part => plainToClass(Part, part)));
    }

    getParts(containerId: string, companyId: string, applicationId: string): Observable<Part[]> {
        this.log.debug('Getting parts for container', [containerId]);
        return this.httpClient
            .get<Part[]>(
                `${backofficeEnvironment.rest.v2.parts}company/${companyId}/application/${applicationId}?containerId=${containerId}`
            )
            .pipe(map(parts => plainToClass(Part, parts)));
    }

    getPartsByTemplate(templateId: string, companyId: string, applicationId: string): Observable<Part[]> {
        this.log.debug('Getting parts for template', [templateId]);
        return this.httpClient
            .get<Part[]>(`${backofficeEnvironment.rest.v2.parts}company/${companyId}/application/${applicationId}?templateId=${templateId}`)
            .pipe(map(parts => plainToClass(Part, parts)));
    }

    deletePart(partId: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Deleting part', [partId]);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.parts}company/${companyId}/application/${applicationId}/${partId}`
        );
    }

    createPart(createPartDto: CreatePartDto, companyId: string, applicationId: string): Observable<PartCreatedDto> {
        this.log.debug('Creating part', [createPartDto]);
        return this.httpClient.post<PartCreatedDto>(
            `${backofficeEnvironment.rest.v2.parts}company/${companyId}/application/${applicationId}`,
            createPartDto
        );
    }

    updatePart(part: Part, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Updating part', [part]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.parts}company/${companyId}/application/${applicationId}/${part.id}`,
            part
        );
    }

    streamPartUpdates(templateId: string, companyId: string, applicationId: string): EventSource {
        return new EventSource(
            `${backofficeEnvironment.rest.v2.parts}stream/company/${companyId}/application/${applicationId}/${templateId}`
        );
    }
}
