<div class="button-group" role="group">
    <button
        [matTooltip]="'v2.part.style.margin.all' | translate"
        type="button"
        class="button-group-button-left"
        autofocus
        (click)="marginType = 'all'"
        [ngClass]="marginType === 'all' ? 'button-group-button-selected' : ''">
        <mat-icon>check_box_outline_blank</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.margin.top' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="marginType = 'top'"
        [ngClass]="marginType === 'top' ? 'button-group-button-selected' : ''">
        <mat-icon>margin</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.margin.left' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="marginType = 'left'"
        [ngClass]="marginType === 'left' ? 'button-group-button-selected' : ''">
        <mat-icon class="transform-270-left">margin</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.margin.bottom' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="marginType = 'bottom'"
        [ngClass]="marginType === 'bottom' ? 'button-group-button-selected' : ''">
        <mat-icon class="transform-180-left">margin</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.margin.right' | translate"
        type="button"
        class="button-group-button-right"
        autofocus
        (click)="marginType = 'right'"
        [ngClass]="marginType === 'right' ? 'button-group-button-selected' : ''">
        <mat-icon class="transform-90-left">margin</mat-icon>
    </button>
</div>

<ng-container *ngIf="marginType === 'all'">
    <codex-pixel-selector
        [label]="'v2.part.style.margin.size' | translate"
        [value]="_marginStyle.margin"
        (changePixels)="_marginStyle.margin = $event.value; onChangeAllMargin()"></codex-pixel-selector>
</ng-container>

<ng-container *ngIf="marginType === 'top'">
    <codex-pixel-selector
        [label]="'v2.part.style.margin.size' | translate"
        [value]="_marginTopStyle.margin"
        (changePixels)="_marginTopStyle.margin = $event.value; onChangeAllMargin()"></codex-pixel-selector>
</ng-container>

<ng-container *ngIf="marginType === 'left'">
    <codex-pixel-selector
        [label]="'v2.part.style.margin.size' | translate"
        [value]="_marginLeftStyle.margin"
        (changePixels)="_marginLeftStyle.margin = $event.value; onChangeAllMargin()"></codex-pixel-selector>
</ng-container>

<ng-container *ngIf="marginType === 'right'">
    <codex-pixel-selector
        [label]="'v2.part.style.margin.size' | translate"
        [value]="_marginRightStyle.margin"
        (changePixels)="_marginRightStyle.margin = $event.value; onChangeAllMargin()"></codex-pixel-selector>
</ng-container>

<ng-container *ngIf="marginType === 'bottom'">
    <codex-pixel-selector
        [label]="'v2.part.style.margin.size' | translate"
        [value]="_marginBottomStyle.margin"
        (changePixels)="_marginBottomStyle.margin = $event.value; onChangeAllMargin()"></codex-pixel-selector>
</ng-container>
