<form
    *ngIf="formGroup && !!company"
    [formGroup]="formGroup"
    (ngSubmit)="onSaveConfiguration()"
    novalidate
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true">
    <label for="azuread-name" class="small-input-label" data-name="azuread-name">{{
        'v2.company.edit.identityproviders.azuread.input.name' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="azuread-name"
        name="azuread-name"
        autocomplete="off"
        cdkFocusInitial
        required
        formControlName="name" />
    <p class="small-input-error" *ngIf="this.formGroup.get('name').touched && this.formGroup.get('name').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.name-required' | translate }}
    </p>

    <label for="azuread-displayname" class="small-input-label" data-name="azuread-displayname">{{
        'v2.company.edit.identityproviders.azuread.input.displayName' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="azuread-displayname"
        name="azuread-displayname"
        autocomplete="off"
        cdkFocusInitial
        required
        formControlName="displayName" />
    <p class="small-input-error" *ngIf="this.formGroup.get('displayName').touched && this.formGroup.get('displayName').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.displayName-required' | translate }}
    </p>

    <label for="azuread-tenantId" class="small-input-label" data-name="azuread-tenantId">{{
        'v2.company.edit.identityproviders.azuread.input.tenantId' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="azuread-tenantId"
        name="azuread-tenantId"
        autocomplete="off"
        required
        formControlName="tenantId" />
    <p class="small-input-error" *ngIf="this.formGroup.get('tenantId').touched && this.formGroup.get('tenantId').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.tenantId-required' | translate }}
    </p>

    <label for="azuread-clientid" class="small-input-label" data-name="azuread-clientid">{{
        'v2.company.edit.identityproviders.azuread.input.clientid' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="azuread-clientid"
        name="azuread-clientid"
        autocomplete="off"
        required
        formControlName="clientid" />
    <p class="small-input-error" *ngIf="this.formGroup.get('clientid').touched && this.formGroup.get('clientid').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.clientid-required' | translate }}
    </p>

    <label for="azuread-clientsecret" class="small-input-label" data-name="azuread-clientsecret">{{
        'v2.company.edit.identityproviders.azuread.input.clientsecret' | translate
    }}</label>
    <input
        class="small-input"
        type="password"
        id="azuread-clientsecret"
        name="azuread-clientsecret"
        autocomplete="off"
        required
        formControlName="clientsecret" />
    <p class="small-input-error" *ngIf="this.formGroup.get('clientsecret').touched && this.formGroup.get('clientsecret').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.clientsecret-required' | translate }}
    </p>
    <label class="toggle-line">
        {{ 'v2.company.edit.identityproviders.azuread.input.forceidp' | translate }}
        <input type="checkbox" value="" class="sr-only peer" formControlName="forceIdp" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>

    <hr class="seperator" />
    <h4>{{ 'v2.company.edit.identityproviders.azuread.redirecturls' | translate }}</h4>
    <ul class="url-list">
        <li>
            https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.co-dex.eu/auth/realms/dev-{{ company.id }}/broker/{{
                formGroup.get('name').value
            }}/endpoint
            <button
                type="button"
                mat-icon-button
                [matTooltip]="'general.copy' | translate"
                [cdkCopyToClipboard]="
                    'https://' +
                    (backofficeEnvironment.production ? '' : 'dev-') +
                    'login.nocode-x.com/auth/realms/dev-' +
                    company.id +
                    '/broker/' +
                    formGroup.get('name').value +
                    '/endpoint'
                ">
                <mat-icon>content_copy</mat-icon>
            </button>
        </li>
        <li>
            https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.co-dex.eu/auth/realms/test-{{ company.id }}/broker/{{
                formGroup.get('name').value
            }}/endpoint
            <button
                type="button"
                mat-icon-button
                [matTooltip]="'general.copy' | translate"
                [cdkCopyToClipboard]="
                    'https://' +
                    (backofficeEnvironment.production ? '' : 'dev-') +
                    'login.nocode-x.com/auth/realms/test-' +
                    company.id +
                    '/broker/' +
                    formGroup.get('name').value +
                    '/endpoint'
                ">
                <mat-icon>content_copy</mat-icon>
            </button>
        </li>
        <li>
            https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.co-dex.eu/auth/realms/accept-{{ company.id }}/broker/{{
                formGroup.get('name').value
            }}/endpoint
            <button
                type="button"
                mat-icon-button
                [matTooltip]="'general.copy' | translate"
                [cdkCopyToClipboard]="
                    'https://' +
                    (backofficeEnvironment.production ? '' : 'dev-') +
                    'login.nocode-x.com/auth/realms/accept-' +
                    company.id +
                    '/broker/' +
                    formGroup.get('name').value +
                    '/endpoint'
                ">
                <mat-icon>content_copy</mat-icon>
            </button>
        </li>
        <li>
            https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.co-dex.eu/auth/realms/{{ company.id }}/broker/{{
                formGroup.get('name').value
            }}/endpoint
            <button
                type="button"
                mat-icon-button
                [matTooltip]="'general.copy' | translate"
                [cdkCopyToClipboard]="
                    'https://' +
                    (backofficeEnvironment.production ? '' : 'dev-') +
                    'login.nocode-x.com/auth/realms/' +
                    company.id +
                    '/broker/' +
                    formGroup.get('name').value +
                    '/endpoint'
                ">
                <mat-icon>content_copy</mat-icon>
            </button>
        </li>
    </ul>

    <div class="flex gap-1 justify-end">
        <button data-name="save" class="button-medium primary-button" [disabled]="!formGroup.valid" type="submit">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="button-medium secondary-button" data-name="cancel" (click)="this.onCancel()" type="button">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
