import { NgModule } from '@angular/core';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { CommonsModule } from '../common/common.module';
import { HttpLoaderInterceptorProvider } from '../common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../common/http/interceptor/http-authentication.interceptor';
import { TagEditFacade } from './facade/tag-edit.facade';
import { TagOverviewFacade } from './facade/tag-overview.facade';
import { TagLinkService } from './service/taglink.service';
import { TagService } from './service/tag.service';

@NgModule({
    declarations: [TagInputComponent],
    imports: [CommonsModule],
    exports: [TagInputComponent],
    providers: [
        HttpLoaderInterceptorProvider,
        HttpErrorInterceptorProvider,
        HttpAuthenticationInterceptorProvider,
        TagEditFacade,
        TagOverviewFacade,
        TagService,
        TagLinkService,
        { provide: Window, useValue: window },
    ],
})
export class V2TagModule {}
