import { Variable } from '../models/util/variable.model';

export interface UpdateColorDefinitionDto {
    type: 'RGB' | 'HSV' | 'HSL';
    value: string;
    theme: 'STANDARD' | 'DARK';
    variable: Variable;
    applicationId: string;
    companyId: string;
    designSystemId: string;
}
