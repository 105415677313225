<mat-dialog-content class="scrollbar scrollbar-primary">
    <ng-container *ngIf="currentView === 'browse'">
        <codex-marketplace-overview-page
            (loadProductDetailPage)="handleLoadProductDetailPage($event)"
            (loadAddProductPage)="handleLoadAddProductPage()"></codex-marketplace-overview-page>
    </ng-container>
    <ng-container *ngIf="currentView === 'detail' && !!productId">
        <codex-product-detail-page [productId]="productId"></codex-product-detail-page>
    </ng-container>
    <ng-container *ngIf="currentView === 'add'">
        <codex-add-to-marketplace-page></codex-add-to-marketplace-page>
    </ng-container>
</mat-dialog-content>
