import { JsonArrayItem } from './json-array-item';
import { JsonEntity } from './json-entity';

const type = ['array', 'boolean', 'integer', 'null', 'number', 'object', 'string'];
type Type = (typeof type)[number];

const noCodeXType = [
    'array',
    'boolean',
    'integer',
    'number',
    'object',
    'string',
    'longstring',
    'email',
    'url',
    'password',
    'file',
    'user',
    'date',
    'currency',
    'single-select',
    'multi-select',
    'reference',
];
type NoCodeXType = (typeof type)[number];

export class JsonProperty implements JsonEntity {
    description?: string | undefined;
    noCodeXType: NoCodeXType;
    type: Type[];
    required?: string[] | undefined;

    multipleOf?: number | undefined;
    minimum?: number | undefined;
    exclusiveMinimum?: number | undefined;
    maximum?: number | undefined;
    exclusiveMaximum?: number | undefined;
    minimumLength?: number | undefined;
    maximumLength?: number | undefined;
    pattern?: string | undefined;
    enum?: string[] | undefined;
    properties?: {
        [key: string]: JsonProperty;
    };
    dataClassification: string | undefined;
    items?: JsonArrayItem;
    format?: string;
    // email & url
    domain?: string;

    // array
    minItems?: number;
    uniqueItems?: boolean;

    constructor(base: Partial<JsonProperty> = {}) {
        this.description = base.description;
        this.type = base.type;
        this.noCodeXType = base.noCodeXType;
        this.required = base.required;
        this.multipleOf = base.multipleOf;
        this.minimum = base.minimum;
        this.exclusiveMinimum = base.exclusiveMinimum;
        this.maximum = base.maximum;
        this.exclusiveMaximum = base.exclusiveMaximum;
        this.minimumLength = base.minimumLength;
        this.maximumLength = base.maximumLength;
        this.pattern = base.pattern;
        this.dataClassification = base.dataClassification;
        this.items = base.items ? new JsonArrayItem(base.items) : undefined;
        this.enum = base.enum;

        if (base.properties) {
            this.properties = {};
            const propertyNames = base.properties ? Object.keys(base.properties) : [];
            propertyNames.forEach(name => {
                this.properties[name] = new JsonProperty(base.properties[name]);
            });
        }
    }
}
