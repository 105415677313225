import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'codex-degrees-picker',
    templateUrl: './degrees-picker.component.html',
})
export class DegreesPickerComponent implements OnInit {
    @Input()
    set value(value: string) {
        if (value) {
            this.degrees = parseFloat(value);
        } else {
            this.degrees = null;
        }
    }

    @Input()
    minDegrees: number = -360;

    @Input()
    maxDegrees: number = 360;

    @Input()
    label: string;

    @Input()
    readonly: boolean;

    degrees: number = 0;

    @Output()
    changeDegrees: EventEmitter<{ value: number }> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    onChangeDegrees($event) {
        if ($event != null) {
            this.changeDegrees.emit({
                value: $event,
            });
        }
    }
}
