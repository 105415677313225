import { PartDetail } from '../../../part-detail.model';

export class IconButtonPartDetail extends PartDetail {
    code: string;
    tooltip: string;
    icon: string;
    type: string;
    enabled: boolean;

    isValid(): boolean {
        return this.isCodeValid() && this.isIconValid() && this.isTypeValid();
    }

    isTypeValid(): boolean {
        return !!this.type && this.type !== '';
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isIconValid(): boolean {
        return !!this.icon && this.icon !== '';
    }

    getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.icon-button.error.code');
        }
        if (!this.isIconValid()) {
            validationErrors.push('v2.part.icon-button.error.icon');
        }
        if (!this.isTypeValid()) {
            validationErrors.push('v2.part.icon-button.error.type');
        }
        return validationErrors;
    }
}
