import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataService } from '../services/data.service';
import { Data } from '../model/data';
import { Page } from '../../../../../../libs/shared/data-access/src/lib/model/page.model';
import { OverviewActionDto } from '../../../../../../libs/backoffice/data-access/editor/src/lib/dto/overview/overview-action.dto';
import { filter, switchMap, take } from 'rxjs/operators';
import { CompanyDto } from '../../../modules/shared/interfaces/company.dto';
import { ApplicationDto } from '../../v2-application/dto/application.dto';
import { selectCurrentContext } from '../../store/data/authenticated.selector';
import { DataFormatService } from '../services/data-format.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../store/application.state';
import { DataFormat } from '../../../../../../libs/backoffice/data-access/editor/src/lib/dataformat/models/data-format';
import { DataCreatedDto } from '../dto/data-created.dto';

@Injectable({
    providedIn: 'root',
})
export class DataEditFacade {
    currentContext$: Observable<{ userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> =
        this.store.select(selectCurrentContext);

    constructor(
        private dataService: DataService,
        private dataFormatService: DataFormatService,
        private store: Store<ApplicationState>
    ) {}

    public getDataFormat(dataFormatId: string): Observable<DataFormat> {
        if (!!dataFormatId) {
            return this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                switchMap(currentContext => {
                    return this.dataFormatService.getDataFormat(
                        dataFormatId,
                        currentContext.selectedCompany.id,
                        currentContext.selectedApplication.id
                    );
                })
            );
        }
    }

    public getDataFormats(
        keyword: string,
        filters: string[],
        orderBy: string,
        page: number,
        maxResults: number
    ): Observable<Page<OverviewActionDto>> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.dataFormatService.getDataFormats(
                    currentContext.selectedCompany.id,
                    currentContext.selectedApplication.id,
                    keyword,
                    orderBy,
                    filters,
                    page,
                    maxResults
                );
            })
        );
    }
}
