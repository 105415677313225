<form>
    <div>
        <label for="name" class="small-input-label">Name</label>
        <input type="text" id="name" class="small-input" required [formControl]="form.controls.name" />
    </div>
    <div>
        <label class="toggle-line">
            Enabled
            <input type="checkbox" class="sr-only peer" [formControl]="form.controls.active" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>
    </div>
</form>
