export class PartStyle {
    // Background
    backgroundColor: string;
    backgroundImage: string;

    // Font
    font: string;
    fontSize: number;
    fontColor: string;
    fontWeight: string;
}
