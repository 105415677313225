export interface OverviewJobDto {
    id: string;
    name: string;
    iconName: string;
    createdBy: string;
    creationDate: string;
    modificationDate: string;
    modifiedBy: string;
    productReference: string;
    productReferenceName?: string;
}

export function isOverviewJobDto(object: any): object is OverviewJobDto {
    return object && object['title'];
}
