import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '@backoffice/utils';
import { Page } from '@shared/data-access';
import { backofficeEnvironment } from '@shared/environment';
import { UserDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/user.dto';
import { CreateCompanyUserDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/create-company-user.dto';

@Injectable()
export class UserService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly logger: LoggerService
    ) {}
    createApplicationUser(createApplicationUser: {
        companyId: string;
        email: string;
        password: string;
        temporaryPassword: boolean | null;
        firstName: string;
        lastName: string;
        emailVerified: boolean;
        forceOtp: boolean;
        passwordLess: boolean;
        forceWebAuthn: boolean;
        triggerEmailValidationImmediatly: boolean;
        environment: string;
    }) {
        return this.httpClient.post<void>(
            `${backofficeEnvironment.rest.users}company/${createApplicationUser.companyId}`,
            createApplicationUser
        );
    }
    findByCompany(
        companyId: string,
        type: string,
        env: string,
        orderBy: string,
        page: number,
        maxResults: number,
        keyword: string
    ): Observable<Page<UserDto>> {
        let params = new HttpParams()
            .set('companyId', companyId)
            .set('type', type)
            .set('environment', env)
            .set('orderBy', orderBy)
            .set('page', String(page))
            .set('maxResults', String(maxResults));

        if (keyword) {
            params = params.append('keyword', keyword);
        }
        return this.httpClient.get<Page<UserDto>>(`${backofficeEnvironment.rest.users}`, { params });
    }

    addGroup(groupId: string, userId: string, applicationId: string, companyId: string): Observable<void> {
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.usergroups}company/${companyId}/application/${applicationId}`, {
            groupId,
            userId,
            applicationId,
            companyId,
        });
    }

    addRole(roleId: string, userId: string, environment: string, companyId: string, applicationId: string): Observable<void> {
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.userroles}company/${companyId}/application/${applicationId}`, {
            roleId,
            userId,
            environment,
            companyId,
            applicationId,
        });
    }

    removeGroup(groupId: string, userId: string, applicationId: string, companyId: string): Observable<void> {
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.usergroups}company/${companyId}/application/${applicationId}`,
            {
                body: {
                    groupId,
                    userId,
                    applicationId,
                    companyId,
                },
            }
        );
    }

    removeRole(roleId: string, userId: string, environment: string, companyId: string, applicationId: string): Observable<void> {
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.userroles}company/${companyId}/application/${applicationId}`, {
            body: {
                roleId,
                userId,
                environment,
                companyId,
                applicationId,
            },
        });
    }

    inviteUser(email: string, companyId: string): Observable<void> {
        const dto: CreateCompanyUserDto = { email, companyId };
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.companyuser}`, dto, {
            headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
        });
    }

    deleteUser(userId: string, companyId: string, environment: string): Observable<void> {
        let params = new HttpParams().set('environment', environment);
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.users}company/${companyId}/user/${userId}`, { params });
    }
}
