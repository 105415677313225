import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { HttpClient } from '@angular/common/http';
import { Method } from '../../../../../../libs/backoffice/data-access/editor/src/lib/action/model/method';
import { backofficeEnvironment } from '@shared/environment';
import { map } from 'rxjs/operators';
import { LoggerService } from '@backoffice/utils';

@Injectable({
    providedIn: 'root',
})
export class MethodService {
    constructor(
        private httpClient: HttpClient,
        private log: LoggerService
    ) {}

    public getMethods(companyId: string, applicationId: string, referenceId: string): Observable<Method[]> {
        this.log.debug('Getting methods for reference', [referenceId]);
        return of(
            plainToClass(Method, [
                {
                    key: 'get.value.of.field',
                    accentColor: '#ffff00',
                    name: 'get.value.of',
                    functionName: 'get.value.of',
                    description: 'get.value.of.description',
                    iconName: 'bolt',
                    parameters: [
                        {
                            id: '1234',
                            name: 'Field',
                            description: 'The field to get the value of',
                            type: 'part',
                            required: true,
                            defaultValue: '',
                        },
                    ],
                    outputs: [
                        {
                            id: '123',
                            name: 'Value of field',
                            description: 'The value of the field should be put in the scope',
                            defaultValue: 'VALUE_OF_FIELD',
                            type: 'string',
                        },
                    ],
                },
            ])
        );
    }

    public getAllMethods(companyId: string, applicationId: string): Observable<Method[]> {
        this.log.debug('Getting all methods');
        return this.httpClient
            .get<Method[]>(`${backofficeEnvironment.rest.v2.methods}company/${companyId}/application/${applicationId}`)
            .pipe(map(methods => plainToClass(Method, methods)));
    }
}
