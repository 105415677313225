import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '../../../../../../libs/shared/data-access/src/lib/model/page.model';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../store/application.state';
import { TagLinkService } from '../service/taglink.service';
import { OverviewTagLinkDto } from '../dto/overview-taglink.dto';
import { TagService } from '../service/tag.service';
import { OverviewTagDto } from '../dto/overview-tag.dto';
import { CreateTaglinkDto } from '../dto/create-taglink.dto';
import { TagLinkCreatedDto } from '../dto/taglink-created.dto';
import { CompanyDto } from '../../../modules/shared/interfaces/company.dto';
import { ApplicationDto } from '../../v2-application/dto/application.dto';
import { selectCurrentContext } from '../../store/data/authenticated.selector';
import { selectSelectedCompanyId } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/statemanagement/selectors/company.selector';

@Injectable({
    providedIn: 'root',
})
export class TagEditFacade {
    selectedCompanyId$: Observable<string> = this.store.select(selectSelectedCompanyId);

    currentContext$: Observable<{ userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> =
        this.store.select(selectCurrentContext);

    constructor(
        private tagLinkService: TagLinkService,
        private tagService: TagService,
        private store: Store<ApplicationState>
    ) {}

    public findTagsForType(
        keyword: string,
        type: string,
        orderBy: string,
        page: number,
        maxResults: number,
        applicationId: string,
        companyId: string
    ): Observable<Page<OverviewTagDto>> {
        let filters = new Array<string>();
        filters.push('type:' + type);
        return this.tagService.getTags(companyId, applicationId, keyword, orderBy, filters, page, maxResults);
    }

    public getTagLinksForEntity(
        entityId: string,
        type: string,
        applicationId: string,
        companyId: string
    ): Observable<OverviewTagLinkDto[]> {
        let filters = new Array<string>();
        filters.push('linkedEntityId:' + entityId);
        filters.push('type:' + type);
        return this.tagLinkService.getTagLinks(companyId, applicationId, '', 'tag desc', filters, 0, 100).pipe(map(page => page.content));
    }

    createTagLink(createTagLinkDto: CreateTaglinkDto): Observable<TagLinkCreatedDto> {
        return this.tagLinkService.createTagLink(createTagLinkDto);
    }

    deleteTagLink(tagLinkId: string): Observable<void> {
        return this.tagLinkService.deleteTagLink(tagLinkId);
    }
}
