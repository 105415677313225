<div
    [showTrigger]="NgxFloatUiTriggers.click"
    [floatUi]="picker"
    [appendTo]="'body'"
    [boundariesElement]="'body'"
    [preventOverflow]="false"
    [positionFixed]="true"
    [placement]="NgxFloatUiPlacements.LEFT"
    (onShown)="onShowFontFamilies()"
    class="relative h-8 mb-2 p-2.5 flex flex-row items-center border rounded-lg border-light-input-border_color dark:border-dark-input-border_color bg-light-input-background_color dark:bg-dark-input-background_color dark:text-gray-300 cursor-pointer">
    <div class="w-full flex items-center justify-center">
        <label class="text-xs">{{ formControl.value }}</label>
    </div>
</div>
<float-ui-content #picker>
    <div
        class="min-w-[30rem] border rounded-lg border-light-input-border_color dark:border-dark-input-border_color bg-light-background_level_0 dark:bg-dark-background_level_0">
        <div class="flex flex-col">
            <div class="m-4 mb-2">
                <input type="text" class="small-input" placeholder="Search fonts ..." (keyup)="handleSearchTermChanged($event)" />
            </div>
            <div class="scrollbar-primary overflow-auto p-4 mb-2 pt-2">
                <ul class="w-full h-96 text-gray-500 list-inside dark:text-gray-300 pb-4">
                    <li
                        *ngFor="let family of fonts$ | async"
                        class="p-4 mb-2 dark:bg-gray-700 rounded-lg cursor-pointer"
                        (click)="handleFamilyClicked(family, picker)">
                        <span class="block text-lg font-bold" [style.font-family]="family.family">{{ family.family }}</span>
                        <span class="block text-sm" [style.font-family]="family.family"
                            >Coding like poetry should be short and concise.
                        </span>
                        <span></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</float-ui-content>
