<div class="overview-item" (click)="onClicked()">
    <div class="icon">
        <input (click)="$event.stopPropagation(); onCheckboxSelected()" id="default-checkbox" type="checkbox" value="" class="checkbox" />
        <i class="material-icons">
            {{ api.iconName ? api.iconName : 'content_paste' }}
        </i>
    </div>
    <div class="name-data">
        <div class="name">
            <span>{{ api.name }}</span>
        </div>
        <div class="product-reference" *ngIf="api.productReference">
            <mat-icon class="text-lg leading-6 !h-5 !w-5">electrical_services</mat-icon>
            <div class="leading-6">{{ api.productReferenceName }}</div>
        </div>
    </div>
    <div class="audit-data">
        <div>
            {{ 'general.search.result.created.by' | translate }}
            {{ api.createdBy }}
            {{ 'general.search.result.on' | translate }} {{ api.creationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
        <div>
            {{ 'general.search.result.updated.by' | translate }} {{ api.modifiedBy }} {{ 'general.search.result.on' | translate }}
            {{ api.modificationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
    </div>
</div>
