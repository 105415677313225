import 'reflect-metadata';
import { Component } from '@angular/core';

@Component({
    selector: 'codex-progress',
    template: `
        <div class="p-2 rounded-full absolute bottom-2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white shadow">
            <mat-progress-spinner strokeWidth="2" diameter="50" mode="indeterminate"></mat-progress-spinner>
        </div>
    `,
})
export class ProgressComponent {}
