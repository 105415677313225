import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../../../store/editor.state';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { LoggerService } from '../../../../../../../utils/src/lib/services/logger.service';
import { AppFacade } from '@core/facades/app.facade';
import { SelectedFacets } from '@backoffice/data-access/editor';
import { Page } from '@shared/data-access';
import { OverviewRightDto } from '../../dto/overview-right.dto';
import { RightsService } from '../../services/roles.service';
import { RightDto } from '../../dto/right.dto';
import {
    clearRightDialogData,
    createRightSuccess,
    deleteRights,
    findRight,
    loadRightDialogData,
    rightDialogFacetsChanged,
    rightDialogFilterPluginsChanged,
    rightDialogPaginationChanged,
    rightDialogSearchTermChanged,
    updateRight,
} from '../actions/right-editor.actions';
import { rightEditorSelectors } from '../selectors/right-editor.selectors';

@Injectable()
export class RightEditorFacade {
    get dialogData(): Observable<Page<OverviewRightDto> | undefined> {
        return this.store.select(rightEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(rightEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(rightEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(rightEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(rightEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{
        searchTerm: string | undefined;
        page: number;
        maxResults: number;
        facets: SelectedFacets;
        filterPlugins;
    }> {
        return this.store.select(rightEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly rightsService: RightsService,
        private readonly appFacade: AppFacade,
        private readonly log: LoggerService
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearRightDialogData());
    }

    create(): Observable<string> {
        this.log.info('Create new right');
        return combineLatest([this.appFacade.selectedCompany, this.appFacade.selectedApplication]).pipe(
            take(1),
            switchMap(([{ id: companyId }, { id: applicationId }]) =>
                this.rightsService
                    .create(
                        {
                            companyId,
                            applicationId,
                        },
                        companyId,
                        applicationId
                    )
                    .pipe(
                        tap(({ id }) => this.store.dispatch(createRightSuccess({ id, openTab: true }))),
                        map(({ id }) => id)
                    )
            )
        );
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(rightDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(rightDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(rightDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(rightDialogFilterPluginsChanged({ filterPlugins }));
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting right`, [ids]);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteRights({ ids }));
        }
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadRightDialogData());
    }

    findById(id: string): Observable<RightDto> {
        this.log.info(`Find right by id ${id}`);
        this.store.dispatch(findRight({ id }));
        return this.store.select(rightEditorSelectors.byId(id)).pipe(
            filter(right => !!right),
            map(right => right as RightDto)
        );
    }

    update(right: RightDto): void {
        this.log.info(`Update right`, [right]);
        this.store.dispatch(updateRight({ right }));
    }
}
