import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgxFloatUiContentComponent, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent {
    @Input() color: string;

    @Output() changeColor: EventEmitter<{ color: string }> = new EventEmitter();

    @ViewChild('colorPopover') popperContent: NgxFloatUiContentComponent;

    colorPickerToggle = true;

    onChangeColor(newColor: string) {
        this.color = newColor;
        this.changeColor.emit({ color: this.color });
    }

    onShowColorPicker(): void {
        setTimeout(() => {
            if (this.popperContent) {
                this.popperContent.update();
            }
        }, 50);
    }

    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
