import { PayloadAction } from '../../common/statemanagement/action/payload.action';
import { DATA_ROLES_SET_USER_RIGHTS, DATA_ROLES_SET_USER_ROLES } from './authentication.actioncreator';
import { CompanyUserRoleDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.user.role.dto';
import { CompanyUserRightDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/company.user.right.dto';

export const CONTEXT_SET_USER_SUCCESS: string = '(Context) set user success';

const initialState = {
    isAuthenticated: false,
    profile: null,
};

export function setUser(user: any): PayloadAction {
    return {
        type: CONTEXT_SET_USER_SUCCESS,
        payload: user,
    };
}

export function companyUserRolesReducer(state: CompanyUserRoleDto[], action: PayloadAction = null): CompanyUserRoleDto[] {
    switch (action.type) {
        case DATA_ROLES_SET_USER_ROLES:
            return action.payload;
        default:
            return state;
    }
}

export function companyUserRightsReducer(state: CompanyUserRightDto[], action: PayloadAction = null): CompanyUserRightDto[] {
    switch (action.type) {
        case DATA_ROLES_SET_USER_RIGHTS:
            return action.payload;
        default:
            return state;
    }
}
