import { Parameter } from '../../parameters/parameter';
import { Output } from '@backoffice/data-access/editor';
import { Type } from 'class-transformer';

export class Method {
    public deprecated: boolean;
    public key?: string;
    public category?: string;
    public names?: any;
    public iconName?: string;
    public accentColor?: string;
    public descriptions?: any;
    public functionName?: string;
    @Type(() => Parameter)
    public parameters: Parameter[];
    @Type(() => Output)
    public outputs: Output[];

    public getName(language?: string) {
        if (this.names) {
            if (language && this.names[language]) {
                return this.names[language];
            } else {
                return this.names['en'];
            }
        } else {
            return 'unknown';
        }
    }

    public getDescription(language?: string) {
        if (this.descriptions) {
            if (language && this.descriptions[language]) {
                return this.descriptions[language];
            } else {
                return this.descriptions['en'];
            }
        } else {
            return 'unknown';
        }
    }
}
