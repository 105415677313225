import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { TemplateService } from '../../services/template/template.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PartTypeEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly templateService: TemplateService,
        private readonly dialog: MatDialog,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}

    /*
  fetch$ = createEffect(() => this.actions$.pipe(
    ofType(findPartTypes),
    concatLatestFrom(() => [
      this.appFacade.selectedCompany,
      this.appFacade.selectedApplication,
      this.store.select(partTypesEditorSelectors.all)
    ]),
    filter(([_template, company, application, detail]) => !detail && !!company && !!application),
    mergeMap(([{id}, {id: companyId}, {id: applicationId}]) => this.templateService.findById(id, companyId, applicationId).pipe(
      map(result => fetchTemplateSuccess({template: result}))
      )
    )
  ));
*/
}
