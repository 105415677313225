import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Part, ScreenType, selectBasedOnPartPositioning, TemplateEditorFacade } from '@backoffice/data-access/editor';
import { NgxFloatUiContentComponent, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
    selector: 'codex-size-position-title',
    templateUrl: './size-position-title.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizePositionTitleComponent implements OnDestroy {
    @Input()
    part: Part;

    @Output()
    changeSizePosition: EventEmitter<any> = new EventEmitter<any>();

    basedOnPartPositioning$: Observable<{
        type: string;
        icon: string;
        isCurrentScreenType: boolean;
    }> = combineLatest([this.templateEditorFacade.currentScreenType, this.templateEditorFacade.selectedPart]).pipe(
        filter(([currentScreenType, selectedPart]) => (!!currentScreenType || currentScreenType === 0) && !!selectedPart),
        map(([currentScreenType, selectedPart]) => {
            const type = selectBasedOnPartPositioning(currentScreenType, selectedPart.positions);
            const icon = this.getBasedOnPartPositioningIcon(type);
            return {
                type,
                icon,
                isCurrentScreenType: type === ScreenType[currentScreenType],
            };
        })
    );

    @ViewChild('screenTypeInheritPopover') popperContent: NgxFloatUiContentComponent;

    protected readonly ScreenType = ScreenType;

    subscriptions: Subscription = new Subscription();

    constructor(private templateEditorFacade: TemplateEditorFacade) {}

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getBasedOnPartPositioningIcon(partPositioning: string) {
        if (partPositioning === 'SMALL') {
            return 'smartphone';
        } else if (partPositioning === 'MEDIUM') {
            return 'tablet_android';
        } else if (partPositioning === 'LARGE') {
            return 'tablet';
        } else if (partPositioning === 'EXTRA_LARGE') {
            return 'laptop';
        } else if (partPositioning === 'EXTRA_EXTRA_LARGE') {
            return 'desktop_windows';
        } else if (partPositioning === 'ALL_SCREENS') {
            return 'devices';
        }
    }

    onShowInformation() {
        setTimeout(() => {
            if (this.popperContent) {
                this.popperContent.update();
            }
        }, 50);
    }

    onResetStyle() {
        this.subscriptions.add(
            this.basedOnPartPositioning$.pipe(take(1)).subscribe(basedOnPartPositioning => {
                const positionIndex = this.part.positions.findIndex(
                    partPosition => partPosition.screenType === basedOnPartPositioning.type
                );
                this.part.positions.splice(positionIndex, 1);
                this.changeSizePosition.emit({ part: this.part });
            })
        );
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
