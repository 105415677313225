export interface OverviewDataFormatDto {
    id: string;
    applicationId: string;
    applicationName: string;
    companyId: string;
    createdBy: string;
    creationDate: string;
    modificationDate: string;
    modifiedBy: string;
    name: string;
    iconName: string;
    productReference: string;
    productReferenceName?: string;
}

export function isOverviewDataFormatDto(object: any): object is OverviewDataFormatDto {
    return object && object['name'];
}
