import { PartDetail } from '../../../part-detail.model';

export class SlideToggleFieldPartDetail extends PartDetail {
    code?: string;
    label?: string;
    answer?: boolean;

    help: boolean;
    helpInformation: string;

    enabled: boolean;

    public isValid(): boolean {
        return this.isCodeValid() && this.isHelpValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isHelpValid(): boolean {
        return !this.help || (!!this.helpInformation && this.helpInformation !== '');
    }

    getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.slide-toggle-field.error.code');
        }
        if (!this.isHelpValid()) {
            validationErrors.push('v2.part.slider-toggle-field.error.help');
        }
        return validationErrors;
    }
}
