export class CompanyIdentityProvider {
    id?: string;
    name?: string;
    displayName?: string;
    discoveryendpoint?: string;
    clientId?: string;
    clientSecret?: string;
    clientAuthentication?: string;
    type: string;
    forceIdp: boolean;

    tenantId?: string;
}
