import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiService } from './services/api.service';
import { DataService } from './services/data.service';
import { MediaService } from './services/media.service';
import { JobService } from './services/job.service';
import { EditorFacade } from './store/facades/editor.facade';
import { ApiEditorFacade } from './store/facades/api-editor.facade';
import { ActionEditorFacade } from './store/facades/action-editor.facade';
import { DataEditorFacade } from './store/facades/data-editor.facade';
import { DataFormatEditorFacade } from './dataformat/store/facades/data-format-editor.facade';
import { MediaEditorFacade } from './store/facades/media-editor.facade';
import { TemplateEditorFacade } from './store/facades/template-editor.facade';
import { JobEditorFacade } from './store/facades/job-editor.facade';
import { StoreModule } from '@ngrx/store';
import { editorStateKey } from './store/editor.state';
import { editorReducers } from './store/reducers/editor.reducers';
import { EffectsModule } from '@ngrx/effects';
import { ApiEffects } from './store/effects/api.effects';
import { ActionEffects } from './store/effects/action.effects';
import { DataEffects } from './store/effects/data.effects';
import { DataFormatEffects } from './dataformat/store/effects/dataformat.effects';
import { MediaEffects } from './store/effects/media.effects';
import { TemplateEffects } from './store/effects/template.effects';
import { JobEffects } from './store/effects/job.effects';
import { PartTypeEffects } from './store/effects/part-type.effects';
import { ActionContextFacade } from './store/facades/action-context.facade';
import { AuthorizationLinkService } from './services/template/authorization-link.service';
import { LayerService } from './services/template/layer.service';
import { PartTypeService } from './services/template/part-type.service';
import { TemplateService } from './services/template/template.service';
import { TemplateInheritanceService } from './services/template/template-inheritance.service';
import { PartService } from './services/template/part.service';
import { ActionDataAccessModule } from '@backoffice/editor/data-access/action';
import { DesignSystemEditorFacade } from './designsystem/store/facades/design-system-editor.facade';
import { DesignSystemService } from './designsystem/services/design-system.service';
import { DataformatService } from './dataformat/services/dataformat.service';
import { GroupsService } from './group/services/groups.service';
import { RightsService } from './right/services/roles.service';
import { DesignSystemEffects } from './designsystem/store/effects/design-system.effects';
import { GroupEditorFacade } from './group/store/facades/group-editor.facade';
import { RightEditorFacade } from './right/store/facades/right-editor.facade';
import { RightEffects } from './right/store/effects/right.effects';
import { GroupEffects } from './group/store/effects/group.effects';
import { ActionTestService } from './action/services/action-test.service';

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([
            ApiEffects,
            ActionEffects,
            DataEffects,
            DataFormatEffects,
            MediaEffects,
            TemplateEffects,
            JobEffects,
            PartTypeEffects,
            DesignSystemEffects,
            RightEffects,
            GroupEffects,
        ]),
        ActionDataAccessModule,
        StoreModule.forFeature(editorStateKey, editorReducers),
    ],
    providers: [
        ActionContextFacade,
        ActionEditorFacade,
        ApiEditorFacade,
        ActionTestService,
        ApiService,
        AuthorizationLinkService,
        DataEditorFacade,
        DataService,
        DataFormatEditorFacade,
        DataformatService,
        DesignSystemEditorFacade,
        DesignSystemService,
        EditorFacade,
        JobEditorFacade,
        JobService,
        LayerService,
        MediaService,
        MediaEditorFacade,
        TemplateService,
        TemplateEditorFacade,
        TemplateInheritanceService,
        PartService,
        PartTypeService,
        GroupsService,
        GroupEditorFacade,
        RightsService,
        RightEditorFacade,
    ],
})
export class BackofficeDataAccessEditorModule {}
