<mat-dialog-content class="scrollbar scrollbar-primary">
    <div class="p-4" [formGroup]="formGroup">
        <h1 class="!mb-2">Create API</h1>
        <div class="choice-grid">
            <div class="choice-card" [class.choice-card-selected]="selectedType === 'BLANK'" (click)="onSelectType('BLANK')">
                <mat-icon>api</mat-icon>
                <span class="choice-card-title">Blank</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_CRUD_FROM_DATAFORMAT'"
                (click)="onSelectType('GENERATE_CRUD_FROM_DATAFORMAT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">CRUD from data-format</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_CREATE_FROM_DATAFORMAT'"
                (click)="onSelectType('GENERATE_CREATE_FROM_DATAFORMAT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Creation API from data-format</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_READ_FROM_DATAFORMAT'"
                (click)="onSelectType('GENERATE_READ_FROM_DATAFORMAT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Read API from data-format</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_UPDATE_FROM_DATAFORMAT'"
                (click)="onSelectType('GENERATE_UPDATE_FROM_DATAFORMAT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Update API from data-format</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_DELETE_FROM_DATAFORMAT'"
                (click)="onSelectType('GENERATE_DELETE_FROM_DATAFORMAT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Delete API from data-format</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_FIND_FROM_DATAFORMAT'"
                (click)="onSelectType('GENERATE_FIND_FROM_DATAFORMAT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Find API from data-format</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_CRUD_FROM_TEXT'"
                (click)="onSelectType('GENERATE_CRUD_FROM_TEXT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">CRUD from Text</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_CREATE_FROM_TEXT'"
                (click)="onSelectType('GENERATE_CREATE_FROM_TEXT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Create API from Text</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_READ_FROM_TEXT'"
                (click)="onSelectType('GENERATE_READ_FROM_TEXT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Read API from text</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_UPDATE_FROM_TEXT'"
                (click)="onSelectType('GENERATE_UPDATE_FROM_TEXT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Update API from text</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_DELETE_FROM_TEXT'"
                (click)="onSelectType('GENERATE_DELETE_FROM_TEXT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Delete API from text</span>
            </div>

            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_FIND_FROM_TEXT'"
                (click)="onSelectType('GENERATE_FIND_FROM_TEXT')">
                <mat-icon>auto_awesome</mat-icon>
                <span class="choice-card-title">Find API from text</span>
            </div>
        </div>
        <ng-container *ngIf="selectedType === 'BLANK'">
            <label for="name" class="small-input-label">{{ 'v2.api.edit.name' | translate }}</label>
            <input
                class="small-input"
                type="text"
                id="name"
                name="name"
                data-name="api-name"
                required
                cdkFocusInitial
                formControlName="name"
                autocomplete="off" />
        </ng-container>
        <ng-container
            *ngIf="
                selectedType === 'GENERATE_CRUD_FROM_DATAFORMAT' ||
                selectedType === 'GENERATE_CREATE_FROM_DATAFORMAT' ||
                selectedType === 'GENERATE_READ_FROM_DATAFORMAT' ||
                selectedType === 'GENERATE_UPDATE_FROM_DATAFORMAT' ||
                selectedType === 'GENERATE_DELETE_FROM_DATAFORMAT'
            ">
            <app-dataformat-picker
                [addQuotesToValue]="false"
                [required]="true"
                [showAddButton]="false"
                [showEditButton]="false"
                (valueUpdated)="onDataFormatPick($event)">
            </app-dataformat-picker>

            <div class="badges">
                <span
                    id="badge-dismiss-default"
                    *ngFor="let dataFormat of dataFormats"
                    class="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300">
                    {{ dataFormat.name }}
                    <button
                        type="button"
                        class="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
                        data-dismiss-target="#badge-dismiss-default"
                        aria-label="Remove"
                        (click)="onRemoveDataFormat(dataFormat)">
                        <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Remove badge</span>
                    </button>
                </span>
            </div>
        </ng-container>
        <ng-container
            *ngIf="
                selectedType === 'GENERATE_CRUD_FROM_TEXT' ||
                selectedType === 'GENERATE_FIND_FROM_TEXT' ||
                selectedType === 'GENERATE_DELETE_FROM_TEXT' ||
                selectedType === 'GENERATE_UPDATE_FROM_TEXT' ||
                selectedType === 'GENERATE_READ_FROM_TEXT' ||
                selectedType === 'GENERATE_CREATE_FROM_TEXT'
            ">
            <label for="datadescription" class="small-input-label">Describe the data of your API</label>
            <textarea
                class="small-input"
                type="text"
                id="datadescription"
                name="datadescription"
                formControlName="datadescription"
                autocomplete="off"></textarea>
        </ng-container>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" data-name="save" type="submit" (click)="onCreate()">
                <mat-icon>add_circle</mat-icon>
                Create API
            </button>
            <button class="secondary-button button-large" data-name="cancel" type="button" (click)="dialogRef.close({ create: false })">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-content>
