import { HttpParams } from '@angular/common/http';

declare module '@angular/common/http' {
    interface HttpParams {
        appendNonNull(key: string, value?: string | number): HttpParams;
    }
}

HttpParams.prototype.appendNonNull = function (key: string, value: unknown) {
    if (((value instanceof String || typeof value === 'string') && value !== '') || value instanceof Number || typeof value === 'number') {
        return this.append(key, String(value));
    } else {
        return this;
    }
};
