import { ColorV2 } from '../../designsystem/models/color/colorv2.model';
import { generateColorStyleV2 } from './color.style';
import { generateBorderStyleWithVars } from './border.style.dto';

export interface BackgroundStyleDto {
    backgroundColor?: string;
    backgroundColorV2?: ColorV2;
    backgroundImage?: string;
    backgroundImageUrl?: string;
    backgroundImageLinkType?: string;
    backgroundImageMediaId?: string;
    backgroundAttachment?: string;
    backgroundPositionX?: string;
    backgroundPositionY?: string;
    backgroundRepeat?: string;
    backgroundSize?: string;
    backgroundClip?: string;
    backgroundOrigin?: string;
}

export function generateBackgroundColor(color: string) {
    let style = '';
    if (color) {
        style += 'background-color:' + color + ';';
    }
    return style;
}

export function generateBackgroundStyleWithVarsBasedOnPrefix(
    prefix: string,
    backgroundStyle: BackgroundStyleDto | null | undefined,
    companyId: string
) {
    return generateBackgroundStyleWithVars(
        backgroundStyle,
        companyId,
        '--' + prefix + '-background-color',
        '--' + prefix + '-background-image',
        '--' + prefix + '-background-attachment',
        '--' + prefix + '-background-position',
        '--' + prefix + '-background-repeat',
        '--' + prefix + '-background-size',
        '--' + prefix + '-background-origin'
    );
}

export function generateBackgroundStyleWithVars(
    backgroundStyle: BackgroundStyleDto | null | undefined,
    companyId: string,
    backgroundColorVarName: string,
    backgroundImageVarName: string,
    backgroundAttachmentVarName: string,
    backgroundPositionVarName: string,
    backgroundRepeatVarName: string,
    backgroundSizeVarName: string,
    backgroundOriginVarName: string
) {
    let style = '';
    if (backgroundStyle) {
        if (backgroundStyle.backgroundColorV2) {
            style += backgroundColorVarName + ':' + generateColorStyleV2(backgroundStyle.backgroundColorV2) + ';';
        } else if (backgroundStyle['backgroundColor']) {
            style += backgroundColorVarName + ':' + backgroundStyle['backgroundColor'] + ';';
        }

        if (backgroundStyle['backgroundImageLinkType'] === 'media-library' && backgroundStyle['backgroundImageMediaId']) {
            style +=
                backgroundImageVarName +
                `:url("v2/media/file/company/${companyId}/application/${companyId}/` +
                backgroundStyle['backgroundImageMediaId'] +
                '");';
        } else if (backgroundStyle['backgroundImageLinkType'] === 'url' && backgroundStyle['backgroundImageUrl']) {
            style += backgroundImageVarName + ':url("' + backgroundStyle['backgroundImageUrl'] + '");';
        }

        if (backgroundStyle['backgroundAttachment']) {
            style += backgroundAttachmentVarName + ':' + backgroundStyle['backgroundAttachment'] + ';';
        }
        if (backgroundStyle['backgroundPositionX'] && backgroundStyle['backgroundPositionY']) {
            style +=
                backgroundPositionVarName +
                ':' +
                backgroundStyle['backgroundPositionX'] +
                ' ' +
                backgroundStyle['backgroundPositionY'] +
                ';';
        }
        if (backgroundStyle['backgroundRepeat']) {
            style += backgroundRepeatVarName + ':' + backgroundStyle['backgroundRepeat'] + ';';
        }
        if (backgroundStyle['backgroundSize']) {
            style += backgroundSizeVarName + ':' + backgroundStyle['backgroundSize'] + ';';
        }
        if (backgroundStyle['backgroundOrigin']) {
            style += backgroundOriginVarName + ':' + backgroundStyle['backgroundSize'] + ';';
        }
    }
    return style;
}

export function generateBackgroundStyle(
    backgroundStyle: BackgroundStyleDto | null | undefined,
    companyId?: string,
    applicationId?: string
) {
    let style = '';
    if (backgroundStyle) {
        if (backgroundStyle.backgroundColorV2) {
            style += 'background-color:' + generateColorStyleV2(backgroundStyle.backgroundColorV2) + ';';
        } else if (backgroundStyle['backgroundColor']) {
            style += 'background-color:' + backgroundStyle['backgroundColor'] + ';';
        }

        if (backgroundStyle['backgroundImageLinkType'] === 'media-library' && backgroundStyle['backgroundImageMediaId']) {
            style +=
                `background-image:url("v2/media/file/company/${companyId}/application/${companyId}/` +
                backgroundStyle['backgroundImageMediaId'] +
                '");';
        }

        if (backgroundStyle['backgroundImageLinkType'] === 'url' && backgroundStyle['backgroundImageUrl']) {
            style += 'background-image:url("' + backgroundStyle['backgroundImageUrl'] + '");';
        }

        if (backgroundStyle['backgroundAttachment']) {
            style += 'background-attachment:' + backgroundStyle['backgroundAttachment'] + ';';
        }
        if (backgroundStyle['backgroundPositionX'] && backgroundStyle['backgroundPositionY']) {
            style += 'background-position:' + backgroundStyle['backgroundPositionX'] + ' ' + backgroundStyle['backgroundPositionY'] + ';';
        }
        if (backgroundStyle['backgroundRepeat']) {
            style += 'background-repeat:' + backgroundStyle['backgroundRepeat'] + ';';
        }
        if (backgroundStyle['backgroundSize']) {
            style += 'background-size:' + backgroundStyle['backgroundSize'] + ';';
        }
        if (backgroundStyle['backgroundOrigin']) {
            style += 'background-origin:' + backgroundStyle['backgroundSize'] + ';';
        }
    }
    return style;
}
