<div class="button-group" role="group">
    <button
        [matTooltip]="'v2.part.style.padding.all' | translate"
        type="button"
        class="button-group-button-left"
        autofocus
        (click)="paddingType = 'all'"
        [ngClass]="paddingType === 'all' ? 'button-group-button-selected' : ''">
        <mat-icon>check_box_outline_blank</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.padding.top' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="paddingType = 'top'"
        [ngClass]="paddingType === 'top' ? 'button-group-button-selected' : ''">
        <mat-icon>padding</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.padding.left' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="paddingType = 'left'"
        [ngClass]="paddingType === 'left' ? 'button-group-button-selected' : ''">
        <mat-icon class="transform-270-left">padding</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.padding.bottom' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="paddingType = 'bottom'"
        [ngClass]="paddingType === 'bottom' ? 'button-group-button-selected' : ''">
        <mat-icon class="transform-180-left">padding</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.padding.right' | translate"
        type="button"
        class="button-group-button-right"
        autofocus
        (click)="paddingType = 'right'"
        [ngClass]="paddingType === 'right' ? 'button-group-button-selected' : ''">
        <mat-icon class="transform-90-left">padding</mat-icon>
    </button>
</div>

<ng-container *ngIf="paddingType === 'all'">
    <codex-pixel-selector
        [label]="'v2.part.style.padding.size' | translate"
        [value]="_paddingStyle.padding"
        (changePixels)="_paddingStyle.padding = $event.value; onChangeAllPadding()"></codex-pixel-selector>
</ng-container>

<ng-container *ngIf="paddingType === 'top'">
    <codex-pixel-selector
        [label]="'v2.part.style.padding.size' | translate"
        [value]="_paddingTopStyle.padding"
        (changePixels)="_paddingTopStyle.padding = $event.value; onChangeAllPadding()"></codex-pixel-selector>
</ng-container>

<ng-container *ngIf="paddingType === 'left'">
    <codex-pixel-selector
        [label]="'v2.part.style.padding.size' | translate"
        [value]="_paddingLeftStyle.padding"
        (changePixels)="_paddingLeftStyle.padding = $event.value; onChangeAllPadding()"></codex-pixel-selector>
</ng-container>

<ng-container *ngIf="paddingType === 'right'">
    <codex-pixel-selector
        [label]="'v2.part.style.padding.size' | translate"
        [value]="_paddingRightStyle.padding"
        (changePixels)="_paddingRightStyle.padding = $event.value; onChangeAllPadding()"></codex-pixel-selector>
</ng-container>
<ng-container *ngIf="paddingType === 'bottom'">
    <codex-pixel-selector
        [label]="'v2.part.style.padding.size' | translate"
        [value]="_paddingBottomStyle.padding"
        (changePixels)="_paddingBottomStyle.padding = $event.value; onChangeAllPadding()"></codex-pixel-selector>
</ng-container>
