import { Injectable } from '@angular/core';
import { filter, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { OverviewActionDto } from '@backoffice/data-access/editor';
import { Page } from '@shared/data-access';
import { Action, ActionService } from '@backoffice/editor/data-access/action';
import { ActionCreatedDto } from '../../dto/action-created.dto';
import { ActionLinkService } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-actions/services/action-link.service';
import { ActionLinkCreatedDto } from '../../dto/action-link-created.dto';
import { ActionLink } from '../../model/action-link';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application.dto';
import { selectCurrentContext } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { selectSelectedCompanyId } from '../../../../../../../feature/company/edit-company/src/lib/statemanagement/selectors/company.selector';

@Injectable({
    providedIn: 'root',
})
export class ActionPickerFacade {
    selectedCompanyId$: Observable<string> = this.store.select(selectSelectedCompanyId);

    currentContext$: Observable<{ userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> =
        this.store.select(selectCurrentContext);

    constructor(
        private actionService: ActionService,
        private actionLinkService: ActionLinkService,
        private store: Store<ApplicationState>
    ) {}

    public getActions(keyword: string, applicationId?: string): Observable<Page<OverviewActionDto>> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionService.findAll(
                    currentContext.selectedCompany.id,
                    applicationId ? applicationId : currentContext.selectedApplication.id,
                    {
                        keyword,
                        orderBy: 'score desc',
                        page: 0,
                        maxResults: 20,
                        filters: [],
                    }
                );
            })
        );
    }

    public getAction(actionId: string, applicationId?: string): Observable<Action> {
        if (!!actionId) {
            return this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                switchMap(currentContext => {
                    return this.actionService.findById(
                        actionId,
                        currentContext.selectedCompany.id,
                        applicationId ? applicationId : currentContext.selectedApplication.id
                    );
                })
            );
        }
    }

    public deleteActionLink(actionLinkId: string, applicationId?: string): Observable<void> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionLinkService.deleteActionLink(
                    actionLinkId,
                    currentContext.selectedCompany.id,
                    applicationId ? applicationId : currentContext.selectedApplication.id
                );
            })
        );
    }

    public createActionLink(
        actionId: string,
        referenceId: string,
        subReferenceId: string,
        type: string,
        applicationId?: string
    ): Observable<ActionLinkCreatedDto> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionLinkService.createActionLink(
                    {
                        actionId: actionId,
                        referenceId: referenceId,
                        subReferenceId: subReferenceId,
                        type: type,
                    },
                    currentContext.selectedCompany.id,
                    applicationId ? applicationId : currentContext.selectedApplication.id
                );
            })
        );
    }

    public createAction(applicationId?: string): Observable<ActionCreatedDto> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionService.create({
                    companyId: currentContext.selectedCompany.id,
                    applicationId: applicationId ? applicationId : currentContext.selectedApplication.id,
                });
            })
        );
    }

    public getActionLinksByReferenceIdAndType(referenceId: string, type: string, applicationId?: string): Observable<ActionLink[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionLinkService.getActionLinksByReferenceIdAndType(
                    referenceId,
                    type,
                    currentContext.selectedCompany.id,
                    applicationId ? applicationId : currentContext.selectedApplication.id
                );
            })
        );
    }

    updateActionLink(actionLink: ActionLink, applicationId?: string): Observable<void> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionLinkService.updateActionLink(
                    actionLink,
                    currentContext.selectedCompany.id,
                    applicationId ? applicationId : currentContext.selectedApplication.id
                );
            })
        );
    }
}
