import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { LoggerService } from '@backoffice/utils';
import { Observable, of } from 'rxjs';
import { PartType } from '../../models/template/part-type.model';

@Injectable()
export class PartTypeEditorFacade {
    constructor(
        private readonly store: Store<EditorState>,
        private readonly log: LoggerService
    ) {}

    findAll(companyId: string, applicationId: string): Observable<PartType[]> {
        this.log.info(`Find parttypes of company ${companyId} and application ${applicationId}`);
        return of();
    }
}
