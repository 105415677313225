import { Output } from '@backoffice/data-access/editor';
import { Type } from 'class-transformer';
import { Action } from '@backoffice/editor/data-access/action';

export class InvocationOutput {
    public value?: string;
    public outputId?: string;
    public type: string;

    // transient
    // Should be of type Output, but can't get this to work with cyclic deps....
    public output?: any;

    @Type(() => InvocationOutput)
    subOutputs: InvocationOutput[];
    subOutputsForAction: string;

    public initOutput(outputs: Output[], actionMap: Map<string, Action>) {
        if (!!outputs) {
            for (let i = 0; i < outputs.length; i++) {
                const output1 = outputs[i];
                if (output1.id === this.outputId) {
                    this.output = output1;
                    break;
                }
            }
        }
    }

    public copy(): InvocationOutput {
        const copiedInvocationOutput: InvocationOutput = new InvocationOutput();
        copiedInvocationOutput.value = this.value;
        copiedInvocationOutput.outputId = this.outputId;
        copiedInvocationOutput.output = this.output;
        copiedInvocationOutput.type = this.type;
        copiedInvocationOutput.subOutputsForAction = this.subOutputsForAction;
        copiedInvocationOutput.subOutputs = this.copyOutputs();
        return copiedInvocationOutput;
    }

    private copyOutputs(): InvocationOutput[] {
        const copiedOutputs: InvocationOutput[] = [];
        this.subOutputs?.forEach(output => {
            copiedOutputs.push(output.copy());
        });
        return copiedOutputs;
    }

    public updateOutput(newOutput: InvocationOutput) {
        this.output = newOutput.output;
    }
}
