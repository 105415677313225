import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { LoggerService } from '@backoffice/utils';
import { backofficeEnvironment } from '@shared/environment';
import { CreateLayerDto } from '../../interfaces/layer/create-layer.dto';
import { LayerCreatedDto } from '../../interfaces/layer/layer-created.dto';
import { Layer } from '../../models/template/layer.model';

@Injectable()
export class LayerService {
    constructor(
        private httpClient: HttpClient,
        private log: LoggerService
    ) {}

    createLayer(layer: CreateLayerDto, companyId: string, applicationId: string): Observable<LayerCreatedDto> {
        this.log.debug('Creating layer', [layer]);
        return this.httpClient.post<LayerCreatedDto>(
            `${backofficeEnvironment.rest.v2.layers}company/${companyId}/application/${applicationId}`,
            layer
        );
    }

    updateLayer(layer: Layer, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Updating layer', [layer]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.layers}company/${companyId}/application/${applicationId}/${layer.id}`,
            layer
        );
    }

    getLayer(layerId: string, companyId: string, applicationId: string): Observable<Layer> {
        this.log.debug('Getting layer', [layerId]);
        return this.httpClient
            .get<Layer>(`${backofficeEnvironment.rest.v2.layers}company/${companyId}/application/${applicationId}/${layerId}`)
            .pipe(map(layer => plainToClass(Layer, layer)));
    }

    getLayers(templateId: string, companyId: string, applicationId: string): Observable<Layer[]> {
        this.log.debug('Getting layers of templateId', [templateId]);
        return this.httpClient
            .get<Layer[]>(
                `${backofficeEnvironment.rest.v2.layers}company/${companyId}/application/${applicationId}?templateId=${templateId}`
            )
            .pipe(map(layers => plainToClass(Layer, layers)));
    }

    streamLayerUpdates(templateId: string, companyId: string, applicationId: string): EventSource {
        return new EventSource(
            `${backofficeEnvironment.rest.v2.layers}stream/company/${companyId}/application/${applicationId}${templateId}`
        );
    }
}
