import { PartDetail } from '../../../part-detail.model';

export class PagingPartDetail extends PartDetail {
    code: string;
    pages: string;
    currentPage: string;
    pageText: string;
    ofText: string;

    override isValid(): boolean {
        return this.isCodeValid() && this.isPagesValid() && this.isCurrentPageValid() && this.isPageTextValid() && this.isOfTextValid();
    }

    private isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    private isPageTextValid(): boolean {
        return !!this.pageText && this.pageText !== '';
    }

    private isOfTextValid(): boolean {
        return !!this.ofText && this.ofText !== '';
    }

    private isPagesValid(): boolean {
        return !!this.pages && this.pages !== '';
    }

    private isCurrentPageValid(): boolean {
        return !!this.currentPage && this.currentPage !== '';
    }

    override getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.paging.error.code');
        }
        if (!this.isPageTextValid()) {
            validationErrors.push('v2.part.paging.error.page.text');
        }
        if (!this.isOfTextValid()) {
            validationErrors.push('v2.part.paging.error.of.text');
        }
        if (!this.isPagesValid()) {
            validationErrors.push('v2.part.paging.error.pages');
        }
        if (!this.isCurrentPageValid()) {
            validationErrors.push('v2.part.paging.error.current.page');
        }
        return validationErrors;
    }

    override hasActions(): boolean {
        return false;
    }
}
