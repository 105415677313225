import { Injectable } from '@angular/core';
import { LoggerService } from '@backoffice/utils';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { DesignSystem } from '../models/design-system';
import { UpdateColorDefinitionDto } from '../dto/update-color-definition.dto';
import { UpdateTypographyDto } from '../dto/update-typography.dto';
import { Page } from '@shared/data-access';
import { OverviewDesignSystemDto } from '../dto/overview-design-system.dto';
import { UpdateDesignsystemDto } from '../dto/update-designsystem.dto';
import { CreateDesignsystemDto } from '../dto/create-designsystem.dto';

@Injectable()
export class DesignSystemService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public create(dto: CreateDesignsystemDto): Observable<string> {
        this.log.debug('Creating data format', [dto]);
        const { companyId, applicationId } = dto;
        const url = `${backofficeEnvironment.rest.v2.designSystem}company/${companyId}/application/${applicationId}`;

        return this.httpClient.post<string>(url, dto);
    }

    public update(dto: UpdateDesignsystemDto): Observable<void> {
        const { id, companyId, applicationId } = dto;
        const url = `${backofficeEnvironment.rest.v2.designSystem}company/${companyId}/application/${applicationId}/${id}`;

        return this.httpClient.put<void>(url, dto);
    }

    public delete(id: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Deleting design system <${id}>`);
        const url = `${backofficeEnvironment.rest.v2.designSystem}company/${companyId}/application/${applicationId}/${id}`;
        return this.httpClient.delete<void>(url);
    }

    public findAll(
        companyId: string,
        applicationId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<OverviewDesignSystemDto>> {
        this.log.debug('Getting design system overview');
        let params = new HttpParams().set('companyId', companyId).set('applicationId', applicationId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('keyword', keyword);
            }

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }
        }

        return this.httpClient.get<Page<OverviewDesignSystemDto>>(`${backofficeEnvironment.rest.v2.designSystem}`, { params });
    }

    public find(companyId: string, applicationId: string): Observable<DesignSystem[]> {
        this.log.debug(`Find design-system for company id <${companyId}> and application id <${applicationId}>`);

        const url = new URL(`${backofficeEnvironment.rest.v2.designSystem}`);
        url.searchParams.append('applicationId', applicationId);
        url.searchParams.append('companyId', companyId);

        return this.httpClient.get<Page<DesignSystem>>(url.href).pipe(
            map(response => {
                return response.content.map(ds => new DesignSystem(ds));
            })
        );
    }

    public findById(id: string, companyId: string, applicationId: string): Observable<DesignSystem> {
        this.log.debug(`Find design-system for company id <${companyId}> and application id <${applicationId}> and id <${id}>`);

        const url = new URL(`${backofficeEnvironment.rest.v2.designSystem}company/${companyId}/application/${applicationId}/${id}`);

        return this.httpClient
            .get<DesignSystem>(url.href, {
                headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
            })
            .pipe(map(response => new DesignSystem(response)));
    }

    public updateColorDefinition(dto: UpdateColorDefinitionDto): Observable<string> {
        const { applicationId, companyId, designSystemId } = dto;
        const url = `${backofficeEnvironment.rest.v2.designSystem}company/${companyId}/application/${applicationId}/${designSystemId}/color`;

        return this.httpClient.put<string>(url, {
            ...dto,
            variableName: dto.variable.name,
        });
    }

    public updateTypography(dto: UpdateTypographyDto): Observable<string> {
        const { applicationId, companyId, designSystemId } = dto;
        const url = `${backofficeEnvironment.rest.v2.designSystem}company/${companyId}/application/${applicationId}/${designSystemId}/typography`;

        return this.httpClient.put<string>(url, {
            ...dto,
            type: dto.type.toUpperCase(),
            fontSize: dto.fontSize.value,
            fontWeight: dto.fontWeight.value,
            fontFamily: dto.fontFamily.fontFamilyNames ? dto.fontFamily.fontFamilyNames[0] : '',
            letterSpacing: dto.letterSpacing.value,
            italic: dto.italic.keyword === 'ITALIC',
            color: dto.color.reference ? dto.color.reference.variable.name : dto.color.hex?.value,
            colorType: dto.color.reference ? 'REFERENCE' : 'RGB',
        });
    }
}
