import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { Product } from '../../models/product.model';
import { ApplicationVersionService } from '@core/services/application-version.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationVersion } from '../../../../../../../editor/data-access/application-version/src';

@Component({
    selector: 'codex-add-version',
    templateUrl: './add-version.component.html',
    styleUrls: ['./add-version.component.scss'],
})
export class AddVersionComponent implements OnInit {
    get nameControl(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get descriptionControl(): FormControl {
        return this.form.get('description') as FormControl;
    }

    get versionControl(): FormControl {
        return this.form.get('versionId') as FormControl;
    }

    versions$: Observable<ApplicationVersion[]> = this.applicationVersionService
        .findAll(this.data.applicationId, this.data.companyId, {
            page: 0,
            maxResults: 1000,
        })
        .pipe(
            map(({ content }) => {
                const alreadyInstalled = new Set(this.data.versions.map(version => version.versionId));
                return content.filter(version => !alreadyInstalled.has(version.id));
            })
        );

    form: FormGroup;

    constructor(
        private readonly fb: FormBuilder,
        private readonly applicationVersionService: ApplicationVersionService,
        public dialogRef: MatDialogRef<AddVersionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Product
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            versionId: [undefined, [Validators.required]],
            name: [undefined, [Validators.required]],
            description: [undefined, [Validators.required]],
        });
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onAddClicked(): void {
        const { valid, value } = this.form;
        if (valid) {
            this.dialogRef.close(value);
        } else {
            this.form.markAllAsTouched();
        }
    }
}
