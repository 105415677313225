import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { Page } from '@shared/data-access';
import { LoggerService } from '@backoffice/utils';
import { Product } from '../../../../../../libs/backoffice/feature/company/marketplace/src/lib/models/product.model';
import { CreateProductDto } from '../../../../../../libs/backoffice/feature/company/marketplace/src/lib/interfaces/create-product.dto';
import { InstallProductDto } from '../../../../../../libs/backoffice/feature/company/marketplace/src/lib/interfaces/install-product.dto';
import { CreateProductCommentDto } from '../../../../../../libs/backoffice/feature/company/marketplace/src/lib/interfaces/create-product-comment.dto';
import { CreateProductVersionDto } from '../../../../../../libs/backoffice/feature/company/marketplace/src/lib/interfaces/create-product-version.dto';

@Injectable()
export class ProductService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    findAll(
        companyId: string,
        options?: { searchTerm?: string; type?: string; page?: number; maxResults?: number }
    ): Observable<Page<Product>> {
        this.log.debug('[GET] requesting all products');

        let params = new HttpParams();
        params = params.append('companyId', companyId);

        if (options) {
            const { searchTerm, type, page, maxResults } = options;
            if (searchTerm !== undefined && searchTerm !== '') {
                params = params.append('searchTerm', searchTerm);
            }

            if (type !== undefined) {
                params = params.append('type', type);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }
        }
        return this.httpClient.get<Page<Product>>(`${backofficeEnvironment.rest.v2.products}`, { params });
    }

    findById(id: string): Observable<Product> {
        console.log(`[GET] requesting product ${id}`);
        return this.httpClient.get<Product>(`${backofficeEnvironment.rest.v2.products}${id}`);
    }

    create(dto: CreateProductDto): Observable<Product> {
        this.log.debug('[POST] creating new product');
        return this.httpClient.post<Product>(`${backofficeEnvironment.rest.v2.products}`, dto);
    }

    copy(): void {
        this.log.debug('[POST] copying products');
        this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.products}copy`, {}).subscribe();
    }

    update(dto: Product): Observable<void> {
        this.log.debug('[POST] updating product');
        return this.httpClient.put<void>(`${backofficeEnvironment.rest.v2.products}${dto.id}`, dto);
    }

    install(productId: string, dto: InstallProductDto): Observable<void> {
        this.log.debug(`[POST] install product ${productId} for application ${dto.applicationId}`);
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.products}${productId}/install`, dto);
    }

    upgrade(productId: string, versionId: string, applicationId: string): Observable<void> {
        this.log.debug(`[POST] update product ${productId} for application ${applicationId}`);
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.products}${productId}/update`, {
            productId,
            applicationId,
            versionId,
        });
    }

    uninstall(productId: string, applicationId: string): Observable<void> {
        this.log.debug(`[POST] uninstall product ${productId} for application ${applicationId}`);
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.products}${productId}/uninstall`, {
            applicationId,
        });
    }

    addComment(dto: CreateProductCommentDto): Observable<void> {
        this.log.debug('[POST] creating new product comment');
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.products}${dto.productId}/comments`, dto);
    }

    addVersion(dto: CreateProductVersionDto): Observable<void> {
        this.log.debug('[POST] creating new product version');
        return this.httpClient.post<void>(`${backofficeEnvironment.rest.v2.products}${dto.productId}/versions`, dto);
    }

    removeVersion(productId: string, productVersionId: string): Observable<void> {
        this.log.debug('[DELETE] deleting product version');
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.products}${productId}/versions/${productVersionId}`);
    }

    delete(productId: string): Observable<void> {
        this.log.debug('[DELETE] removing product');
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.products}${productId}`);
    }
}
