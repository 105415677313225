export class TreeMapSeriesOptions {
    readonly type = 'treemap';
    name?: string;
    visibleMin?: number;
    label?: TreeMapSeriesLabelOptions = new TreeMapSeriesLabelOptions();
    itemStyle?: TreeMapSeriesItemStyleOptions = new TreeMapSeriesItemStyleOptions();
    levels: TreeMapSeriesLevelOptions[] = [];
    data: TreeMapSeriesDataOptions[] = [];

    constructor(base?: Partial<TreeMapSeriesOptions>) {
        if (!!base) {
            this.name = base.name ?? this.name;
            this.visibleMin = base.visibleMin ?? this.visibleMin;
            this.label = base.label ?? this.label;
            this.itemStyle = base.itemStyle ?? this.itemStyle;
            this.levels = base.levels ?? this.levels;
            this.data = base.data ?? this.data;
        }
    }
}

export class TreeMapSeriesLabelOptions {
    show = true;
    formatter?: string;

    constructor(base?: Partial<TreeMapSeriesLabelOptions>) {
        if (!!base) {
            this.show = base.show ?? this.show;
            this.formatter = base.formatter ?? this.formatter;
        }
    }
}

export class TreeMapSeriesLevelOptions {
    colorSaturation: number[] = [];
    itemStyle?: TreeMapSeriesItemStyleOptions;

    constructor(base?: Partial<TreeMapSeriesLevelOptions>) {
        if (!!base) {
            this.colorSaturation = base.colorSaturation ?? this.colorSaturation;
            this.itemStyle = base.itemStyle ?? this.itemStyle;
        }
    }
}

export class TreeMapSeriesItemStyleOptions {
    borderColor = '#fff';
    borderColorSaturation?: number;
    borderWidth?: number;
    borderWith?: number;
    gapWidth?: number;

    constructor(base?: Partial<TreeMapSeriesItemStyleOptions>) {
        if (!!base) {
            this.borderColor = base.borderColor ?? this.borderColor;
            this.borderColorSaturation = base.borderColorSaturation ?? this.borderColorSaturation;
            this.borderWith = base.borderWith ?? this.borderWith;
            this.gapWidth = base.gapWidth ?? this.gapWidth;
        }
    }
}

export class TreeMapSeriesDataOptions {
    id?: string;
    name?: string;
    value?: number;
    children: TreeMapSeriesDataOptions[] = [];

    constructor(base?: Partial<TreeMapSeriesDataOptions>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.value = base.value ?? this.value;
            this.children = base.children ?? this.children;
        }
    }
}
