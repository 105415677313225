import { PartStyle } from '../../../part-style.model';
import { ColorV2 } from '../../../../../designsystem/models/color/colorv2.model';

export class RadarChartPartStyle extends PartStyle {
    shape: string;
    centerX: string;
    centerY: string;
    radius: string;
    series: RadarChartSeriesStyle[] = [];
    legend = new RadarChartLegendStyle();

    constructor(base?: Partial<RadarChartPartStyle>) {
        super();

        if (!!base) {
            this.series = base.series ?? this.series;
            this.legend = base.legend ?? this.legend;
            this.radius = base.radius ?? this.radius;
            this.shape = base.shape ?? this.shape;
            this.centerX = base.centerX ?? this.centerX;
            this.centerY = base.centerY ?? this.centerY;
        }
    }
}

export class RadarChartSeriesStyle {
    id: string;
    name: string;
    code: string;
    itemStyle = new RadarChartSeriesItemStyleStyle();

    constructor(base?: Partial<RadarChartSeriesStyle>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.code = base.code ?? this.code;
            this.itemStyle = base.itemStyle ? new RadarChartSeriesItemStyleStyle(base.itemStyle) : this.itemStyle;
        }
    }
}

export class RadarChartSeriesItemStyleStyle {
    color?: string;
    colorV2?: ColorV2;
    fillArea?: boolean;
    areaColor?: string;
    areaColorV2?: ColorV2;

    constructor(base?: Partial<RadarChartSeriesItemStyleStyle>) {
        if (!!base) {
            this.color = base.color ?? this.color;
            this.colorV2 = base.colorV2 ?? this.colorV2;
            this.fillArea = base.fillArea ?? this.fillArea;
            this.areaColor = base.areaColor ?? this.areaColor;
            this.areaColorV2 = base.areaColorV2 ?? this.areaColorV2;
        }
    }
}

export class RadarChartLegendStyle {
    show = true;
    orient?: 'vertical' | 'horizontal' = 'horizontal';
    left?: 'auto' | 'left' | 'center' | 'right';
    top?: 'auto' | 'top' | 'middle' | 'bottom';
}
