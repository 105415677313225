import { PartDetail } from '../../../part-detail.model';

export class TitlePartDetail extends PartDetail {
    code: string;
    title: string;
    level: number;
    tooltip: string;

    override isValid(): boolean {
        return this.isCodeValid() && this.isTitleValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isTitleValid(): boolean {
        return !!this.title && this.title !== '';
    }

    override getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.title.error.code');
        }
        if (!this.isTitleValid()) {
            validationErrors.push('v2.part.title.error.title');
        }
        return validationErrors;
    }
}
