<div class="flex gap-1 flex-col" [formGroup]="formGroup">
    <label for="actions" class="small-input-label">{{ 'v2.action.picker.select.label' | translate }}</label>
    <div class="flex flex-row gap-1 flex-auto items-center">
        <mat-form-field class="flex-grow !text-xxs" appearance="outline">
            <mat-select
                id="actions"
                formControlName="actionId"
                [placeholder]="'v2.action.picker.select.select' | translate"
                panelClass="toolpaneSelect scrollbar scrollbar-primary">
                <mat-option>
                    <ngx-mat-select-search
                        formControlName="actionIdFilter"
                        [placeholderLabel]="'v2.action.picker.select.search' | translate">
                        <span ngxMatSelectNoEntriesFound>
                            {{ 'v2.action.picker.select.none.found' | translate }}
                        </span>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let action of filteredActions$ | async" [value]="action.id">
                    <mat-icon>{{ action.iconName }}</mat-icon>
                    <span>{{ action.name }}</span> <br />
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button
            class="primary-button button-extra-small mb-2"
            data-name="create-new-action"
            (click)="createNewAction()"
            [matTooltip]="'v2.action.picker.create.action' | translate"
            type="button">
            <mat-icon>add_circle</mat-icon>
        </button>
    </div>
</div>
<ng-container *ngIf="{ actionLinks: actionLinks$ | async } as observables">
    <ul class="item-list" *ngIf="observables.actionLinks && observables.actionLinks.length > 0">
        <li *ngFor="let actionLink of observables.actionLinks">
            <div class="item-name">{{ actionLink.sequence }}: {{ actionLink.actionName }}</div>
            <mat-icon [matTooltip]="'v2.action.picker.edit' | translate" class="simple-icon-button" (click)="onEditAction(actionLink)"
                >edit
            </mat-icon>
            <mat-icon
                [matTooltip]="'v2.action.picker.remove' | translate"
                class="simple-icon-button simple-destructive-icon-button"
                (click)="clearAction(actionLink.id)"
                >clear
            </mat-icon>
        </li>
    </ul>
</ng-container>
