import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Font, GoogleFontService } from '@backoffice/editor/data-access/picker';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxFloatUiContentComponent, NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'nocodex-font-family-v2',
    templateUrl: 'font-family-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FontFamilyPickerComponent),
            multi: true,
        },
    ],
})
export class FontFamilyPickerComponent implements OnInit, ControlValueAccessor {
    private readonly DEFAULT_FONT = 'Poppins';

    @Input() formControl: FormControl<string>;

    @Input() value: string;

    @Output() fontChanged: EventEmitter<{ value: string }> = new EventEmitter();

    fonts$: Observable<Font[]>;
    searchTermChanged$ = new BehaviorSubject('');

    oldValue: string;
    subscription = new Subscription();

    @ViewChild('picker') popperContent: NgxFloatUiContentComponent;

    constructor(private readonly googleFontService: GoogleFontService) {}

    ngOnInit(): void {
        // Als er geen control meegegeven wordt gaan we zelf eentje maken.
        if (!this.formControl) {
            this.formControl = new FormControl(this.DEFAULT_FONT, { initialValueIsDefault: true });
            this.subscription.add(this.formControl.valueChanges.subscribe(color => this.writeValue(color)));

            if (!!this.value) {
                this.formControl.setValue(this.value, { emitEvent: false });
            }
        }

        this.oldValue = this.formControl.value;
        this.fonts$ = this.searchTermChanged$.pipe(
            debounceTime(300),
            switchMap(searchTerm => this.googleFontService.list(searchTerm))
        );
    }

    handleFamilyClicked(font: Font, picker: any): void {
        this.formControl.setValue(font.family);
        picker.hide();
    }

    handleSearchTermChanged(event: KeyboardEvent): void {
        const value = (event.target as HTMLInputElement).value;
        this.searchTermChanged$.next(value);
    }

    registerOnChange(fn: any): void {
        // not implemented
    }

    registerOnTouched(fn: any): void {
        // not implemented
    }

    setDisabledState(isDisabled: boolean): void {
        // not implemented
    }

    writeValue(font: string): void {
        // Send event
        const { value } = this.formControl;
        if (!!this.oldValue && this.oldValue !== font) {
            this.fontChanged.emit({ value: font });
            this.oldValue = font;
        }
    }

    onShowFontFamilies() {
        setTimeout(() => {
            if (this.popperContent) {
                this.popperContent.update();
            }
        }, 50);
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
