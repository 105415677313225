import { NgModule } from '@angular/core';
import { ActionPickerComponent } from './action-picker/action-picker.component';
import { CommonsModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { PickerDataAccessModule } from '@backoffice/editor/data-access/picker';
import { ApplicationPickerComponent } from './application-picker/application-picker.component';
import { DataformatPickerComponent } from './dataformat-picker/dataformat-picker.component';
import { OutputPickerComponent } from './output-picker/output-picker.component';
import { TemplatePickerComponent } from './template-picker/template-picker.component';
import { MediaPickerComponent } from './media-picker/media-picker.component';
import { BackgroundPickerComponent } from './background-picker/background-picker.component';
import { IconPickerComponent } from './icon-picker/icon-picker.component';
import { PixelSelectorComponent } from './pixel-selector/pixel-selector.component';
import { DegreesPickerComponent } from './degrees-picker/degrees-picker.component';
import { CursorPickerComponent } from './cursor-picker/cursor-picker.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { BorderPickerComponent } from './border-picker/border-picker.component';
import { ColorPickerV2Component } from './color-picker-v2/color-picker-v2.component';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { PaddingPickerComponent } from './padding-picker/padding-picker.component';
import { ColumnPickerComponent } from './column-picker/column-picker.component';
import { MarginPickerComponent } from './margin-picker/margin-picker.component';
import { ShadowPickerComponent } from './shadow-picker/shadow-picker.component';
import { BorderPickerWrapperComponent } from './border-picker-wrapper/border-picker-wrapper.component';
import { PaddingPickerWrapperComponent } from './padding-picker-wrapper/padding-picker-wrapper.component';
import { MarginPickerWrapperComponent } from './margin-picker-wrapper/margin-picker-wrapper.component';
import { BackgroundPickerWrapperComponent } from './background-picker-wrapper/background-picker-wrapper.component';
import { FontPickerWrapperComponent } from './font-picker-wrapper/font-picker-wrapper.component';
import { FontPickerComponent } from './font-picker/font-picker.component';
import { FontFamilyPickerComponent } from './font-family-picker/font-family-picker.component';
import { ViewportSelectorComponent } from './viewport-selector/viewport-selector.component';
import { PositionPickerComponent } from './position-picker/position-picker.component';
import { SizePickerComponent } from './size-picker/size-picker.component';
import { GridConfigComponent } from './grid-config/grid-config.component';
import { ShadowPickerWrapperComponent } from './shadow-picker-wrapper/shadow-picker-wrapper.component';
import { CursorPickerWrapperComponent } from './cursor-picker-wrapper/cursor-picker-wrapper.component';
import { FontLabelPickerWrapperComponent } from './font-label-picker-wrapper/font-label-picker-wrapper.component';
import { OverflowWrapperComponent } from './overflow-wrapper/overflow-wrapper.component';
import { FontAnswerPickerWrapperComponent } from './font-answer-picker-wrapper/font-answer-picker-wrapper.component';
import { FontLabelDtoPickerWrapperComponent } from './font-labeldto-picker-wrapper/font-label-dto-picker-wrapper.component';
import { ActionSinglePickerComponent } from './action-single-picker/action-single-picker.component';
import { BackofficeDataAccessEditorModule } from '@backoffice/data-access/editor';
import { SelectSearchComponent } from './select-search/select-search.component';

@NgModule({
    imports: [CommonsModule, PickerDataAccessModule, ColorPickerModule, BackofficeDataAccessEditorModule],
    declarations: [
        PositionPickerComponent,
        SizePickerComponent,
        GridConfigComponent,
        ActionPickerComponent,
        ApplicationPickerComponent,
        DataformatPickerComponent,
        OutputPickerComponent,
        TemplatePickerComponent,
        MediaPickerComponent,
        BackgroundPickerComponent,
        IconPickerComponent,
        PixelSelectorComponent,
        DegreesPickerComponent,
        CursorPickerComponent,
        ColorPickerComponent,
        BorderPickerComponent,
        ColorPickerV2Component,
        PaddingPickerComponent,
        MarginPickerComponent,
        ColumnPickerComponent,
        ShadowPickerComponent,
        FontPickerComponent,
        FontFamilyPickerComponent,
        ViewportSelectorComponent,
        BorderPickerWrapperComponent,
        PaddingPickerWrapperComponent,
        MarginPickerWrapperComponent,
        BackgroundPickerWrapperComponent,
        FontPickerWrapperComponent,
        ShadowPickerWrapperComponent,
        CursorPickerWrapperComponent,
        FontLabelPickerWrapperComponent,
        OverflowWrapperComponent,
        FontAnswerPickerWrapperComponent,
        FontLabelDtoPickerWrapperComponent,
        ActionSinglePickerComponent,
        SelectSearchComponent,
    ],
    exports: [
        PositionPickerComponent,
        SizePickerComponent,
        GridConfigComponent,
        ActionPickerComponent,
        ApplicationPickerComponent,
        DataformatPickerComponent,
        OutputPickerComponent,
        TemplatePickerComponent,
        MediaPickerComponent,
        BackgroundPickerComponent,
        IconPickerComponent,
        PixelSelectorComponent,
        DegreesPickerComponent,
        CursorPickerComponent,
        ColorPickerComponent,
        BorderPickerComponent,
        ColorPickerV2Component,
        PaddingPickerComponent,
        ColumnPickerComponent,
        MarginPickerComponent,
        ShadowPickerComponent,
        ColorPickerModule,
        FontPickerComponent,
        FontFamilyPickerComponent,
        ViewportSelectorComponent,
        BorderPickerWrapperComponent,
        PaddingPickerWrapperComponent,
        MarginPickerWrapperComponent,
        BackgroundPickerWrapperComponent,
        FontPickerWrapperComponent,
        ShadowPickerWrapperComponent,
        CursorPickerWrapperComponent,
        FontLabelPickerWrapperComponent,
        OverflowWrapperComponent,
        FontAnswerPickerWrapperComponent,
        FontLabelDtoPickerWrapperComponent,
        ActionSinglePickerComponent,
        SelectSearchComponent,
    ],
})
export class BackofficeFeatureEditorPickersModule {}
