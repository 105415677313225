import { NgModule } from '@angular/core';
import { AssistantComponent } from './components/assistant/assistant.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { CommonsModule } from '../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { BackofficeDataAccessAssistantModule } from 'backoffice/data-access/assistant';
import { AssistantGenerateTestDataInputComponent } from './components/assistant-generate-test-data-input/assistant-generate-test-data-input.component';
import { AssistantGenerateDataformatInputComponent } from './components/assistant-generate-dataformat-input/assistant-generate-dataformat-input.component';
import { BackofficeUiArgumentsModule } from '@backoffice/ui-arguments';
import { AssistantGenerateDataformatValidateComponent } from './components/assistant-generate-dataformat-validate/assistant-generate-dataformat-validate.component';
import { DataformatUiModule } from '@backoffice/editor/ui/dataformat';
import { AssistantGenerateApiEndpointsInputComponent } from './components/assistant-generate-api-endpoints-input/assistant-generate-api-endpoints-input.component';
import { AssistantGenerateRootpageInputComponent } from './components/assistant-generate-rootpage-input/assistant-generate-rootpage-input.component';
import { AssistantGenerateGlobalthemeInputComponent } from './components/assistant-generate-global-theme-input/assistant-generate-globaltheme-input.component';
import { BackofficeFeatureEditorPickersModule } from 'backoffice/feature/editor/pickers';

@NgModule({
    imports: [
        CommonsModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        BackofficeDataAccessAssistantModule,
        BackofficeUiArgumentsModule,
        DataformatUiModule,
        BackofficeFeatureEditorPickersModule,
    ],
    declarations: [
        AssistantComponent,
        AssistantGenerateTestDataInputComponent,
        AssistantGenerateDataformatInputComponent,
        AssistantGenerateDataformatValidateComponent,
        AssistantGenerateApiEndpointsInputComponent,
        AssistantGenerateRootpageInputComponent,
        AssistantGenerateGlobalthemeInputComponent,
    ],
    exports: [AssistantComponent],
})
export class BackofficeUiAssistantModule {}
