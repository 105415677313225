import { Part } from './part.model';

export class PartDetail {
    public partType: string;

    //transient
    public initialized = false;

    public markAsInitialized(): void {
        this.initialized = true;
    }

    public getSubContainers(): any[] {
        return [];
    }

    public isValid(): boolean {
        return false;
    }

    public hasActions(): boolean {
        return true;
    }

    public removeContainerPart(partId: string): void {}

    public addContainerPart(part: Part, index: number): void {}

    public updateContainerPart(part: Part): Part {
        return null;
    }

    public getChildParts(): Part[] {
        return [];
    }

    public getValidationErrors(): string[] {
        return [];
    }
}
