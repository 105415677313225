import { BackgroundStyleDto } from '../../../../../interfaces/style/background.style.dto';
import { BorderStyleDto } from '../../../../../interfaces/style/border.style.dto';
import { PaddingStyleDto } from '../../../../../interfaces/style/padding.style.dto';
import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';

export interface ButtonStyle {
    backgroundStyle?: BackgroundStyleDto | null;

    borderStyle?: BorderStyleDto | null;
    borderTopStyle?: BorderStyleDto | null;
    borderLeftStyle?: BorderStyleDto | null;
    borderRightStyle?: BorderStyleDto | null;
    borderBottomStyle?: BorderStyleDto | null;

    paddingStyle?: PaddingStyleDto | null;
    paddingTopStyle?: PaddingStyleDto | null;
    paddingLeftStyle?: PaddingStyleDto | null;
    paddingRightStyle?: PaddingStyleDto | null;
    paddingBottomStyle?: PaddingStyleDto | null;

    labelFontStyle?: FontStyleDto | null;
}
