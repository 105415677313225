import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../../../store/editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { DataFormat } from '../../models/data-format';
import { plainToClass } from 'class-transformer';
import { JsonSchema } from '../../models/json-schema';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const dataFormatAdapter = createEntityAdapter<DataFormat>();

const { selectIds, selectAll, selectTotal, selectEntities } = dataFormatAdapter.getSelectors();

export const dataFormatEditorSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.dataformat)),
    dialogData: createSelector(selectState, state => state.dataformatDialog.result),
    dialogFilter: createSelector(selectState, state => {
        const { page, maxResults, searchTerm, facets, filterPlugins } = state.dataformatDialog;
        return {
            page,
            maxResults,
            searchTerm,
            facets,
            filterPlugins,
        };
    }),
    searchTerm: createSelector(selectState, state => state.dataformatDialog.searchTerm),
    pagination: createSelector(selectState, state => {
        const { page, maxResults } = state.dataformatDialog;
        return { page, maxResults };
    }),
    facets: createSelector(selectState, state => state.dataformatDialog.facets),
    filterPlugins: createSelector(selectState, state => state.dataformatDialog.filterPlugins),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<DataFormat> = selectEntities(state.dataformat);
            if (id in dictionary) {
                const dataFormat: DataFormat = plainToClass(DataFormat, dictionary[id]);
                if (dataFormat.jsonSchema) {
                    const jsonSchemaStr: string = dataFormat.jsonSchema + '';
                    dataFormat.jsonSchema = new JsonSchema(JSON.parse(jsonSchemaStr));
                }
                return dataFormat;
            }
            return undefined;
        }),
};
