export class ListStyleDto {
    itemGap: string;
    rowGap: string;
    columnGap: string;
    direction: string;
    wrap: string;
    horizontalAlign:
        | 'flex-start'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'start'
        | 'end'
        | 'left'
        | 'right'
        | 'safe'
        | 'unsafe';
    verticalAlign:
        | 'flex-start'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'start'
        | 'end'
        | 'baseline'
        | 'first baseline'
        | 'last baseline';
}

export function generateListStyle(listStyleDto: ListStyleDto) {
    let style = '';
    if (listStyleDto) {
        if (listStyleDto.itemGap) {
            style += `gap: ${listStyleDto.itemGap};`;
        }
        if (listStyleDto.rowGap) {
            style += `row-gap: ${listStyleDto.rowGap};`;
        }
        if (listStyleDto.columnGap) {
            style += `column-gap: ${listStyleDto.columnGap};`;
        }
        if (listStyleDto.direction) {
            style += `flex-direction: ${listStyleDto.direction};`;
        }
        if (listStyleDto.wrap) {
            style += `flex-wrap: ${listStyleDto.wrap};`;
        }
        if (listStyleDto.verticalAlign) {
            style += `justify-content: ${listStyleDto.verticalAlign};`;
        }

        if (listStyleDto.horizontalAlign) {
            style += `align-items: ${listStyleDto.horizontalAlign};`;
        }
    }
    return style;
}
