export interface RadarChartOptions {
    id?: string;
    indicator: RadarChartIndicatorOptions[];
    radius: string;
    shape: string;
    center: any[];
    splitArea: any;
    splitLine: any;
    axisName: any;
}

export class RadarChartIndicatorOptions {
    name: string;
    max?: number;
    min?: number;

    constructor(base?: Partial<RadarChartIndicatorOptions>) {
        if (!!base) {
            this.name = base.name ?? this.name;
            this.max = base.max ?? this.max;
            this.min = base.min ?? this.min;
        }
    }
}
