import { Part } from '../../../part.model';
import { Type } from 'class-transformer';

export class AccordionPartItem {
    id?: string;
    code?: string;
    name?: string;
    icon?: string;

    //transient
    @Type(() => Part)
    parts?: Part[];
}
