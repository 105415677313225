import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Page } from '@shared/data-access';
import { DataCreateDto } from '../dto/create-data.dto';
import { DataCreatedDto } from '../dto/data-created.dto';
import { Data } from '../model/data';
import { OverviewDataDto } from '../../../../../../libs/backoffice/data-access/editor/src/lib/dto/overview/overview-data.dto';
import { LoggerService } from '@backoffice/utils';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    constructor(
        private httpClient: HttpClient,
        private log: LoggerService
    ) {}

    public createData(dataCreateDto: DataCreateDto, companyId: string, applicationId: string): Observable<DataCreatedDto> {
        this.log.debug('Creating data', [dataCreateDto]);
        return this.httpClient.post<DataCreatedDto>(
            `${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}`,
            dataCreateDto
        );
    }

    public updateData(data: Data, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Updating data', [data]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}/${data.id}`,
            data
        );
    }

    public deleteData(dataId: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Deleting data', [dataId]);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}/${dataId}`
        );
    }

    public getData(dataId: string, companyId: string, applicationId: string): Observable<Data> {
        this.log.debug('Getting data', [dataId]);
        return this.httpClient
            .get<Data>(`${backofficeEnvironment.rest.v2.data}company/${companyId}/application/${applicationId}/${dataId}`)
            .pipe(map(data => plainToClass(Data, data)));
    }

    public getDatas(
        companyId: string,
        applicationId: string,
        keyword: string,
        orderBy: string,
        filters: string[],
        page: number,
        maxResults: number
    ): Observable<Page<OverviewDataDto>> {
        this.log.debug('Getting data overview');
        let params = new HttpParams()
            .set('companyId', companyId)
            .set('applicationId', applicationId)
            .set('orderBy', orderBy)
            .set('page', String(page))
            .set('maxResults', String(maxResults));

        if (keyword) {
            params = params.append('keyword', keyword);
        }

        filters.forEach(filter => (params = params.append('filters', filter)));
        return this.httpClient.get<Page<OverviewDataDto>>(`${backofficeEnvironment.rest.v2.data}`, { params });
    }
}
