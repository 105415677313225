import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssistantMessageDto } from 'backoffice/data-access/assistant';
import { AssistantFacade } from '../../../../../../data-access/assistant/src/lib/facade/assistant.facade';
import { AssistantGenerateDataformatValidateComponent } from '../assistant-generate-dataformat-validate/assistant-generate-dataformat-validate.component';
import { DataFormatEditorFacade } from '@backoffice/data-access/editor';

@Component({
    selector: 'app-assistant-generate-globaltheme-input',
    templateUrl: './assistant-generate-globaltheme-input.component.html',
    styleUrls: ['./assistant-generate-globaltheme-input.component.scss'],
})
export class AssistantGenerateGlobalthemeInputComponent implements OnInit {
    formGroup: FormGroup;

    @Output()
    addMessage: EventEmitter<{ message: AssistantMessageDto }> = new EventEmitter<{ message: AssistantMessageDto }>();

    constructor(
        private fb: FormBuilder,
        private assistantFacade: AssistantFacade,
        private dataFormatEditorFacade: DataFormatEditorFacade,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.formGroup = this.fb.group({
            input: ['', Validators.required],
        });
        this.addMessage.emit({
            message: {
                user: false,
                text: 'Would you like me to create some api endpoints for you? Please provide me with as much information as possible of the data-formats you need.',
            },
        });
    }

    sendInput($event) {
        const prompt = this.formGroup.get('input')?.value;
        this.addMessage.emit({ message: { user: true, text: this.formGroup.get('input')?.value } });
        this.formGroup.get('input')?.reset();
        this.assistantFacade.generateGlobalTheme(prompt).subscribe(response => {
            this.addMessage.emit({
                message: {
                    user: false,
                    text: response.response,
                    dialoglink: true,
                    dialogClass: AssistantGenerateDataformatValidateComponent,
                    response: response,
                },
            });
            this.changeDetectorRef.detectChanges();
        });
    }
}
