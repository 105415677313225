import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationVersionService } from './services/application-version.service';
import { ApplicationVersionFacade } from './facades/application-version.facade';

@NgModule({
    imports: [CommonModule],
    providers: [ApplicationVersionService, ApplicationVersionFacade],
})
export class ApplicationVersionDataAccessModule {}
