<div class="small-number-wrapper">
    <label for="grid-columns" class="small-input-label" data-name="grid-columns">{{ 'v2.part.style.grid.columns' | translate }}</label>
    <input
        class="small-input small-number-input"
        type="number"
        id="grid-columns"
        name="grid-columns"
        autocomplete="off"
        [(ngModel)]="_grid.gridColumns"
        (change)="onChangeGridColumns()" />
</div>

<div class="small-number-wrapper">
    <label class="small-input-label w-1/2">{{ 'v2.part.style.grid.columngapsize' | translate }}</label>
    <codex-viewport-selector
        class="small-viewport-selector"
        [viewPort]="_grid.gridColumnGapSize"
        [unit]="'pixels'"
        [allowedUnits]="['pixels']"
        (viewPortChange)="onChangeGridColumnGapSize($event)">
    </codex-viewport-selector>
</div>

<div class="clear-both flex justify-between">
    <label class="small-input-label w-1/2">{{ 'v2.part.style.grid.columncolor' | translate }}</label>
    <nocodex-color-picker-v2
        class="small-color-picker"
        [hex]="_grid.gridColumnColor ? _grid.gridColumnColor : null"
        [colorV2]="!!_grid.gridColumnColorV2 ? _grid.gridColumnColorV2 : null"
        (colorChangedV2)="onChangeGridColumnColor($event)"></nocodex-color-picker-v2>
</div>
<hr class="seperator" />
<div class="small-number-wrapper">
    <label for="grid-rows" class="small-input-label" data-name="grid-rows">{{ 'v2.part.style.grid.rows' | translate }}</label>
    <input
        class="small-input small-number-input"
        type="number"
        id="grid-rows"
        name="grid-rows"
        autocomplete="off"
        [(ngModel)]="_grid.gridRows"
        (change)="onChangeGridRows()" />
</div>

<div class="small-number-wrapper">
    <label class="small-input-label w-1/2">{{ 'v2.part.style.grid.rowgapsize' | translate }}</label>
    <codex-viewport-selector
        class="small-viewport-selector"
        [viewPort]="_grid.gridRowGapSize"
        [unit]="'pixels'"
        [allowedUnits]="['pixels']"
        (viewPortChange)="onChangeGridRowGapSize($event)">
    </codex-viewport-selector>
</div>

<div class="small-number-wrapper">
    <label class="small-input-label w-1/2">{{ 'v2.part.style.grid.rowcolor' | translate }}</label>
    <nocodex-color-picker-v2
        class="small-color-picker"
        [hex]="_grid.gridRowColor ? _grid.gridRowColor : null"
        [colorV2]="!!_grid.gridRowColorV2 ? _grid.gridRowColorV2 : null"
        (colorChangedV2)="onChangeGridRowColor($event)"></nocodex-color-picker-v2>
</div>

<hr class="seperator" />

<div class="small-number-wrapper">
    <label for="grid-snap" class="small-input-label" data-name="grid-snap">{{ 'v2.part.style.grid.snap' | translate }}</label>
    <input
        class="small-input small-number-input"
        type="number"
        id="grid-snap"
        name="grid-snap"
        autocomplete="off"
        [(ngModel)]="_grid.snapStrength"
        (change)="onChangeSnapStrength()" />
</div>

<hr class="seperator" *ngIf="showToggleGrid" />

<label class="toggle-line" *ngIf="showToggleGrid">
    {{ 'v2.part.style.grid.show' | translate }}
    <input
        type="checkbox"
        class="sr-only peer"
        [(ngModel)]="_grid.showGrid"
        [ngModelOptions]="{ standalone: true }"
        (click)="onGridStyleChanged()" />
    <div
        class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
</label>
