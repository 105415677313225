import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product } from '../../models/product.model';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { MarketplaceFacade } from '../../facade/marketplace.facade';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject } from 'rxjs';

export interface EditProductData {
    product: Product;
}

@Component({
    selector: 'codex-edit-product',
    templateUrl: './edit-product.component.html',
    styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
    form: FormGroup;

    product$: BehaviorSubject<Product> = new BehaviorSubject<Product>(null);

    environment: BackofficeEnvironmentDto = backofficeEnvironment;

    options: UploaderOptions;
    files: UploadFile[] = [];
    uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
    showUploadIcon = false;
    dragOverIconUpload: boolean;

    uploading = false;

    timestamp: string = Date.now().toString();

    get nameControl(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get descriptionControl(): FormControl {
        return this.form.get('description') as FormControl;
    }

    get readmeControl(): FormControl {
        return this.form.get('readMe') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        public dialogRef: MatDialogRef<EditProductComponent>,
        public marketPlaceFacade: MarketplaceFacade,
        public keyCloakService: KeycloakService,
        @Inject(MAT_DIALOG_DATA) public data: EditProductData
    ) {}

    ngOnInit() {
        this.product$.next(this.data.product);
        this.form = this.fb.group({
            name: [this.product$.value.name, [Validators.required]],
            description: [this.product$.value.description, [Validators.required]],
            readMe: [this.product$.value.readMe],
        });
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onEditClicked(): void {
        const { valid, value } = this.form;
        const product = this.product$.value;
        product.name = value.name;
        product.description = value.description;
        product.readMe = value.readMe;
        if (valid) {
            this.dialogRef.close(product);
        } else {
            this.form.markAllAsTouched();
        }
    }

    onUploadOutput(output: UploadOutput): void {
        switch (output.type) {
            case 'allAddedToQueue':
                break;
            case 'addedToQueue':
                if (typeof output.file !== 'undefined') {
                    this.files.push(output.file);
                }
                break;
            case 'uploading':
                if (typeof output.file !== 'undefined') {
                    // update current data in files array for uploading file
                    const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
                    this.files[index] = output.file;
                }
                break;
            case 'removed':
                // remove file from array when removed
                this.files = this.files.filter((file: UploadFile) => file !== output.file);
                break;
            case 'dragOver':
                this.dragOverIconUpload = true;
                break;
            case 'dragOut':
                break;
            case 'drop':
                this.dragOverIconUpload = false;
                this.showUploadIcon = false;
                this.keyCloakService.getToken().then(token => {
                    const event: UploadInput = {
                        type: 'uploadAll',
                        url: `${backofficeEnvironment.rest.v2.products}${this.product$.value.id}/icon`,
                        method: 'POST',
                        headers: { Authorization: 'Bearer ' + token },
                        data: {
                            fileName: this.files[0].name,
                            contentType: this.files[0].type,
                        },
                    };
                    this.uploading = true;
                    this.uploadInput.emit(event);
                });
                break;
            case 'start':
            case 'done':
                setTimeout(() => {
                    this.marketPlaceFacade.getProduct(this.product$.value.id).subscribe(product => {
                        this.timestamp = Date.now().toString();
                        this.product$.next(product);
                        this.uploading = false;
                    });
                }, 1000);
                break;
        }
    }

    onChangeIcon() {
        if (this.product$.value.iconUrl) {
            this.product$.value.iconUrl = null;
        } else {
            this.showUploadIcon = true;
        }
    }
}
