import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../store/application.state';
import { Page } from '../../../../../../libs/shared/data-access/src/lib/model/page.model';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ApplicationService } from '@core/services/application.service';
import { Application } from '../model/application';
import { ApplicationDto } from '../dto/application.dto';
import { ApplicationCreatedDto } from '../dto/application-created.dto';
import { selectSelectedCompanyId } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/statemanagement/selectors/company.selector';

@Injectable({
    providedIn: 'root',
})
export class ApplicationOverviewFacade {
    selectedCompanyId$: Observable<string> = this.store.select(selectSelectedCompanyId);

    constructor(
        private applicationService: ApplicationService,
        private store: Store<ApplicationState>
    ) {}

    public deleteApplications(applications: Application[]): Observable<void[]> {
        return this.selectedCompanyId$.pipe(
            filter(selectedCompanyId => !!selectedCompanyId),
            distinctUntilChanged(),
            switchMap(selectedCompanyId => {
                return forkJoin(
                    applications.map(application => this.applicationService.deleteApplication(application.id, selectedCompanyId))
                );
            })
        );
    }
}
