<mat-dialog-content class="!p-4">
    <form (ngSubmit)="onCreateMedia()" *ngIf="formGroup" [cdkTrapFocusAutoCapture]="true" [formGroup]="formGroup" cdkTrapFocus novalidate>
        <h1 class="!mb-2">{{ 'v2.media.create.title' | translate }}</h1>
        <div class="flex flex-row gap-2">
            <div class="flex flex-col flex-grow">
                <label for="media-name" class="small-input-label" data-name="media-name">Name</label>
                <input
                    class="small-input"
                    type="text"
                    id="media-name"
                    name="company-name"
                    autocomplete="off"
                    cdkFocusInitial
                    required
                    formControlName="title" />
                <p
                    class="small-input-error"
                    *ngIf="companyNameControl.touched && companyNameControl.invalid && companyNameControl.hasError('required')">
                    Please provide a name for the file you wish to upload.
                </p>
                <ul class="max-w-[600px] w-full mt-4" *ngIf="this.files && this.files.length >= 1">
                    <li
                        class="even:bg-gray-50 even:dark:bg-gray-800 odd:bg-white odd:dark:bg-gray-900 rounded-lg mb-1"
                        *ngFor="let file of this.files">
                        <div class="flex items-center space-x-4 rtl:space-x-reverse h-8 p-2">
                            <div class="flex-1 min-w-0">
                                <div class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {{ file.name }}
                                </div>
                            </div>
                            <div class="inline-flex gap-1 items-center text-base font-semibold text-gray-900 dark:text-white">
                                <button
                                    type="button"
                                    class="destructive-button button-extra-small"
                                    [matTooltip]="'v2.action.picker.remove' | translate"
                                    (click)="removeFile(file.id)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div
                    (uploadOutput)="onUploadOutput($event)"
                    [ngClass]="{ 'is-drop-over': dragOver }"
                    [options]="options"
                    [style.visibility]="this.files && this.files.length >= 1 ? 'hidden' : 'visible'"
                    [style.height]="this.files && this.files.length >= 1 ? '0px' : '200px'"
                    [uploadInput]="uploadInput"
                    class="drop-container"
                    ngFileDrop>
                    <h1 class="!mb-2">{{ 'v2.media.edit.dropfile' | translate }}</h1>
                    <label for="file-upload" class="upload-button primary-button button-large"
                        ><mat-icon>folder</mat-icon>Browse files</label
                    >
                    <input
                        type="file"
                        id="file-upload"
                        class="hidden"
                        ngFileSelect
                        [options]="options"
                        (uploadOutput)="onUploadOutput($event)"
                        [uploadInput]="uploadInput" />
                </div>
            </div>
        </div>
        <div class="buttonContainer">
            <button buttonType="submit" class="primary-button button-medium">
                <mat-icon>save</mat-icon>
                {{ 'general.save' | translate }}
            </button>
            <button (click)="onCancel()" class="secondary-button button-medium" type="button">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </form>
</mat-dialog-content>
