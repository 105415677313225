import { Injectable } from '@angular/core';

@Injectable()
export class Environment {
    getBasePath(): string {
        return 'codex';
    }

    getAfterLoginPage(): string {
        return '/' + this.getBasePath() + '/dashboard';
    }
}
