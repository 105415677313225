import { PartDetail } from '../../../part-detail.model';

export class HorizontalDividerPartDetail extends PartDetail {
    code: string;

    isValid(): boolean {
        return this.isCodeValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    public override hasActions(): boolean {
        return false;
    }

    getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.horizontal-divider.error.code');
        }
        return validationErrors;
    }
}
