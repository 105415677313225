import { ColorDefinition } from './color-definition.model';
import { Typography } from './typography.model';
import { Variable } from './util/variable.model';
import { UpdateTypographyDto } from '../dto/update-typography.dto';

export class Theme {
    // Colors
    primary: ColorDefinition;
    secondary: ColorDefinition;
    tertiary: ColorDefinition;
    alternate: ColorDefinition;
    primaryText: ColorDefinition;
    secondaryText: ColorDefinition;
    primaryBackground: ColorDefinition;
    secondaryBackground: ColorDefinition;
    success: ColorDefinition;
    info: ColorDefinition;
    warning: ColorDefinition;
    error: ColorDefinition;
    accentOne: ColorDefinition;
    accentTwo: ColorDefinition;
    accentThree: ColorDefinition;
    accentFour: ColorDefinition;

    colorDefinitions: ColorDefinition[] = [];
    customerColors: ColorDefinition[];

    // Typographies
    displayLarge: Typography;
    displayMedium: Typography;
    displaySmall: Typography;
    headlineLarge: Typography;
    headlineMedium: Typography;
    headlineSmall: Typography;
    titleLarge: Typography;
    titleMedium: Typography;
    titleSmall: Typography;
    labelLarge: Typography;
    labelMedium: Typography;
    labelSmall: Typography;
    bodyLarge: Typography;
    bodyMedium: Typography;
    bodySmall: Typography;

    typographies: Typography[] = [];

    constructor(base: Theme) {
        this.primary = new ColorDefinition(base.primary);
        this.secondary = new ColorDefinition(base.secondary);
        this.tertiary = new ColorDefinition(base.tertiary);
        this.alternate = new ColorDefinition(base.alternate);
        this.primaryText = new ColorDefinition(base.primaryText);
        this.secondaryText = new ColorDefinition(base.secondaryText);
        this.primaryBackground = new ColorDefinition(base.primaryBackground);
        this.secondaryBackground = new ColorDefinition(base.secondaryBackground);
        this.success = new ColorDefinition(base.success);
        this.info = new ColorDefinition(base.info);
        this.warning = new ColorDefinition(base.warning);
        this.error = new ColorDefinition(base.error);
        this.accentOne = new ColorDefinition(base.accentOne);
        this.accentTwo = new ColorDefinition(base.accentTwo);
        this.accentThree = new ColorDefinition(base.accentThree);
        this.accentFour = new ColorDefinition(base.accentFour);

        this.displayLarge = new Typography(base.displayLarge);
        this.displayMedium = new Typography(base.displayMedium);
        this.displaySmall = new Typography(base.displaySmall);
        this.headlineLarge = new Typography(base.headlineLarge);
        this.headlineMedium = new Typography(base.headlineMedium);
        this.headlineSmall = new Typography(base.headlineSmall);
        this.titleLarge = new Typography(base.titleLarge);
        this.titleMedium = new Typography(base.titleMedium);
        this.titleSmall = new Typography(base.titleSmall);
        this.labelLarge = new Typography(base.labelLarge);
        this.labelMedium = new Typography(base.labelMedium);
        this.labelSmall = new Typography(base.labelSmall);
        this.bodyLarge = new Typography(base.bodyLarge);
        this.bodyMedium = new Typography(base.bodyMedium);
        this.bodySmall = new Typography(base.bodySmall);

        this.typographies = [
            this.displayLarge,
            this.displayMedium,
            this.displaySmall,
            this.headlineLarge,
            this.headlineMedium,
            this.headlineSmall,
            this.titleLarge,
            this.titleMedium,
            this.titleSmall,
            this.labelLarge,
            this.labelMedium,
            this.labelSmall,
            this.bodyLarge,
            this.bodyMedium,
            this.bodySmall,
        ];

        this.colorDefinitions = [
            this.primary,
            this.secondary,
            this.tertiary,
            this.alternate,
            this.primaryText,
            this.secondaryText,
            this.primaryBackground,
            this.secondaryBackground,
            this.success,
            this.info,
            this.warning,
            this.error,
            this.accentOne,
            this.accentTwo,
            this.accentThree,
            this.accentFour,
        ];
    }

    extractColorMap(): Map<string, ColorDefinition> {
        const colors = new Map<string, ColorDefinition>();

        colors.set(this.primary.variable.name, this.primary);
        colors.set(this.secondary.variable.name, this.secondary);
        colors.set(this.tertiary.variable.name, this.tertiary);
        colors.set(this.alternate.variable.name, this.alternate);
        colors.set(this.primaryText.variable.name, this.primaryText);
        colors.set(this.secondaryText.variable.name, this.secondaryText);
        colors.set(this.primaryBackground.variable.name, this.primaryBackground);
        colors.set(this.secondaryBackground.variable.name, this.secondaryBackground);
        colors.set(this.success.variable.name, this.success);
        colors.set(this.info.variable.name, this.info);
        colors.set(this.warning.variable.name, this.warning);
        colors.set(this.error.variable.name, this.error);
        colors.set(this.accentOne.variable.name, this.accentOne);
        colors.set(this.accentTwo.variable.name, this.accentTwo);
        colors.set(this.accentThree.variable.name, this.accentThree);
        colors.set(this.accentFour.variable.name, this.accentFour);

        return colors;
    }

    findColorByVariable(variable: Variable): ColorDefinition {
        const colorDefinition = this.colorDefinitions.find(c => c.variable.name === variable.name);
        if (!colorDefinition) {
            return this.primary;
        }
        return colorDefinition;
    }

    findTypography(type: string): Typography {
        switch (type) {
            case 'display_large':
                return this.displayLarge;
            case 'display_medium':
                return this.displayMedium;
            case 'display_small':
                return this.displaySmall;
            case 'headline_large':
                return this.headlineLarge;
            case 'headline_medium':
                return this.headlineMedium;
            case 'headline_small':
                return this.headlineSmall;
            case 'title_large':
                return this.titleLarge;
            case 'title_medium':
                return this.titleMedium;
            case 'title_small':
                return this.titleSmall;
            case 'label_large':
                return this.labelLarge;
            case 'label_medium':
                return this.labelMedium;
            case 'label_small':
                return this.labelSmall;
            case 'body_large':
                return this.bodyLarge;
            case 'body_medium':
                return this.bodyMedium;
            case 'body_small':
                return this.bodySmall;
            default:
                console.log('not found');
                throw new Error();
        }
    }

    public updateColor(variable: Variable, type: 'RGB' | 'HSV' | 'HSL', value: string): void {
        const colorDefinition = this.findColorByVariable(variable);
        colorDefinition.update(type, value);
    }

    public updateTypography(dto: UpdateTypographyDto): void {
        const typography = this.findTypography(dto.type);
        typography.fontSize = dto.fontSize;
        typography.fontWeight = dto.fontWeight;
        typography.letterSpacing = dto.letterSpacing;
        typography.fontFamily = dto.fontFamily;
        typography.fontStyle = dto.italic;
        typography.color = dto.color;
    }
}
