<h3
    [attr.id]="'titlestyle-accordion-' + identifier + '-border-heading'"
    [attr.data-name]="'titlestyle-accordion-border-header-' + identifier"
    class="p-0">
    <button
        type="button"
        class="accordion-heading"
        [class.accordion-heading-first]="firstPanel"
        [class.accordion-heading-last]="lastPanel"
        [attr.data-accordion-target]="'#titlestyle-accordion-' + identifier + '-border'"
        [attr.aria-controls]="'titlestyle-accordion-' + identifier + '-border'"
        aria-expanded="true">
        <div class="flex gap-1 justify-center items-center w-full">
            <span class="leading-7 grow text-left">{{ title ? title : ('v2.template.style.border' | translate) }}</span>
            <button
                class="text-button"
                type="button"
                (click)="resetStyle(); $event.stopPropagation()"
                [matTooltip]="'v2.template.style.reset' | translate">
                Reset
            </button>
        </div>
    </button>
</h3>
<div
    [attr.id]="'titlestyle-accordion-' + identifier + '-border'"
    [attr.data-name]="'border-' + identifier"
    [attr.aria-labelledby]="'titlestyle-accordion-' + identifier + '-border'"
    [class.accordion-panel-last]="lastPanel"
    [class.accordion-panel]="true"
    class="hidden">
    <div class="button-group" role="group">
        <button
            *ngIf="allowedTypes.indexOf('normal') > -1"
            type="button"
            class="button-group-button-left"
            autofocus
            (click)="type = 'normal'"
            [ngClass]="type === 'normal' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.normal' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('hover') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'hover'"
            [ngClass]="type === 'hover' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.hover' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('focus') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'focus'"
            [ngClass]="type === 'focus' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.focus' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('active') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'active'"
            [ngClass]="type === 'active' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.active' | translate }}
        </button>
    </div>
    <codex-border-picker
        *ngIf="type === 'normal'"
        [borderStyle]="wrapperStyle?.borderStyle"
        [borderTopStyle]="wrapperStyle?.borderTopStyle"
        [borderLeftStyle]="wrapperStyle?.borderLeftStyle"
        [borderBottomStyle]="wrapperStyle?.borderBottomStyle"
        [borderRightStyle]="wrapperStyle?.borderRightStyle"
        (changeBorder)="onChangeStyle($event)"></codex-border-picker>
    <codex-border-picker
        *ngIf="type === 'hover'"
        [borderStyle]="hoverWrapperStyle?.borderStyle"
        [borderTopStyle]="hoverWrapperStyle?.borderTopStyle"
        [borderLeftStyle]="hoverWrapperStyle?.borderLeftStyle"
        [borderBottomStyle]="hoverWrapperStyle?.borderBottomStyle"
        [borderRightStyle]="hoverWrapperStyle?.borderRightStyle"
        (changeBorder)="onChangeHoverStyle($event)"></codex-border-picker>
    <codex-border-picker
        *ngIf="type === 'focus'"
        [borderStyle]="focusWrapperStyle?.borderStyle"
        [borderTopStyle]="focusWrapperStyle?.borderTopStyle"
        [borderLeftStyle]="focusWrapperStyle?.borderLeftStyle"
        [borderBottomStyle]="focusWrapperStyle?.borderBottomStyle"
        [borderRightStyle]="focusWrapperStyle?.borderRightStyle"
        (changeBorder)="onChangeFocusStyle($event)"></codex-border-picker>
    <codex-border-picker
        *ngIf="type === 'active'"
        [borderStyle]="activeWrapperStyle?.borderStyle"
        [borderTopStyle]="activeWrapperStyle?.borderTopStyle"
        [borderLeftStyle]="activeWrapperStyle?.borderLeftStyle"
        [borderBottomStyle]="activeWrapperStyle?.borderBottomStyle"
        [borderRightStyle]="activeWrapperStyle?.borderRightStyle"
        (changeBorder)="onChangeActiveStyle($event)"></codex-border-picker>
</div>
