import { Component, EventEmitter, Input, Output } from '@angular/core';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';
import { OverviewRightDto } from '../../../../../../../data-access/editor/src/lib/right/dto/overview-right.dto';

@Component({
    selector: 'codex-right-overview-row',
    templateUrl: './right-overview-row.component.html',
    styleUrls: ['./right-overview-row.component.scss'],
})
export class RightOverviewRowComponent {
    @Input() right: OverviewRightDto;

    @Output() clicked = new EventEmitter<void>();
    @Output() boxSelectionChanged = new EventEmitter<boolean>();

    checkboxSelected = false;
    environment: BackofficeEnvironmentDto = backofficeEnvironment;

    onClicked(): void {
        this.clicked.emit();
    }

    onCheckboxSelected() {
        this.checkboxSelected = !this.checkboxSelected;
        this.boxSelectionChanged.emit(this.checkboxSelected);
    }
}
