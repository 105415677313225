import { NgModule } from '@angular/core';
import { PluginOverviewPageComponent } from './pages/plugin-overview-page/plugin-overview-page.component';
import { CommonsModule } from '../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { HttpLoaderInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-authentication.interceptor';
import { BackofficeUiSearchModule } from '@backoffice/ui/search';

@NgModule({
    declarations: [PluginOverviewPageComponent],
    imports: [CommonsModule, BackofficeUiSearchModule],
    exports: [PluginOverviewPageComponent],
    providers: [HttpLoaderInterceptorProvider, HttpErrorInterceptorProvider, HttpAuthenticationInterceptorProvider],
})
export class BackofficeFeaturePluginsModule {}
