<h3
    [attr.id]="'style-accordion-' + identifier + '-margin-heading'"
    [attr.data-name]="'style-accordion-margin-header-' + identifier"
    class="p-0">
    <button
        type="button"
        class="accordion-heading"
        [class.accordion-heading-first]="true"
        [class.accordion-heading-last]="false"
        [attr.data-accordion-target]="'#style-accordion-' + identifier + '-margin'"
        [attr.aria-controls]="'style-accordion-' + identifier + '-margin'"
        aria-expanded="true">
        <div class="flex gap-1 justify-center items-center w-full">
            <span class="leading-7 grow text-left">{{ title ? title : ('v2.part.style.margin' | translate) }}</span>
            <button
                class="text-button"
                type="button"
                (click)="resetStyle(); $event.stopPropagation()"
                [matTooltip]="'v2.template.style.reset' | translate">
                Reset
            </button>
        </div>
    </button>
</h3>
<div
    [attr.id]="'style-accordion-' + identifier + '-margin'"
    [attr.data-name]="'margin-' + identifier"
    [attr.aria-labelledby]="'style-accordion-' + identifier + '-margin'"
    [class.accordion-panel-last]="false"
    [class.accordion-panel]="true"
    class="hidden">
    <div class="button-group" role="group">
        <button
            *ngIf="allowedTypes.indexOf('normal') > -1"
            type="button"
            class="button-group-button-left"
            autofocus
            (click)="type = 'normal'"
            [ngClass]="type === 'normal' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.normal' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('hover') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'hover'"
            [ngClass]="type === 'hover' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.hover' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('focus') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'focus'"
            [ngClass]="type === 'focus' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.focus' | translate }}
        </button>
    </div>
    <codex-margin-picker
        *ngIf="type === 'normal'"
        [marginStyle]="wrapperStyle.marginStyle"
        [marginTopStyle]="wrapperStyle.marginTopStyle"
        [marginLeftStyle]="wrapperStyle.marginLeftStyle"
        [marginBottomStyle]="wrapperStyle.marginBottomStyle"
        [marginRightStyle]="wrapperStyle.marginRightStyle"
        (changeMargin)="onChangeStyle($event)"></codex-margin-picker>

    <codex-margin-picker
        *ngIf="type === 'hover'"
        [marginStyle]="hoverWrapperStyle.marginStyle"
        [marginTopStyle]="hoverWrapperStyle.marginTopStyle"
        [marginLeftStyle]="hoverWrapperStyle.marginLeftStyle"
        [marginBottomStyle]="hoverWrapperStyle.marginBottomStyle"
        [marginRightStyle]="hoverWrapperStyle.marginRightStyle"
        (changeMargin)="onChangeHoverStyle($event)"></codex-margin-picker>
    <codex-margin-picker
        *ngIf="type === 'focus'"
        [marginStyle]="focusWrapperStyle.marginStyle"
        [marginTopStyle]="focusWrapperStyle.marginTopStyle"
        [marginLeftStyle]="focusWrapperStyle.marginLeftStyle"
        [marginBottomStyle]="focusWrapperStyle.marginBottomStyle"
        [marginRightStyle]="focusWrapperStyle.marginRightStyle"
        (changeMargin)="onChangeFocusStyle($event)"></codex-margin-picker>
</div>
