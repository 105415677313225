<mat-dialog-content class="!p-4 bg-dark-background_level_0 scrollbar scrollbar-primary">
    <form [formGroup]="selectWorkspaceForm" novalidate fxLayout="column">
        <label for="filtername" class="small-input-label" data-name="filtername">{{ 'workspace.selector.filter' | translate }}</label>
        <input
            class="small-input"
            type="text"
            id="filtername"
            name="filtername"
            autocomplete="off"
            cdkFocusInitial
            required
            formControlName="filterName" />

        <ul class="workspace-list">
            <ng-container *ngFor="let application of filteredApplications$ | async">
                <li
                    class="hover:bg-accentlight hover:cursor-pointer"
                    [attr.data-name]="application.name.toLowerCase()"
                    (click)="onApplicationSelect(application)"
                    [class.selected]="application.id === selectedApplication.id">
                    <div class="workspace-selector-icon-wrapper">
                        <mat-icon matListItemIcon>{{ application.iconName }}</mat-icon>
                    </div>
                    <div class="workspace-name">{{ application.name }}</div>
                </li>
            </ng-container>
        </ul>
    </form>
</mat-dialog-content>
