import 'reflect-metadata';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'search-form',
    templateUrl: 'search-form.component.html',
})
export class SearchFormComponent implements OnInit {
    @Input() searchPlaceHolder = 'general.search.placeholder';
    @Input() searchString = '';

    @Output() search = new EventEmitter<string>();

    searchStringSubject$ = new Subject<string>();

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.searchStringSubject$.pipe(untilDestroyed(this), debounceTime(300), distinctUntilChanged()).subscribe(searchString => {
            this.search.emit(searchString);
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { page: 0, keyword: searchString },
                queryParamsHandling: 'merge',
            });
        });
    }

    onInputChanged(event: any): void {
        this.searchStringSubject$.next(event.target.value);
    }
}
