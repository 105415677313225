import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateApplicationDto } from '../../../app/v2-application/dto/create-application.dto';
import { ApplicationCreatedDto } from '../../../app/v2-application/dto/application-created.dto';
import { backofficeEnvironment } from '@shared/environment';
import { Application } from '../../../app/v2-application/model/application';
import { ApplicationDto } from '../../../app/v2-application/dto/application.dto';
import { Page } from '@shared/data-access';
import { LoggerService } from '@backoffice/utils';
import { map } from 'rxjs/operators';

@Injectable()
export class ApplicationService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    public createApplication(createApplicationDto: CreateApplicationDto, companyId: string): Observable<ApplicationCreatedDto> {
        this.log.debug('Creating application', [createApplicationDto]);
        return this.httpClient.post<ApplicationCreatedDto>(
            `${backofficeEnvironment.rest.v2.applications}company/${companyId}/`,
            createApplicationDto
        );
    }

    public updateApplication(application: Application, companyId: string): Observable<void> {
        this.log.debug('Updating application', [application]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.applications}company/${companyId}/${application.id}`,
            application
        );
    }

    public validateDomain(companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Validating domain for application', [applicationId]);
        return this.httpClient.get<void>(
            `${backofficeEnvironment.rest.v2.applications}company/${companyId}/${applicationId}/check-custom-domain`
        );
    }

    public deleteApplication(applicationId: string, companyId: string): Observable<void> {
        this.log.debug('Deleting application', [applicationId]);
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.applications}company/${companyId}/${applicationId}`);
    }

    public getApplication(applicationId: string, companyId: string): Observable<ApplicationDto> {
        this.log.debug('Getting application', [applicationId]);
        return this.httpClient
            .get<ApplicationDto>(`${backofficeEnvironment.rest.v2.applications}company/${companyId}/${applicationId}`)
            .pipe(
                map(result => ({
                    ...result,
                }))
            );
    }

    public getApplications(
        companyId: string,
        keyword?: string,
        orderBy?: string,
        filters?: string[],
        page: number = 0,
        maxResults: number = 1000
    ): Observable<Page<ApplicationDto>> {
        this.log.debug('Getting applications of company', [companyId]);
        let params = new HttpParams().set('companyId', companyId).set('page', String(page)).set('maxResults', String(maxResults));
        if (!!keyword) {
            params = params.append('keyword', keyword);
        }

        if (!!orderBy) {
            params = params.set('orderBy', orderBy);
        }

        if (!!filters) {
            filters.forEach(filter => (params = params.append('filters', filter)));
        }

        return this.httpClient.get<Page<ApplicationDto>>(`${backofficeEnvironment.rest.v2.applications}`, { params });
    }
}
