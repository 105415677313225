import { PartDetail } from '../../../part-detail.model';
import { Type } from 'class-transformer';
import { TemplateResult } from '../../../template-result.model';
import { Argument } from '../../../../../arguments/argument';

export class TemplatePartDetail extends PartDetail {
    code: string;
    templateId: string | null;
    formgroupId: string | null;

    templateIdLockedForLanguageBundle: boolean | null;

    @Type(() => Argument)
    arguments: Argument[];

    @Type(() => TemplateResult)
    results: TemplateResult[];

    isValid(): boolean {
        return this.isCodeValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    hasActions(): boolean {
        return false;
    }

    getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.template.error.code');
        }
        return validationErrors;
    }
}
