<mat-dialog-content class="!p-4">
    <ng-container *ngIf="selectCompany$ | async">
        <img width="300" src="/src/images/theme/no_applications.svg" />
        <p class="text-white">{{ 'v2.application.onboarding.no.applications.found' | translate }}</p>
        <button
            class="primary-button button-large flex justify-center gap-2"
            (click)="onAddApplication()"
            color="primary"
            mat-button
            mat-raised-button>
            <mat-icon>add_circle</mat-icon>
            {{ 'v2.application.onboarding.create.application' | translate }}
        </button>
    </ng-container>
</mat-dialog-content>
