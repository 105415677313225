import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'codex-invite-user',
    templateUrl: './invite-user.component.html',
})
export class InviteUserComponent implements OnInit {
    form: FormGroup;

    get mailControl(): FormControl {
        return this.form.get('mail') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly dialogRef: MatDialogRef<InviteUserComponent>
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            mail: [undefined, [Validators.email, Validators.required]],
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
