<div class="flex gap-2 justify-center items-center" *ngIf="{ basedOnPartPositioning: basedOnPartPositioning$ | async } as observables">
    <ng-container *ngIf="observables.basedOnPartPositioning">
        <svg
            *ngIf="observables.basedOnPartPositioning.type === 'SMALL'"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="screenTypeInheritPopover"
            appendTo="body"
            [preventOverflow]="false"
            [boundariesElement]="'rootBoundary'"
            [appendTo]="'body'"
            [positionFixed]="true"
            [placement]="NgxFloatUiPlacements.LEFT"
            (onShown)="onShowInformation()"
            class="icon"
            aria-hidden="true"
            focusable="false">
            <use href="#mobile-screens" class="stroke-white"></use>
        </svg>
        <svg
            *ngIf="observables.basedOnPartPositioning.type === 'LARGE'"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="screenTypeInheritPopover"
            appendTo="body"
            [preventOverflow]="false"
            [boundariesElement]="'rootBoundary'"
            [appendTo]="'body'"
            [positionFixed]="true"
            [placement]="NgxFloatUiPlacements.LEFT"
            (onShown)="onShowInformation()"
            class="icon"
            aria-hidden="true"
            focusable="false">
            <use href="#tablet-screens" class="stroke-white"></use>
        </svg>
        <svg
            *ngIf="observables.basedOnPartPositioning.type === 'EXTRA_EXTRA_LARGE'"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="screenTypeInheritPopover"
            appendTo="body"
            [preventOverflow]="false"
            [boundariesElement]="'rootBoundary'"
            [appendTo]="'body'"
            [positionFixed]="true"
            [placement]="NgxFloatUiPlacements.LEFT"
            (onShown)="onShowInformation()"
            class="icon"
            aria-hidden="true"
            focusable="false">
            <use href="#large-screens" class="stroke-white"></use>
        </svg>
        <svg
            *ngIf="observables.basedOnPartPositioning.type === 'ALL_SCREENS'"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="screenTypeInheritPopover"
            appendTo="body"
            [preventOverflow]="false"
            [boundariesElement]="'rootBoundary'"
            [appendTo]="'body'"
            [positionFixed]="true"
            [placement]="NgxFloatUiPlacements.LEFT"
            (onShown)="onShowInformation()"
            class="icon"
            aria-hidden="true"
            focusable="false">
            <use href="#all-screens" class="fill-white"></use>
        </svg>

        <span class="leading-7 grow text-left">{{ 'v2.part.style.size.and.position' | translate }}</span>
        <button
            *ngIf="observables.basedOnPartPositioning.isCurrentScreenType && this.part.positions && this.part.positions.length > 1"
            class="text-button"
            type="button"
            (click)="onResetStyle(); $event.stopPropagation()"
            [matTooltip]="'v2.template.style.reset' | translate">
            Reset
        </button>
    </ng-container>
</div>
<float-ui-content #screenTypeInheritPopover>
    <div class="popover max-w-[250px]">
        {{ 'v2.part.style.size.and.position.screentype.description' | translate }}
    </div>
</float-ui-content>
