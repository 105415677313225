import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { LoggerService } from '@backoffice/utils';
import { InvocationScopeDto } from '../../dto/invocation-scope.dto';

@Injectable()
export class InvocationScopeEditorFacade {
    constructor(
        private readonly store: Store<EditorState>,
        private readonly log: LoggerService
    ) {}

    findById(invocationId: string): Observable<InvocationScopeDto> {
        this.log.info(`Find invocation scope by id ${invocationId}`);
        return of();
    }
}
