import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CompanyDto } from '../../../../modules/shared/interfaces/company.dto';
import { CompanySelectorAllDialog } from '../company-selector-all/company-selector-all.dialog';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-company-selector',
    templateUrl: './company-selector.component.html',
    styleUrls: ['./company-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySelectorComponent {
    @Input() companies: CompanyDto[];
    @Input() recentCompanies: CompanyDto[];
    @Input() selectedCompany: CompanyDto;
    @Output() companySelect: EventEmitter<CompanyDto> = new EventEmitter<CompanyDto>();
    @Output() companyAdd = new EventEmitter<void>();

    private _allCompaniesDialog: MatDialogRef<CompanySelectorAllDialog>;

    isOpen: boolean;

    constructor(
        public dialog: MatDialog,
        public router: Router
    ) {}

    onAddCompany(): void {
        this.isOpen = false;
        this.companyAdd.emit();
    }

    onCompanySelected(company: CompanyDto) {
        this.isOpen = false;
        this.companySelect.emit(company);
    }

    onAllCompaniesClicked(): void {
        this._allCompaniesDialog = this.dialog.open(
            CompanySelectorAllDialog,
            Object.assign(
                {
                    data: {
                        selectedCompany: this.selectedCompany,
                        companies: this.companies,
                        lastUsedCompanies: this.recentCompanies,
                    },
                },
                backofficeEnvironment.dialogConfig.normal
            )
        );
        this._allCompaniesDialog.afterClosed().subscribe(result => {
            if (!!result) {
                this.onCompanySelected(result);
            }
        });
    }
}
