import { NgModule } from '@angular/core';
import { FilterPluginsComponent } from './components/filter-plugins/filter-plugins.component';
import { SearchFacets } from './components/search-facets/search-facets.component';
import { SearchFacetsLoadingPlaceholderComponent } from './components/search-facets-loading-placeholder/search-facets-loading-placeholder.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { SearchResultLoadingPlaceholderComponent } from './components/search-result-loading-placeholder/search-result-loading-placeholder.component';
import { SearchResultsPagingComponent } from './components/search-results-paging/search-results-paging.component';
import { CommonsModule } from '../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { FacetsComponent } from './components/facets/facets.component';
import { SearchResults } from './components/search-results-container/search-results-container.component';
import { SearchHeaderComponent } from './components/search-header/search-header.component';

@NgModule({
    declarations: [
        FilterPluginsComponent,
        SearchFacets,
        SearchFacetsLoadingPlaceholderComponent,
        SearchFormComponent,
        SearchResults,
        SearchResultLoadingPlaceholderComponent,
        SearchResultsPagingComponent,
        FilterPluginsComponent,
        FacetsComponent,
        SearchHeaderComponent,
        SearchHeaderComponent,
    ],
    imports: [CommonsModule],
    exports: [
        FilterPluginsComponent,
        SearchFacets,
        SearchResults,
        SearchFacetsLoadingPlaceholderComponent,
        SearchFormComponent,
        SearchResultLoadingPlaceholderComponent,
        SearchResultsPagingComponent,
        FilterPluginsComponent,
        FacetsComponent,
        SearchHeaderComponent,
    ],
})
export class BackofficeUiSearchModule {}
