import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export interface WrapperStyle {
    innerSize?: {
        overflowX?: string | null | undefined;
        overflowY?: string | null | undefined;
    } | null;
}

@Component({
    selector: 'codex-overflow-wrapper',
    templateUrl: './overflow-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverflowWrapperComponent {
    @Input()
    firstPanel: boolean = false;

    @Input()
    lastPanel: boolean = false;

    @Input()
    identifier: string;

    @Output()
    wrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    wrapperStyle: WrapperStyle;

    @Output()
    hoverWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    hoverWrapperStyle: WrapperStyle;

    @Output()
    focusWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    focusWrapperStyle: WrapperStyle;

    @Output()
    activeWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    activeWrapperStyle: WrapperStyle;

    @Input()
    allowedTypes: string[] = ['normal', 'hover', 'focus', 'active'];

    type: string = 'normal';

    resetStyle() {
        if (this.type === 'normal') {
            this.resetWrapperStyle();
        } else if (this.type === 'hover') {
            this.resetHoverWrapperStyle();
        } else if (this.type === 'focus') {
            this.resetFocusWrapperStyle();
        } else if (this.type === 'active') {
            this.resetActiveWrapperStyle();
        }
    }

    resetWrapperStyle() {
        this.wrapperStyle.innerSize.overflowX = null;
        this.wrapperStyle.innerSize.overflowY = null;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    resetHoverWrapperStyle() {
        this.hoverWrapperStyle.innerSize.overflowX = null;
        this.hoverWrapperStyle.innerSize.overflowY = null;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    resetFocusWrapperStyle() {
        this.focusWrapperStyle.innerSize.overflowX = null;
        this.focusWrapperStyle.innerSize.overflowY = null;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    resetActiveWrapperStyle() {
        this.activeWrapperStyle.innerSize.overflowX = null;
        this.activeWrapperStyle.innerSize.overflowY = null;
        this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle });
    }
}
