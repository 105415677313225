import { createReducer, on } from '@ngrx/store';
import { fetchActionSuccess } from '../actions/action-editor.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { ActionContextState } from '../state/action-context.state';
import { ActionContext } from '../../interfaces/action-context.interface';
import {
    fetchActionContextSuccess,
    fetchActionMethodsSuccess,
    markActionScopeOutdated,
    updateActionScope,
    updateCalledActions,
} from '../actions/action-context.actions';

const adapterActionContext = createEntityAdapter<ActionContext>();
export const actionContextInitialState: ActionContextState = adapterActionContext.getInitialState();

export const actionContextsReducer = (initialData: ActionContextState) =>
    createReducer(
        initialData,
        on(fetchActionSuccess, (state, { action }) =>
            adapterActionContext.addOne(
                {
                    id: action.id,
                    language: 'en',
                },
                state
            )
        ),
        on(updateActionScope, (state, { id, scope }) =>
            adapterActionContext.updateOne(
                {
                    id: id,
                    changes: { scope: scope, scopeOutdated: false },
                },
                state
            )
        ),
        on(updateCalledActions, (state, { id, calledActions }) =>
            adapterActionContext.updateOne(
                {
                    id: id,
                    changes: { scopeOutdated: true, calledActions: calledActions },
                },
                state
            )
        ),
        on(fetchActionContextSuccess, (state, { action, actionContext }) =>
            adapterActionContext.updateOne(
                {
                    id: action.id,
                    changes: { scopeOutdated: true, actionCtxs: actionContext },
                },
                state
            )
        ),
        on(fetchActionMethodsSuccess, (state, { action, methods }) =>
            adapterActionContext.updateOne(
                {
                    id: action.id,
                    changes: { scopeOutdated: true, methods: methods },
                },
                state
            )
        ),
        on(markActionScopeOutdated, (state, { action }) =>
            adapterActionContext.updateOne(
                {
                    id: action.id,
                    changes: { scopeOutdated: true },
                },
                state
            )
        )
    );
