import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';

@Injectable()
export class DateUtil {
    public formatClientDefault(date: any): string {
        return this.format(date, 'DD-MM-YYYY');
    }

    public formatClientWithHours(date: any): string {
        return this.format(date, 'DD-MM-YYYY HH:mm:ss');
    }

    private format(date: any, format: string) {
        let result: string = '';
        if (date != null && typeof date !== 'undefined') {
            result = date.format(format);
        }
        return result;
    }

    public parseClientDefault(dateString: string): any {
        return this.parse(dateString, 'DD-MM-YYYY');
    }

    public parseServerDefault(dateString: string): any {
        return this.parse(dateString, 'YYYYMMDDHHmmss');
    }

    public parse(dateString: string, format: string) {
        let result: Moment = null;
        if (dateString !== 'undefined' && dateString != null && dateString != '') {
            result = moment(dateString, format);
        }
        return result;
    }

    public formatServerDefault(date: any): string {
        return this.format(date, 'YYYYMMDDHHmmss');
    }

    public convertServerDefaultToClientDefault(dateString: string) {
        return this.formatClientDefault(this.parseServerDefault(dateString));
    }

    public convertClientDefaultToServerDefault(dateString: string) {
        return this.formatServerDefault(this.parseClientDefault(dateString));
    }
}
