import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationLogsFacade } from '../../facade/application-logs.facade';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ApplicationLogDto } from '@backoffice/data-access/application-logs';
import { RouterFacade } from '../../../../../../utils-routing/src';
import { initFlowbite } from 'flowbite';
import { backofficeEnvironment } from '../../../../../../../shared/environment/src';
import { Argument } from '../../../../../../data-access/editor/src/lib/arguments/argument';
import { EditorFacade } from '../../../../../../data-access/editor/src';

@Component({
    selector: 'codex-application-logs',
    templateUrl: './application-logs.page.html',
})
export class ApplicationLogsPage implements OnInit, AfterViewInit, OnDestroy {
    templateId: string | null | undefined = null;

    actionId: string | null | undefined = null;

    from: Date = new Date();
    to: Date = new Date();

    fromTime: string;
    toTime: string;

    level: string;

    streamSubscription: Subscription = new Subscription();

    streaming = false;

    logs: BehaviorSubject<ApplicationLogDto[]> = new BehaviorSubject<[]>([]);

    advancedLogSearch = false;

    constructor(private viewLogsFacade: ApplicationLogsFacade) {}

    async isElementLoaded(selector) {
        while (document.querySelector(selector) === null) {
            await new Promise(resolve => requestAnimationFrame(resolve));
        }
        return document.querySelector(selector);
    }

    ngOnDestroy() {
        this.streamSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.from.setHours(this.from.getHours() - 1);
        this.to.setHours(this.to.getHours() + 3);
        console.log(this.to.toTimeString().split(' ')[0]);
        this.onChangeToTime(this.to.toTimeString().split(' ')[0]);
        this.onChangeFromTime(this.from.toTimeString().split(' ')[0]);
        this.fetchLogs();
    }

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    fetchLogs(): void {
        this.viewLogsFacade.getActionLogs(this.templateId, this.actionId, this.from, this.to, this.level).subscribe(actionLogs => {
            this.logs.next(actionLogs);
        });
    }

    onChangeLevel() {
        this.fetchLogs();
    }

    onChangeFromDate() {
        this.fetchLogs();
    }

    onChangeToDate() {
        this.fetchLogs();
    }

    onChangeToTime($event?) {
        if ($event) {
            this.toTime = $event;
        }
        const [hours, minutes, seconds] = this.toTime.replace(/_/gi, '').split(':');
        if (!!hours) {
            this.to.setHours(Number(hours.slice(0, 2)));
        }
        if (!!minutes) {
            this.to.setMinutes(Number(minutes.slice(0, 2)));
        }
        if (!!seconds) {
            this.to.setSeconds(Number(seconds.slice(0, 2)));
        }
        this.fetchLogs();
    }

    onChangeFromTime($event?) {
        if ($event) {
            this.fromTime = $event;
        }
        const [hours, minutes, seconds] = this.fromTime.replace(/_/gi, '').split(':');
        if (!!hours) {
            this.from.setHours(Number(hours.slice(0, 2)));
        }
        if (!!minutes) {
            this.from.setMinutes(Number(minutes.slice(0, 2)));
        }
        if (!!seconds) {
            this.from.setSeconds(Number(seconds.slice(0, 2)));
        }
        this.fetchLogs();
    }

    onTemplateChanged($event: { value: string; arguments?: Argument[] | undefined }) {
        if ($event.value) {
            this.templateId = this.removeQuotesIfPresent($event.value);
        } else {
            this.templateId = null;
        }
        this.fetchLogs();
    }

    onActionChanged($event: { value: string; arguments?: Argument[] | undefined }) {
        if ($event.value) {
            this.actionId = this.removeQuotesIfPresent($event.value);
        } else {
            this.actionId = null;
        }
        this.fetchLogs();
    }

    refreshLogs() {
        this.fetchLogs();
    }

    streamLogs() {
        if (this.streaming) {
            this.streaming = false;
            this.streamSubscription.unsubscribe();
            this.streamSubscription = new Subscription();
        } else {
            this.streaming = true;
            this.streamSubscription.add(
                timer(1, 3000).subscribe(() => {
                    this.fetchLogs();
                })
            );
        }
    }

    handleAdvancedSearchClicked() {
        this.advancedLogSearch = !this.advancedLogSearch;
    }

    removeQuotesIfPresent(value: string | undefined): string | undefined {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    protected readonly environment = backofficeEnvironment;
}
