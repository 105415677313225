import { Component } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { AppFacade } from '../../../../modules/core/facades/app.facade';

@Component({
    selector: 'codex-application-onboarding',
    templateUrl: './application-onboarding.component.html',
    styleUrls: ['./application-onboarding.component.scss'],
})
export class ApplicationOnboardingComponent {
    selectCompany$ = this.appFacade.selectedCompany;

    constructor(
        public dialogRef: MatDialogRef<ApplicationOnboardingComponent>,
        private readonly appFacade: AppFacade
    ) {}

    onAddApplication() {
        this.appFacade.closeOnboardingDialog();
        this.dialogRef.close();
    }
}
