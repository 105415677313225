<mat-dialog-content class="!p-4">
    <h1 class="!mb-2">{{ 'company.edit.permissions.title' | translate }}</h1>
    <form [formGroup]="form">
        <select id="select-application" class="small-input" [formControl]="applicationControl" data-name="select-application">
            <option selected value="">Select application</option>
            <option *ngFor="let application of applications$ | async" [value]="application.id">
                {{ application.name }}
            </option>
        </select>
        <div *ngIf="groupsAndRoles$ | async as data; else selectApp">
            <div class="groups" *ngIf="data.groups as groups">
                <h2 class="!mb-2">{{ 'company.edit.permissions.groups.title' | translate }}</h2>
                <div class="groups__content grid grid-cols-4">
                    <ng-container *ngIf="groups.length != 0; else noGroups">
                        <ng-container *ngFor="let group of groups">
                            <input
                                [value]="group.id"
                                [checked]="group.checked"
                                (click)="onGroupSelected(group, group.applicationId, group.companyId)"
                                [attr.id]="'group-' + group.id"
                                type="checkbox"
                                value=""
                                class="checkbox" />
                            <label [attr.for]="'group-' + group.id" class="small-input-label">{{ group.name }}</label>
                        </ng-container>
                    </ng-container>
                    <ng-template #noGroups>
                        <p class="text-text_2">{{ 'company.edit.permissions.groups.no.found' | translate }}</p>
                    </ng-template>
                </div>
            </div>
            <div class="roles" *ngIf="data.roles as roles">
                <h2 class="!mb-2">{{ 'company.edit.permissions.roles.title' | translate }}</h2>
                <div class="roles__content grid grid-cols-4">
                    <ng-container *ngIf="roles.length != 0; else noRoles">
                        <ng-container *ngFor="let role of roles">
                            <input
                                [value]="role.id"
                                [checked]="role.checked"
                                (click)="onRoleSelected(role, role.companyId, role.applicationId)"
                                [attr.id]="'role-' + role.id"
                                type="checkbox"
                                value=""
                                class="checkbox" />
                            <label [attr.for]="'role-' + role.id" class="small-input-label">{{ role.name }}</label>
                        </ng-container>
                    </ng-container>
                    <ng-template #noRoles>
                        <p class="text-text_2">{{ 'company.edit.permissions.roles.no.found' | translate }}</p>
                    </ng-template>
                </div>
            </div>
        </div>
        <ng-template #selectApp>
            <p class="text-xs text-white">{{ 'company.edit.permissions.application.select' | translate }}</p>
        </ng-template>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" [mat-dialog-close]="true">
                <mat-icon>done</mat-icon>{{ 'general.ok' | translate }}
            </button>
        </div>
    </form>
</mat-dialog-content>
