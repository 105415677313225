import { PartDetail } from '../../../part-detail.model';
import { DropdownFieldPartChoice } from './dropdown-field-part.choice';
import { CheckboxFieldPartChoice } from '../checkbox-field-part/checkbox-field-part.choice';

export class DropdownFieldPartDetail extends PartDetail {
    code: string;
    label: string;

    required: boolean;
    requiredMessage: string;

    help: boolean;
    helpInformation: string;

    answer: string;

    choices: DropdownFieldPartChoice[];
    emptyChoice: boolean;

    enabled: boolean;

    isValid(): boolean {
        return this.isCodeValid() && this.isLabelValid() && this.areChoicesValid() && this.isRequiredValid() && this.isHelpValid();
    }

    getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.dropdown-field.error.code');
        }
        if (!this.isLabelValid()) {
            validationErrors.push('v2.part.dropdown-field.error.label');
        }
        if (!this.areChoicesValid()) {
            validationErrors.push('v2.part.dropdown-field.error.choices');
        }
        if (!this.isRequiredValid()) {
            validationErrors.push('v2.part.dropdown-field.error.required');
        }
        if (!this.isHelpValid()) {
            validationErrors.push('v2.part.dropdown-field.error.help');
        }
        return validationErrors;
    }

    areChoicesValid() {
        if (!!this.choices && this.choices.length > 0) {
            let valid = true;
            for (const choice of this.choices) {
                valid = valid && this.isChoiceValid(choice);
            }
            return valid;
        } else {
            return true;
        }
    }

    isChoiceValid(choice: CheckboxFieldPartChoice) {
        return !!choice.code && choice.code !== '' && !!choice.label && choice.label !== '';
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isLabelValid(): boolean {
        return !!this.label && this.label !== '';
    }

    isHelpValid(): boolean {
        return !this.help || (!!this.helpInformation && this.helpInformation !== '');
    }

    isRequiredValid(): boolean {
        return !this.required || (!!this.requiredMessage && this.requiredMessage !== '');
    }
}
