import { Injectable, InjectionToken } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';

export const LOG_LEVEL = new InjectionToken<LogLevel>('LoggerLevel');

export enum LogLevel {
    DEBUG,
    INFO,
    WARN,
    ERROR,
}

@Injectable()
export class LoggerService {
    private readonly logLevel = backofficeEnvironment.production ? LogLevel.ERROR : LogLevel.ERROR;

    public error(): (text: any, arg?: any[]) => void {
        return LogLevel.ERROR >= this.logLevel
            ? console.error.bind(window.console)
            : () => {
                  //DO NOTHING
              };
    }

    public get warn(): (text: any, arg?: any[]) => void {
        return LogLevel.WARN >= this.logLevel
            ? console.warn.bind(window.console)
            : () => {
                  //DO NOTHING
              };
    }

    public get info(): (text: any, arg?: any[]) => void {
        return LogLevel.INFO >= this.logLevel
            ? console.info.bind(window.console)
            : () => {
                  //DO NOTHING
              };
    }

    public get debug(): (text: any, arg?: any[]) => void {
        return LogLevel.DEBUG >= this.logLevel
            ? console.info.bind(window.console)
            : () => {
                  //DO NOTHING
              };
    }
}
