<div class="overview-filter-card" [formGroup]="formGroup">
    <h2 class="mb-2">Filters</h2>
    <label class="toggle-line">
        <h5>{{ label }}</h5>
        <input type="checkbox" value="" class="sr-only peer" formControlName="filterPlugins" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>
    <ng-container *ngIf="shouldShowFacets(facets)" class="p-2">
        <div class="flex flex-col">
            <ng-container *ngFor="let facet of facets | keyvalue">
                <ng-container *ngIf="facet.value?.length > 0 || selectedValues.has(facet.key)">
                    <section>
                        <h4 class="!mb-1">
                            {{ prefix ? ('facets.' + prefix + '.' + facet.key + '.label' | translate) : facet.key }}
                        </h4>
                        <label *ngFor="let field of facet.value" class="toggle-line">
                            <ng-container
                                *ngIf="
                                    field.count > 0 ||
                                    (selectedValues.get(facet.key) && selectedValues.get(facet.key)?.indexOf(field.name) > -1)
                                ">
                                <h5 [attr.data-tooltip-target]="'tooltip-' + field.name" *ngIf="!field.displayName" class="!mb-0">
                                    {{
                                        prefix
                                            ? ('facets.' + prefix + '.' + facet.key + '.options.' + field.name | translate)
                                            : (field.name | truncate: 23)
                                    }}
                                    ({{ field.count }})
                                </h5>
                                <div
                                    *ngIf="!field.displayName"
                                    [attr.id]="'tooltip-' + field.name"
                                    role="tooltip"
                                    class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    {{
                                        prefix
                                            ? ('facets.' + prefix + '.' + facet.key + '.options.' + field.name | translate)
                                            : (field.name | truncate: 23)
                                    }}
                                    ({{ field.count }})
                                    <div class="tooltip-arrow" data-popper-arrow></div>
                                </div>

                                <h5 [attr.data-tooltip-target]="'tooltip-' + field.name" *ngIf="field.displayName" class="!mb-0">
                                    {{ field.displayName | truncate: 23 }} ({{ field.count }})
                                </h5>
                                <div
                                    *ngIf="field.displayName"
                                    [attr.id]="'tooltip-' + field.name"
                                    role="tooltip"
                                    class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    {{ field.displayName }}
                                    <div class="tooltip-arrow" data-popper-arrow></div>
                                </div>
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                    (click)="onCheckboxChanged(facet.key, field.name)"
                                    [attr.checked]="
                                        selectedValues.has(facet.key) && selectedValues.get(facet.key) === field.name ? '' : null
                                    " />
                                <div
                                    class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
                            </ng-container>
                        </label>
                    </section>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>
