import { PartDetail } from '../../../part-detail.model';
import { classToPlain, plainToClass, Type } from 'class-transformer';
import { Part } from '../../../part.model';

export class HorizontalListPartDetail extends PartDetail {
    code: string;

    //transient
    @Type(() => Part)
    parts?: Part[];

    partSelectorIds: string[] = [];

    public removeContainerPart(partSelectorId: string): void {
        this.partSelectorIds.splice(this.partSelectorIds.indexOf(partSelectorId), 1);
        this.parts.splice(
            this.parts.findIndex(part => part.selectorId === partSelectorId),
            1
        );
    }

    public updateContainerPart(partToUpdate: Part) {
        const currentPart = this.parts.find(part => part.selectorId === partToUpdate.selectorId);
        let currentPartIndex = this.parts.indexOf(currentPart);
        if (currentPart) {
            this.removeContainerPart(currentPart.selectorId);
        }
        if (currentPartIndex === -1) {
            currentPartIndex = this.parts.length;
        }

        const plainPartToUpdate = classToPlain(partToUpdate);
        const objectUpdatedPart: Part = plainToClass(Part, plainPartToUpdate);
        this.addContainerPart(objectUpdatedPart, currentPartIndex);
        return objectUpdatedPart;
    }

    public addContainerPart(part: Part, index: number): void {
        this.parts.push(part);
        this.partSelectorIds.splice(index, 0, part.selectorId);
        this.sortParts();
    }

    public sortParts() {
        if (this.parts && this.parts.length > 0) {
            this.parts.sort((a, b) => this.partSelectorIds.indexOf(a.selectorId) - this.partSelectorIds.indexOf(b.selectorId));
        }
    }

    isValid(): boolean {
        return this.isCodeValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.horizontal-list.error.code');
        }
        return validationErrors;
    }

    public getChildParts(): Part[] {
        return this.parts;
    }

    public shouldInitializeChildParts(): boolean {
        return !this.initialized;
    }
}
