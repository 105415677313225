import { Injectable } from '@angular/core';
import { EditorState } from '../../../store/editor.state';
import { Store } from '@ngrx/store';
import { CurrentContext, LoggerService } from '@backoffice/utils';
import { ApplicationState } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { AppFacade } from '@core/facades/app.facade';
import { combineLatest, Observable, of } from 'rxjs';
import { Page } from '@shared/data-access';
import { selectCurrentContext } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
    OverviewDesignSystemDto,
    SelectedFacets,
    UpdateColorDefinitionDto,
    UpdateDesignsystemDto,
    UpdateTypographyDto,
} from '../../../../index';
import { DesignSystemService } from '../../services/design-system.service';
import { DesignSystem } from '../../models/design-system';
import { designSystemEditorSelectors } from '../selectors/design-system-editor.selectors';
import {
    clearDesignSystemDialogData,
    createDesignSystemSuccess,
    deleteDesignSystems,
    designSystemDialogFacetsChanged,
    designSystemDialogFilterPluginsChanged,
    designSystemDialogPaginationChanged,
    designSystemDialogSearchTermChanged,
    findDesignSystem,
    loadDesignSystemDialogData,
    updateColorDefinition,
    updateDesignSystem,
    updateTypography,
} from '../actions/design-system-editor.actions';

@Injectable()
export class DesignSystemEditorFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.applicationStateStore.select(selectCurrentContext);

    get activeDesignSystem(): Observable<DesignSystem | undefined> {
        return this.currentContext$.pipe(
            filter(
                (context): context is CurrentContext =>
                    !!context && !!context.selectedApplication && !!context.selectedApplication.designsystemId
            ),
            map(context => context.selectedApplication.designsystemId),
            switchMap(designsystemId => this.findById(designsystemId).pipe(catchError(() => of(undefined))))
        );
    }

    get dialogData(): Observable<Page<OverviewDesignSystemDto> | undefined> {
        return this.store.select(designSystemEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(designSystemEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(designSystemEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(designSystemEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(designSystemEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{
        searchTerm: string | undefined;
        page: number;
        maxResults: number;
        facets: SelectedFacets;
        filterPlugins: boolean;
    }> {
        return this.store.select(designSystemEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly applicationStateStore: Store<ApplicationState>,
        private readonly designSystemService: DesignSystemService,
        private readonly appFacade: AppFacade,
        private readonly log: LoggerService
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearDesignSystemDialogData());
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(designSystemDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(designSystemDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(designSystemDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(designSystemDialogFilterPluginsChanged({ filterPlugins }));
    }

    create(openTab: boolean = true): Observable<string> {
        this.log.info('Create new design system');
        return combineLatest([this.appFacade.selectedCompany, this.appFacade.selectedApplication]).pipe(
            take(1),
            switchMap(([{ id: companyId }, { id: applicationId }]) =>
                this.designSystemService
                    .create({
                        companyId,
                        applicationId,
                        name: 'Designsystem',
                    })
                    .pipe(
                        tap(id => this.store.dispatch(createDesignSystemSuccess({ id, openTab }))),
                        map(id => id)
                    )
            )
        );
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting design system`, [ids]);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteDesignSystems({ ids }));
        }
    }

    findAll(keyword: string | undefined, filters: string[], orderBy: string, page: number, maxResults: number): Observable<Page<any>> {
        return this.currentContext$.pipe(
            filter((currentContext): currentContext is CurrentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.designSystemService.findAll(currentContext.selectedCompany.id, currentContext.selectedApplication.id, {
                    keyword,
                    orderBy,
                    filters,
                    page,
                    maxResults,
                });
            })
        );
    }

    findById(id: string): Observable<DesignSystem> {
        this.log.info(`Find design system by id ${id}`);
        this.store.dispatch(findDesignSystem({ id }));
        return this.store.select(designSystemEditorSelectors.byId(id)).pipe(
            filter(designSystem => !!designSystem),
            map(designSystem => new DesignSystem(designSystem))
        );
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadDesignSystemDialogData());
    }

    updateColor(definition: UpdateColorDefinitionDto): void {
        this.log.info(`Updating color definition`, [definition]);
        this.store.dispatch(updateColorDefinition({ definition }));
    }

    updateTypography(typography: UpdateTypographyDto): void {
        this.log.info(`Updating typography`, [typography]);
        this.store.dispatch(updateTypography({ typography }));
    }

    update(designSystem: UpdateDesignsystemDto): void {
        this.log.info(`Update design system`, [designSystem]);
        this.store.dispatch(updateDesignSystem({ designSystem }));
    }
}
