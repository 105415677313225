import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../../../store/editor.state';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { LoggerService } from '../../../../../../../utils/src/lib/services/logger.service';
import { AppFacade } from '@core/facades/app.facade';
import { SelectedFacets } from '@backoffice/data-access/editor';
import { Page } from '@shared/data-access';
import { groupEditorSelectors } from '../selectors/group-editor.selectors';
import { GroupsService } from '../../services/groups.service';
import {
    clearGroupDialogData,
    createGroupSuccess,
    deleteGroups,
    findGroup,
    groupDialogFacetsChanged,
    groupDialogFilterPluginsChanged,
    groupDialogPaginationChanged,
    groupDialogSearchTermChanged,
    loadGroupDialogData,
    updateGroup,
} from '../actions/group-editor.actions';
import { GroupDto } from 'libs/backoffice/data-access/editor/src/lib/group/dto/group.dto';
import { OverviewGroupDto } from '../../dto/overview-group.dto';

@Injectable()
export class GroupEditorFacade {
    get dialogData(): Observable<Page<OverviewGroupDto> | undefined> {
        return this.store.select(groupEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(groupEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(groupEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(groupEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(groupEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{
        searchTerm: string | undefined;
        page: number;
        maxResults: number;
        facets: SelectedFacets;
        filterPlugins;
    }> {
        return this.store.select(groupEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly groupService: GroupsService,
        private readonly appFacade: AppFacade,
        private readonly log: LoggerService
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearGroupDialogData());
    }

    create(): Observable<string> {
        this.log.info('Create new group');
        return combineLatest([this.appFacade.selectedCompany, this.appFacade.selectedApplication]).pipe(
            take(1),
            switchMap(([{ id: companyId }, { id: applicationId }]) =>
                this.groupService
                    .create(
                        {
                            companyId,
                            applicationId,
                        },
                        companyId,
                        applicationId
                    )
                    .pipe(
                        tap(({ id }) => this.store.dispatch(createGroupSuccess({ id, openTab: true }))),
                        map(({ id }) => id)
                    )
            )
        );
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(groupDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(groupDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(groupDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(groupDialogFilterPluginsChanged({ filterPlugins }));
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting group`, [ids]);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteGroups({ ids }));
        }
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadGroupDialogData());
    }

    findById(id: string): Observable<GroupDto> {
        this.log.info(`Find group by id ${id}`);
        this.store.dispatch(findGroup({ id }));
        return this.store.select(groupEditorSelectors.byId(id)).pipe(
            filter(group => !!group),
            map(group => group as GroupDto)
        );
    }

    update(group: GroupDto): void {
        this.log.info(`Update group`, [group]);
        this.store.dispatch(updateGroup({ group }));
    }
}
