import { NgModule } from '@angular/core';
import { CommonsModule } from '../common/common.module';
import { ContainerPartStyleComponent } from './components/container-part-style/container-part-style.component';
import { GenericInputFieldPartStyleComponent } from './components/generic-input-field-part-style/generic-input-field-part-style.component';
import { GenericStyleComponentComponent } from './components/generic-style-component/generic-style-component.component';
import { TemplatePickerComponent } from './components/template-picker/template-picker.component';
import { AuthorizationLinkPickerComponent } from './components/authorization-link-picker/authorization-link-picker.component';
import { SizePositionTitleComponent } from './components/size-position-title/size-position-title.component';
import { BackofficeUiArgumentsModule } from '@backoffice/ui-arguments';
import { BackofficeFeatureEditorPickersModule } from 'backoffice/feature/editor/pickers';

@NgModule({
    declarations: [
        ContainerPartStyleComponent,
        GenericInputFieldPartStyleComponent,
        GenericStyleComponentComponent,
        TemplatePickerComponent,
        AuthorizationLinkPickerComponent,
        SizePositionTitleComponent,
    ],

    imports: [CommonsModule, BackofficeUiArgumentsModule, BackofficeFeatureEditorPickersModule],
    exports: [
        ContainerPartStyleComponent,
        GenericInputFieldPartStyleComponent,
        GenericStyleComponentComponent,
        TemplatePickerComponent,
        AuthorizationLinkPickerComponent,
        SizePositionTitleComponent,
    ],
})
export class V2SharedModule {}
