<div mat-dialog-content>
    <h1>{{ 'v2.hub.detail.add.component.title' | translate }}</h1>
    <label for="comment" class="small-input-label" data-name="comment">{{
        'v2.hub.detail.add.component.labels.comment' | translate
    }}</label>
    <input class="small-input" type="text" id="comment" name="comment" autocomplete="off" [formControl]="valueControl" />

    <div class="buttonContainer">
        <button class="nocodexbutton" mat-raised-button color="primary" (click)="onAddClicked()">
            <mat-icon>add_comment</mat-icon>
            {{ 'v2.hub.detail.add.component.actions.ok' | translate }}
        </button>
        <button class="nocodexbutton" mat-raised-button (click)="onCancelClick()">
            <mat-icon>cancel</mat-icon>
            {{ 'v2.hub.detail.add.component.actions.cancel' | translate }}
        </button>
    </div>
</div>
