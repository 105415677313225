export enum ScreenType {
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE,
    EXTRA_EXTRA_LARGE,
    ALL_SCREENS,
}

export const ScreenTypeEditorWidth = new Map<number, number | null>([
    [ScreenType.SMALL, 360],
    [ScreenType.MEDIUM, 768],
    [ScreenType.LARGE, 1024],
    [ScreenType.EXTRA_LARGE, 1366],
    [ScreenType.EXTRA_EXTRA_LARGE, 1920],
]);

export const ScreenTypeEditorHeight = new Map<number, number | null>([
    [ScreenType.SMALL, 800],
    [ScreenType.MEDIUM, 1024],
    [ScreenType.LARGE, 768],
    [ScreenType.EXTRA_LARGE, 768],
    [ScreenType.EXTRA_EXTRA_LARGE, 1080],
]);

export const ScreenTypeMinWidth = new Map<number, number | null>([
    [ScreenType.SMALL, null],
    [ScreenType.MEDIUM, 600],
    [ScreenType.LARGE, 900],
    [ScreenType.EXTRA_LARGE, 1200],
    [ScreenType.EXTRA_EXTRA_LARGE, 1800],
]);

export const ScreenTypeMaxWidth = new Map<number, number | null>([
    [ScreenType.SMALL, 600],
    [ScreenType.MEDIUM, 900],
    [ScreenType.LARGE, 1200],
    [ScreenType.EXTRA_LARGE, 1800],
    [ScreenType.EXTRA_EXTRA_LARGE, null],
]);
