import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../app/common/statemanagement/state/applicationstate';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { CalculatedRightSet } from '../../../app/authenticated/dto/calculatedrightset';

@Injectable({
    providedIn: 'root',
})
export class CalculatedRightsService {
    constructor(
        private store: Store<ApplicationState>,
        private http: HttpClient
    ) {}

    getCalculatedRights(applicationId: string, companyId: string, userId: string): Observable<CalculatedRightSet> {
        let params = new HttpParams().set('applicationId', applicationId).set('companyId', companyId).set('userId', userId);
        return this.http.get<CalculatedRightSet>(`${backofficeEnvironment.rest.v2.calculatedRights}`, { params: params });
    }
}
