import { NgModule } from '@angular/core';
import { AddCompanyDialogComponent } from './components/add-company/add-company-dialog.component';
import { CommonsModule } from '../../app/common/common.module';
import { CompanyService } from '@add-company/service/company.service';
import { CompanyIdentityproviderService } from '@add-company/service/companyidentityprovider.service';
import { CompanyOnboardingComponent } from '@add-company/components/company-onboarding/company-onboarding.component';
import { ChoosePackageComponent } from './components/choose-package/choose-package.component';
import { BillingPackageService } from '@add-company/service/billingpackage.service';
import { CompanySandbox } from '../../../../../libs/backoffice/feature/company/edit-company/src/lib/facade/company.sandbox';

@NgModule({
    declarations: [AddCompanyDialogComponent, CompanyOnboardingComponent, ChoosePackageComponent],
    imports: [CommonsModule],
    providers: [CompanySandbox, CompanyService, CompanyIdentityproviderService, BillingPackageService],
    exports: [CompanyOnboardingComponent],
})
export class AddCompanyModule {}
