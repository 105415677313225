import { Facet } from './facet.model';

export class Page<T> {
    count: number;
    content: T[];
    facetFields?: { [key: string]: [Facet] };

    constructor(base?: Page<T>) {
        if (base) {
            this.count = base.count;
            this.content = base.content;
            this.facetFields = base.facetFields;
        }
    }
}
