import { createAction, props } from '@ngrx/store';
import { Page } from '@shared/data-access';
import {
    DesignSystem,
    OverviewDesignSystemDto,
    SelectedFacets,
    UpdateColorDefinitionDto,
    UpdateDesignsystemDto,
    UpdateTypographyDto,
} from '@backoffice/data-access/editor';

export const findDesignSystem = createAction('[ACTION] fetch design system', props<{ id: string }>());
export const fetchDesignSystemSuccess = createAction(
    '[EFFECT] fetch design system success',
    props<{
        designSystem: DesignSystem;
    }>()
);
export const fetchDesignSystemError = createAction(
    '[ACTION] fetch design system error',
    props<{
        designSystem: DesignSystem;
    }>()
);

export const deleteDesignSystem = createAction('[ACTION] delete design system', props<{ id: string }>());
export const deleteDesignSystemSuccess = createAction('[EFFECT] delete design system success', props<{ id: string }>());

export const updateColorDefinition = createAction(
    '[ACTION] update color definition',
    props<{
        definition: UpdateColorDefinitionDto;
    }>()
);

export const updateTypography = createAction(
    '[ACTION] update typography',
    props<{
        typography: UpdateTypographyDto;
    }>()
);

export const updateDesignSystem = createAction(
    '[ACTION] update design system',
    props<{
        designSystem: UpdateDesignsystemDto;
    }>()
);
export const updateDesignSystemSuccess = createAction(
    '[EFFECT] update design system success',
    props<{
        id: string;
    }>()
);

export const createDesignSystemSuccess = createAction(
    '[EFFECT] create design system success',
    props<{
        id: string;
        openTab?: boolean;
    }>()
);

// Editor Dialog Actions

export const clearDesignSystemDialogData = createAction('[ACTION] design system editor dialog data cleared');
export const clearDesignSystemDialogFilter = createAction('[ACTION] design system editor dialog filter cleared');

export const deleteDesignSystems = createAction('[ACTION] delete design systems', props<{ ids: string[] }>());
export const deleteDesignSystemsSuccess = createAction(
    '[EFFECT] delete design systems success',
    props<{
        ids: string[];
    }>()
);

export const loadDesignSystemDialogData = createAction('[ACTION] load design system editor dialog data');
export const loadDesignSystemDialogSuccess = createAction(
    '[EFFECT] load design system editor dialog data success',
    props<{ data: Page<OverviewDesignSystemDto> }>()
);

export const designSystemDialogPaginationChanged = createAction(
    '[ACTION] design system editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const designSystemDialogSearchTermChanged = createAction(
    '[ACTION] design system editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const designSystemDialogFacetsChanged = createAction(
    '[ACTION] design system editor dialog facets changed',
    props<{ facets: SelectedFacets }>()
);

export const designSystemDialogFilterPluginsChanged = createAction(
    '[ACTION] design system editor dialog filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
