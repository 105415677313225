import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [
    {
        path: 'codex',
        loadChildren: () => import('./authenticated/authenticated.module').then(m => m.AuthenticatedModule),
    },
    {
        path: 'status',
        loadChildren: () => import('./status/status.module').then(m => m.StatusModule),
    },
];

export const appRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
});
