export class Api {
    public readonly id: string;
    public name: string;
    public description: string;
    public iconName: string;
    public apiMethod: string;
    public domainName: string;
    public endpoint: string;
    public companyId: string;
    public applicationId: string;
    public actionId: string;
    public authenticatedAccess: boolean;

    public isValid(): boolean {
        return (
            !!this.id &&
            !!this.name &&
            this.name !== '' &&
            !!this.iconName &&
            this.iconName !== '' &&
            !!this.apiMethod &&
            this.apiMethod !== '' &&
            !!this.endpoint &&
            this.endpoint !== ''
        );
    }
}
