import { combineReducers, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import { selectApplicationSuccess } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { SelectedFacets } from '../../../dto/overview/facets.dto';
import { Page } from '@shared/data-access';
import { GroupDto } from 'libs/backoffice/data-access/editor/src/lib/group/dto/group.dto';
import { RightEditorState } from '../state/right-editor.state';
import { RightDialogState } from '../state/right-dialog.state';
import { OverviewRightDto } from '../../dto/overview-right.dto';
import {
    clearRightDialogData,
    clearRightDialogFilter,
    deleteRightSuccess,
    fetchRightSuccess,
    loadRightDialogSuccess,
    rightDialogFacetsChanged,
    rightDialogFilterPluginsChanged,
    rightDialogPaginationChanged,
    rightDialogSearchTermChanged,
    updateRightSuccess,
} from '../actions/right-editor.actions';

const adapterAction = createEntityAdapter<GroupDto>();
export const rightInitialState: RightEditorState = adapterAction.getInitialState();
export const rightDialogState: RightDialogState = {
    page: 0,
    maxResults: 10,
    facets: new SelectedFacets(),
    filterPlugins: false,
};

export const rightReducer = (initialData: RightEditorState) =>
    createReducer(
        initialData,
        on(fetchRightSuccess, (state, { right }) => adapterAction.addOne(right, state)),
        on(deleteRightSuccess, (state, { id }) => adapterAction.removeOne(id, state)),
        on(updateRightSuccess, (state, { right }) => adapterAction.updateOne({ id: right.id, changes: right }, state)),
        on(selectApplicationSuccess, state => adapterAction.removeAll(state))
    );

const facetsReducer = (initialData: SelectedFacets | undefined) =>
    createReducer(
        initialData,
        on(rightDialogFacetsChanged, (state, { facets }) => facets),
        on(clearRightDialogFilter, () => new SelectedFacets())
    );

const filterPluginsReducer = (initialData: boolean | undefined) =>
    createReducer(
        initialData,
        on(rightDialogFilterPluginsChanged, (state, { filterPlugins }) => filterPlugins),
        on(clearRightDialogFilter, () => false)
    );

const pageReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(rightDialogPaginationChanged, (_, { page }) => page),
        on(rightDialogSearchTermChanged, () => 0),
        on(clearRightDialogFilter, () => 0)
    );

const maxResultsReducer = (initialData: number) =>
    createReducer(
        initialData,
        on(rightDialogPaginationChanged, (_, { maxResults }) => maxResults),
        on(clearRightDialogFilter, () => 10)
    );

const searchTermReducer = (initialData: string | undefined) =>
    createReducer(
        initialData,
        on(rightDialogSearchTermChanged, (_, { searchTerm }) => searchTerm),
        on(clearRightDialogFilter, () => undefined)
    );

const resultReducer = (initialData: Page<OverviewRightDto> | undefined) =>
    createReducer(
        initialData,
        on(loadRightDialogSuccess, (_, { data }) => data),
        on(clearRightDialogData, () => undefined)
    );

export const rightDialogReducers = combineReducers({
    page: pageReducer(rightDialogState.page),
    maxResults: maxResultsReducer(rightDialogState.maxResults),
    searchTerm: searchTermReducer(rightDialogState.searchTerm),
    result: resultReducer(rightDialogState.result),
    facets: facetsReducer(rightDialogState.facets),
    filterPlugins: filterPluginsReducer(rightDialogState.filterPlugins),
});
