import { initFlowbite } from 'flowbite';
import { concatMap, debounceTime, of, Subject, throttleTime } from 'rxjs';

let flowbiteQueue = new Subject<any>();

let mutationObserver: MutationObserver;

flowbiteQueue.pipe(concatMap(item => of(item).pipe(debounceTime(500)))).subscribe(() => {
    const elements = document.querySelectorAll('*');
    const flowbiteElements = [];
    const initializedElements = Array.from(document.querySelectorAll('[flowbite-initialized]'));

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const attributes = element.attributes;

        for (let j = 0; j < attributes.length; j++) {
            const attribute = attributes[j];

            if (attribute.name.startsWith('data-')) {
                // add to the flowbiteElements array if it doesn't exist
                if (!flowbiteElements.includes(element) && !initializedElements.find(x => x.isEqualNode(element))) {
                    flowbiteElements.push(element);
                }
            }
        }
    }

    if (flowbiteElements.length > 0) {
        // add an attribute to the element to indicate that it has been initialized
        for (let i = 0; i < flowbiteElements.length; i++) {
            const element = flowbiteElements[i];
            element.setAttribute('flowbite-initialized', '');
        }
        initFlowbite();

        flowbiteElements.forEach(element => {
            const attributes: { name: string; value: string }[] = Array.from(element.attributes);
            const dataAttributes = attributes.filter(attribute => attribute.name.startsWith('data-'));

            dataAttributes.forEach(attribute => {
                element.setAttribute(attribute.name.replace('data-', 'fb-'), attribute.value);
                element.removeAttribute(attribute.name);
            });
        });
    } else {
    }
});

export function FlowbiteNow() {
    return function (target: any) {
        const originalOnInit = target.prototype.ngOnInit;
        target.prototype.ngOnInit = function () {
            if (originalOnInit) {
                originalOnInit.apply(this);
            }
            mutationObserver = new MutationObserver(() => {
                flowbiteQueue.next({});
            });
            mutationObserver.observe(document.body, {
                childList: true,
                attributes: false,
                subtree: true,
            });
        };
        const originalOnDestroy = target.prototype.originalOnDestroy;
        target.prototype.originalOnDestroy = function () {
            if (originalOnDestroy) {
                originalOnDestroy.apply(this);
            }
            if (mutationObserver) {
                mutationObserver.disconnect();
            }
        };
    };
}
