export class FontStyle {
    keyword: 'NORMAL' | 'ITALIC' | 'OBLIQUE' | 'INHERIT' | 'INITIAL' | 'REVERT' | 'REVERTLAYER' | 'UNSET';
    angle: number | undefined;

    constructor(
        keyword: 'NORMAL' | 'ITALIC' | 'OBLIQUE' | 'INHERIT' | 'INITIAL' | 'REVERT' | 'REVERTLAYER' | 'UNSET',
        angle: number | undefined
    ) {
        this.keyword = keyword;
        this.angle = angle;
    }
}
