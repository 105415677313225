<label for="types" class="small-input-label">{{ 'v2.data-format.attribute.dataclassification' | translate }}</label>
<select id="types" class="small-input" (input)="this.change.emit()" [(ngModel)]="this.attribute.dataClassification">
    <option value="SECRET">{{ 'v2.data-format.attribute.dataclassifications.secret' | translate }}</option>
    <option value="MEDICAL">{{ 'v2.data-format.attribute.dataclassifications.medical' | translate }}</option>
    <option value="CONFIDENTIAL">{{ 'v2.data-format.attribute.dataclassifications.confidential' | translate }}</option>
    <option value="RESTRICTED">{{ 'v2.data-format.attribute.dataclassifications.restricted' | translate }}</option>
    <option value="UNRESTRICTED">{{ 'v2.data-format.attribute.dataclassifications.unrestricted' | translate }}</option>
    <option value="PUBLIC">{{ 'v2.data-format.attribute.dataclassifications.public' | translate }}</option>
</select>

<div class="property__properties">
    <div class="flex gap-1 justify-end mb-2">
        <h2>Array type</h2>
        <button
            type="button"
            class="primary-button button-medium"
            (click)="onDeclareArrayTypeClicked()"
            *ngIf="this.attribute.types.indexOf('array') > -1 && !this.attribute?.arrayDefinition">
            <mat-icon>add</mat-icon>
            <span>Declare array type</span>
        </button>
    </div>
    <codex-json-schema-array-item
        *ngIf="this.attribute.arrayDefinition"
        [attribute]="this.attribute"
        [item]="this.attribute.arrayDefinition"
        (delete)="onDeleteItem($event)"
        (change)="onArrayItemChanged($event)"></codex-json-schema-array-item>
</div>
