import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';
import { GenericStyleV2Dto } from '../../../../../interfaces/style/generic-style-v2.dto';
import { GenericMarginStyleDto } from '../../../../../interfaces/style/generic-margin.style.dto';
import { ColorV2 } from '../../../../../designsystem/models/color/colorv2.model';

export interface SlideToggleFieldStyle extends GenericStyleV2Dto {
    labelFontStyle: FontStyleDto;
    labelMarginStyle: GenericMarginStyleDto;

    slideToggleColor?: string;
    slideToggleColorV2?: ColorV2;

    slideBarColor?: string;
    slideBarColorV2?: ColorV2;
}
