import { AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { GUIDFunctions } from '../../../../../../../../libs/shared/utils/src/lib/guid/guid';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';

declare var tinymce: any;
declare var $: any;

// Tinymce directive
@Directive({
    inputs: ['htmlEditor'],
    selector: '[htmlEditor]',
})
export class CdEditorDirective implements AfterViewInit {
    @Input()
    set text(text: string) {
        this._text = text;
        //if (this.getEditor()) {
        //  this.getEditor().setContent(this._text ? this._text : '');
        //}
    }

    _text: string;
    editor: any;

    @Output() update = new EventEmitter();

    @Input()
    readOnly: boolean = false;

    @HostBinding('id') id: string = 'text';

    constructor(
        private elementRef: ElementRef,
        private guidFunctions: GUIDFunctions
    ) {
        this.id = 'text-' + guidFunctions.newGuid();
    }

    ngAfterViewInit(): void {
        this.elementRef.nativeElement.innerHTML = this._text ? this._text : '';
        tinymce.init({
            selector: `#${this.id}`,
            inline: true,
            schema: 'html5-strict',
            element_format: 'xhtml',
            encoding: 'xml',
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen placeholders',
                'insertdatetime media table contextmenu paste textcolor hr',
            ],
            toolbar:
                'insertfile undo redo | styleselect fontsizeselect | bold italic | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor',
            valid_elements:
                'img[src|alt|width|height],span[class|attrid|typecode|contenteditable|style],select[class|style],option[class|style],p[class|style],div[class|style],h1[class|style],h2[class|style],' +
                'h3[class|style],h4[class|style],h5[class|style],h6[class|style],ul[class|style],a[class|style|href|target],' +
                'li[class|style],ol[class|style],b[class|style],br[class|style],strong[class|style],table[class|style|border|cellspacing],tbody[class|style|border|cellspacing],' +
                'th[class|style|border|cellspacing],tr[class|style|border|cellspacing|rowspan],td[class|style|border|cellspacing|colspan|rowspan],pre[class|style]',
            contextmenu: 'placeholders choices',
            closed: /^(input)$/,
            entity_encoding: 'raw',
            fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
            readonly: this.readOnly ? 1 : 0,
        });

        const editor = this.getEditor();

        editor.on('keyup', e => {
            this.update.emit(editor.getContent());
        });

        editor.on('focus', function () {
            $('#' + this.id)
                .find('.initial-placeholder')
                .remove();
        });

        $('.tinymce-editor')
            .find('option.selectedOption')
            .each(function () {
                $(this).attr('selected', true);
            });

        if (this.getEditor()) {
            this.getEditor().setContent(this._text ? this._text : '');
        }
        this.bindChoicesEvents();
    }

    bindChoicesEvents(): void {
        $('select.choices').on('change', function (e: any): void {
            const optionSelected = $('option:selected', this);

            $(this)
                .find('option.selectedOption')
                .each(function () {
                    if (optionSelected !== this) {
                        $(this).removeClass('selectedOption');
                        $(this).removeAttr('selected');
                    }
                });
            optionSelected.attr('selected', 'selected');
            optionSelected.prop('selected', true);
            optionSelected.addClass('selectedOption');
        });
    }

    getEditor() {
        if (!Boolean(this.editor)) {
            this.editor = tinymce.get(this.id);
        }
        return this.editor;
    }
}
