import { PartStyle } from '../../../part-style.model';
import { BackgroundStyleDto } from '../../../../../interfaces/style/background.style.dto';
import { ContainerStyleDto } from '../../../../../interfaces/style/container.style.dto';
import { GridStyleDto } from '../../../../../interfaces/grid/grid.style.dto';

export class PlanePartStyle extends PartStyle {
    backgroundStyle: BackgroundStyleDto;

    normalStyle: ContainerStyleDto;

    hoverStyle: ContainerStyleDto;

    grid: GridStyleDto;
}
