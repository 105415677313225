import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { BillingPackageService } from '@add-company/service/billingpackage.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'codex-choose-package',
    templateUrl: './choose-package.component.html',
    styleUrls: ['./choose-package.component.scss'],
})
export class ChoosePackageComponent implements OnInit {
    company: CompanyDto;

    showFree: boolean = true;

    couponCode: string;

    amountOfCores: number = 1;

    yearly: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<ChoosePackageComponent>,
        public billingPackageService: BillingPackageService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.company = this.data.company;
        this.showFree = this.data.showFree;
    }

    initiateBillingPackage(packageName: string) {
        this.billingPackageService
            .initiateUpdateBillingPackage(this.company, packageName, this.amountOfCores, this.couponCode, this.yearly)
            .pipe(take(1))
            .subscribe(result => {
                window.location.href = result.redirectUrl;
            });
    }
}
