// TODO: model should not have been used in part styles
export class Color {
    reference?: string;
    type: 'RGB' | 'HSV' | 'HSL';
    value: string;

    constructor(base?: Partial<Color>) {
        Object.assign(this, base);
    }
}
