export class FontWeight {
    value: number;
    keyword: 'NORMAL' | 'BOLD' | 'LIGHTER' | 'BOLDER' | 'INHERIT' | 'INITIAL' | 'REVERT' | 'REVERTLAYER' | 'UNSET';

    constructor(
        value: number,
        keyword: 'NORMAL' | 'BOLD' | 'LIGHTER' | 'BOLDER' | 'INHERIT' | 'INITIAL' | 'REVERT' | 'REVERTLAYER' | 'UNSET'
    ) {
        this.value = value;
        this.keyword = keyword;
    }
}
