import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { LoggerService } from '../../../../../../utils/src/lib/services/logger.service';
import { apiEditorSelectors } from '../selectors/api-editor.selectors';
import { Api } from '../../api/model/api';
import {
    apiDialogFacetsChanged,
    apiDialogFilterPluginsChanged,
    apiDialogPaginationChanged,
    apiDialogSearchTermChanged,
    clearApiDialogData,
    createApiSuccess,
    deleteApis,
    findApi,
    loadApiDialogData,
    updateApi,
} from '../actions/api-editor.actions';
import { AppFacade } from '@core/facades/app.facade';
import { ApiService } from '../../services/api.service';
import { DataFormatEditorFacade, SelectedFacets } from '@backoffice/data-access/editor';
import { Page } from '@shared/data-access';
import { OverviewApiDto } from '../../dto/overview/overview-api.dto';
import { backofficeEnvironment } from '@shared/environment';
import { createTemplateSuccess } from '../actions/templates-editor.actions';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ApiEditorFacade {
    get dialogData(): Observable<Page<OverviewApiDto> | undefined> {
        return this.store.select(apiEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(apiEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(apiEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(apiEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(apiEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{ searchTerm: string | undefined; page: number; maxResults: number; facets: SelectedFacets; filterPlugins }> {
        return this.store.select(apiEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly apiService: ApiService,
        private readonly dataFormatFacade: DataFormatEditorFacade,
        private readonly appFacade: AppFacade,
        private readonly log: LoggerService,
        private readonly dialog: MatDialog
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearApiDialogData());
    }

    changeTypeToGenerateFromDataFormat(type: string) {
        if (type === 'GENERATE_FIND_FROM_TEXT') {
            return 'GENERATE_FIND_FROM_DATAFORMAT';
        } else if (type === 'GENERATE_DELETE_FROM_TEXT') {
            return 'GENERATE_DELETE_FROM_DATAFORMAT';
        } else if (type === 'GENERATE_UPDATE_FROM_TEXT') {
            return 'GENERATE_UPDATE_FROM_DATAFORMAT';
        } else if (type === 'GENERATE_READ_FROM_TEXT') {
            return 'GENERATE_READ_FROM_DATAFORMAT';
        } else if (type === 'GENERATE_CREATE_FROM_TEXT') {
            return 'GENERATE_CREATE_FROM_DATAFORMAT';
        } else if (type === 'GENERATE_CRUD_FROM_TEXT') {
            return 'GENERATE_CRUD_FROM_DATAFORMAT';
        }
    }
    create(componentType: ComponentType<any>): Observable<string> {
        this.log.info('Create new api');
        return combineLatest([
            this.appFacade.selectedCompany,
            this.appFacade.selectedApplication,
            this.dialog
                .open(componentType, Object.assign({ disableClose: true }, backofficeEnvironment.dialogConfig.extrasmall))
                .afterClosed(),
        ]).pipe(
            take(1),
            filter(([company, application, { create }]) => !!create && !!company && !!application),
            switchMap(([{ id: companyId }, { id: applicationId }, { type, name, dataFormatIds, dataFormatDescription }]) => {
                if (
                    type === 'GENERATE_FIND_FROM_TEXT' ||
                    type === 'GENERATE_DELETE_FROM_TEXT' ||
                    type === 'GENERATE_UPDATE_FROM_TEXT' ||
                    type === 'GENERATE_READ_FROM_TEXT' ||
                    type === 'GENERATE_CREATE_FROM_TEXT' ||
                    type === 'GENERATE_CRUD_FROM_TEXT'
                ) {
                    return this.dataFormatFacade
                        .createDataFormat(companyId, applicationId, 'GENERATE_FROM_TEXT', 'null', dataFormatDescription, null, false)
                        .pipe(
                            switchMap(createdDataFormatIds => {
                                return this.apiService
                                    .create({
                                        companyId,
                                        applicationId,
                                        type: this.changeTypeToGenerateFromDataFormat(type),
                                        dataFormatIds: createdDataFormatIds,
                                        name,
                                    })
                                    .pipe(
                                        tap(({ id, generationResponse }) => {
                                            if (
                                                !!generationResponse &&
                                                !!generationResponse.generatedApis &&
                                                generationResponse.generatedApis.length > 0
                                            ) {
                                                generationResponse.generatedApis.forEach(generatedApi => {
                                                    this.store.dispatch(createApiSuccess({ id: generatedApi }));
                                                });
                                            }
                                        }),
                                        map(({ id }) => id)
                                    );
                            })
                        );
                } else {
                    return this.apiService
                        .create({
                            companyId,
                            applicationId,
                            type,
                            dataFormatIds,
                            name,
                        })
                        .pipe(
                            tap(({ id, generationResponse }) => {
                                if (type === 'BLANK') {
                                    this.store.dispatch(createApiSuccess({ id }));
                                } else if (
                                    !!generationResponse &&
                                    !!generationResponse.generatedApis &&
                                    generationResponse.generatedApis.length > 0
                                ) {
                                    generationResponse.generatedApis.forEach(generatedApi => {
                                        this.store.dispatch(createApiSuccess({ id: generatedApi }));
                                    });
                                }
                            }),
                            map(({ id }) => id)
                        );
                }
            })
        );
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(apiDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(apiDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(apiDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(apiDialogFilterPluginsChanged({ filterPlugins }));
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting api`, [ids]);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteApis({ ids }));
        }
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadApiDialogData());
    }

    findById(id: string): Observable<Api> {
        this.log.info(`Find api by id ${id}`);
        this.store.dispatch(findApi({ id }));
        return this.store.select(apiEditorSelectors.byId(id)).pipe(
            filter(api => !!api),
            map(api => api as Api)
        );
    }

    update(api: Api): void {
        this.log.info(`Update api`, [api]);
        this.store.dispatch(updateApi({ api }));
    }
}
