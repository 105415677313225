import { NgModule } from '@angular/core';
import { ApplicationEditComponent } from './components/application-edit/application-edit.component';
import { CommonsModule } from '../../app/common/common.module';
import { V2SharedModule } from '../../app/v2-shared/v2-shared.module';
import { ApplicationEditGeneralComponent } from './components/application-edit-general/application-edit-general.component';
import { ApplicationEditMetaComponent } from './components/application-edit-meta/application-edit-meta.component';
import { ApplicationEditErrorComponent } from './components/application-edit-error/application-edit-error.component';
import { BackofficeUiArgumentsModule } from '@backoffice/ui-arguments';
import { BackofficeDataAccessEditorModule } from '@backoffice/data-access/editor';
import { BackofficeFeatureEditorPickersModule } from 'backoffice/feature/editor/pickers';

@NgModule({
    declarations: [ApplicationEditComponent, ApplicationEditGeneralComponent, ApplicationEditMetaComponent, ApplicationEditErrorComponent],
    imports: [
        CommonsModule,
        V2SharedModule,
        BackofficeUiArgumentsModule,
        BackofficeDataAccessEditorModule,
        BackofficeDataAccessEditorModule,
        BackofficeFeatureEditorPickersModule,
    ],
    exports: [ApplicationEditComponent],
})
export class EditApplicationModule {}
