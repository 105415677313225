<div>
    <div *ngIf="formGroup" [formGroup]="formGroup">
        <label class="toggle-line">
            {{ 'v2.authorization.authenticated.access' | translate }}
            <input type="checkbox" value="" class="sr-only peer" formControlName="authenticatedAccess" (change)="onAuthenticatedAccess()" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>
    </div>

    <div class="authentications flex flex-col justify-center items-center" *ngIf="this.authenticatedAccess">
        <ng-container *ngIf="{ authorizationLinks: authorizationLinks$ | async } as observables">
            <ng-container *ngIf="!observables.authorizationLinks || observables.authorizationLinks.length === 0">
                <img class="w-[350px] h-[150px] object-contain" src="/src/images/theme/authorization_animation.gif" />
                <h1 class="text-center !mb-2">We couldn't find any authorization rules.</h1>
                <p class="!mb-8 text-white text-center">
                    Users have to be authenticated to use this api but do not need to have any special rights or belong to any particular
                    group. Start building securing your application even further by adding a new authorization rule.
                </p>
                <button type="button" class="primary-button button-extra-large" (click)="createNewAuthorization()">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
                    </svg>
                    Create new authorization rule
                </button>
            </ng-container>
            <div class="w-full" *ngIf="observables.authorizationLinks && observables.authorizationLinks.length > 0" [formGroup]="formGroup">
                <div class="title-button-header">
                    <h1></h1>
                    <div class="title-button-header-buttons">
                        <button class="primary-button button-medium" (click)="createNewAuthorization()" type="button">
                            <mat-icon>add_circle</mat-icon>
                            {{ 'v2.authorization.addnew' | translate }}
                        </button>
                    </div>
                </div>
                <ul class="item-list">
                    <li *ngFor="let authorizationLink of observables.authorizationLinks; trackBy: identifyAuthorizationLink">
                        <div class="item-name">{{ authorizationLink.name }}</div>
                        <mat-icon
                            [matTooltip]="'v2.authorization.edit' | translate"
                            class="simple-icon-button"
                            (click)="onEditAuthorization(authorizationLink)"
                            >edit
                        </mat-icon>
                        <mat-icon
                            [matTooltip]="'v2.authorization.remove' | translate"
                            class="simple-icon-button simple-destructive-icon-button"
                            (click)="onDeleteAuthorization(authorizationLink)"
                            >clear
                        </mat-icon>
                    </li>
                </ul>
            </div>
        </ng-container>
    </div>
</div>
