import { ApplicationState } from '../../../store/application.state';
import { createSelector } from '@ngrx/store';
import { ApplicationDto } from '../../dto/application.dto';

export const selectSelectedApplication = (state: ApplicationState) => state.context.selectedApplication;
export const selectSelectedApplicationId = createSelector(selectSelectedApplication, (selectedApplication: ApplicationDto): string => {
    if (selectedApplication) {
        return selectedApplication.id;
    }
});

export const selectApplications = (state: ApplicationState) => state.context.applications;
