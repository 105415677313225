import { Component, EventEmitter, Input, Output } from '@angular/core';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';
import { Media } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-media-overview-row-v2',
    templateUrl: './media-overview-row.component.html',
    styleUrls: ['./media-overview-row.component.scss'],
})
export class MediaOverviewRowComponent {
    @Input() media: Media;

    @Output() clicked = new EventEmitter<void>();
    @Output() boxSelectionChanged = new EventEmitter<boolean>();

    checkboxSelected = false;
    environment: BackofficeEnvironmentDto = backofficeEnvironment;

    onClicked(): void {
        this.clicked.emit();
    }

    onCheckboxSelected() {
        this.checkboxSelected = !this.checkboxSelected;
        this.boxSelectionChanged.emit(this.checkboxSelected);
    }
}
