<div class="button-group" role="group">
    <button
        [matTooltip]="'v2.part.style.border.all' | translate"
        type="button"
        class="button-group-button-left"
        autofocus
        (click)="borderType = 'all'"
        [ngClass]="borderType === 'all' ? 'button-group-button-selected' : ''">
        <mat-icon>border_outer</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.border.top' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="borderType = 'top'"
        [ngClass]="borderType === 'top' ? 'button-group-button-selected' : ''">
        <mat-icon>border_top</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.border.left' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="borderType = 'left'"
        [ngClass]="borderType === 'left' ? 'button-group-button-selected' : ''">
        <mat-icon>border_left</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.border.bottom' | translate"
        type="button"
        class="button-group-button-middle"
        autofocus
        (click)="borderType = 'bottom'"
        [ngClass]="borderType === 'bottom' ? 'button-group-button-selected' : ''">
        <mat-icon>border_bottom</mat-icon>
    </button>
    <button
        [matTooltip]="'v2.part.style.border.right' | translate"
        type="button"
        class="button-group-button-right"
        autofocus
        (click)="borderType = 'right'"
        [ngClass]="borderType === 'right' ? 'button-group-button-selected' : ''">
        <mat-icon>border_right</mat-icon>
    </button>
</div>

<div class="input-top" *ngIf="borderType === 'all'">
    <div class="flex flex-row justify-between gap-2 items-center">
        <label for="select-bordertype" class="small-input-label">{{ 'v2.part.style.border.type' | translate }}</label>
        <select
            id="select-bordertype"
            class="small-input max-w-[170px]"
            [(ngModel)]="_borderStyle.borderType"
            (ngModelChange)="onChangeAllBorders()">
            <option *ngFor="let borderType of borderTypes" [value]="borderType">{{ borderType }}</option>
        </select>
    </div>

    <div class="small-color-picker-wrapper">
        <label class="small-input-label">{{ 'v2.part.style.border.color' | translate }}</label>
        <nocodex-color-picker-v2
            class="small-color-picker"
            [hex]="_borderStyle.color ? _borderStyle.color.value : null"
            [colorV2]="!!_borderStyle.colorV2 ? _borderStyle.colorV2 : null"
            (colorChangedV2)="onChangeColor($event)"></nocodex-color-picker-v2>
    </div>

    <codex-pixel-selector
        [value]="_borderStyle.borderSize"
        [label]="'v2.part.style.border.size' | translate"
        (changePixels)="_borderStyle.borderSize = $event.value; onChangeAllBorders()"></codex-pixel-selector>
    <codex-pixel-selector
        [value]="_borderStyle.borderRadius"
        [label]="'v2.part.style.border.radius' | translate"
        (changePixels)="_borderStyle.borderRadius = $event.value; onChangeAllBorders()"></codex-pixel-selector>
</div>

<div *ngIf="borderType === 'top'">
    <div class="flex flex-row justify-between gap-2 items-center">
        <label for="select-top-bordertype" class="small-input-label">{{ 'v2.part.style.border.type' | translate }}</label>
        <select
            id="select-top-bordertype"
            class="small-input max-w-[170px]"
            [(ngModel)]="_borderTopStyle.borderType"
            (ngModelChange)="onChangeAllBorders()">
            <option *ngFor="let borderType of borderTypes" [value]="borderType">{{ borderType }}</option>
        </select>
    </div>
    <div class="small-color-picker-wrapper">
        <label class="small-input-label">{{ 'v2.part.style.border.color' | translate }}</label>
        <nocodex-color-picker-v2
            class="small-color-picker"
            [hex]="_borderTopStyle.color ? _borderTopStyle.color.value : null"
            [colorV2]="!!_borderTopStyle.colorV2 ? _borderTopStyle.colorV2 : null"
            (colorChangedV2)="onChangeTopColor($event)"></nocodex-color-picker-v2>
    </div>
    <codex-pixel-selector
        [value]="_borderTopStyle.borderSize"
        [label]="'v2.part.style.border.size' | translate"
        (changePixels)="_borderTopStyle.borderSize = $event.value; onChangeAllBorders()"></codex-pixel-selector>
    <codex-pixel-selector
        [value]="_borderTopStyle.borderRadius"
        [label]="'v2.part.style.border.radius' | translate"
        (changePixels)="_borderTopStyle.borderRadius = $event.value; onChangeAllBorders()"></codex-pixel-selector>
</div>

<div *ngIf="borderType === 'left'">
    <div class="flex flex-row justify-between gap-2 items-center">
        <label for="select-left-bordertype" class="small-input-label">{{ 'v2.part.style.border.type' | translate }}</label>
        <select
            id="select-left-bordertype"
            class="small-input max-w-[170px]"
            [(ngModel)]="_borderLeftStyle.borderType"
            (ngModelChange)="onChangeAllBorders()">
            <option *ngFor="let borderType of borderTypes" [value]="borderType">{{ borderType }}</option>
        </select>
    </div>
    <div class="small-color-picker-wrapper">
        <label class="small-input-label">{{ 'v2.part.style.border.color' | translate }}</label>
        <nocodex-color-picker-v2
            class="small-color-picker"
            [hex]="_borderLeftStyle.color ? _borderLeftStyle.color.value : null"
            [colorV2]="!!_borderLeftStyle.colorV2 ? _borderLeftStyle.colorV2 : null"
            (colorChangedV2)="onChangeLeftColor($event)">
        </nocodex-color-picker-v2>
    </div>
    <codex-pixel-selector
        [value]="_borderLeftStyle.borderSize"
        [label]="'v2.part.style.border.size' | translate"
        (changePixels)="_borderLeftStyle.borderSize = $event.value; onChangeAllBorders()"></codex-pixel-selector>
    <codex-pixel-selector
        [value]="_borderLeftStyle.borderRadius"
        [label]="'v2.part.style.border.radius' | translate"
        (changePixels)="_borderLeftStyle.borderRadius = $event.value; onChangeAllBorders()"></codex-pixel-selector>
</div>

<div *ngIf="borderType === 'right'">
    <div class="flex flex-row justify-between gap-2 items-center">
        <label for="select-right-bordertype" class="small-input-label">{{ 'v2.part.style.border.type' | translate }}</label>
        <select
            id="select-right-bordertype"
            class="small-input max-w-[170px]"
            [(ngModel)]="_borderRightStyle.borderType"
            (ngModelChange)="onChangeAllBorders()">
            <option *ngFor="let borderType of borderTypes" [value]="borderType">{{ borderType }}</option>
        </select>
    </div>
    <div class="small-color-picker-wrapper">
        <label class="small-input-label">{{ 'v2.part.style.border.color' | translate }}</label>
        <nocodex-color-picker-v2
            class="small-color-picker"
            [hex]="_borderRightStyle.color ? _borderRightStyle.color.value : null"
            [colorV2]="!!_borderRightStyle.colorV2 ? _borderRightStyle.colorV2 : null"
            (colorChangedV2)="onChangeRightColor($event)"></nocodex-color-picker-v2>
    </div>
    <codex-pixel-selector
        [value]="_borderRightStyle.borderSize"
        [label]="'v2.part.style.border.size' | translate"
        (changePixels)="_borderRightStyle.borderSize = $event.value; onChangeAllBorders()"></codex-pixel-selector>
    <codex-pixel-selector
        [value]="_borderRightStyle.borderRadius"
        [label]="'v2.part.style.border.radius' | translate"
        (changePixels)="_borderRightStyle.borderRadius = $event.value; onChangeAllBorders()"></codex-pixel-selector>
</div>

<div *ngIf="borderType === 'bottom'">
    <div class="flex flex-row justify-between gap-2 items-center">
        <label for="select-bottom-bordertype" class="small-input-label">{{ 'v2.part.style.border.type' | translate }}</label>
        <select
            id="select-bottom-bordertype"
            class="small-input max-w-[170px]"
            [(ngModel)]="_borderBottomStyle.borderType"
            (ngModelChange)="onChangeAllBorders()">
            <option *ngFor="let borderType of borderTypes" [value]="borderType">{{ borderType }}</option>
        </select>
    </div>
    <div class="small-color-picker-wrapper">
        <label class="small-input-label">{{ 'v2.part.style.border.color' | translate }}</label>
        <nocodex-color-picker-v2
            class="small-color-picker"
            [hex]="_borderBottomStyle.color ? _borderBottomStyle.color.value : null"
            [colorV2]="!!_borderBottomStyle.colorV2 ? _borderBottomStyle.colorV2 : null"
            (colorChangedV2)="onChangeBottomColor($event)"></nocodex-color-picker-v2>
    </div>
    <codex-pixel-selector
        [value]="_borderBottomStyle.borderSize"
        [label]="'v2.part.style.border.size' | translate"
        (changePixels)="_borderBottomStyle.borderSize = $event.value; onChangeAllBorders()"></codex-pixel-selector>
    <codex-pixel-selector
        [value]="_borderBottomStyle.borderRadius"
        [label]="'v2.part.style.border.radius' | translate"
        (changePixels)="_borderBottomStyle.borderRadius = $event.value; onChangeAllBorders()"></codex-pixel-selector>
</div>
