export class SelectedFacets extends Map<string, string> {
    toFilter(): string[] {
        const filters = [];
        for (const [key, value] of this) {
            filters.push(`${key}:"${value}"`);
        }
        return filters;
    }

    toJSON(): string {
        return JSON.stringify(this.toFilter());
    }
}

export function toFilter(facets: SelectedFacets, filterPlugins: boolean) {
    const filters = [];
    if (facets) {
        for (const [key, value] of facets) {
            filters.push(`${key}:"${value}"`);
        }
    }
    if (filterPlugins) {
        filters.push(`nativeElement:${filterPlugins}`);
    }
    return filters;
}
