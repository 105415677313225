import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger as MatMenuTrigger } from '@angular/material/menu';
import { UserDto } from '../../../../../../../feature/company/edit-company/src/lib/dto/deprecated/user.dto';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, tap } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Page } from '@shared/data-access';
import { EditUserPermissionsComponent } from '../edit-user-permissions/edit-user-permissions.component';
import { ConfirmDialog } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, debounceTime, startWith, switchMap, take } from 'rxjs/operators';
import { UserFacade } from '@core/facades/user.facade';
import { FormBuilder, FormGroup } from '@angular/forms';
import { initFlowbite } from 'flowbite';
import { AuthorizationLinkComponent } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-shared/components/authorization-link/authorization-link.component';
import { CreateUserComponent } from '../create-user/create-user.component';

@Component({
    selector: 'codex-user-overview',
    templateUrl: './user-overview.component.html',
})
export class UserOverviewComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    usersContent$: Observable<Page<UserDto>>;

    @Output() userInvited = new EventEmitter();

    @Output() userDeleted = new EventEmitter();

    environment: string = 'DEV';

    readonly displayedColumns: string[] = ['email', 'firstName', 'lastName', 'actions'];

    private readonly _subscriptions = new Subscription();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    form: FormGroup;

    refreshPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        private readonly dialog: MatDialog,
        private userFacade: UserFacade,
        private confirmDialog: ConfirmDialog,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            filter: [''],
            environment: ['DEV'],
        });
    }

    ngOnDestroy(): void {
        this._subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.initUserTable();
    }

    initUserTable(): void {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        this.usersContent$ = combineLatest([
            this.sort.sortChange.pipe(startWith(null)),
            this.paginator.page.pipe(startWith(null)),
            this.form.get('filter').valueChanges.pipe(startWith(null), debounceTime(backofficeEnvironment.inputdebounce)),
            this.form.get('environment').valueChanges.pipe(startWith(null)),
            this.refreshPage,
        ]).pipe(
            switchMap(([sort, page, filter, env]) => {
                return this.userFacade
                    .findUsers(
                        this.sort.active + ' ' + this.sort.direction,
                        this.paginator.pageIndex,
                        this.paginator.pageSize,
                        filter ? filter : '*',
                        env ? env : 'DEV'
                    )
                    .pipe(
                        tap(() => setTimeout(() => initFlowbite())),
                        catchError(() => of(null))
                    );
            })
        );
    }

    openRolesAndPermissionsDialog(userId: string) {
        const dialogRef = this.dialog.open(
            EditUserPermissionsComponent,
            Object.assign(
                {
                    restoreFocus: false,
                    data: {
                        userId,
                        environment: this.form.get('environment').value ? this.form.get('environment').value : 'DEV',
                    },
                },
                backofficeEnvironment.dialogConfig.normal
            )
        );

        this._subscriptions.add(
            dialogRef.afterClosed().subscribe(() => {
                this.refreshPage.next(this.refreshPage.value + 1);
            })
        );
    }

    openCreateUserDialog() {
        const dialogRef = this.dialog.open(CreateUserComponent, Object.assign({}, backofficeEnvironment.dialogConfig.normal));
        this._subscriptions.add(
            dialogRef.afterClosed().subscribe(() => {
                this.refreshPage.next(this.refreshPage.value + 1);
            })
        );
    }

    deleteUser(companyUserId: string) {
        this.confirmDialog.showConfirmDialog(
            'v2.usermanagement.users.delete.title',
            'v2.usermanagement.users.delete.description',
            'v2.usermanagement.users.delete.ok',
            'v2.usermanagement.users.delete.cancel',
            'v2.usermanagement.users.delete.success.title',
            'v2.usermanagement.users.delete.description',
            () => {
                this.userFacade
                    .deleteUser(companyUserId, this.form.get('environment')?.value)
                    .pipe(take(1))
                    .subscribe(() => {
                        this.refreshPage.next(this.refreshPage.value + 1);
                    });
            }
        );
    }
}
