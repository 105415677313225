import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedComponent } from './pages/authenticated/authenticated.component';
import { AuthenticatedGuard } from '../common/guards/authenticated.guard';

export const authenticatedRoutes: Routes = [
    {
        path: '',
        component: AuthenticatedComponent,
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'company/:companyId',
        component: AuthenticatedComponent,
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'company/:companyId/application/:applicationId',
        component: AuthenticatedComponent,
        canActivate: [AuthenticatedGuard],
        children: [
            {
                path: '',
                redirectTo: 'editor',
                pathMatch: 'full',
            },
            {
                path: 'applications',
                loadChildren: () => import('../v2-application/application.module').then(m => m.ApplicationModule),
                canActivate: [AuthenticatedGuard],
            },
            {
                path: 'editor',
                loadChildren: () =>
                    import('../../../../../libs/backoffice/feature/editor/src/lib/backoffice-feature-editor.module').then(
                        m => m.BackofficeFeatureEditorModule
                    ),
                canActivate: [AuthenticatedGuard],
            },
            {
                path: 'billing',
                loadChildren: () => import('@billing/billing.module').then(m => m.BillingModule),
                canActivate: [AuthenticatedGuard],
            },
            {
                path: 'usermanagement',
                loadChildren: () => import('../../features/user-management/user-management.module').then(m => m.UserManagementModule),
                canActivate: [AuthenticatedGuard],
            },
            {
                path: 'tasks',
                loadChildren: () => import('@tasks/task.module').then(m => m.TaskModule),
                canActivate: [AuthenticatedGuard],
            },
        ],
    },
];

export const authenticatedRoutingModule = RouterModule.forChild(authenticatedRoutes);
