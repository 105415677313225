import 'reflect-metadata';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'search-result-loading-placeholder',
    styleUrls: ['./search-result-loading-placeholder.scss'],
    template: `
        <mat-card class="search-result">
            <mat-card-content>
                <div fxFlex="1 1 20px" class="search-result-checkbox-placeholder loading"></div>
                <div fxFlex="1 1 50px" class="search-result-type-placeholder loading"></div>
                <div fxFlex="1 1 auto" class="search-result-data-placeholder">
                    <div class="search-result-name-placeholder loading"></div>
                    <div class="search-result-metadata-placeholder loading"></div>
                    <div class="search-result-metadata-placeholder loading"></div>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultLoadingPlaceholderComponent {}
