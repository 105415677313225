import { Params } from '@angular/router';

export const routerStateKey = 'router';

export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
}

export interface RouterState {
    isRoot: boolean;
    route?: RouterStateUrl;
}

export const routerInitialState: RouterState = {
    isRoot: false,
    route: undefined,
};
