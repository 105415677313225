import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter, switchMap, take } from 'rxjs/operators';
import { Product } from '../models/product.model';
import { ProductService } from '@core/services/product.service';
import { CurrentContext } from '@backoffice/utils';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';

@Injectable()
export class MarketplaceFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.store.select(selectCurrentContext);

    constructor(
        private productService: ProductService,
        private store: Store<ApplicationState>
    ) {}

    public installLatestVersion(product: Product): Observable<void> {
        if (!!product) {
            return this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                switchMap(currentContext => {
                    return this.productService.install(product.id, {
                        applicationId: currentContext.selectedApplication.id,
                        productId: product.id,
                        versionId: product.versions[0].versionId,
                    });
                })
            );
        }
        return of();
    }

    public updateProduct(product: Product): Observable<void> {
        if (!!product) {
            return this.productService.update(product);
        }
        return of();
    }

    public uninstallProduct(id: string): Observable<void> {
        if (!!id) {
            return this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                switchMap(currentContext => this.productService.uninstall(id, currentContext.selectedApplication.id))
            );
        }
        return of();
    }

    public getProduct(productId: string): Observable<Product> {
        return this.productService.findById(productId);
    }
}
