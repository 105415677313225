import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CompanyDto } from '@shared/interfaces/company.dto';

@Component({
    selector: 'codex-select-search',
    templateUrl: './select-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSearchComponent implements OnInit, OnDestroy {
    @Input()
    value: string;

    @Input()
    placeHolder: string;

    @Input()
    showSearch: boolean = true;

    @Input()
    items: {
        label: string;
        value: string;
    }[] = [
        {
            label: 'Testje',
            value: 'test',
        },
        {
            label: 'Tristan',
            value: 'test',
        },
    ];

    openedDropdown: boolean = false;

    public filter$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public filteredItems$: BehaviorSubject<
        {
            label: string;
            value: string;
        }[]
    > = new BehaviorSubject<
        {
            label: string;
            value: string;
        }[]
    >([]);

    public subscriptions: Subscription = new Subscription();

    @Output() select: EventEmitter<{ label: string; value: string }> = new EventEmitter<{ label: string; value: string }>();

    @ViewChild('toggleButton') toggleButton: ElementRef;

    @ViewChild('menu') menu: ElementRef;

    constructor(
        private renderer: Renderer2,
        changeDetectorRef: ChangeDetectorRef
    ) {
        this.renderer.listen('window', 'click', (e: Event) => {
            /**
             * Only run when toggleButton is not clicked
             * If we don't check this, all clicks (even on the toggle button) gets into this
             * section which in the result we might never see the menu open!
             * And the menu itself is checked here, and it's where we check just outside of
             * the menu and button the condition abbove must close the menu
             */

            if (
                this.toggleButton &&
                this.menu &&
                e.target !== this.toggleButton.nativeElement &&
                !this.toggleButton.nativeElement.contains(e.target) &&
                e.target !== this.menu.nativeElement
            ) {
                this.openedDropdown = false;
                changeDetectorRef.detectChanges();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.filter$);
    }

    toggleDropdown() {
        this.openedDropdown = !this.openedDropdown;
    }

    getLabel(value: string) {
        let item = this.items.find(item => item.value === value);
        return item.label;
    }
}
