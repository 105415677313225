import { Component, Input } from '@angular/core';
import { ApplicationLogDto } from '../../../../../../data-access/application-logs/src';
import { EditorFacade } from '../../../../../../data-access/editor/src';

@Component({
    selector: 'codex-application-log-lines',
    templateUrl: './application-log-lines.component.html',
    styleUrls: ['application-log-lines.component.scss'],
})
export class ApplicationLogLinesComponent {
    expandedLogLines: string[] = [];

    @Input()
    logs: ApplicationLogDto[] = [];

    @Input()
    invocationId: string;

    constructor(private editorFacade: EditorFacade) {}

    expandToggle(logLine: ApplicationLogDto) {
        if (this.expandedLogLines.indexOf(logLine.id) > -1) {
            this.expandedLogLines.splice(this.expandedLogLines.indexOf(logLine.id), 1);
        } else {
            this.expandedLogLines.push(logLine.id);
        }
    }

    getIcon(level: string) {
        if (level === 'WARN') {
            return 'warning';
        } else if (level === 'ERROR') {
            return 'bug_report';
        } else if (level === 'DEBUG') {
            return 'bug_report';
        } else if (level === 'TRACE') {
            return 'info';
        } else if (level === 'INFO') {
            return 'info';
        }
        return 'info';
    }

    gotoAction(actionId: string, invocationId: string) {
        console.log(invocationId);
        this.editorFacade.registerTab({
            typeId: actionId,
            type: 'action',
            arguments: [{ key: 'errorInvocation', value: invocationId }],
        });
    }

    gotoTemplate(templateId: string) {
        this.editorFacade.registerTab({
            typeId: templateId,
            type: 'template',
        });
    }
}
