import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { startWith, switchMap } from 'rxjs/operators';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { matchSorter } from 'match-sorter';

@Component({
    selector: 'codex-application-selector-all',
    templateUrl: './application-selector-all.component.html',
    styleUrls: ['./application-selector-all.component.scss'],
})
export class ApplicationSelectorAllComponent implements OnInit {
    @Input() applications: ApplicationDto[];

    @Input() lastUsedApplications: ApplicationDto[];

    @Input() selectedApplication: ApplicationDto;

    selectWorkspaceForm: FormGroup;

    filteredApplications$: Observable<ApplicationDto[]>;

    constructor(
        private readonly dialogRef: MatDialogRef<ApplicationSelectorAllComponent>,
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.initWorkspaceSelectorForm();
        this.selectedApplication = this.data.selectedApplication;
        this.applications = this.data.applications;
        this.lastUsedApplications = this.data.lastUsedApplications;
        this.filteredApplications$ = this.selectWorkspaceForm.get('filterName').valueChanges.pipe(
            startWith(null),
            switchMap(filterValue => {
                if (filterValue && filterValue !== '') {
                    return of(matchSorter(this.applications, filterValue.toLowerCase(), { keys: ['name'] }));
                } else {
                    return of(this.applications);
                }
            })
        );
    }

    initWorkspaceSelectorForm() {
        this.selectWorkspaceForm = this.fb.group({
            filterName: [''],
        });
    }

    onApplicationSelect(application: ApplicationDto) {
        this.dialogRef.close({ application });
    }
}
