import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';

export const statusRoutes: Routes = [
    {
        path: '404',
        component: NotFoundPageComponent,
    },
];

export const statusRouting = RouterModule.forChild(statusRoutes);
