export class Scope {
    scopeItemByInvocation: Map<string, Map<string, ScopeItem>> = new Map();
    scopeItemsByInvocationAndByType: Map<string, Map<string, Map<string, ScopeItem>>> = new Map();

    public addScopeItem(invocationId: string, scopeItems: Map<string, ScopeItem>) {
        if (!this.scopeItemByInvocation.has(invocationId)) {
            this.scopeItemByInvocation.set(invocationId, new Map());
            this.scopeItemsByInvocationAndByType.set(invocationId, new Map());
        }
        this.scopeItemByInvocation.set(
            invocationId,
            new Map([...scopeItems.entries(), ...this.scopeItemByInvocation.get(invocationId).entries()])
        );
        scopeItems.forEach((scopeItem, key) => {
            const types = Scope.getMatchingTypes(scopeItem.type);
            types.forEach(type => {
                if (!this.scopeItemsByInvocationAndByType.get(invocationId).has(type)) {
                    this.scopeItemsByInvocationAndByType.get(invocationId).set(type, new Map());
                }
                this.scopeItemsByInvocationAndByType.get(invocationId).get(type).set(key, scopeItem);
            });
        });
    }

    public static getMatchingTypes(type: string) {
        if (type === 'STRING') {
            return [
                'VARIABLE',
                'STRING',
                'STATUS',
                'EMAIL',
                'PASSWORD',
                'JSON_FIELD',
                'DATE',
                'CONDITIONAL',
                'URL',
                'NUMBER',
                'PARTIAL_DATA',
                'DATA_FORMAT_ATTRIBUTE',
                'SELECT_ONE',
                'ACTION',
            ];
        } else if (type === 'DATE') {
            return ['VARIABLE', 'JSON_FIELD', 'DATE', 'CONDITIONAL', 'STRING', 'PARTIAL_DATA'];
        } else if (type === 'BOOLEAN') {
            return ['VARIABLE', 'BOOLEAN', 'STRING', 'CONDITIONAL', 'JSON_FIELD', 'PARTIAL_DATA'];
        } else if (type === 'NUMBER') {
            return ['VARIABLE', 'STRING', 'DOUBLE', 'NUMBER', 'JSON_FIELD', 'CONDITIONAL', 'PARTIAL_DATA'];
        } else if (type === 'DOUBLE') {
            return ['VARIABLE', 'STRING', 'DOUBLE', 'NUMBER', 'JSON_FIELD', 'CONDITIONAL', 'PARTIAL_DATA'];
        } else if (type === 'EMAIL') {
            return ['VARIABLE', 'STRING', 'EMAIL', 'JSON_FIELD', 'CONDITIONAL', 'PARTIAL_DATA'];
        } else if (type === 'URL') {
            return ['VARIABLE', 'STRING', 'URL', 'JSON_FIELD', 'CONDITIONAL', 'PARTIAL_DATA'];
        } else if (type === 'PASSWORD') {
            return ['VARIABLE', 'STRING', 'PASSWORD', 'JSON_FIELD', 'CONDITIONAL', 'PARTIAL_DATA'];
        } else if (type === 'ARRAY') {
            return ['VARIABLE', 'STRING', 'ARRAY', 'JSON_FIELD', 'MAP', 'CONDITIONAL', 'PARTIAL_DATA', 'OBJECT', 'ARRAY'];
        } else if (type === 'JSON') {
            return ['VARIABLE', 'STRING', 'JSON', 'JSON_FIELD', 'CONDITIONAL', 'PARTIAL_DATA'];
        } else if (type === 'JSON_NODE') {
            return ['VARIABLE', 'STRING', 'JSON', 'JSON_NODE', 'JSON_FIELD', 'CONDITIONAL', 'DATA_BODY', 'PARTIAL_DATA', 'OBJECT'];
        } else if (type === 'DATA') {
            return ['VARIABLE', 'STRING', 'DATA', 'CONDITIONAL', 'PARTIAL_DATA'];
        } else if (type === 'PAGE') {
            return ['VARIABLE', 'STRING', 'PAGE', 'ARRAY', 'OBJECT'];
        } else if (type === 'SELECT_ONE') {
            return ['VARIABLE', 'STRING', 'CONDITIONAL', 'SELECT_ONE', 'JSON_FIELD'];
        } else if (type === 'TEMPLATE') {
            return ['VARIABLE', 'STRING', 'TEMPLATE', 'CONDITIONAL'];
        } else if (type === 'DATA_FORMAT') {
            return ['VARIABLE', 'STRING', 'DATA_FORMAT'];
        } else if (type === 'PART') {
            return ['VARIABLE', 'PART', 'CONDITIONAL'];
        } else if (type === 'MAP') {
            return ['VARIABLE', 'MAP', 'STRING', 'CONDITIONAL', 'ARRAY', 'JSON_FIELD'];
        } else if (type === 'FILE') {
            return ['VARIABLE', 'STRING', 'FILE', 'CONDITIONAL'];
        } else if (type === 'FOLDER') {
            return ['VARIABLE', 'STRING', 'FOLDER', 'CONDITIONAL'];
        } else if (type === 'STATUS') {
            return ['VARIABLE', 'STRING', 'STATUS', 'CONDITIONAL', 'JSON_FIELD'];
        } else if (type === 'USER') {
            return ['VARIABLE', 'STRING', 'USER', 'CONDITIONAL', 'JSON_FIELD'];
        } else if (type === 'COMPOSED_LIST') {
            return ['VARIABLE', 'COMPOSED_LIST', 'ARRAY', 'STRING', 'CONDITIONAL'];
        } else if (type === 'COMPOSED') {
            return ['VARIABLE', 'COMPOSED', 'STRING', 'MAP', 'CONDITIONAL'];
        } else if (type === 'ACTION') {
            return ['VARIABLE', 'ACTION', 'STRING', 'CONDITIONAL'];
        } else if (type === 'ACTION_PARAMS') {
            return ['VARIABLE', 'ACTION_PARAMS', 'STRING', 'CONDITIONAL'];
        } else if (type === 'DATA_BODY') {
            return ['VARIABLE', 'DATA_BODY', 'OBJECT', 'STRING', 'JSON_FIELD'];
        } else if (type === 'OBJECT') {
            return ['VARIABLE', 'OBJECT', 'CONDITIONAL', 'DATA_BODY', 'PARTIAL_DATA', 'STRING', 'JSON_FIELD', 'JSON_NODE'];
        } else if (type === 'DATA_FORMAT_ATTRIBUTE') {
            return ['VARIABLE', 'DATA_FORMAT_ATTRIBUTE', 'STRING'];
        } else if (type === 'VARIABLE') {
            return ['VARIABLE', 'OBJECT', 'STRING', 'NUMBER', 'ARRAY', 'DOUBLE', 'DATE', 'JSON_FIELD'];
        } else {
            return [type];
        }
    }
}

export class ScopeItem {
    key: string;
    id?: string;
    name: string;
    description: string;
    type: string;
    subType?: string;
    subTypePath?: string;
    subTypeProperties?: any;
}
