import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoggerService } from '@backoffice/utils';
import { map, Observable } from 'rxjs';
import { Page } from '@shared/data-access';
import { ApplicationVersion } from '../models/application-version.model';
import { backofficeEnvironment } from '@shared/environment';
import { ApplicationVersionCreatedDto } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application-version-created.dto';
import { CreateApplicationVersionDto } from '../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/create-application-version.dto';
import { plainToInstance } from 'class-transformer';

@Injectable()
export class ApplicationVersionService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    create(applicationId: string, companyId: string, dto: CreateApplicationVersionDto): Observable<ApplicationVersionCreatedDto> {
        this.log.debug('Creating application version', [dto]);
        return this.httpClient.post<ApplicationVersionCreatedDto>(
            `${backofficeEnvironment.rest.v2.applicationVersions}company/${companyId}/application/${applicationId}`,
            dto,
            {
                headers: new HttpHeaders().set('handle-errors', 'true'),
            }
        );
    }

    compare(applicationId: string, companyId: string, baseVersionId: string, compareVersionId: string): Observable<any> {
        this.log.debug(`Comparing application version ${compareVersionId} against ${baseVersionId}`);
        const params = new HttpParams().append('baseVersionId', baseVersionId).append('compareVersionId', compareVersionId);

        return this.httpClient.get(
            `${backofficeEnvironment.rest.v2.applicationVersions}company/${companyId}/application/${applicationId}/compare`,
            {
                params,
                headers: new HttpHeaders().set('handle-errors', 'true'),
            }
        );
    }

    findAll(
        applicationId: string,
        companyId: string,
        options?: {
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<ApplicationVersion>> {
        this.log.debug('Find all application versions');
        let params = new HttpParams();
        if (options) {
            const { page, maxResults } = options;
            if (page) {
                params = params.append('page', page);
            }
            if (maxResults) {
                params = params.append('maxResults', maxResults);
            }
        }
        return this.httpClient
            .get<Page<ApplicationVersion>>(
                `${backofficeEnvironment.rest.v2.applicationVersions}company/${companyId}/application/${applicationId}`,
                { params }
            )
            .pipe(
                map(({ count, content, facetFields }) => {
                    return {
                        count,
                        content: content.map(version => plainToInstance(ApplicationVersion, version)),
                        facetFields,
                    };
                })
            );
    }

    findById(id: string, applicationId: string, companyId: string): Observable<ApplicationVersion> {
        this.log.debug('Find versions by id');
        return this.httpClient.get<ApplicationVersion>(
            `${backofficeEnvironment.rest.v2.applicationVersions}company/${companyId}/application/${applicationId}/${id}`
        );
    }

    findDevelopment(applicationId: string, companyId: string): Observable<ApplicationVersion> {
        this.log.debug('Find development version');
        return this.httpClient.get<ApplicationVersion>(
            `${backofficeEnvironment.rest.v2.applicationVersions}company/${companyId}/application/${applicationId}/development`
        );
    }

    findFull(id: string, applicationId: string, companyId: string): Observable<ApplicationVersion> {
        this.log.debug('Find full versions by id');
        return this.httpClient.get<ApplicationVersion>(
            `${backofficeEnvironment.rest.v2.applicationVersions}company/${companyId}/application/${applicationId}/${id}/full`
        );
    }

    update(applicationId: string, companyId: string, applicationVersion: ApplicationVersion): Observable<void> {
        this.log.debug('Updating application version', [applicationVersion]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.applicationVersions}company/${companyId}/application/${applicationId}/${applicationVersion.id}`,
            applicationVersion
        );
    }
}
