import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { FormBuilder } from '@angular/forms';
import { ConfirmDialog } from '../../../../app/common/lib/confirmdialog/confirm.dialog.lib';
import { AppFacade } from '@core/facades/app.facade';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { BehaviorSubject, timer } from 'rxjs';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-application-edit',
    templateUrl: './application-edit.component.html',
})
export class ApplicationEditComponent implements AfterViewInit {
    application$: BehaviorSubject<ApplicationDto> = new BehaviorSubject(null);

    constructor(
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly dialogRef: MatDialogRef<ApplicationEditComponent>,
        private readonly confirmDialog: ConfirmDialog,
        private readonly appFacade: AppFacade
    ) {
        this.application$.next(data.application);
        this.checkValidations();
    }

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }

    onDeleteApplication() {
        this.confirmDialog.showConfirmDialog(
            'v2.application.edit.delete.title',
            'v2.application.edit.delete.description',
            'v2.application.edit.delete.ok',
            'v2.application.edit.delete.cancel',
            'v2.application.edit.delete.success.title',
            'v2.application.edit.delete.success.description',
            () => {
                this.appFacade.closeEditApplicationDialog('DELETE', this.application$.value);
                this.dialogRef.close();
            }
        );
    }

    onUpdateApplication() {
        this.appFacade.closeEditApplicationDialog('UPDATE', this.application$.value);
        this.dialogRef.close();
    }

    checkValidations() {
        if (
            this.application$.value.customDomainFrontendDevCheck === 'CHECK_PENDING' ||
            this.application$.value.customDomainFrontendTestCheck === 'CHECK_PENDING' ||
            this.application$.value.customDomainFrontendAcceptCheck === 'CHECK_PENDING' ||
            this.application$.value.customDomainFrontendProductionCheck === 'CHECK_PENDING' ||
            this.application$.value.customDomainBackendDevCheck === 'CHECK_PENDING' ||
            this.application$.value.customDomainBackendTestCheck === 'CHECK_PENDING' ||
            this.application$.value.customDomainBackendAcceptCheck === 'CHECK_PENDING' ||
            this.application$.value.customDomainBackendProductionCheck === 'CHECK_PENDING'
        ) {
            this.appFacade
                .fetchApplication(this.application$.value.id)
                .pipe(
                    tap(response => this.application$.next(response)),
                    takeWhile(
                        application =>
                            application.customDomainFrontendDevCheck === 'CHECK_PENDING' ||
                            application.customDomainFrontendTestCheck === 'CHECK_PENDING' ||
                            application.customDomainFrontendAcceptCheck === 'CHECK_PENDING' ||
                            application.customDomainFrontendProductionCheck === 'CHECK_PENDING' ||
                            application.customDomainBackendDevCheck === 'CHECK_PENDING' ||
                            application.customDomainBackendTestCheck === 'CHECK_PENDING' ||
                            application.customDomainBackendAcceptCheck === 'CHECK_PENDING' ||
                            application.customDomainBackendProductionCheck === 'CHECK_PENDING'
                    )
                )
                .subscribe();
        }
    }

    onValidateDomain() {
        if (this.application$.value.customDomain != null && this.application$.value.customDomain != '')
            this.appFacade
                .validateDomain(this.application$.value)
                .pipe(
                    switchMap(() =>
                        timer(0, 1000).pipe(
                            switchMap(() => this.appFacade.fetchApplication(this.application$.value.id)),
                            tap(response => {
                                this.application$.next(response);
                            }),
                            takeWhile(
                                application =>
                                    application.customDomainFrontendDevCheck === 'CHECK_PENDING' ||
                                    application.customDomainFrontendTestCheck === 'CHECK_PENDING' ||
                                    application.customDomainFrontendAcceptCheck === 'CHECK_PENDING' ||
                                    application.customDomainFrontendProductionCheck === 'CHECK_PENDING' ||
                                    application.customDomainBackendDevCheck === 'CHECK_PENDING' ||
                                    application.customDomainBackendTestCheck === 'CHECK_PENDING' ||
                                    application.customDomainBackendAcceptCheck === 'CHECK_PENDING' ||
                                    application.customDomainBackendProductionCheck === 'CHECK_PENDING'
                            )
                        )
                    )
                )
                .subscribe();
    }

    onCancel() {
        this.dialogRef.close();
    }
}
