export interface AuditLogDto {
    id: string;
    applicationId: string;
    templateId: string;
    apiId: string;
    actionId: string;
    actionInvocationId: string;
    message: string;
    userId: string;
    type: string;
    date: Date;
}
