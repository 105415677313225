import { NgModule } from '@angular/core';
import { CompanySelectorComponent } from './components/company-selector/company-selector.component';
import { CompanySelectorAllDialog } from './components/company-selector-all/company-selector-all.dialog';
import { ApplicationSelectorComponent } from './components/application-selector/application-selector.component';
import { ApplicationSelectorAllComponent } from './components/application-selector-all/application-selector-all.component';
import { CommonsModule } from '../../app/common/common.module';
import { AvatarModule } from 'ngx-avatars';

@NgModule({
    declarations: [CompanySelectorComponent, CompanySelectorAllDialog, ApplicationSelectorComponent, ApplicationSelectorAllComponent],
    imports: [CommonsModule, AvatarModule],
    exports: [CompanySelectorComponent, ApplicationSelectorComponent],
})
export class WorkspaceSelectionModule {}
