import { Component, Input, OnInit } from '@angular/core';
import { BillingPackage } from '@billing/dto/billingpackage';

@Component({
    selector: 'codex-application-usage',
    templateUrl: './application-usage.component.html',
    styleUrls: ['./application-usage.component.scss'],
})
export class ApplicationUsageComponent implements OnInit {
    public math: Math = Math;

    @Input()
    public billingPackage: BillingPackage;

    constructor() {}

    ngOnInit(): void {}
}
