<div class="small-number-wrapper">
    <label for="pixelpicker" class="small-input-label" data-name="pixelpicker-name" *ngIf="label">{{ label }}</label>
    <input
        class="small-input small-number-input"
        type="number"
        id="pixelpicker"
        name="pixelpicker"
        autocomplete="off"
        [min]="minPixels"
        [max]="maxPixels"
        [readonly]="readonly"
        [disabled]="readonly"
        [(ngModel)]="pixels"
        (ngModelChange)="onChangePixels($event)" />
</div>
