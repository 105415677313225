<mat-dialog-content>
    <ng-container *ngIf="{ currentContext: billingFacade.currentContext$ | async } as observables">
        <div class="usage-alert-column">
            <img src="/src/images/theme/warning.svg" />
        </div>
        <div class="usage-alert-column">
            <h1>{{ 'v2.billing.usage.exceeded.title' | translate }}</h1>
            <span>{{ 'v2.billing.usage.exceeded.description' | translate }}</span>
            <button class="nocodexbutton" (click)="dialogRef.close()" color="primary" mat-button mat-raised-button>
                <mat-icon>fact_check</mat-icon>
                {{ 'v2.billing.usage.alert.check' | translate }}
            </button>
        </div>
    </ng-container>
</mat-dialog-content>
