<form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onSaveConfiguration()" novalidate cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <label class="small-input-label">{{ 'v2.company.edit.identityproviders.openidconnect.input.name' | translate }}</label>
    <input type="text" name="name" class="small-input" formControlName="name" />
    <p class="small-input-error" *ngIf="this.formGroup.get('name').touched && this.formGroup.get('name').invalid">
        {{ 'v2.company.edit.identityproviders.openidconnect.input.name-required' | translate }}
    </p>

    <label class="small-input-label">{{ 'v2.company.edit.identityproviders.openidconnect.input.displayName' | translate }}</label>
    <input type="text" name="displayName" class="small-input" formControlName="displayName" />
    <p class="small-input-error" *ngIf="this.formGroup.get('displayName').touched && this.formGroup.get('displayName').invalid">
        {{ 'v2.company.edit.identityproviders.openidconnect.input.displayName-required' | translate }}
    </p>
    <ng-container *ngIf="type === 'openidconnect'">
        <label class="small-input-label">{{
            'v2.company.edit.identityproviders.openidconnect.input.discovery-endpoint' | translate
        }}</label>
        <input type="text" name="discoveryendpoint" class="small-input" formControlName="discoveryendpoint" required />
        <p
            class="small-input-error"
            *ngIf="
                formGroup.get('discoveryendpoint').touched &&
                this.formGroup.get('discoveryendpoint').invalid &&
                formGroup.get('discoveryendpoint').hasError('required')
            ">
            {{ 'v2.company.edit.identityproviders.openidconnect.input.discovery-endpoint-required' | translate }}
        </p>
    </ng-container>
    <p
        class="small-input-error"
        *ngIf="
            formGroup.get('discoveryendpoint').touched &&
            formGroup.get('discoveryendpoint').invalid &&
            formGroup.get('discoveryendpoint').hasError('pattern')
        ">
        {{ 'v2.company.edit.identityproviders.openidconnect.input.discovery-endpoint-pattern' | translate }}
    </p>

    <label class="small-input-label">{{ 'v2.company.edit.identityproviders.openidconnect.input.clientid' | translate }}</label>
    <input type="text" name="clientid" class="small-input" formControlName="clientid" required />
    <p class="small-input-error" *ngIf="this.formGroup.get('clientid').touched && this.formGroup.get('clientid').invalid">
        {{ 'v2.company.edit.identityproviders.openidconnect.input.clientid-required' | translate }}
    </p>

    <label class="small-input-label">{{ 'v2.company.edit.identityproviders.openidconnect.input.clientsecret' | translate }}</label>
    <input type="text" name="clientsecret" class="small-input" formControlName="clientsecret" required />
    <p class="small-input-error" *ngIf="this.formGroup.get('clientsecret').touched && this.formGroup.get('clientsecret').invalid">
        {{ 'v2.company.edit.identityproviders.openidconnect.input.clientsecret-required' | translate }}
    </p>

    <label class="toggle-line">
        {{ 'v2.company.edit.identityproviders.openidconnect.input.forceidp' | translate }}
        <input type="checkbox" value="" class="sr-only peer" formControlName="forceIdp" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>

    <hr class="seperator" />
    <h4>{{ 'v2.company.edit.identityproviders.azuread.redirecturls' | translate }}</h4>
    <ul class="url-list">
        <li>
            https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.nocode-x.com/auth/realms/dev-{{ company.id }}/broker/{{
                formGroup.get('name').value
            }}/endpoint
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="
                    'https://' +
                    (backofficeEnvironment.production ? '' : 'dev-') +
                    'login.nocode-x.com/auth/realms/dev-' +
                    company.id +
                    '/broker/' +
                    formGroup.get('name').value +
                    '/endpoint'
                "
                [matTooltip]="'Copy'"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.nocode-x.com/auth/realms/test-{{ company.id }}/broker/{{
                formGroup.get('name').value
            }}/endpoint
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="
                    'https://' +
                    (backofficeEnvironment.production ? '' : 'dev-') +
                    'login.nocode-x.com/auth/realms/test-' +
                    company.id +
                    '/broker/' +
                    formGroup.get('name').value +
                    '/endpoint'
                "
                [matTooltip]="'Copy'"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.nocode-x.com/auth/realms/accept-{{ company.id }}/broker/{{
                formGroup.get('name').value
            }}/endpoint
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="
                    'https://' +
                    (backofficeEnvironment.production ? '' : 'dev-') +
                    'login.nocode-x.com/auth/realms/accept-' +
                    company.id +
                    '/broker/' +
                    formGroup.get('name').value +
                    '/endpoint'
                "
                [matTooltip]="'Copy'"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
        <li>
            https://{{ backofficeEnvironment.production ? '' : 'dev-' }}login.nocode-x.com/auth/realms/{{ company.id }}/broker/{{
                formGroup.get('name').value
            }}/endpoint
            <svg
                class="icon icon-button"
                [cdkCopyToClipboard]="
                    'https://' +
                    (backofficeEnvironment.production ? '' : 'dev-') +
                    'login.nocode-x.com/auth/realms/' +
                    company.id +
                    '/broker/' +
                    formGroup.get('name').value +
                    '/endpoint'
                "
                [matTooltip]="'Copy'"
                aria-hidden="true"
                focusable="false">
                <use href="#copy" class="ui-element"></use>
            </svg>
        </li>
    </ul>

    <div class="flex justify-end gap-1">
        <button data-name="save" class="primary-button button-medium" [disabled]="!formGroup.valid" type="submit">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-medium" data-name="cancel" (click)="this.onCancel()" type="button">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
