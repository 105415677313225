import { ApplicationState } from '../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { routerSelectors } from '../../../../../../apps/no-code-x-backoffice/src/app/store/router-store/router.selector';
import { Injectable } from '@angular/core';
import { NavigationExtras, Params, Router } from '@angular/router';

@Injectable()
export class RouterFacade {
    constructor(
        private readonly store: Store<ApplicationState>,
        private readonly router: Router
    ) {}

    get params(): Observable<Params> {
        return this.store.select(routerSelectors.params).pipe(
            filter(params => !!params),
            map(params => params as Params)
        );
    }

    get queryParams(): Observable<Params> {
        return this.store.select(routerSelectors.queryParams).pipe(
            filter(params => !!params),
            map(params => params as Params)
        );
    }

    get companyId(): Observable<string> {
        return this.store.select(routerSelectors.companyId);
    }

    get applicationId(): Observable<string> {
        return this.store.select(routerSelectors.selectedApplication);
    }

    navigate(commands: any[], extras?: NavigationExtras) {
        this.router.navigate(commands, extras);
    }
}
