<h3
    [attr.id]="'partstyle-container-accordion-' + identifier + '-overflow-heading'"
    [attr.data-name]="'partstyle-container-accordion-overflow-header-' + identifier"
    class="p-0">
    <button
        type="button"
        class="accordion-heading"
        [class.accordion-heading-first]="firstPanel"
        [class.accordion-heading-last]="lastPanel"
        [attr.data-accordion-target]="'#partstyle-container-accordion-' + identifier + '-overflow'"
        [attr.aria-controls]="'partstyle-container-accordion-' + identifier + '-overflow'"
        aria-expanded="true">
        <div class="flex gap-1 justify-center items-center w-full">
            <span class="leading-7 grow text-left">{{ 'v2.part.style.overflow' | translate }}</span>
            <button
                class="text-button"
                type="button"
                (click)="resetStyle(); $event.stopPropagation()"
                [matTooltip]="'v2.template.style.reset' | translate">
                Reset
            </button>
        </div>
    </button>
</h3>
<div
    [attr.id]="'partstyle-container-accordion-' + identifier + '-overflow'"
    [attr.data-name]="'overflow-' + identifier"
    [attr.aria-labelledby]="'partstyle-container-accordion-' + identifier + '-overflow'"
    [class.accordion-panel-last]="lastPanel"
    [class.accordion-panel]="true"
    class="hidden p-2">
    <div class="button-group" role="group">
        <button
            *ngIf="allowedTypes.indexOf('normal') > -1"
            type="button"
            class="button-group-button-left"
            autofocus
            (click)="type = 'normal'"
            [ngClass]="type === 'normal' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.normal' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('hover') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'hover'"
            [ngClass]="type === 'hover' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.hover' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('focus') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'focus'"
            [ngClass]="type === 'focus' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.focus' | translate }}
        </button>
        <button
            *ngIf="allowedTypes.indexOf('active') > -1"
            type="button"
            class="button-group-button-middle"
            autofocus
            (click)="type = 'active'"
            [ngClass]="type === 'active' ? 'button-group-button-selected' : ''">
            {{ 'v2.part.style.type.active' | translate }}
        </button>
    </div>
    <ng-container *ngIf="type === 'normal'">
        <div class="flex flex-row justify-between gap-2 items-center">
            <label for="select-overflow-x" class="small-input-label">{{ 'v2.part.style.overflowx' | translate }}</label>
            <select
                id="select-overflow-x"
                class="small-input max-w-[170px]"
                [(ngModel)]="wrapperStyle.innerSize.overflowX"
                (ngModelChange)="this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle })">
                <option [value]="'visible'">visible</option>
                <option [value]="'hidden'">hidden</option>
                <option [value]="'scroll'">scroll</option>
                <option [value]="'auto'">auto</option>
                <option [value]="'initial'">initial</option>
                <option [value]="'inherit'">inherit</option>
            </select>
        </div>
        <div class="flex flex-row justify-between gap-2 items-center">
            <label for="select-overflow-y" class="small-input-label">{{ 'v2.part.style.overflowy' | translate }}</label>
            <select
                id="select-overflow-y"
                class="small-input max-w-[170px]"
                [(ngModel)]="wrapperStyle.innerSize.overflowY"
                (ngModelChange)="this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle })">
                <option [value]="'visible'">visible</option>
                <option [value]="'hidden'">hidden</option>
                <option [value]="'scroll'">scroll</option>
                <option [value]="'auto'">auto</option>
                <option [value]="'initial'">initial</option>
                <option [value]="'inherit'">inherit</option>
            </select>
        </div>
    </ng-container>
    <ng-container *ngIf="type === 'hover'">
        <div class="flex flex-row justify-between gap-2 items-center">
            <label for="select-hover-overflow-x" class="small-input-label">{{ 'v2.part.style.overflowx' | translate }}</label>
            <select
                id="select-hover-overflow-x"
                class="small-input max-w-[170px]"
                [(ngModel)]="hoverWrapperStyle.innerSize.overflowX"
                (ngModelChange)="this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle })">
                <option [value]="'visible'">visible</option>
                <option [value]="'hidden'">hidden</option>
                <option [value]="'scroll'">scroll</option>
                <option [value]="'auto'">auto</option>
                <option [value]="'initial'">initial</option>
                <option [value]="'inherit'">inherit</option>
            </select>
        </div>
        <div class="flex flex-row justify-between gap-2 items-center">
            <label for="select-hover-overflow-y" class="small-input-label">{{ 'v2.part.style.overflowy' | translate }}</label>
            <select
                id="select-hover-overflow-y"
                class="small-input max-w-[170px]"
                [(ngModel)]="hoverWrapperStyle.innerSize.overflowY"
                (ngModelChange)="this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle })">
                <option [value]="'visible'">visible</option>
                <option [value]="'hidden'">hidden</option>
                <option [value]="'scroll'">scroll</option>
                <option [value]="'auto'">auto</option>
                <option [value]="'initial'">initial</option>
                <option [value]="'inherit'">inherit</option>
            </select>
        </div>
    </ng-container>
    <ng-container *ngIf="type === 'focus'">
        <div class="flex flex-row justify-between gap-2 items-center">
            <label for="select-focus-overflow-x" class="small-input-label">{{ 'v2.part.style.overflowx' | translate }}</label>
            <select
                id="select-focus-overflow-x"
                class="small-input max-w-[170px]"
                [(ngModel)]="focusWrapperStyle.innerSize.overflowX"
                (ngModelChange)="this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle })">
                <option [value]="'visible'">visible</option>
                <option [value]="'hidden'">hidden</option>
                <option [value]="'scroll'">scroll</option>
                <option [value]="'auto'">auto</option>
                <option [value]="'initial'">initial</option>
                <option [value]="'inherit'">inherit</option>
            </select>
        </div>
        <div class="flex flex-row justify-between gap-2 items-center">
            <label for="select-focus-overflow-y" class="small-input-label">{{ 'v2.part.style.overflowy' | translate }}</label>
            <select
                id="select-focus-overflow-y"
                class="small-input max-w-[170px]"
                [(ngModel)]="focusWrapperStyle.innerSize.overflowY"
                (ngModelChange)="this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle })">
                <option [value]="'visible'">visible</option>
                <option [value]="'hidden'">hidden</option>
                <option [value]="'scroll'">scroll</option>
                <option [value]="'auto'">auto</option>
                <option [value]="'initial'">initial</option>
                <option [value]="'inherit'">inherit</option>
            </select>
        </div>
    </ng-container>
    <ng-container *ngIf="type === 'active'">
        <div class="flex flex-row justify-between gap-2 items-center">
            <label for="select-active-overflow-x" class="small-input-label">{{ 'v2.part.style.overflowx' | translate }}</label>
            <select
                id="select-active-overflow-x"
                class="small-input max-w-[170px]"
                [(ngModel)]="activeWrapperStyle.innerSize.overflowX"
                (ngModelChange)="this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle })">
                <option [value]="'visible'">visible</option>
                <option [value]="'hidden'">hidden</option>
                <option [value]="'scroll'">scroll</option>
                <option [value]="'auto'">auto</option>
                <option [value]="'initial'">initial</option>
                <option [value]="'inherit'">inherit</option>
            </select>
        </div>
        <div class="flex flex-row justify-between gap-2 items-center">
            <label for="select-active-overflow-y" class="small-input-label">{{ 'v2.part.style.overflowy' | translate }}</label>
            <select
                id="select-active-overflow-y"
                class="small-input max-w-[170px]"
                [(ngModel)]="activeWrapperStyle.innerSize.overflowY"
                (ngModelChange)="this.activeWrapperStyleUpdated.emit({ wrapperStyle: this.activeWrapperStyle })">
                <option [value]="'visible'">visible</option>
                <option [value]="'hidden'">hidden</option>
                <option [value]="'scroll'">scroll</option>
                <option [value]="'auto'">auto</option>
                <option [value]="'initial'">initial</option>
                <option [value]="'inherit'">inherit</option>
            </select>
        </div>
    </ng-container>
</div>
