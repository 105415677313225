export interface ApplicationLogDto {
    id: string;
    applicationId: string;
    templateId: string;
    apiId: string;
    actionId: string;
    actionInvocationId: string;
    actionInvocationName: string;
    actionInvocationMethodKey: string;
    stackTrace: string;
    message: string;
    subject: string;
    level: string;
    direct: boolean;
    environment: string;
    date: Date;
}
