<form *ngIf="company && form" [formGroup]="form" (ngSubmit)="onUpdateCompany()" novalidate>
    <label for="company-name" class="small-input-label">{{ 'v2.company.edit.name.title' | translate }}</label>
    <input
        class="small-input"
        type="text"
        id="company-name"
        name="company-name"
        data-name="company-name"
        cdkFocusInitial
        formControlName="name"
        (change)="onChangeCompanyName()"
        required
        autocomplete="off" />
    <p
        class="small-input-error"
        *ngIf="form.controls['name'].touched && form.controls['name'].invalid && form.controls['name'].hasError('required')">
        {{ 'v2.company.edit.name.required' | translate }}
    </p>
    <div class="flex justify-end gap-1">
        <button class="primary-button button-medium" data-name="save">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-medium" data-name="cancel" [mat-dialog-close]="true">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
