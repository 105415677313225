import { NgModule } from '@angular/core';
import { ApplicationVersionPageComponent } from './pages/application-version/application-version-page.component';
import { ApplicationVersionUiModule } from '@backoffice/editor/ui/application-version';
import { HttpLoaderInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-authentication.interceptor';
import { CommonsModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ApplicationVersionDataAccessModule } from '@backoffice/editor/data-access/application-version';
import { ApplicationVersionDiffComponent } from './pages/application-version-diff/application-version-diff.component';

@NgModule({
    declarations: [ApplicationVersionPageComponent, ApplicationVersionDiffComponent],
    imports: [
        ApplicationVersionDataAccessModule,
        ApplicationVersionUiModule,
        CommonsModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
    ],
    exports: [ApplicationVersionPageComponent, ApplicationVersionDiffComponent],
    providers: [HttpLoaderInterceptorProvider, HttpErrorInterceptorProvider, HttpAuthenticationInterceptorProvider],
})
export class ApplicationVersionFeatureModule {}
