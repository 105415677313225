export interface OverviewTagDto {
    id: string;
    tag: string;
    companyId: string;
    applicationId: string;
}

export function isOverviewTagDto(object: any): object is OverviewTagDto {
    return object && object['tag'];
}
