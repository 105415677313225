import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, debounceTime, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../../../store/editor.state';
import {
    clearDesignSystemDialogFilter,
    createDesignSystemSuccess,
    deleteDesignSystem,
    deleteDesignSystems,
    deleteDesignSystemsSuccess,
    deleteDesignSystemSuccess,
    designSystemDialogFacetsChanged,
    designSystemDialogFilterPluginsChanged,
    designSystemDialogPaginationChanged,
    designSystemDialogSearchTermChanged,
    fetchDesignSystemError,
    fetchDesignSystemSuccess,
    findDesignSystem,
    loadDesignSystemDialogData,
    loadDesignSystemDialogSuccess,
    updateColorDefinition,
    updateDesignSystem,
    updateDesignSystemSuccess,
    updateTypography,
} from '../actions/design-system-editor.actions';
import { closeTab, closeTabs, registerTab, updateTab } from '../../../store/actions/editor.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';

import { DesignSystemService, toFilter } from '@backoffice/data-access/editor';
import { DesignSystemEditorFacade } from '../facades/design-system-editor.facade';
import { selectApplicationSuccess } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { Page } from '@shared/data-access';
import { OverviewDesignSystemDto } from '../../dto/overview-design-system.dto';
import { DesignSystem } from '../../models/design-system';

@Injectable()
export class DesignSystemEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly editorFacade: DesignSystemEditorFacade,
        private readonly designSystemService: DesignSystemService,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearDesignSystemDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDesignSystems),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.designSystemService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteDesignSystemsSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDesignSystemsSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'designsystem' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDesignSystem),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.designSystemService.delete(id, companyId, applicationId).pipe(map(() => deleteDesignSystemSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findDesignSystem),
            concatLatestFrom(({ id }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([_dataformat, company, application]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }]) => {
                return this.designSystemService.findById(id, companyId, applicationId).pipe(
                    map(result => fetchDesignSystemSuccess({ designSystem: result })),
                    catchError(() => of(fetchDesignSystemError({ designSystem: DesignSystem.createDeleted(id) })))
                );
            })
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteDesignSystemsSuccess,
                loadDesignSystemDialogData,
                designSystemDialogPaginationChanged,
                designSystemDialogSearchTermChanged,
                designSystemDialogFacetsChanged,
                designSystemDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.designSystemService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewDesignSystemDto>) => loadDesignSystemDialogSuccess({ data }))
        )
    );

    updateColorDefinition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateColorDefinition),
            concatMap(({ definition }) =>
                this.designSystemService
                    .updateColorDefinition(definition)
                    .pipe(map(() => updateDesignSystemSuccess({ id: definition.designSystemId })))
            )
        )
    );

    updateTypography$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTypography),
            concatMap(({ typography }) =>
                this.designSystemService
                    .updateTypography(typography)
                    .pipe(map(() => updateDesignSystemSuccess({ id: typography.designSystemId })))
            )
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDesignSystem),
            concatMap(({ designSystem }) =>
                this.designSystemService.update(designSystem).pipe(map(() => updateDesignSystemSuccess({ id: designSystem.id })))
            )
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDesignSystemSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'designsystem' }))
        )
    );

    openSnack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateDesignSystemSuccess),
                tap(() => {
                    this.snackBar.open(this.translate.instant('v2.designsystem.edit.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createDesignSystemSuccess),
            filter(({ id, openTab }) => openTab),
            map(({ id }) => registerTab({ definition: { type: 'designsystem', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchDesignSystemSuccess),
            map(({ designSystem }) => {
                const { id: typeId, name, iconName: icon } = designSystem;
                return updateTab({ definition: { type: 'designsystem', typeId, name, icon } });
            })
        )
    );
}
