import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from './router.state';

export class ApplicationAndCompanySerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        const { url } = routerState;
        let route = routerState.root;
        const { queryParams } = route;
        let params = {};
        do {
            params = { ...params, ...route.params };
            route = route.firstChild;
        } while (route);
        return { url, queryParams, params };
    }
}
