import { Component, Inject, OnInit } from '@angular/core';
import { CompanyIdentityProvider } from '@shared/interfaces/company-identity-provider.dto';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { CompanyDto } from '@shared/interfaces/company.dto';

@Component({
    selector: 'codex-add-identityprovider',
    templateUrl: './add-identityprovider.component.html',
    styleUrls: ['./add-identityprovider.component.scss'],
})
export class AddIdentityproviderComponent implements OnInit {
    type: string;

    identityProvider: CompanyIdentityProvider;

    company: CompanyDto;

    constructor(
        public dialogRef: MatDialogRef<AddIdentityproviderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        if (this.data?.identityProvider) {
            this.type = this.data.identityProvider.type;
            this.identityProvider = this.data.identityProvider;
        }
        this.company = this.data.company;
    }

    onChooseType(type: string) {
        this.type = type;
    }

    onCancel() {
        this.identityProvider = null;
        this.type = null;
        this.dialogRef.close();
    }
}
