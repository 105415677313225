import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'codex-add-comment',
    templateUrl: './add-comment.component.html',
    styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent implements OnInit {
    get valueControl(): FormControl {
        return this.form.get('value') as FormControl;
    }

    form: FormGroup;

    constructor(
        private readonly fb: FormBuilder,
        public dialogRef: MatDialogRef<AddCommentComponent>
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            value: [undefined, [Validators.required]],
        });
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    onAddClicked(): void {
        const { valid, value } = this.form;
        if (valid) {
            this.dialogRef.close(value);
        } else {
            this.form.markAllAsTouched();
        }
    }
}
