import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { BehaviorSubject } from 'rxjs';
import { ApplicationDto } from '../../../../../../../data-access/editor/src/lib/interfaces/application.dto';
import { RouterFacade } from '../../../../../../../utils-routing/src';
import { CompanyIdentityProvider } from '@shared/interfaces/company-identity-provider.dto';
import { TabDefinition } from '../../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { CompanySecurityDto } from '@shared/interfaces/company-security.dto';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-company-edit-authentication',
    templateUrl: './company-edit-authentication.component.html',
})
export class CompanyEditAuthenticationComponent implements OnInit {
    @Input()
    company: CompanyDto;

    @Input()
    companySecurity: CompanySecurityDto;

    @Input()
    apiSecret: string;

    @Input()
    identityProviders: CompanyIdentityProvider[];

    @Output()
    updateCompany: EventEmitter<{ company: CompanyDto; companySecurity: CompanySecurityDto }> = new EventEmitter<{
        company: CompanyDto;
        companySecurity: CompanySecurityDto;
    }>();

    @Output()
    viewSecret: EventEmitter<{ companySecurity: CompanySecurityDto; environment: string }> = new EventEmitter<{
        companySecurity: CompanySecurityDto;
        environment: string;
    }>();

    @Output()
    createCompanyIdentityProvider: EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }> = new EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }>();

    @Output()
    updateCompanyIdentityProvider: EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }> = new EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }>();

    @Output()
    deleteCompanyIdentityProvider: EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }> = new EventEmitter<{
        companyIdentityProvider: CompanyIdentityProvider;
    }>();

    @Output()
    cancelUpdateCompany: EventEmitter<{ company: CompanyDto }> = new EventEmitter<{ company: CompanyDto }>();

    formGroup: FormGroup;

    application: BehaviorSubject<ApplicationDto> = new BehaviorSubject<ApplicationDto>(null);

    constructor(
        private readonly fb: FormBuilder,
        private routerFacade: RouterFacade,
        public changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({});
    }

    onLoginTemplateChanged($event: { value: string | undefined }) {
        this.company.loginTemplateId = $event.value;
    }

    onLoginRedirectTemplateChanged($event: { value: string | undefined }) {
        this.company.loginRedirectTemplateId = $event.value;
    }

    onLoginOtpTemplateChanged($event: { value: string | undefined }) {
        this.company.loginOtpTemplateId = $event.value;
    }

    onLoginOtpConfigTemplateChanged($event: { value: string | undefined }) {
        this.company.loginConfigOtpTemplateId = $event.value;
    }

    onLoginResetPasswordTemplateChanged($event: { value: string | undefined }) {
        this.company.loginResetPasswordTemplateId = $event.value;
    }

    onLoginVerifyEmailTemplateChanged($event: { value: string | undefined }) {
        this.company.loginVerifyEmailTemplateId = $event.value;
    }

    onLoginExpiredTemplateChanged($event: { value: string | undefined }) {
        this.company.loginPageExpiredTemplateId = $event.value;
    }

    onLoginUpdatePasswordTemplateChanged($event: { value: string | undefined }) {
        this.company.loginUpdatePasswordTemplateId = $event.value;
    }
    onOpenTemplate($event: TabDefinition) {
        this.routerFacade.navigate([`/company/${this.company.id}/application/${this.company.loginApplicationId}/editor`], {
            queryParams: {
                type: 'template',
                id: $event.typeId,
            },
        });
    }

    onOpenAction($event: TabDefinition) {
        this.routerFacade.navigate([`/company/${this.company.id}/application/${this.company.loginApplicationId}/editor`], {
            queryParams: {
                type: 'action',
                id: $event.typeId,
            },
        });
    }

    onUpdateCompany() {
        this.updateCompany.emit({ company: this.company, companySecurity: this.companySecurity });
    }

    onViewSecret($event: { companySecurity: CompanySecurityDto; environment: string }) {
        this.viewSecret.emit($event);
    }

    onCancel() {
        this.cancelUpdateCompany.emit({ company: this.company });
    }

    onApplicationChanged($event: { application: ApplicationDto }) {
        this.application.next($event.application);
        if ($event.application?.id) {
            this.company.loginApplicationId = null;
            this.changeDetectorRef.detectChanges();

            this.company.loginApplicationId = $event.application.id;
            this.company.loginTemplateId = null;
            this.company.loginOtpTemplateId = null;
            this.company.loginConfigOtpTemplateId = null;
            this.company.loginPageExpiredTemplateId = null;
            this.company.loginRedirectTemplateId = null;
            this.company.loginResetPasswordTemplateId = null;
            this.company.loginUpdatePasswordTemplateId = null;
            this.company.loginVerifyEmailTemplateId = null;
        } else {
            this.company.loginTemplateId = null;
        }
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly backofficeEnvironment = backofficeEnvironment;
}
