import { NgModule } from '@angular/core';
import { BackofficeUtilsRoutingModule } from '@backoffice/utils-routing';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { AuditLogsPage } from './pages/audit-logs/audit-logs.page';
import { AuditLogsFacade } from './facade/audit-logs.facade';
import { MatListModule } from '@angular/material/list';
import { BackofficeUiArgumentsModule } from '@backoffice/ui-arguments';
import { CommonsModule } from '../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { HttpLoaderInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-authentication.interceptor';
import { BackofficeFeatureEditorPickersModule } from '../../../editor/pickers/src';

@NgModule({
    imports: [
        CommonsModule,
        MatListModule,
        BackofficeUtilsRoutingModule,
        BackofficeUiArgumentsModule,
        NgxMatTimepickerModule,
        BackofficeFeatureEditorPickersModule,
    ],
    declarations: [AuditLogsPage],
    providers: [HttpLoaderInterceptorProvider, HttpErrorInterceptorProvider, HttpAuthenticationInterceptorProvider, AuditLogsFacade],
})
export class BackofficeFeatureAuditLogsModule {}
