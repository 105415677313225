export class Media {
    id: string;
    title: string;
    alt?: string | null;
    url: string;
    type: string;
    companyId: string;
    applicationId: string;
    fileName: string;
    fileSize: number;
    environment: string;
    createdBy: string;
    creationDate: string;
    modifiedBy: string;
    modificationDate: string;
    installFromHub: boolean;
    installedFromMediaId: string;
    extension: 'default' | 'image' | 'pdf' | 'word' | 'excel' | 'csv' | 'json' | 'xml' | 'zip' | 'text' | 'mp4' | 'mp3' | 'md';
    mainType: string;
    productReference: string;
    productReferenceName?: string;

    constructor(base: Partial<Media> = {}) {
        Object.assign(this, base);
        this.defineExtension();
    }

    public isValid() {
        return true;
    }

    defineExtension(): void {
        switch (this.type) {
            case 'image/mbp':
            case 'image/gif':
            case 'image/jpeg':
            case 'image/jpg':
            case 'image/png':
            case 'image/tiff':
            case 'image/ico':
            case 'image/webp':
            case 'image/svg+xml':
                this.extension = 'image';
                break;
            case 'video/mp4':
                this.extension = 'mp4';
                break;
            case 'audio/mpeg':
            case 'audio/mpeg3':
            case 'audio/mp3':
                this.extension = 'mp3';
                break;
            case 'application/pdf':
                this.extension = 'pdf';
                break;
            case 'text/markdown':
                this.extension = 'md';
                break;
            case 'application/xml':
                this.extension = 'xml';
                break;
            case 'application/json':
                this.extension = 'json';
                break;
            case 'text/csv':
                this.extension = 'csv';
                break;
            case 'application/zip':
                this.extension = 'zip';
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                this.extension = 'excel';
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
                this.extension = 'word';
                break;
            case 'text/plain':
                this.extension = 'text';
                break;
            default:
                this.extension = 'default';
        }
    }
}
