import { PartStyle } from '../../../part-style.model';
import { UploaderFilePartStyle } from './uploader-file-part-style';
import { UploaderButtonPartStyle } from './uploader-button-part-style';
import { UploaderDropzonePartStyle } from './uploader-dropzone-part-style';
import { UploaderGeneralPartStyle } from './uploader-general-part-style';

export class UploaderPartStyle extends PartStyle {
    code: string;
    normalUploaderFilePartStyle: UploaderFilePartStyle;
    hoverUploaderFilePartStyle: UploaderFilePartStyle;

    normalUploaderButtonPartStyle: UploaderButtonPartStyle;
    hoverUploaderButtonPartStyle: UploaderButtonPartStyle;

    normalUploaderDropzonePartStyle: UploaderDropzonePartStyle;
    hoverUploaderDropzonePartStyle: UploaderDropzonePartStyle;

    normalUploaderGeneralPartStyle: UploaderGeneralPartStyle;
    hoverUploaderGeneralPartStyle: UploaderGeneralPartStyle;
}
