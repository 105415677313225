<ng-container
    *ngIf="{
        company: company$ | async,
        companySecurity: companySecurity$ | async,
        identityProviders: companyIdentityProviders$ | async,
        apiSecret: apiSecretViewed$ | async
    } as observables">
    <mat-dialog-content class="overflow-hidden">
        <div class="tabs-header-wrapper">
            <ul
                class="tabs-header"
                id="default-styled-tab"
                data-tabs-toggle="#company-tabs"
                data-tabs-inactive-classes="tabs-tab-inactive"
                data-tabs-active-classes="tabs-tab-active"
                role="tablist">
                <li class="me-2" role="presentation">
                    <button
                        class="tabs-tab-button"
                        id="company-general-info-tab"
                        data-tabs-target="#company-general-info"
                        type="button"
                        role="tab"
                        aria-controls="arguments"
                        aria-selected="false">
                        <mat-icon data-name="general-info-tab" class="example-tab-icon">public</mat-icon>
                        {{ 'v2.company.edit.general-info.title' | translate }}
                    </button>
                </li>
                <li class="me-2" role="presentation">
                    <button
                        class="tabs-tab-button"
                        id="company-usage-tab"
                        data-tabs-target="#company-usage"
                        type="button"
                        role="tab"
                        aria-controls="properties"
                        aria-selected="false">
                        <mat-icon data-name="general-info-tab" class="example-tab-icon">security</mat-icon>
                        {{ 'v2.navigation.company.billing' | translate }}
                    </button>
                </li>
                <li class="me-2" role="presentation">
                    <button
                        class="tabs-tab-button"
                        id="company-authentication-tab"
                        data-tabs-target="#company-authentication"
                        type="button"
                        role="tab"
                        aria-controls="properties"
                        aria-selected="false">
                        <mat-icon data-name="general-info-tab" class="example-tab-icon">security</mat-icon>
                        {{ 'v2.company.edit.authentication.title' | translate }}
                    </button>
                </li>
            </ul>
        </div>
        <div id="invocation-configuration-tabs" class="p-3 scrollbar-primary scrollbar">
            <div id="company-general-info" class="hidden" role="tabpanel" aria-labelledby="invocation-configuration-arguments-tab">
                <company-edit-general-info
                    *ngIf="observables.company"
                    [company]="observables.company"
                    (updateCompany)="onUpdateCompany($event)">
                </company-edit-general-info>
            </div>
            <div id="company-usage" class="hidden" role="tabpanel" aria-labelledby="invocation-configuration-arguments-tab">
                <codex-application-billing></codex-application-billing>
            </div>
            <div id="company-authentication" class="hidden" role="tabpanel" aria-labelledby="invocation-configuration-properties-tab">
                <codex-company-edit-authentication
                    *ngIf="observables.company"
                    [company]="observables.company"
                    [companySecurity]="observables.companySecurity"
                    [apiSecret]="observables.apiSecret"
                    [identityProviders]="observables.identityProviders"
                    (updateCompany)="onUpdateCompany($event)"
                    (viewSecret)="onViewSecret($event)"
                    (createCompanyIdentityProvider)="onCreateCompanyIdentityProvider($event)"
                    (updateCompanyIdentityProvider)="onUpdateCompanyIdentityProvider($event)"
                    (deleteCompanyIdentityProvider)="onDeleteCompanyIdentityProvider($event)"></codex-company-edit-authentication>
            </div>
        </div>
    </mat-dialog-content>
</ng-container>
