import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { CurrentContext, LoggerService } from '@backoffice/utils';
import { Media } from '../../models/media';
import { mediaEditorSelectors } from '../selectors/media-editor.selectors';
import {
    clearMediaDialogData,
    createMediaSuccess,
    deleteMultipleMedia,
    findMedia,
    loadMediaDialogData,
    mediaDialogFacetsChanged,
    mediaDialogFilterPluginsChanged,
    mediaDialogPaginationChanged,
    mediaDialogSearchTermChanged,
    updateMedia,
} from '../actions/media-editor.actions';
import { MediaService } from '../../services/media.service';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '../../dto/overview/facets.dto';
import { ComponentType } from '@angular/cdk/overlay';
import { selectCurrentContext } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationState } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';

@Injectable()
export class MediaEditorFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.applicationStateStore.select(selectCurrentContext);

    get dialogData(): Observable<Page<Media> | undefined> {
        return this.store.select(mediaEditorSelectors.dialogData);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(mediaEditorSelectors.facets);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(mediaEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(mediaEditorSelectors.pagination);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(mediaEditorSelectors.filterPlugins);
    }
    constructor(
        private readonly applicationStateStore: Store<ApplicationState>,
        private readonly dialog: MatDialog,
        private readonly log: LoggerService,
        private readonly keycloakService: KeycloakService,
        private readonly mediaService: MediaService,
        private readonly store: Store<EditorState>
    ) {}

    get filter(): Observable<{
        searchTerm: string | undefined;
        page: number;
        maxResults: number;
        facets: SelectedFacets;
        filterPlugins: boolean;
    }> {
        return this.store.select(mediaEditorSelectors.dialogFilter);
    }

    clearDialogData(): void {
        this.store.dispatch(clearMediaDialogData());
    }

    create(componentType: ComponentType<any>): Observable<string> {
        this.log.info('Create new media');
        return combineLatest([
            this.keycloakService.getToken(),
            this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                map(context => {
                    const { selectedCompany, selectedApplication } = context;
                    return { companyId: selectedCompany.id, applicationId: selectedApplication.id };
                })
            ),
        ]).pipe(
            switchMap(([token, context]) => {
                const { companyId, applicationId } = context;
                return this.dialog
                    .open(componentType, {
                        data: {
                            token,
                            applicationId,
                            companyId,
                        },
                        disableClose: true,
                        ...backofficeEnvironment.dialogConfig.normal,
                    })
                    .afterClosed()
                    .pipe(
                        filter(result => !!result && !!result.id),
                        tap(({ id, name }) => {
                            return this.store.dispatch(createMediaSuccess({ id, name }));
                        }),
                        map(({ id }) => id)
                    );
            })
        );
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(mediaDialogSearchTermChanged({ searchTerm }));
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(mediaDialogPaginationChanged({ page, maxResults }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(mediaDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(mediaDialogFilterPluginsChanged({ filterPlugins }));
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting media <${ids}>`);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteMultipleMedia({ ids }));
        }
    }

    findById(id: string): Observable<Media> {
        this.log.info(`Find media by id ${id}`);
        this.store.dispatch(findMedia({ id }));
        return this.store.select(mediaEditorSelectors.byId(id)).pipe(
            filter(media => !!media),
            map(media => media as Media)
        );
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadMediaDialogData());
    }

    update(media: Media): void {
        this.log.info(`Update media`, [media]);
        this.store.dispatch(updateMedia({ media }));
    }
}
