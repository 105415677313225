import { Action } from '@ngrx/store';
import { DATA_ERROR_ADD_ERROR, DATA_ERROR_CLEAR_ERRORS, DATA_ERROR_SET_ERRORS } from '../actiontypes';
import { PayloadAction } from '../action/payload.action';

export function addError(error: string): PayloadAction {
    return {
        type: DATA_ERROR_ADD_ERROR,
        payload: error,
    };
}

export function setErrors(errors: string[]): PayloadAction {
    return {
        type: DATA_ERROR_SET_ERRORS,
        payload: errors,
    };
}

export function clearErrors(): Action {
    return {
        type: DATA_ERROR_CLEAR_ERRORS,
    };
}
