import { Injectable } from '@angular/core';
import { MatPaginatorIntl as MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CodexPaginator extends MatPaginatorIntl {
    constructor(translateService: TranslateService) {
        super();
        this.nextPageLabel = translateService.instant('general.pagination.nextPage');
        this.itemsPerPageLabel = translateService.instant('general.pagination.itemsPerPage');
        this.previousPageLabel = translateService.instant('general.pagination.previousPage');
        this.firstPageLabel = translateService.instant('general.pagination.firstPage');
        this.lastPageLabel = translateService.instant('general.pagination.lastPage');
    }
}
