import { CompanyDto } from '@shared/interfaces/company.dto';
import { CompanyLanguageDto } from '../../dto/deprecated/company.language.dto';
import { PayloadAction } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/common/statemanagement/action/payload.action';

export const CONTEXT_SET_COMPANIES_SUCCESS: string = '(Context) set companies success';

export const DATA_COMPANY_SET_SELECTED_COMPANY = 'DATA_COMPANY_SET_SELECTED_COMPANY';
export const DATA_COMPANY_SET_SELECTED_COMPANY_ID = 'DATA_COMPANY_SET_SELECTED_COMPANY_ID';

export const DATA_COMPANY_SET_OTHER_USER_COMPANIES: string = 'DATA_COMPANY_SET_OTHER_USER_COMPANIES';
export const DATA_COMPANY_SET_COMPANY: string = 'DATA_COMPANY_SET_COMPANY';
export const DATA_COMPANY_SET_SELECTED_COMPANY_LANGUAGES: string = 'DATA_COMPANY_SET_SELECTED_COMPANY_LANGUAGES';
export const DATA_COMPANY_SET_SELECTED_COMPANY_BILLING: string = 'DATA_COMPANY_SET_SELECTED_COMPANY_BILLING';
export const DATA_COMPANY_SET_SEEN_COMPANY_BILLING_WARNING: string = 'DATA_COMPANY_SET_SEEN_COMPANY_BILLING_WARNING';
export const DATA_COMPANY_ADD_USAGE_TO_BILLING: string = 'DATA_COMPANY_ADD_USAGE_TO_BILLING';

export function setSelectedCompanyLanguages(selectedCompanyLanguages: CompanyLanguageDto[]) {
    return {
        type: DATA_COMPANY_SET_SELECTED_COMPANY_LANGUAGES,
        payload: selectedCompanyLanguages,
    };
}

export function addUsageToCompanyBilling(type: string) {
    return {
        type: DATA_COMPANY_ADD_USAGE_TO_BILLING,
        payload: type,
    };
}

export function setSeenBillingWarning() {
    return {
        type: DATA_COMPANY_SET_SEEN_COMPANY_BILLING_WARNING,
    };
}

export function setSelectedCompany(selectedCompany: CompanyDto): PayloadAction {
    return {
        type: DATA_COMPANY_SET_SELECTED_COMPANY,
        payload: selectedCompany,
    };
}

export function setSelectedCompanyId(selectedCompanyId: string): PayloadAction {
    return {
        type: DATA_COMPANY_SET_SELECTED_COMPANY_ID,
        payload: selectedCompanyId,
    };
}

export function setCompanies(companies: Array<CompanyDto>): PayloadAction {
    return {
        type: CONTEXT_SET_COMPANIES_SUCCESS,
        payload: companies,
    };
}

export function setOtherUserCompanies(userId: string, companies: Array<CompanyDto>): PayloadAction {
    return {
        type: DATA_COMPANY_SET_OTHER_USER_COMPANIES,
        payload: companies,
    };
}

export function setCompany(company: CompanyDto): PayloadAction {
    return {
        type: DATA_COMPANY_SET_COMPANY,
        payload: company,
    };
}
