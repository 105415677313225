import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmDialog } from '../../../../../../../../apps/no-code-x-backoffice/src/app/common/lib/confirmdialog/confirm.dialog.lib';
import { DataFormatEditorFacade, OverviewDataFormatDto, SelectedFacets } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-search-dataformat-dialog',
    templateUrl: './search-dataformat-dialog.component.html',
})
export class SearchDataformatDialogComponent implements OnInit, OnDestroy {
    facets$ = this.editFacade.facets;
    page$ = this.editFacade.dialogData;
    pagination$ = this.editFacade.pagination;
    filterPlugins$ = this.editFacade.filterPlugins;
    searchTerm$ = this.editFacade.searchTerm.pipe(map(term => (!!term ? term : '')));
    selectedRows: { id: string }[] = [];

    @HostListener('document:keyup', ['$event'])
    handleFastKeysForConsole(event: KeyboardEvent) {
        if (event.altKey) {
            event.preventDefault();
            switch (event.key) {
                case 'n':
                    this.handleCreateClicked();
                    break;
                case 'r':
                    this.handleDeleteClicked();
                    break;
            }
        }
    }

    constructor(
        private readonly confirmDialog: ConfirmDialog,
        private readonly dialogRef: MatDialogRef<SearchDataformatDialogComponent>,
        private readonly editFacade: DataFormatEditorFacade
    ) {}

    ngOnInit() {
        this.editFacade.initialiseDialogData();
    }

    ngOnDestroy() {
        this.editFacade.clearDialogData();
    }

    handleCreateClicked(): void {
        this.dialogRef.close({
            action: 'create',
            content: {
                type: 'data-format',
            },
        });
    }

    handleDeleteClicked(): void {
        if (this.selectedRows && this.selectedRows.length > 0) {
            this.confirmDialog.showConfirmDialog(
                `v2.data-format.overview.delete.title`,
                `v2.data-format.overview.delete.description`,
                `v2.data-format.overview.delete.ok`,
                `v2.data-format.overview.delete.cancel`,
                `v2.data-format.overview.delete.success.title`,
                `v2.data-format.overview.delete.success.description`,
                () => {
                    this.editFacade.delete(this.selectedRows.map(row => row.id));
                    this.selectedRows = [];
                }
            );
        }
    }

    handleFacetsChanged(selectedFacets: SelectedFacets): void {
        this.editFacade.changeFacets(selectedFacets);
    }

    handleFilterPluginsChanged(filterPlugins: boolean): void {
        this.editFacade.changeFilterPlugins(filterPlugins);
    }

    handleItemClicked(item: OverviewDataFormatDto): void {
        this.dialogRef.close({
            action: 'open',
            content: {
                type: 'data-format',
                name: item.name,
                typeId: item.id,
            },
        });
    }

    handlePaginationChanged(event: PageEvent): void {
        const { pageIndex: page, pageSize: maxResults } = event;
        this.editFacade.changePagination(page, maxResults);
    }

    handleRowSelectionChanged(selected: boolean, id: string): void {
        if (selected) {
            this.selectedRows.push({ id: id });
        } else {
            this.selectedRows = this.selectedRows.filter(entry => entry.id !== id);
        }
    }

    handleSearchTermChanged(term: string | undefined): void {
        this.editFacade.changeSearchTerm(term);
    }
}
