import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { Observable } from 'rxjs';
import { Role } from '@usermanagement/models/role.model';
import { PageDto } from '@shared/data-access';
import { CreateRoleDto } from '@usermanagement/dto/create-role.dto';
import { RightDto } from '../dto/right.dto';
import { LoggerService } from '@backoffice/utils';
import { OverviewRightDto } from '../dto/overview-right.dto';

@Injectable()
export class RightsService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    findById(id: string, companyId: string, applicationId: string): Observable<RightDto> {
        return this.httpClient.get<RightDto>(
            `${backofficeEnvironment.rest.v2.roles}company/${companyId}/application/${applicationId}/${id}`
        );
    }

    getRightsByGroupId(applicationId: string, companyId: string, groupId: string) {
        let params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('page', String(0))
            .set('maxResults', String(1000));

        if (!!groupId) {
            params = params.set('groupId', groupId);
        }

        return this.httpClient.get<PageDto<RightDto>>(`${backofficeEnvironment.rest.v2.roles}`, { params });
    }

    getRightsByApplicationId(applicationId: string, companyId: string, defaultRoles: boolean) {
        const params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('defaultRoles', defaultRoles)
            .set('page', String(0))
            .set('maxResults', String(1000));

        return this.httpClient.get<PageDto<RightDto>>(`${backofficeEnvironment.rest.v2.roles}`, { params });
    }

    getRightsByCompanyId(companyId: string, defaultRoles: boolean) {
        const params = new HttpParams()
            .set('companyId', companyId)
            .set('defaultRoles', defaultRoles)
            .set('page', String(0))
            .set('maxResults', String(1000));

        return this.httpClient.get<PageDto<RightDto>>(`${backofficeEnvironment.rest.v2.roles}`, { params });
    }

    findAll(
        applicationId: string,
        companyId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<PageDto<OverviewRightDto>> {
        let params = new HttpParams().set('applicationId', applicationId).set('companyId', companyId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('name', keyword);
            }
            params = params.append('defaultRoles', false);

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }
        }

        return this.httpClient.get<PageDto<OverviewRightDto>>(`${backofficeEnvironment.rest.v2.roles}`, { params });
    }

    fetchByUserId(applicationId: string, companyId: string, userId: string): Observable<PageDto<RightDto>> {
        const params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('userId', userId)
            .set('page', 0)
            .set('maxResults', 1000);

        return this.httpClient.get<PageDto<RightDto>>(`${backofficeEnvironment.rest.v2.roles}`, { params });
    }

    create(dto: CreateRoleDto, companyId: string, applicationId: string): Observable<RightDto> {
        return this.httpClient.post<RightDto>(
            `${backofficeEnvironment.rest.v2.roles}company/${companyId}/application/${applicationId}`,
            dto
        );
    }

    update(role: RightDto, companyId: string, applicationId: string): Observable<RightDto> {
        this.log.info(`Updating role: ${JSON.stringify(role)}`, [RightsService.name]);

        const { id } = role;
        return this.httpClient.put<RightDto>(
            `${backofficeEnvironment.rest.v2.roles}company/${companyId}/application/${applicationId}/${id}`,
            role
        );
    }

    delete(roleId: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Deleting role', [roleId]);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.roles}company/${companyId}/application/${applicationId}/${roleId}`
        );
    }
}
