import { PartDetail } from '../../../part-detail.model';

export class LineChartPartDetail extends PartDetail {
    id: string;
    code = 'code';
    title: string;
    xaxis: AxisConfiguration;
    datasources: DatasourceConfiguration[] = [];

    public override isValid(): boolean {
        return true;
    }

    public override hasActions(): boolean {
        return false;
    }
}

export class DatasourceConfiguration {
    id: string;
    code: string;
    name: string;
    points: string;

    constructor(base?: Partial<DatasourceConfiguration>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.code = base.code ?? this.code;
            this.name = base.name ?? this.name;
            this.points = base.points ?? this.points;
        }
    }
}

export class AxisConfiguration {
    id?: string;
    name?: string;
    points?: string;

    constructor(base?: Partial<AxisConfiguration>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.points = base.points ?? this.points;
        }
    }
}
