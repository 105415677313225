import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TabDefinition } from '../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, Observable, ReplaySubject, Subscription } from 'rxjs';
import { OverviewTemplateDto, TemplateEditorFacade } from '@backoffice/data-access/editor';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { Argument, createArguments } from '../../../../../../data-access/editor/src/lib/arguments/argument';
import { TemplateTypeAndLanguageComponent } from '../../../../../../ui/editor/src/lib/template/editor/language/template-type-and-language/template-type-and-language.component';

@Component({
    selector: 'app-template-picker',
    templateUrl: './template-picker.component.html',
    styleUrls: ['./template-picker.component.scss'],
})
export class TemplatePickerComponent {
    @Input() label: string | undefined;
    @Input() value: string | undefined | null;
    @Input() required = false;
    @Input() addQuotesToValue = true;
    @Input() enableArguments = false;
    @Input() applicationId: string | undefined;
    @Input() arguments: Argument[] = [];
    @Input() argumentsForParent: string;
    @Input() contextId: string;
    @Input() enableAddButton: boolean = true;

    @Output() valueUpdated: EventEmitter<{ value: string | undefined; arguments?: Argument[] | undefined }> = new EventEmitter<{
        value: string | undefined;
        arguments?: Argument[] | undefined;
    }>();

    @Output() argumentsUpdated: EventEmitter<{ arguments: Argument[] }> = new EventEmitter<{ arguments: Argument[] }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    valueControl(): FormControl {
        return this.form?.get('value') as FormControl;
    }

    filterControl(): FormControl {
        return this.form?.get('valueFilter') as FormControl;
    }

    filterChanged$ = new ReplaySubject<string>();

    filteredTemplates$: Observable<OverviewTemplateDto[]>;

    form: FormGroup;

    missingTemplate = false;

    private readonly _subscriptions = new Subscription();

    subscriptions: Subscription = new Subscription();

    constructor(
        private readonly fb: FormBuilder,
        private readonly templateEditorFacade: TemplateEditorFacade,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        const templateId = this.value ? this.removeQuotesIfPresent(this.value) : undefined;
        const required = this.required;

        this.form = this.fb.group({
            value: [templateId, required ? Validators.required : null],
            valueFilter: [''],
        });

        // De subscription kan na de emit van de value komen en dan missen we die.
        this._subscriptions.add(
            this.filterControl()
                .valueChanges.pipe(distinctUntilChanged())
                .subscribe(value => {
                    this.filterChanged$.next(value);
                })
        );

        this.filteredTemplates$ = this.filterChanged$.pipe(
            switchMap(value =>
                this.templateEditorFacade
                    .findAll(value ?? '', [], 'score desc', 0, 10, this.applicationId)
                    .pipe(map(({ content }) => content))
            )
        );

        if (!!templateId) {
            this._subscriptions.add(
                this.templateEditorFacade
                    .findById(templateId, this.applicationId)
                    .pipe(
                        take(1),
                        tap(template => {
                            if (!template.deleted) {
                                const { name, id } = template;
                                this.filterControl().setValue(name);
                                this.valueControl().setValue(id);
                                this.missingTemplate = false;
                                if (this.enableArguments) {
                                    this.mergeArguments(createArguments(template.parameters));
                                }
                                this.changeDetectorRef.detectChanges();
                            } else {
                                this.filterControl().setValue('');
                                this.filterControl().setValue('Missing template', { emitEvent: false });
                                this.missingTemplate = true;
                                if (this.enableArguments) {
                                    this.arguments = [];
                                }
                                this.changeDetectorRef.detectChanges();
                            }
                        })
                    )
                    .subscribe()
            );
        } else {
            this.filterControl().setValue('');
            if (this.enableArguments) {
                this.arguments = [];
            }
            this.changeDetectorRef.detectChanges();
        }

        this._subscriptions.add(this.valueControl().valueChanges.subscribe(value => this.onChange(value)));
    }

    public mergeArguments(newArguments: Argument[] | undefined) {
        if (newArguments && newArguments.length > 0) {
            this.arguments?.forEach(subArgument => {
                const newArgument: Argument | undefined = newArguments?.find(
                    newArgument => subArgument.selectorId === newArgument.selectorId || subArgument.parameterId === newArgument.parameterId
                );
                if (newArgument) {
                    subArgument.updateArgument(newArgument);
                    subArgument.mergeArguments(newArgument.subArguments);
                }
            });
            newArguments.forEach(newArgument => {
                const existingSubArgument: Argument | undefined = this.arguments?.find(
                    subArgument => subArgument.selectorId === newArgument.selectorId || subArgument.parameterId === newArgument.parameterId
                );
                if (!existingSubArgument) {
                    if (!this.arguments) {
                        this.arguments = [];
                    }
                    this.arguments.push(newArgument);
                }
            });
            if (this.arguments && this.arguments.length > 0) {
                this.arguments = this.arguments.filter(subArgument =>
                    newArguments.find(
                        newArgument =>
                            subArgument.selectorId === newArgument.selectorId || newArgument.parameterId === subArgument.parameterId
                    )
                );
            }
        }
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this._subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    onChange(value: any) {
        if (this.addQuotesToValue) {
            if (this.value !== `'${value}'`) {
                if (value) {
                    this.value = `'${value}'`;
                } else {
                    this.value = undefined;
                }
                this.missingTemplate = false;
                this.valueUpdated.emit({ value: this.value, arguments: this.arguments });
            }
        } else {
            if (this.value !== `${value}`) {
                if (value) {
                    this.value = `${value}`;
                } else {
                    this.value = undefined;
                }
                this.missingTemplate = false;
                this.valueUpdated.emit({ value: this.value, arguments: this.arguments });
            }
        }
    }

    onOpenTemplate(templateId: string | undefined) {
        if (templateId) {
            this.openTab.emit({ typeId: this.removeQuotesIfPresent(templateId), type: 'template' });
        }
    }

    onCreateTemplate() {
        this.templateEditorFacade.create(TemplateTypeAndLanguageComponent).subscribe(templateCreatedId => {
            this.value = `'${templateCreatedId}'`;
            this.valueUpdated.emit({ value: this.value, arguments: [] });
            this.onOpenTemplate(templateCreatedId);
        });
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    deleteTemplateLink(): void {
        this.value = undefined;

        this.valueControl().reset(null, { onlySelf: true, emitEvent: false });
        this.filterControl().reset(null, { onlySelf: true, emitEvent: false });
        this.missingTemplate = false;

        this.valueUpdated.emit({ value: this.value, arguments: [] });
    }
}
