<div class="container">
    <mat-toolbar data-name="header" class="codex-nav-bar" color="primary">
        <div class="logoContainer" (click)="router.navigate(['/'])">
            <div class="logo"></div>
        </div>
    </mat-toolbar>
    <div class="content">
        <h1>{{ '404.title' | translate }}</h1>
        <div class="not-found"></div>
        <button class="home nocodexbutton" color="primary" type="button" mat-button mat-raised-button (click)="router.navigate(['/'])">
            <mat-icon>home</mat-icon>
            {{ '404.back.to.home' | translate }}
        </button>
    </div>
</div>
