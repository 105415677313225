import { Injectable } from '@angular/core';
import { LoggerService } from '@backoffice/utils';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { GenerateTestDataResponse } from '../dto/generate-test-data-response.dto';
import { GenerateDataFormatResponse } from '../dto/generate-data-format-response.dto';

@Injectable()
export class AssistantService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public generateTestData(
        companyId: string,
        applicationId: string,
        prompt: string,
        dataFormatIds: string[]
    ): Observable<GenerateTestDataResponse> {
        this.log.debug('Sending a request to generate test data', [prompt]);
        return this.httpClient.post<GenerateTestDataResponse>(
            `${backofficeEnvironment.rest.v2.assistant.generatetestdata}company/${companyId}/application/${applicationId}/`,
            {
                supportingPrompt: prompt,
                dataFormatIds: dataFormatIds,
            }
        );
    }

    public generateApis(
        companyId: string,
        applicationId: string,
        prompt: string,
        dataFormatIds: string[]
    ): Observable<GenerateTestDataResponse> {
        this.log.debug('Sending a request to generate apis', [prompt]);
        return this.httpClient.post<GenerateTestDataResponse>(
            `${backofficeEnvironment.rest.v2.assistant.generateapis}company/${companyId}/application/${applicationId}/`,
            {
                supportingPrompt: prompt,
                dataFormatIds: dataFormatIds,
            }
        );
    }

    public generateRootPage(companyId: string, applicationId: string, prompt: string, type: string): Observable<GenerateTestDataResponse> {
        this.log.debug('Sending a request to generate root page', [prompt]);
        return this.httpClient.post<GenerateTestDataResponse>(
            `${backofficeEnvironment.rest.v2.assistant.generaterootpage}company/${companyId}/application/${applicationId}/`,
            {
                type,
            }
        );
    }

    public generateGlobalTheme(
        companyId: string,
        applicationId: string,
        prompt: string,
        type: string
    ): Observable<GenerateTestDataResponse> {
        this.log.debug('Sending a request to generate a global theme', [prompt]);
        return this.httpClient.post<GenerateTestDataResponse>(
            `${backofficeEnvironment.rest.v2.assistant.generaterootpage}company/${companyId}/application/${applicationId}/`,
            {
                type,
            }
        );
    }

    public generateCsvImport(
        companyId: string,
        applicationId: string,
        prompt: string,
        dataFormatIds: string[]
    ): Observable<GenerateTestDataResponse> {
        this.log.debug('Sending a request to generate test data', [prompt]);
        return this.httpClient.post<GenerateTestDataResponse>(
            `${backofficeEnvironment.rest.v2.assistant.generatecsvupload}company/${companyId}/application/${applicationId}/`,
            {
                supportingPrompt: prompt,
                dataFormatIds: dataFormatIds,
            }
        );
    }

    public generateDataFormat(companyId: string, applicationId: string, prompt: string): Observable<GenerateDataFormatResponse> {
        this.log.debug('Sending a request to generate test data', [prompt]);
        return this.httpClient.post<GenerateDataFormatResponse>(
            `${backofficeEnvironment.rest.v2.assistant.generatedataformat}company/${companyId}/application/${applicationId}/`,
            {
                supportingPrompt: prompt,
            }
        );
    }
}
