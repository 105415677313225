import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { DataService } from '../../services/data.service';
import { dataEditorSelectors } from '../selectors/data-editor.selectors';
import {
    clearDataDialogFilter,
    createDataSuccess,
    dataDialogFacetsChanged,
    dataDialogFilterPluginsChanged,
    dataDialogPaginationChanged,
    dataDialogSearchTermChanged,
    deleteData,
    deleteDatas,
    deleteDatasSuccess,
    deleteDataSuccess,
    fetchDataSuccess,
    findData,
    loadDataDialogData,
    loadDataDialogSuccess,
    updateData,
    updateDataSuccess,
} from '../actions/data-editor.actions';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { toFilter } from '@backoffice/data-access/editor';
import { DataEditorFacade } from '../facades/data-editor.facade';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { forkJoin } from 'rxjs';
import { Page } from '@shared/data-access';
import { OverviewDataDto } from '../../dto/overview/overview-data.dto';

@Injectable()
export class DataEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly editorFacade: DataEditorFacade,
        private readonly dataService: DataService,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearDataDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDatas),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.dataService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteDatasSuccess({ ids }))
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDataSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'data' }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDatasSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'data' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteData),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.dataService.delete(id, companyId, applicationId).pipe(map(() => deleteDataSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findData),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(dataEditorSelectors.byId(id)),
            ]),
            filter(([_data, company, application, detail]) => {
                return !detail && !!company && !!application;
            }),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.dataService.findById(id, companyId, applicationId).pipe(map(result => fetchDataSuccess({ data: result })))
            )
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteDatasSuccess,
                loadDataDialogData,
                dataDialogPaginationChanged,
                dataDialogSearchTermChanged,
                dataDialogFacetsChanged,
                dataDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.dataService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewDataDto>) => loadDataDialogSuccess({ data }))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateData),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ data }, { id: companyId }, { id: applicationId }]) =>
                this.dataService.update(data, companyId, applicationId).pipe(map(() => updateDataSuccess({ data })))
            )
        )
    );

    openSnack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateDataSuccess),
                tap(() => {
                    this.snackBar.open(this.translate.instant('v2.data.edit.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createDataSuccess),
            filter(({ openTab }) => !!openTab),
            map(({ id, name }) => registerTab({ definition: { type: 'data', typeId: id, name } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDataSuccess),
            map(({ data }) => {
                const { id: typeId, name, iconName: icon } = data;
                return updateTab({ definition: { type: 'data', typeId, name, icon } });
            })
        )
    );
}
