import { GenericStyleV2Dto } from '../../../../../interfaces/style/generic-style-v2.dto';
import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';
import { GenericMarginStyleDto } from '../../../../../interfaces/style/generic-margin.style.dto';
import { GenericPaddingStyleDto } from '../../../../../interfaces/style/generic-padding.style.dto';
import { ColumnsStyleDto } from '../../../../../interfaces/style/columns.style.dto';
import { ColorV2 } from '@shared/data-access';
import { HelpStyleDto } from '../../../../../interfaces/style/help.style.dto';

export interface CheckboxFieldStyle extends GenericStyleV2Dto {
    labelFontStyle: FontStyleDto;
    labelMarginStyle: GenericMarginStyleDto;
    labelPaddingStyle: GenericPaddingStyleDto;

    answerFontStyle: FontStyleDto;
    answerMarginStyle: GenericMarginStyleDto;
    answerPaddingStyle: GenericPaddingStyleDto;

    columnsStyle: ColumnsStyleDto;

    //deprecated
    selectedColor: string;

    selectedColorV2: ColorV2;
    unSelectedColorV2: ColorV2;

    helpStyle: HelpStyleDto;
}
