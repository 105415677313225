import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-rename-propery-dialog',
    templateUrl: './rename-propery-dialog.component.html',
})
export class RenameProperyDialogComponent implements OnInit {
    form = this.fb.group({
        name: ['', { validators: [Validators.required] }],
    });

    constructor(
        private readonly fb: FormBuilder,
        private readonly dialogRef: MatDialogRef<RenameProperyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: string
    ) {}

    ngOnInit(): void {
        this.form.patchValue({
            name: this.data,
        });
    }

    handleCancelClick(): void {
        this.dialogRef.close();
    }

    handleRenameClicked(): void {
        const { valid, value } = this.form;
        if (valid) {
            const { name } = value;
            this.dialogRef.close(name);
        } else {
            this.form.markAllAsTouched();
        }
    }
}
