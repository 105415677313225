import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverviewDesignSystemDto } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-designsystem-overview-v2',
    templateUrl: './designsystem-overview-row.component.html',
    styleUrls: ['./designsystem-overview-row.component.scss'],
})
export class DesignsystemOverviewRowComponent {
    @Input() designsystem: OverviewDesignSystemDto;

    @Output() clicked = new EventEmitter<void>();
    @Output() boxSelectionChanged = new EventEmitter<boolean>();

    checkboxSelected = false;

    onClicked(): void {
        this.clicked.emit();
    }

    onCheckboxSelected() {
        this.checkboxSelected = !this.checkboxSelected;
        this.boxSelectionChanged.emit(this.checkboxSelected);
    }
}
