// Angular Directive - Prevent double click for angular 6 material button (6.2)
// using Angular 6, Angular material button: 6.2.1
import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[preventDoubleClick]',
})
export class PreventDoubleClickPipe {
    constructor() {}

    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.stopPropagation(); // not working as I expected.
        event.preventDefault(); // not working as I expected.

        event.srcElement.setAttribute('disabled', true); // it won't be working unless the element is input.
        event.srcElement.setAttribute('style', 'pointer-events: none;'); // test if 'pointer-events: none' is working but seems not.

        setTimeout(function () {
            event.srcElement.removeAttribute('disabled');
        }, 500);
    }
}
