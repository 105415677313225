export interface MarginStyleDto {
    margin?: string;
}

export function generateMarginStyle(
    marginStyle: MarginStyleDto,
    marginLeftStyle: MarginStyleDto,
    marginRightStyle: MarginStyleDto,
    marginTopStyle: MarginStyleDto,
    marginBottomStyle: MarginStyleDto
) {
    let style = '';
    if (marginStyle) {
        style += generateTypeMarginStyle('margin', marginStyle);
    }
    if (marginLeftStyle) {
        style += generateTypeMarginStyle('margin-left', marginLeftStyle);
    }
    if (marginTopStyle) {
        style += generateTypeMarginStyle('margin-top', marginTopStyle);
    }
    if (marginRightStyle) {
        style += generateTypeMarginStyle('margin-right', marginRightStyle);
    }
    if (marginBottomStyle) {
        style += generateTypeMarginStyle('margin-bottom', marginBottomStyle);
    }
    return style;
}

export function generateTypeMarginStyle(type: string, marginStyleDto: MarginStyleDto) {
    let style = '';
    if (marginStyleDto.margin) {
        style += type + ':' + marginStyleDto.margin + ';';
    }
    return style;
}

export function generateTypeMarginStyleWithVars(marginStyleDto: MarginStyleDto, marginVar: string) {
    let style = '';
    if (marginStyleDto?.margin) {
        style += marginVar + ':' + marginStyleDto.margin + ';';
    }
    return style;
}

export function generateMarginStyleWithVars(
    prefix: string | undefined,
    modifier: string | undefined,
    marginStyle: MarginStyleDto,
    marginTopStyle: MarginStyleDto,
    marginRightStyle: MarginStyleDto,
    marginBottomStyle: MarginStyleDto,
    marginLeftStyle: MarginStyleDto
) {
    const alteredModifier = !!modifier ? '-' + modifier : '';
    const alteredPrefix = !!prefix ? prefix : '';
    const marginStyleCss = generateTypeMarginStyleWithVars(marginStyle, '--' + alteredPrefix + alteredModifier + '-margin');
    const marginTopStyleCss = generateTypeMarginStyleWithVars(marginTopStyle, '--' + alteredPrefix + alteredModifier + '-margin-top');
    const marginRightStyleCss = generateTypeMarginStyleWithVars(marginRightStyle, '--' + alteredPrefix + alteredModifier + '-margin-right');
    const marginBottomStyleCss = generateTypeMarginStyleWithVars(
        marginBottomStyle,
        '--' + alteredPrefix + alteredModifier + '-margin-bottom'
    );
    const marginLeftStyleCss = generateTypeMarginStyleWithVars(marginLeftStyle, '--' + alteredPrefix + alteredModifier + '-margin-left');

    return {
        marginStyleCss,
        marginTopStyleCss,
        marginRightStyleCss,
        marginBottomStyleCss,
        marginLeftStyleCss,
    };
}
