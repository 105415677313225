<div>
    <label [attr.for]="'attribute-minimumLength-' + this.attribute.id" class="small-input-label">
        {{ 'v2.data-format.attribute.minimum-length' | translate }}
    </label>
    <input
        [attr.id]="'attribute-minimumLength-' + this.attribute.id"
        [attr.name]="'attribute-minimumLength-' + this.attribute.id"
        class="small-input"
        type="number"
        autocomplete="off"
        (input)="this.change.emit()"
        [(ngModel)]="this.attribute.minimumLength" />

    <label [attr.for]="'attribute-minimumLength-' + this.attribute.id" class="small-input-label">
        {{ 'v2.data-format.attribute.maximum-length' | translate }}
    </label>
    <input
        [attr.id]="'attribute-maximumLength-' + this.attribute.id"
        [attr.name]="'attribute-maximumLength-' + this.attribute.id"
        class="small-input"
        type="number"
        autocomplete="off"
        (input)="this.change.emit()"
        [(ngModel)]="this.attribute.maximumLength" />

    <label [attr.for]="'attribute-pattern-' + this.attribute.id" class="small-input-label">
        {{ 'v2.data-format.attribute.pattern' | translate }}
    </label>
    <input
        [attr.id]="'attribute-pattern-' + this.attribute.id"
        [attr.name]="'attribute-pattern-' + this.attribute.id"
        class="small-input"
        type="number"
        autocomplete="off"
        (input)="this.change.emit()"
        [(ngModel)]="this.attribute.pattern" />

    <label [attr.for]="'attribute-enum-' + this.attribute.id" class="small-input-label">
        {{ 'v2.data-format.attribute.enum' | translate }}
    </label>
    <input
        [attr.id]="'attribute-enum-' + this.attribute.id"
        [attr.name]="'attribute-enum-' + this.attribute.id"
        class="small-input"
        type="text"
        autocomplete="off"
        [(ngModel)]="enum"
        (input)="handleEnumChange($event)" />

    <label [attr.for]="'attribute-type-' + this.attribute.id" class="small-input-label">{{
        'v2.data-format.attribute.dataclassification' | translate
    }}</label>
    <select
        [attr.id]="'attribute-type-' + this.attribute.id"
        class="small-input"
        (input)="this.change.emit()"
        [(ngModel)]="this.attribute.dataClassification">
        <option value="SECRET">{{ 'v2.data-format.attribute.dataclassifications.secret' | translate }}</option>
        <option value="MEDICAL">{{ 'v2.data-format.attribute.dataclassifications.medical' | translate }}</option>
        <option value="CONFIDENTIAL">{{ 'v2.data-format.attribute.dataclassifications.confidential' | translate }}</option>
        <option value="RESTRICTED">{{ 'v2.data-format.attribute.dataclassifications.restricted' | translate }}</option>
        <option value="UNRESTRICTED">{{ 'v2.data-format.attribute.dataclassifications.unrestricted' | translate }}</option>
        <option value="PUBLIC">{{ 'v2.data-format.attribute.dataclassifications.public' | translate }}</option>
    </select>
</div>
