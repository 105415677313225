import { NgModule } from '@angular/core';
import { DeveloperOverviewComponent } from './components/developer-overview/developer-overview.component';
import { EditDeveloperPermissionsComponent } from './components/edit-developer-permissions/edit-developer-permissions.component';
import { EditUserPermissionsComponent } from './components/edit-user-permissions/edit-user-permissions.component';
import { UserOverviewComponent } from './components/user-overview/user-overview.component';
import { CommonsModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { BackofficeUiSearchModule } from '@backoffice/ui/search';
import { CreateUserComponent } from './components/create-user/create-user.component';

@NgModule({
    imports: [CommonsModule, BackofficeUiSearchModule],
    declarations: [
        DeveloperOverviewComponent,
        EditDeveloperPermissionsComponent,
        EditUserPermissionsComponent,
        UserOverviewComponent,
        CreateUserComponent,
    ],
    exports: [DeveloperOverviewComponent, EditDeveloperPermissionsComponent, EditUserPermissionsComponent, UserOverviewComponent],
})
export class BackofficeUiCompanyUsersModule {}
