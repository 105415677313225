import { PartStyle } from '../../../part-style.model';
import { GridStyleDto } from '../../../../../interfaces/grid/grid.style.dto';
import { AccordionStyle } from './accordion.style';

export class AccordionPartStyle extends PartStyle {
    normalStyle: AccordionStyle;

    hoverStyle: AccordionStyle;

    grid: GridStyleDto;
}
