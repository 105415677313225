import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationDto } from '../../../../../utils/src/lib/interfaces/application.dto';
import { selectCurrentContext } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationLogService, ApplicationLogDto } from '@backoffice/data-access/application-logs';
import { ApplicationState } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';

@Injectable()
export class ApplicationLogsFacade {
    currentContext$: Observable<{ userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> =
        this.store.select(selectCurrentContext);

    constructor(
        private actionLogService: ApplicationLogService,
        private store: Store<ApplicationState>
    ) {}

    public getActionLogs(templateId: string, actionId: string, from: Date, to: Date, level: string): Observable<ApplicationLogDto[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionLogService.findApplicationLogs(
                    currentContext.selectedCompany.id,
                    currentContext.selectedApplication.id,
                    templateId,
                    actionId,
                    from,
                    to,
                    level
                );
            })
        );
    }
}
