import { Component, Inject, OnInit } from '@angular/core';
import { ApplicationVersionFacade } from '@backoffice/editor/data-access/application-version';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-application-version-diff',
    templateUrl: './application-version-diff.component.html',
    styleUrls: ['./application-version-diff.component.scss'],
})
export class ApplicationVersionDiffComponent implements OnInit {
    comparison$: Observable<any>;

    constructor(
        private readonly facade: ApplicationVersionFacade,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            sourceVersionId: string;
            currentVersionId: string;
        }
    ) {}

    ngOnInit(): void {
        const { sourceVersionId, currentVersionId } = this.data;
        this.comparison$ = this.facade.compare(sourceVersionId, currentVersionId);
    }
}
