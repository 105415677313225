<div class="overview-item" (click)="onClicked()">
    <div class="icon">
        <input (click)="$event.stopPropagation(); onCheckboxSelected()" id="default-checkbox" type="checkbox" value="" class="checkbox" />
        <i class="material-icons">
            {{ template.iconName ? template.iconName : 'content_paste' }}
        </i>
    </div>
    <div class="name-data">
        <div class="name">
            <span [matBadge]="template.languageCode">{{ template.name }}</span>
        </div>
        <div class="product-reference" *ngIf="template.productReference">
            <mat-icon class="text-lg leading-6 !h-5 !w-5">electrical_services</mat-icon>
            <div class="leading-6">{{ template.productReferenceName }}</div>
        </div>
    </div>
    <div class="audit-data">
        <div>
            {{ 'general.search.result.created.by' | translate }}
            {{ template.createdBy }}
            {{ 'general.search.result.on' | translate }} {{ template.creationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
        <div>
            {{ 'general.search.result.updated.by' | translate }} {{ template.modifiedBy }} {{ 'general.search.result.on' | translate }}
            {{ template.modificationDate | date: 'dd/MM/yyyy HH:mm:ss' }}
        </div>
    </div>
    <div class="attribute-icons flex-[10%]">
        <mat-icon [matTooltip]="'v2.template.overview.attribute.authenticated-access' | translate" *ngIf="template.authenticatedAccess"
            >lock
        </mat-icon>
        <mat-icon [matTooltip]="'v2.template.overview.attribute.component' | translate" *ngIf="template.component"> grid_view </mat-icon>
    </div>
</div>
