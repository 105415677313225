import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { PartTypeDto } from '../../interfaces/parttype/part-type.dto';

@Injectable()
export class PartTypeService {
    constructor(private httpClient: HttpClient) {}

    getPartTypes(companyId: string, applicationId: string): Observable<PartTypeDto[]> {
        return this.httpClient.get<PartTypeDto[]>(
            `${backofficeEnvironment.rest.v2.partTypes}company/${companyId}/application/${applicationId}`
        );
    }
}
