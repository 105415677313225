import { JsonProperty } from './json-property';
import { JsonEntity } from './json-entity';

const type = ['array', 'boolean', 'integer', 'null', 'number', 'object', 'string'];
type Type = (typeof type)[number];
export class JsonSchema implements JsonEntity {
    $schema = 'https://json-schema.org/draft/2020-12/schema';
    $id = 'https://example.com/product.schema.json';
    title: string | undefined;
    description?: string | undefined;
    type: Type[] = ['object'];
    required?: string[] | undefined;
    properties?: {
        [key: string]: JsonProperty;
    } = {};

    constructor(base: Partial<JsonSchema> = {}) {
        this.title = base.title;
        this.description = base.description;
        this.type = base.type ?? ['object'];
        this.required = base.required;

        if (base.properties) {
            this.properties = {};
            const propertyNames = base.properties ? Object.keys(base.properties) : [];
            propertyNames.forEach(name => {
                this.properties[name] = new JsonProperty(base.properties[name]);
            });
        }
    }

    get propertyKeys(): string[] {
        return this.properties ? Object.keys(this.properties) : [];
    }

    getPropertyValue(key: string): JsonProperty | undefined {
        return this.properties[key];
    }
}
