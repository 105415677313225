<mat-dialog-content class="!p-4 min-h-[400px]">
    <ng-container *ngIf="{ applicationVersions: applicationVersions$ | async } as observables">
        <ng-container *ngIf="!addNewApplicationVersion">
            <div *ngIf="observables.applicationVersions && observables.applicationVersions.length > 0" class="buttonContainer">
                <button class="primary-button button-large flex gap-1 mb-2" (click)="addNewApplicationVersion = true">
                    <mat-icon>add</mat-icon>
                    {{ 'v2.applicationversion.add' | translate }}
                </button>
            </div>
            <ul class="item-list relative" *ngIf="observables.applicationVersions && observables.applicationVersions.length > 0">
                <ng-container *ngFor="let applicationVersion of observables.applicationVersions">
                    <li>
                        <div class="item-name flex items-center gap-1">
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                *ngIf="applicationVersion?.environments?.indexOf('PRODUCTION') > -1"
                                [matTooltip]="'v2.applicationversion.production' | translate"
                                class="icon released">
                                <use href="#version" class="ui-element"></use>
                            </svg>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                *ngIf="applicationVersion?.environments?.indexOf('ACCEPT') > -1"
                                [matTooltip]="'v2.applicationversion.acceptance' | translate"
                                class="icon acceptance">
                                <use href="#version" class="ui-element"></use>
                            </svg>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                *ngIf="applicationVersion?.environments?.indexOf('TEST') > -1"
                                [matTooltip]="'v2.applicationversion.test' | translate"
                                class="icon test">
                                <use href="#version" class="ui-element"></use>
                            </svg>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                *ngIf="
                                    applicationVersion?.environments?.indexOf('TEST') === -1 &&
                                    applicationVersion.environments.indexOf('ACCEPT') === -1 &&
                                    applicationVersion.environments.indexOf('PRODUCTION') === -1
                                "
                                [matTooltip]="'Not in use'"
                                class="icon">
                                <use href="#version" class="ui-element"></use>
                            </svg>
                            <span>{{ applicationVersion.name }}</span>
                        </div>
                        <div class="spinner">
                            <mat-spinner diameter="20" *ngIf="applicationVersion.building()"></mat-spinner>
                        </div>
                        <div class="error" *ngIf="applicationVersion.failed()">
                            <mat-icon>error</mat-icon>
                        </div>
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            *ngIf="!applicationVersion.building()"
                            (click)="onHandleCompare(applicationVersion.id)"
                            [matTooltip]="'v2.applicationversion.compare' | translate"
                            class="icon icon-button">
                            <use href="#differences" class="ui-element"></use>
                        </svg>
                        <div
                            *ngIf="
                                applicationVersion.canPromote() &&
                                (applicationVersion.canPromoteToTest() ||
                                    applicationVersion.canPromoteToAccept() ||
                                    applicationVersion.canPromoteToProduction())
                            "
                            [attr.data-dropdown-toggle]="'promoteDropdown-' + applicationVersion.id"
                            data-dropdown-trigger="click"
                            data-name="controls">
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                [matTooltip]="'v2.applicationversion.promote.title' | translate"
                                class="icon icon-button">
                                <use href="#promote-version" class="ui-element"></use>
                            </svg>
                        </div>
                    </li>
                    <div [attr.id]="'promoteDropdown-' + applicationVersion.id" class="dropdown-items hidden">
                        <ul class="dropdown-items-wrapper" aria-labelledby="dropdownDelayButton">
                            <li>
                                <a
                                    *ngIf="applicationVersion.canPromoteToTest()"
                                    (click)="onPromoteToTest(applicationVersion)"
                                    class="dropdown-item">
                                    <mat-icon class="test">check_circle</mat-icon>
                                    {{ 'v2.applicationversion.promote.test' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    *ngIf="applicationVersion.canPromoteToAccept()"
                                    (click)="onPromoteToAcceptance(applicationVersion)"
                                    class="dropdown-item">
                                    <mat-icon class="acceptance">check_circle</mat-icon>
                                    {{ 'v2.applicationversion.promote.acceptance' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    *ngIf="applicationVersion.canPromoteToProduction()"
                                    (click)="onPromoteToProduction(applicationVersion)"
                                    class="dropdown-item">
                                    <mat-icon class="released">check_circle</mat-icon>
                                    {{ 'v2.applicationversion.promote.production' | translate }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </ul>
            <div
                class="no-versions flex flex-col justify-center items-center gap-2"
                *ngIf="observables.applicationVersions && observables.applicationVersions.length === 0">
                <h1 class="!mb-2">{{ 'v2.applicationversion.none.found.title' | translate }}</h1>
                <img class="w-[350px] h-[150px] object-cover" src="/src/images/theme/no_results_found.gif" />
                <p class="text-white">{{ 'v2.applicationversion.none.found.description' | translate }}</p>
                <button class="primary-button button-large flex justify-center gap-1" (click)="onCreateApplicationVersion()">
                    <mat-icon>add</mat-icon>
                    {{ 'v2.templateversion.add.first' | translate }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="addNewApplicationVersion">
            <codex-application-version-detail (onHide)="onHideNewApplicationVersion($event)"></codex-application-version-detail>
        </ng-container>
    </ng-container>
</mat-dialog-content>
