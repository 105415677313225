import { PartStyle } from '../../../part-style.model';

export class TreeMapPartStyle extends PartStyle {
    nodes: TreeMapNodeSourceStyle[] = [];
    legend = new TreeMapLegendStyle();

    constructor(base?: Partial<TreeMapPartStyle>) {
        super();

        if (!!base) {
            this.nodes = base.nodes ?? this.nodes;
            this.legend = base.legend ?? this.legend;
        }
    }
}

export class TreeMapNodeSourceStyle {
    id: string;
    name: string;
    color?: string;

    constructor(base?: Partial<TreeMapNodeSourceStyle>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.color = base.color ?? this.color;
        }
    }
}

export class TreeMapLegendStyle {
    show = true;
    orient?: 'vertical' | 'horizontal' = 'horizontal';
    left?: 'auto' | 'left' | 'center' | 'right';
    top?: 'auto' | 'top' | 'middle' | 'bottom';
}
