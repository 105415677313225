import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataFormatAttribute } from '../../../../../../../../../data-access/editor/src/lib/dataformat/models/data-format-attribute';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-integer-type-settings',
    templateUrl: './integer-type-settings.component.html',
})
export class IntegerTypeSettingsComponent {
    @Input() attribute: DataFormatAttribute;

    @Output() change: EventEmitter<void> = new EventEmitter();
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
