import { catchError, map, switchMap } from 'rxjs/operators';
import { Font } from '../models/font';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

const previewFontsStylesheet = document.createElement('style');
document.head.appendChild(previewFontsStylesheet);

@Injectable()
export class GoogleFontService {
    readonly PREVIEW_ATTRIBUTE_NAME = 'data-is-preview';
    readonly apiKey = 'AIzaSyCnM4B4cVq6j5orBPTNbZ39mjXYnYz7kOE';

    list(searchTerm: string): Observable<Font[]> {
        const url = new URL('https://www.googleapis.com/webfonts/v1/webfonts');
        url.searchParams.append('sort', 'popularity');
        url.searchParams.append('key', this.apiKey);

        return this.get(url.href).pipe(
            map(json => JSON.parse(json)),
            map(response =>
                response.items
                    .map((font: any) => new Font({ ...font }))
                    .filter((f: Font) => f.family !== 'Material Icons' && f.family.toLowerCase().includes(searchTerm.toLowerCase()))
                    .slice(0, 20)
            ),
            switchMap((fonts: Font[]) => {
                const nonExistingFonts = fonts.map(font => font.id).filter(fontId => !this.stylesheetExists(fontId));

                // Create stylesheet elements for all fonts which will be fetched
                nonExistingFonts.forEach(fontId => this.createStylesheet(fontId));

                return this.stylesheet(fonts).pipe(
                    map(response => {
                        const fontStyles = this.extractFontStyles(response);

                        fonts.forEach(font => {
                            // this.applyFontPreview(font);
                            if (nonExistingFonts.includes(font.id)) {
                                if (!(font.id in fontStyles)) {
                                    return;
                                }
                                this.fillStylesheet(font.id, fontStyles[font.id]);
                            }
                        });
                        return fonts;
                    })
                );
            }),
            catchError(() => of([]))
        );
    }

    stylesheet(fonts: Font[]): Observable<string> {
        const url = new URL('https://fonts.googleapis.com/css');
        const previewText = 'Display Large Medium Small Headline Title Label Body';

        // Build query URL for specified font families and variants
        const familiesStr = fonts.map((font): string => `${font.family}:regular}`);
        url.searchParams.append('family', familiesStr.join('|'));
        url.searchParams.append('subset', 'latin');

        // Concatenate the family names of all fonts
        const familyNamesConcat = fonts
            .map((font): string => font.family)
            .join('')
            .concat(previewText);

        // Create a string with all characters (listed once) contained in the font family names
        const downloadChars = familyNamesConcat
            .split('')
            .filter((char, pos, self): boolean => self.indexOf(char) === pos)
            .join('');

        // Query only the identified characters
        //url.searchParams.append('text', downloadChars);

        // Fetch and return stylesheet
        return this.get(url.href);
    }

    get(url: string): Observable<string> {
        return Observable.create(function (observer: any) {
            const request = new XMLHttpRequest();
            request.overrideMimeType('application/json');
            request.open('GET', url, true);
            request.onreadystatechange = (): void => {
                // Request has completed
                if (request.readyState === 4) {
                    if (request.status !== 200) {
                        // On error
                        observer.error(new Error(`Response has status code ${request.status}`));
                    } else {
                        // On success
                        observer.next(request.responseText);
                    }
                }
            };
            request.send();
        });
    }

    private applyFontPreview(previewFont: Font): void {
        const fontId = this.getFontId(previewFont.family);
        const style = `
			#font-button-${fontId} {
				font-family: "${previewFont.family}";
			}
		`;
        previewFontsStylesheet.appendChild(document.createTextNode(style));
    }

    private createStylesheet(fontId: string): void {
        const stylesheetNode = document.createElement('style');
        stylesheetNode.id = this.getStylesheetId(fontId);
        stylesheetNode.setAttribute(this.PREVIEW_ATTRIBUTE_NAME, 'true');
        document.head.appendChild(stylesheetNode);
    }

    private extractFontStyles(allFontStyles: string): Record<string, string> {
        const FONT_FACE_REGEX = /@font-face {([\s\S]*?)}/gm;
        const FONT_FAMILY_REGEX = /font-family: ['"](.*?)['"]/gm;

        // Run Regex to separate font-face rules
        const rules = this.getMatches(FONT_FACE_REGEX, allFontStyles);

        // Assign font-face rules to fontIds
        const fontStyles: Record<string, string> = {};
        rules.forEach((rule): void => {
            // Run regex to get font family
            const fontFamily = this.getMatches(FONT_FAMILY_REGEX, rule)[0];
            const fontId = this.getFontId(fontFamily);

            // Append rule to font font family's other rules
            if (!(fontId in fontStyles)) {
                fontStyles[fontId] = '';
            }
            fontStyles[fontId] += `@font-face {\n${rule}\n}\n\n`;
        });
        return fontStyles;
    }

    private fillStylesheet(fontId: string, styles: string): void {
        const stylesheetId = this.getStylesheetId(fontId);
        const stylesheetNode = document.getElementById(stylesheetId);
        if (stylesheetNode) {
            stylesheetNode.textContent = styles;
        } else {
            console.error(`Could not fill stylesheet: Stylesheet with ID "${stylesheetId}" not found`);
        }
    }

    private getFontId(fontFamily: string): string {
        return fontFamily.replace(/\s+/g, '-').toLowerCase();
    }

    private getMatches(regex: RegExp, str: string): string[] {
        const matches: string[] = [];
        let match;
        do {
            match = regex.exec(str);
            if (match) {
                matches.push(match[1]);
            }
        } while (match);
        return matches;
    }

    private getStylesheetId(fontId: string): string {
        return `font-picker-${fontId}`;
    }

    private stylesheetExists(fontId: string, isPreview?: boolean): boolean {
        const stylesheetNode = document.getElementById(this.getStylesheetId(fontId));
        if (isPreview === null || isPreview === undefined) {
            return stylesheetNode !== null;
        }
        return stylesheetNode !== null && stylesheetNode.getAttribute(this.PREVIEW_ATTRIBUTE_NAME) === isPreview.toString();
    }
}
