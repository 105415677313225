import { PartDetail } from '../../../part-detail.model';

export class TreeMapPartDetail extends PartDetail {
    code: string;
    label: string;
    title: string;
    nodes: TreeMapNodeConfiguration[] = [];

    constructor(base?: Partial<TreeMapPartDetail>) {
        super();
        if (!!base) {
            this.code = base.code ?? this.code;
            this.label = base.label ?? this.label;
            this.title = base.title ?? this.title;
            this.nodes = base.nodes ?? this.nodes;
        }
    }
}

export class TreeMapNodeConfiguration {
    id: string;
    name: string;
    value: number;
    children: TreeMapNodeConfiguration[] = [];

    constructor(base?: Partial<TreeMapNodeConfiguration>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.value = base.value ?? this.value;
            this.children = base.children ?? this.children;
        }
    }
}
