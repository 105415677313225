<mat-dialog-content>
    <div>
        <h1 class="!text-xxl !font-bold !text-black !mb-4">Add a new property to the schema</h1>
        <div class="mb-2">
            <label for="select-type" class="small-input-label">Type</label>
            <select id="select-type" class="small-input" [formControl]="typeControl">
                <option [value]="'boolean'">Boolean</option>
                <option [value]="'integer'">Integer</option>
                <option [value]="'null'">Null</option>
                <option [value]="'number'">Number</option>
                <option [value]="'object'">Object</option>
                <option [value]="'string'">String</option>
            </select>
        </div>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" (click)="onAddClicked()">
                <mat-icon>add_circle</mat-icon>
                {{ 'v2.template.create' | translate }}
            </button>
            <button class="secondary-button button-large" (click)="onCancelClick()">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-content>
