<div>
    <label for="attribute-multipleOf" class="small-input-label">
        Multiple Of
        <div
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="attributeMultipleOfHelp"
            [appendTo]="'body'"
            [boundariesElement]="'body'"
            [preventOverflow]="false"
            [positionFixed]="true"
            [applyClass]="'!max-w-[250px]'"
            [placement]="NgxFloatUiPlacements.BOTTOMSTART"
            class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -end-2 dark:border-gray-900">
            <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </label>
    <float-ui-content #attributeMultipleOfHelp>
        <div class="popover">
            Numbers can be restricted to a multiple of a given number, using the multipleOf keyword. It may be set to any positive number.
        </div>
    </float-ui-content>
    <input
        class="small-input"
        type="number"
        id="attribute-multipleOf"
        name="attribute-multipleOf"
        class="small-input"
        autocomplete="off"
        (input)="this.change.emit()"
        [(ngModel)]="attribute.multipleOf" />

    <label for="attribute-minimum" class="small-input-label">
        Minimum
        <div
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="attributeMinimum"
            [appendTo]="'body'"
            [boundariesElement]="'body'"
            [preventOverflow]="false"
            [positionFixed]="true"
            [applyClass]="'!max-w-[250px]'"
            [placement]="NgxFloatUiPlacements.BOTTOMSTART"
            class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -end-2 dark:border-gray-900">
            <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </label>
    <float-ui-content #attributeMinimum>
        <div class="popover">x ≥ minimum</div>
    </float-ui-content>
    <input
        class="small-input"
        type="number"
        id="attribute-minimum"
        name="attribute-minimum"
        class="small-input"
        autocomplete="off"
        (input)="this.change.emit()"
        [(ngModel)]="attribute.minimum" />

    <label for="attribute-exclusiveMinimum" class="small-input-label">
        Exclusive Minimum
        <div
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="attributeExclusiveMinimum"
            [appendTo]="'body'"
            [boundariesElement]="'body'"
            [preventOverflow]="false"
            [positionFixed]="true"
            [applyClass]="'!max-w-[250px]'"
            [placement]="NgxFloatUiPlacements.BOTTOMSTART"
            class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -end-2 dark:border-gray-900">
            <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </label>
    <float-ui-content #attributeExclusiveMinimum>
        <div class="popover">x > exclusiveMinimum</div>
    </float-ui-content>
    <input
        class="small-input"
        type="number"
        id="attribute-exclusiveMinimum"
        name="attribute-exclusiveMinimum"
        class="small-input"
        autocomplete="off"
        (input)="this.change.emit()"
        [(ngModel)]="attribute.exclusiveMinimum" />

    <label for="attribute-maximum" class="small-input-label">
        Maximum
        <div
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="attributeMaximum"
            [appendTo]="'body'"
            [boundariesElement]="'body'"
            [preventOverflow]="false"
            [positionFixed]="true"
            [applyClass]="'!max-w-[250px]'"
            [placement]="NgxFloatUiPlacements.BOTTOMSTART"
            class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -end-2 dark:border-gray-900">
            <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </label>
    <float-ui-content #attributeMaximum><div class="popover">x ≤ maximum</div></float-ui-content>
    <input
        class="small-input"
        type="number"
        id="attribute-maximum"
        name="attribute-maximum"
        class="small-input"
        autocomplete="off"
        (input)="this.change.emit()"
        [(ngModel)]="attribute.maximum" />

    <label for="attribute-exclusiveMaximum" class="small-input-label">
        Exclusive Maximum
        <div
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="attributeExclusiveMaximum"
            [appendTo]="'body'"
            [boundariesElement]="'body'"
            [preventOverflow]="false"
            [positionFixed]="true"
            [applyClass]="'!max-w-[250px]'"
            [placement]="NgxFloatUiPlacements.BOTTOMSTART"
            class="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -end-2 dark:border-gray-900">
            <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </label>
    <float-ui-content #attributeExclusiveMaximum><div class="popover">x < exclusiveMaximum</div></float-ui-content>
    <input
        class="small-input"
        type="number"
        id="attribute-exclusiveMaximum"
        name="attribute-exclusiveMaximum"
        class="small-input"
        autocomplete="off"
        (input)="this.change.emit()"
        [(ngModel)]="attribute.exclusiveMaximum" />

    <label for="types" class="small-input-label">{{ 'v2.data-format.attribute.dataclassification' | translate }}</label>
    <select id="types" class="small-input" [(ngModel)]="this.attribute.dataClassification">
        <option value="SECRET">{{ 'v2.data-format.attribute.dataclassifications.secret' | translate }}</option>
        <option value="MEDICAL">{{ 'v2.data-format.attribute.dataclassifications.medical' | translate }}</option>
        <option value="CONFIDENTIAL">{{ 'v2.data-format.attribute.dataclassifications.confidential' | translate }}</option>
        <option value="RESTRICTED">{{ 'v2.data-format.attribute.dataclassifications.restricted' | translate }}</option>
        <option value="UNRESTRICTED">{{ 'v2.data-format.attribute.dataclassifications.unrestricted' | translate }}</option>
        <option value="PUBLIC">{{ 'v2.data-format.attribute.dataclassifications.public' | translate }}</option>
    </select>
</div>
