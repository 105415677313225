import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Page } from '@shared/data-access';
import { DataFormat } from '../../../../../../libs/backoffice/data-access/editor/src/lib/dataformat/models/data-format';
import { LoggerService } from '@backoffice/utils';
import { JsonSchema } from '../../../../../../libs/backoffice/data-access/editor/src/lib/dataformat/models/json-schema';
import { OverviewDataFormatDto } from '@backoffice/data-access/editor';

@Injectable({
    providedIn: 'root',
})
export class DataFormatService {
    constructor(
        private httpClient: HttpClient,
        private log: LoggerService
    ) {}

    public getDataFormat(dataFormatId: string, companyId: string, applicationId: string): Observable<DataFormat> {
        this.log.debug('Getting data format', [dataFormatId]);
        return this.httpClient
            .get<DataFormat>(`${backofficeEnvironment.rest.v2.dataFormat}company/${companyId}/application/${applicationId}/${dataFormatId}`)
            .pipe(
                map(data => {
                    const dataFormat = plainToClass(DataFormat, data);
                    const jsonSchemaStr: string = dataFormat.jsonSchema + '';
                    dataFormat.jsonSchema = new JsonSchema(JSON.parse(jsonSchemaStr));
                    return dataFormat;
                })
            );
    }

    public getDataFormats(
        companyId: string,
        applicationId: string,
        keyword: string,
        orderBy: string,
        filters: string[],
        page: number,
        maxResults: number
    ): Observable<Page<OverviewDataFormatDto>> {
        this.log.debug('Getting data format overview');
        let params = new HttpParams()
            .set('companyId', companyId)
            .set('applicationId', applicationId)
            .set('orderBy', orderBy)
            .set('page', String(page))
            .set('maxResults', String(maxResults));

        if (keyword) {
            params = params.append('keyword', keyword);
        }

        filters.forEach(filter => (params = params.append('filters', filter)));
        return this.httpClient.get<Page<OverviewDataFormatDto>>(`${backofficeEnvironment.rest.v2.dataFormat}`, { params: params });
    }
}
