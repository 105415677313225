import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';
import { ApplicationVersionFacade } from '../../../../../../data-access/application-version/src/lib/facades/application-version.facade';

@Component({
    selector: 'codex-application-version-detail',
    templateUrl: './application-version-detail.component.html',
    styleUrls: ['./application-version-detail.component.scss'],
})
export class ApplicationVersionDetailComponent implements OnInit {
    formGroup: FormGroup;

    @Output() onHide: EventEmitter<{ created: boolean }> = new EventEmitter();

    constructor(
        public fb: FormBuilder,
        public snackBar: MatSnackBar,
        public translate: TranslateService,
        private readonly appVersionFacade: ApplicationVersionFacade
    ) {}

    ngOnInit(): void {
        this.initFormGroup();
    }

    initFormGroup() {
        this.formGroup = this.fb.group({
            name: ['', Validators.required],
            description: [''],
        });
    }

    onCreateNewApplicationVersion(): void {
        this.appVersionFacade
            .create({
                description: this.formGroup.get('description').value,
                name: this.formGroup.get('name').value,
                overwriteEnabled: false,
            })
            .pipe(
                catchError(err => {
                    if (err.error.message === 'NAME_ALREADY_EXISTS') {
                        this.formGroup.get('name').setErrors({ nameAlreadyExists: true });
                    } else {
                        this.snackBar.open('error.unknown.error', null, {
                            duration: 10000,
                            panelClass: 'error',
                        });
                    }
                    return of(null);
                })
            )
            .subscribe(result => {
                if (result) {
                    this.snackBar.open(this.translate.instant('v2.applicationversion.create.success'), null, {
                        panelClass: ['success'],
                    });
                    this.onHide.emit({ created: true });
                }
            });
    }
}
