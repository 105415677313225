<ng-container [formGroup]="formGroup">
    <div class="flex flex-col">
        <label class="small-input-label">{{ 'v2.tags' | translate }}</label>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
                #tagInput
                type="text"
                (click)="onClickInput()"
                (keydown.enter)="addTagAfterEnter()"
                matInput
                formControlName="tagInput"
                [matAutocomplete]="auto" />
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="toolpaneSelect scrollbar scrollbar-primary">
                <mat-option
                    (onSelectionChange)="onAddSelectedTag(autoCompleteItem); tagInput.value = ''"
                    *ngFor="let autoCompleteItem of tags$ | async"
                    [value]="autoCompleteItem">
                    <mat-icon>label</mat-icon>
                    <span>{{ autoCompleteItem.tag }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</ng-container>
<div class="badges">
    <span
        id="badge-dismiss-default"
        *ngFor="let tagLink of tagLinksSubject$ | async"
        class="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300">
        {{ tagLink.tag }}
        <button
            type="button"
            class="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
            data-dismiss-target="#badge-dismiss-default"
            aria-label="Remove"
            (click)="onRemoveTag(tagLink)">
            <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Remove badge</span>
        </button>
    </span>
</div>
