import { Data } from '@angular/router';

export interface DataFormatAttribute {
    id?: string;
    name?: string;
    types: string[];
    noCodeXType: string;
    description?: string | undefined;
    required?: boolean | undefined;
    nullable?: boolean | undefined;

    format?: string;

    attributes?: DataFormatAttribute[] | undefined;

    arrayDefinition?: DataFormatAttribute | undefined;

    dataClassification?: string | undefined;

    //number & integer
    multipleOf?: number | undefined;
    minimum?: number | undefined;
    exclusiveMinimum?: number | undefined;
    maximum?: number | undefined;
    exclusiveMaximum?: number | undefined;

    // string
    minimumLength?: number | undefined;
    maximumLength?: number | undefined;
    pattern?: string | undefined;
    enum?: string[] | undefined;

    // email & url
    domain?: string;

    // array
    minItems?: number;
    uniqueItems?: boolean;
}

export function setTypeBasedPropertiesOfAttribute(attribute: DataFormatAttribute) {
    if (attribute.noCodeXType) {
        if (
            attribute.noCodeXType === 'array' ||
            attribute.noCodeXType === 'boolean' ||
            attribute.noCodeXType === 'integer' ||
            attribute.noCodeXType === 'number' ||
            attribute.noCodeXType === 'object' ||
            attribute.noCodeXType === 'string'
        ) {
            // Do absolutely nothing these are basic types
        } else if (attribute.noCodeXType === 'longstring') {
            attribute.types = ['string', 'null'];
        } else if (attribute.noCodeXType === 'email') {
            attribute.types = ['string', 'null'];
            attribute.format = 'email';
        } else if (attribute.noCodeXType === 'url') {
            attribute.types = ['string', 'null'];
            attribute.format = 'uri';
        } else if (attribute.noCodeXType === 'password') {
            attribute.types = ['string', 'null'];
            attribute.dataClassification = 'SECRET';
        } else if (attribute.noCodeXType === 'file') {
            attribute.types = ['string', 'null'];
        } else if (attribute.noCodeXType === 'user') {
            attribute.types = ['string', 'null'];
        } else if (attribute.noCodeXType === 'currency') {
            attribute.types = ['string', 'null'];
            attribute.pattern = '"^([A-Z]{3}|\\\\$|€|£)?\\\\d+(\\\\.\\\\d{2})?$';
        } else if (attribute.noCodeXType === 'single-select') {
            attribute.types = ['string', 'null'];
            attribute.enum = ['Option1', 'Option2', 'Option3'];
        } else if (attribute.noCodeXType === 'multi-select') {
            attribute.types = ['array', 'null'];
            attribute.arrayDefinition = {
                id: attribute.id + '.type',
                name: attribute.id + '.type',
                noCodeXType: 'string',
                types: ['string'],
            };
            attribute.arrayDefinition.minItems = 1;
            attribute.arrayDefinition.uniqueItems = true;
        } else if (attribute.noCodeXType === 'reference') {
            attribute.types = ['string', 'null'];
        } else if (attribute.noCodeXType === 'date') {
            attribute.types = ['string', 'null'];
            attribute.format = 'date-time';
        }
    }
}
