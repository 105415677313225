<ng-container [formGroup]="form">
    <div class="flex flex-col">
        <div class="flex flex-row gap-1 flex-auto items-center">
            <mat-form-field subscriptSizing="dynamic" [attr.class]="this.missingTemplate ? 'select-error' : ''" appearance="outline">
                <mat-select
                    id="template"
                    formControlName="value"
                    panelClass="toolpaneSelect scrollbar scrollbar-primary"
                    [placeholder]="label ? label : ('v2.action.argument.template.select' | translate)">
                    <mat-option>
                        <ngx-mat-select-search
                            [placeholderLabel]="'v2.action.argument.template.search' | translate"
                            [clearSearchInput]="false"
                            formControlName="valueFilter">
                            <span ngxMatSelectNoEntriesFound>
                                {{ 'v2.action.argument.template.none.found' | translate }}
                            </span>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let template of filteredTemplates$ | async" [value]="template.id">
                        <mat-icon>{{ template.iconName }}</mat-icon>
                        <span>{{ template.name }}</span> <br />
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button
                (click)="deleteTemplateLink(); $event.stopPropagation()"
                *ngIf="!!form.get('value').value"
                [matTooltip]="'v2.action.argument.template.clear' | translate"
                class="destructive-button button-extra-small mb-2">
                <mat-icon>clear</mat-icon>
            </button>
            <button
                (click)="onOpenTemplate(this.value); $event.stopPropagation()"
                *ngIf="!!form.get('value').value && !this.missingTemplate"
                [matTooltip]="'v2.action.argument.template.edit' | translate"
                class="primary-button button-extra-small mb-2">
                <mat-icon>edit</mat-icon>
            </button>
            <button
                (click)="onCreateTemplate(); $event.stopPropagation()"
                *ngIf="this.enableAddButton && (!form.get('value').value || this.missingTemplate)"
                [matTooltip]="'v2.action.argument.template.create' | translate"
                class="primary-button button-extra-small mb-2">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    <mat-error *ngIf="missingTemplate" class="!text-xs">{{ 'v2.action.argument.template.select.not.found' | translate }} </mat-error>
</ng-container>
