import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaddingStyleDto } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-padding-picker',
    templateUrl: './padding-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaddingPickerComponent implements OnInit {
    paddingType: string = 'all';

    @Input()
    set paddingStyle(paddingStyle: PaddingStyleDto) {
        if (paddingStyle) {
            this._paddingStyle = paddingStyle;
        } else {
            this._paddingStyle = this.defaultPaddingStyle();
        }
    }

    _paddingStyle: PaddingStyleDto;

    @Input()
    set paddingTopStyle(paddingStyle: PaddingStyleDto) {
        if (paddingStyle) {
            this._paddingTopStyle = paddingStyle;
        } else {
            this._paddingTopStyle = this.defaultPaddingStyle();
        }
    }

    _paddingTopStyle: PaddingStyleDto;

    @Input()
    set paddingLeftStyle(paddingStyle: PaddingStyleDto) {
        if (paddingStyle) {
            this._paddingLeftStyle = paddingStyle;
        } else {
            this._paddingLeftStyle = this.defaultPaddingStyle();
        }
    }

    _paddingLeftStyle: PaddingStyleDto;

    @Input()
    set paddingBottomStyle(paddingStyle: PaddingStyleDto) {
        if (paddingStyle) {
            this._paddingBottomStyle = paddingStyle;
        } else {
            this._paddingBottomStyle = this.defaultPaddingStyle();
        }
    }

    _paddingBottomStyle: PaddingStyleDto;

    @Input()
    set paddingRightStyle(paddingStyle: PaddingStyleDto) {
        if (paddingStyle) {
            this._paddingRightStyle = paddingStyle;
        } else {
            this._paddingRightStyle = this.defaultPaddingStyle();
        }
    }

    _paddingRightStyle: PaddingStyleDto;

    @Output()
    changePadding: EventEmitter<{
        padding: {
            paddingStyle: PaddingStyleDto;
            paddingTopStyle: PaddingStyleDto;
            paddingLeftStyle: PaddingStyleDto;
            paddingBottomStyle: PaddingStyleDto;
            paddingRightStyle: PaddingStyleDto;
        };
    }> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    defaultPaddingStyle() {
        return {
            padding: null,
        };
    }

    onChangeAllPadding() {
        this.changePadding.emit({
            padding: {
                paddingStyle: this._paddingStyle,
                paddingTopStyle: this._paddingTopStyle,
                paddingLeftStyle: this._paddingLeftStyle,
                paddingBottomStyle: this._paddingBottomStyle,
                paddingRightStyle: this._paddingRightStyle,
            },
        });
    }
}
