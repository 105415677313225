import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { ApplicationDto } from '../../../v2-application/dto/application.dto';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-top-right-navigation',
    templateUrl: './top-right-navigation.component.html',
    styleUrls: ['./top-right-navigation.component.scss'],
})
export class TopRightNavigationComponent implements AfterViewInit {
    protected readonly environment = backofficeEnvironment;

    @Input()
    application: ApplicationDto;

    @Input()
    showVersionNav: boolean;

    @Output()
    openDialog: EventEmitter<'logs' | 'audit-logs'> = new EventEmitter<'logs' | 'audit-logs'>();

    @Output()
    openApplicationVersions: EventEmitter<void> = new EventEmitter<void>();

    ngAfterViewInit() {
        setTimeout(() => initFlowbite());
    }
}
