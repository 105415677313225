import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataFormatAttribute } from '@backoffice/data-access/editor';

@Component({
    selector: 'codex-boolean-type-settings',
    templateUrl: './boolean-type-settings.component.html',
})
export class BooleanTypeSettingsComponent {
    @Input() attribute: DataFormatAttribute;

    @Output() change: EventEmitter<void> = new EventEmitter();
}
