import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { Observable } from 'rxjs';
import { PageDto } from '@shared/data-access';
import { CreateGroupDto } from '@usermanagement/dto/create-group.dto';
import { GroupDto } from 'libs/backoffice/data-access/editor/src/lib/group/dto/group.dto';
import { CreateRoleGroupDto } from '@usermanagement/dto/create-role-group.dto';
import { tap } from 'rxjs/operators';
import { LoggerService } from '@backoffice/utils';
import { OverviewGroupDto } from '../dto/overview-group.dto';

@Injectable()
export class GroupsService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    fetchByGroupId(groupId: string, companyId: string, applicationId: string): Observable<GroupDto> {
        this.log.debug('Find group by id', [groupId]);
        return this.httpClient
            .get<GroupDto>(`${backofficeEnvironment.rest.v2.groups}company/${companyId}/application/${applicationId}/${groupId}`)
            .pipe(tap(xd => this.log.info(xd)));
    }

    fetchAll(applicationId: string, companyId: string): Observable<PageDto<GroupDto>> {
        const params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('page', 0)
            .set('maxResults', 1000);
        return this.httpClient.get<PageDto<GroupDto>>(`${backofficeEnvironment.rest.v2.groups}`, { params });
    }

    findAll(
        applicationId: string,
        companyId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
            userId?: string;
        }
    ): Observable<PageDto<OverviewGroupDto>> {
        let params = new HttpParams().set('applicationId', applicationId).set('companyId', companyId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults, userId } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('name', keyword);
            }

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }

            if (!!userId) {
                params = params.set('userId', userId);
            }
        }

        return this.httpClient.get<PageDto<OverviewGroupDto>>(`${backofficeEnvironment.rest.v2.groups}`, { params });
    }

    fetchByUserId(applicationId: string, companyId: string, userId: string): Observable<PageDto<GroupDto>> {
        const params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('userId', userId)
            .set('page', 0);

        return this.httpClient.get<PageDto<GroupDto>>(`${backofficeEnvironment.rest.v2.groups}`, { params });
    }

    create(dto: CreateGroupDto, companyId: string, applicationId: string): Observable<GroupDto> {
        return this.httpClient.post<GroupDto>(
            `${backofficeEnvironment.rest.v2.groups}company/${companyId}/application/${applicationId}`,
            dto
        );
    }

    addRole(roleId: string, groupId: string, companyId: string, applicationId: string): Observable<void> {
        const dto: CreateRoleGroupDto = { roleId, groupId, companyId, applicationId };
        return this.httpClient.post<void>(
            `${backofficeEnvironment.rest.v2.roleGroup}company/${companyId}/application/${applicationId}`,
            dto
        );
    }

    removeRole(roleId: string, groupId: string, companyId: string, applicationId: string): Observable<void> {
        const params = new HttpParams().set('groupId', groupId).set('roleId', roleId);

        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.roleGroup}company/${companyId}/application/${applicationId}`, {
            params,
            headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
        });
    }

    update(group: GroupDto, companyId: string, applicationId: string): Observable<GroupDto> {
        this.log.info(`Updating group: ${JSON.stringify(group)}`, [GroupsService.name]);

        const { id } = group;
        return this.httpClient.put<GroupDto>(
            `${backofficeEnvironment.rest.v2.groups}company/${companyId}/application/${applicationId}/${id}`,
            group
        );
    }

    delete(groupId: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug('Deleting group', [groupId]);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.groups}company/${companyId}/application/${applicationId}/${groupId}`
        );
    }
}
