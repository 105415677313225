import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '@core/services/product.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '@core/services/application.service';
import { CreateProductDto } from '../../interfaces/create-product.dto';
import { ApplicationVersionService } from '@core/services/application-version.service';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { backofficeEnvironment } from '@shared/environment';
import { ApplicationDto } from '../../../../../../../data-access/editor/src/lib/interfaces/application.dto';
import { ApplicationVersion } from '../../../../../../../editor/data-access/application-version/src';
import { AppFacade } from '@core/facades/app.facade';

@Component({
    selector: 'codex-add-to-marketplace-page',
    templateUrl: './add-to-marketplace-page.component.html',
    styleUrls: ['./add-to-marketplace-page.component.scss'],
})
export class AddToMarketplacePageComponent implements OnInit {
    protected readonly backofficeEnvironment = backofficeEnvironment;

    applications$: Observable<ApplicationDto[]>;
    selectedApplication$: Observable<ApplicationDto>;
    versions$: Observable<ApplicationVersion[]>;

    form: FormGroup;

    get applicationIdControl(): FormControl {
        return this.form.get('applicationId') as FormControl;
    }

    get companyIdControl(): FormControl {
        return this.form.get('companyId') as FormControl;
    }

    get versionControl(): FormControl {
        return this.form.get('versionId') as FormControl;
    }

    get nameControl(): FormControl {
        return this.form.get('name') as FormControl;
    }

    get descriptionControl(): FormControl {
        return this.form.get('description') as FormControl;
    }

    get typeControl(): FormControl {
        return this.form.get('type') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly productService: ProductService,
        private readonly applicationService: ApplicationService,
        private readonly applicationVersionService: ApplicationVersionService,
        private readonly translateService: TranslateService,
        private readonly router: Router,
        private readonly changeDetectorRef: ChangeDetectorRef,
        public readonly snackBar: MatSnackBar,
        public readonly appFacade: AppFacade
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            applicationId: [undefined, [Validators.required]],
            companyId: [undefined, Validators.required],
            versionId: [undefined, [Validators.required]],
            name: [undefined, [Validators.required]],
            description: [undefined, [Validators.required]],
            type: ['PUBLIC', [Validators.required]],
        });

        this.applications$ = this.appFacade.selectedCompany.pipe(
            switchMap(({ id }) => this.applicationService.getApplications(id).pipe(map(({ content }) => content)))
        );

        this.selectedApplication$ = this.applicationIdControl.valueChanges.pipe(
            switchMap((applicationId: string) =>
                this.appFacade.selectedCompany.pipe(switchMap(({ id }) => this.applicationService.getApplication(applicationId, id)))
            ),
            tap((application: ApplicationDto) =>
                this.form.patchValue({
                    name: application.name,
                    description: application.description,
                    companyId: application.companyId,
                    version: null,
                })
            ),
            tap(() => this.changeDetectorRef.detectChanges())
        );

        this.versions$ = combineLatest([this.appFacade.selectedCompany, this.selectedApplication$]).pipe(
            switchMap(([company, application]) =>
                this.applicationVersionService
                    .findAll(application.id, company.id, {
                        page: 0,
                        maxResults: 1000,
                    })
                    .pipe(map(({ content }) => content))
            )
        );
    }

    onUpload(): void {
        const { valid } = this.form;
        if (!valid) {
            this.form.markAsTouched();
        } else {
            const { value } = this.form;
            const dto: CreateProductDto = {
                ...value,
            };
            this.productService
                .create(dto)
                .pipe(take(1))
                .subscribe(product => {
                    this.snackBar.open(this.translateService.instant('v2.hub.add.success'), undefined, {
                        panelClass: ['success'],
                    });
                    this.router.navigate([`../${product.id}`], { relativeTo: this.route });
                });
        }
    }

    onVersionSelected(version: ApplicationVersion): void {
        this.descriptionControl.setValue(version.description);
    }
}
