import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '@shared/data-access';
import { HttpClient, HttpParams } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { OverviewTagLinkDto } from '../dto/overview-taglink.dto';
import { CreateTaglinkDto } from '../dto/create-taglink.dto';
import { TagLinkCreatedDto } from '../dto/taglink-created.dto';
import { LoggerService } from '@backoffice/utils';

@Injectable({
    providedIn: 'root',
})
export class TagLinkService {
    constructor(
        private httpClient: HttpClient,
        private log: LoggerService
    ) {}

    public createTagLink(createTagLink: CreateTaglinkDto): Observable<TagLinkCreatedDto> {
        this.log.debug('Creating tagLink', [createTagLink]);
        return this.httpClient.post<TagLinkCreatedDto>(backofficeEnvironment.rest.v2.tagLinks, createTagLink);
    }

    public deleteTagLink(tagLinkId: string): Observable<void> {
        this.log.debug('Deleting tagLinkId', [tagLinkId]);
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.tagLinks}${tagLinkId}`);
    }

    public getTagLinks(
        companyId: string,
        applicationId: string,
        keyword: string,
        orderBy: string,
        filters: string[],
        page: number,
        maxResults: number
    ): Observable<Page<OverviewTagLinkDto>> {
        this.log.debug('Getting taglink overview');
        let params = new HttpParams()
            .set('companyId', companyId)
            .set('applicationId', applicationId)
            .set('orderBy', orderBy)
            .set('page', String(page))
            .set('maxResults', String(maxResults));

        if (keyword) {
            params = params.append('keyword', keyword);
        }

        filters.forEach(filter => (params = params.append('filters', filter)));
        return this.httpClient.get<Page<OverviewTagLinkDto>>(`${backofficeEnvironment.rest.v2.tagLinks}`, { params: params });
    }
}
