import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Template } from '../../models/template/template.model';
import { TemplateContextEditorState } from '../state/template-context-editor.state';
import { editorSelectors } from './editor.selectors';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const templateAdapter = createEntityAdapter<Template>();

const { selectIds, selectAll, selectTotal, selectEntities } = templateAdapter.getSelectors();

const templateContextAdapter = createEntityAdapter<TemplateContextEditorState>();
const templateContextDefaultSelectors = templateContextAdapter.getSelectors();

export const templateEditorSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.template)),
    dialogData: createSelector(selectState, state => state.templateDialog.result),
    dialogFilter: createSelector(selectState, state => {
        const { page, maxResults, searchTerm, facets, filterPlugins } = state.templateDialog;
        return {
            page,
            maxResults,
            searchTerm,
            facets,
            filterPlugins,
        };
    }),
    searchTerm: createSelector(selectState, state => state.templateDialog.searchTerm),
    pagination: createSelector(selectState, state => {
        const { page, maxResults } = state.templateDialog;
        return { page, maxResults };
    }),
    facets: createSelector(selectState, state => state.templateDialog.facets),
    filterPlugins: createSelector(selectState, state => state.templateDialog.filterPlugins),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<Template> = selectEntities(state.template);
            return dictionary[id];
        }),
};

export const templateContextSelectors = {
    toolPaneOpen: () =>
        createSelector(selectState, editorSelectors.activeTab, (state, activeTab) => {
            const dictionary: Dictionary<TemplateContextEditorState> = templateContextDefaultSelectors.selectEntities(
                state.templateContext
            );
            if (activeTab && activeTab.type === 'template') {
                return dictionary[activeTab.typeId]?.toolpaneOpen;
            } else {
                return undefined;
            }
        }),
    currentViewScreenType: () =>
        createSelector(selectState, editorSelectors.activeTab, (state, activeTab) => {
            const dictionary: Dictionary<TemplateContextEditorState> = templateContextDefaultSelectors.selectEntities(
                state.templateContext
            );
            if (activeTab && activeTab.type === 'template') {
                return dictionary[activeTab.typeId]?.currentViewScreenType;
            } else {
                return undefined;
            }
        }),
    currentScreenType: () =>
        createSelector(selectState, editorSelectors.activeTab, (state, activeTab) => {
            const dictionary: Dictionary<TemplateContextEditorState> = templateContextDefaultSelectors.selectEntities(
                state.templateContext
            );
            if (activeTab && activeTab.type === 'template') {
                return dictionary[activeTab.typeId]?.currentScreenType;
            } else {
                return undefined;
            }
        }),
    draggingPart: () =>
        createSelector(selectState, editorSelectors.activeTab, (state, activeTab) => {
            const dictionary: Dictionary<TemplateContextEditorState> = templateContextDefaultSelectors.selectEntities(
                state.templateContext
            );
            if (activeTab && activeTab.type === 'template') {
                return dictionary[activeTab.typeId]?.draggingPart;
            } else {
                return undefined;
            }
        }),
    selectedPart: () =>
        createSelector(selectState, editorSelectors.activeTab, (state, activeTab) => {
            const dictionary: Dictionary<TemplateContextEditorState> = templateContextDefaultSelectors.selectEntities(
                state.templateContext
            );
            if (activeTab && activeTab.type === 'template') {
                return dictionary[activeTab.typeId]?.selectedPart;
            } else {
                return undefined;
            }
        }),
    selectedParts: () =>
        createSelector(selectState, editorSelectors.activeTab, (state, activeTab) => {
            const dictionary: Dictionary<TemplateContextEditorState> = templateContextDefaultSelectors.selectEntities(
                state.templateContext
            );
            if (activeTab && activeTab.type === 'template') {
                return dictionary[activeTab.typeId]?.selectedParts;
            } else {
                return undefined;
            }
        }),
    currentTool: () =>
        createSelector(selectState, editorSelectors.activeTab, (state, activeTab) => {
            const dictionary: Dictionary<TemplateContextEditorState> = templateContextDefaultSelectors.selectEntities(
                state.templateContext
            );
            if (activeTab && activeTab.type === 'template') {
                return dictionary[activeTab.typeId]?.currentTool;
            } else {
                return undefined;
            }
        }),
};
