<div *ngIf="$designSystem | async as designSystem">
    <app-design-system-general
        [designSystem]="designSystem"
        (changed)="handleDesignSystemChanged($event, designSystem)"></app-design-system-general>
    <hr class="seperator" />
    <div>
        <h2 class="!mb-2">Colors</h2>
        <app-design-system-theme
            class="mt-4"
            title="Standard Theme"
            [theme]="designSystem.standard"
            (colorChanged)="handleColorChanged($event, 'STANDARD', designSystem)"></app-design-system-theme>
        <app-design-system-theme
            class="mt-4"
            title="Dark Theme"
            [theme]="designSystem.dark"
            (colorChanged)="handleColorChanged($event, 'DARK', designSystem)"></app-design-system-theme>
    </div>
    <hr class="seperator" />
    <div>
        <h2 class="!mb-2">Typography</h2>
        <div class="w-full mt-4 grid grid-cols-12 gap-8">
            <label class="col-span-4 dark:text-white">Style Name</label>
            <label class="col-span-1 dark:text-white">Font Size</label>
            <label class="col-span-1 dark:text-white">Spacing</label>
            <label class="col-span-1 dark:text-white">Italic</label>
            <label class="col-span-1 dark:text-white">Font Weight</label>
            <label class="col-span-2 dark:text-white">Color</label>
            <label class="col-span-2 dark:text-white">Font Family</label>
        </div>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.displayLarge"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'display_large')"
            title="Display Large"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.displayMedium"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'display_medium')"
            title="Display Medium"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.displaySmall"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'display_small')"
            title="Display Small"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.headlineLarge"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'headline_large')"
            title="Headline Large"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.headlineMedium"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'headline_medium')"
            title="Headline Medium"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.headlineSmall"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'headline_small')"
            title="Headline Small"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.titleLarge"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'title_large')"
            title="Title Headline"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.titleMedium"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'title_medium')"
            title="Title Medium"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.titleSmall"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'title_small')"
            title="Title Small"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.labelLarge"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'label_large')"
            title="Label Large">
        </app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.labelMedium"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'label_medium')"
            title="Label Medium"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.labelSmall"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'label_small')"
            title="Label Small"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.bodyLarge"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'body_large')"
            title="Body Large"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.bodyMedium"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'body_medium')"
            title="Body Medium"></app-design-system-typography>
        <app-design-system-typography
            class="w-full mb-2"
            [designSystem]="designSystem"
            [typography]="designSystem.dark.bodySmall"
            (typographyChanged)="handleTypographyChanged($event, designSystem, 'body_small')"
            title="Body Small"></app-design-system-typography>
    </div>
</div>
