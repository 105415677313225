import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { ApplicationDto } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application.dto';
import { Group } from '@usermanagement/models/group.model';
import { Role } from '@usermanagement/models/role.model';
import { ActivatedRoute } from '@angular/router';
import { AppFacade } from '@core/facades/app.facade';
import { ApplicationService } from '@core/services/application.service';
import { GroupsService } from 'libs/backoffice/data-access/editor/src/lib/group/services/groups.service';
import { RightsService } from 'libs/backoffice/data-access/editor/src/lib/right/services/roles.service';
import { UserFacade } from '@core/facades/user.facade';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, switchMap } from 'rxjs/operators';
import { MatCheckboxChange as MatCheckboxChange } from '@angular/material/checkbox';
import { RightDto } from '../../../../../../../data-access/editor/src/lib/right/dto/right.dto';
import { GroupDto } from '../../../../../../../data-access/editor/src/lib/group/dto/group.dto';

@Component({
    selector: 'codex-edit-user-permissions',
    templateUrl: './edit-user-permissions.component.html',
    styleUrls: ['./edit-user-permissions.component.scss'],
})
export class EditUserPermissionsComponent implements OnInit {
    form: FormGroup;

    applications$: Observable<ApplicationDto[]>;
    private groups$: Observable<GroupDto[]>;
    private selectedGroups$: Observable<GroupDto[]>;
    private roles$: Observable<RightDto[]>;
    private selectedRoles$: Observable<RightDto[]>;

    groupsAndRoles$: Observable<{
        groups: { id: string; name: string; checked: boolean; companyId: string; applicationId: string }[];
        roles: { id: string; name: string; checked: boolean; companyId: string; applicationId: string }[];
    }>;

    get applicationControl(): FormControl {
        return this.form.get('applicationId') as FormControl;
    }

    constructor(
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly appFacade: AppFacade,
        private readonly applicationService: ApplicationService,
        private readonly groupService: GroupsService,
        private readonly rightsService: RightsService,
        private readonly userFacade: UserFacade,
        @Inject(MAT_DIALOG_DATA) public data: { userId: string; environment: string }
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            applicationId: [Validators.required],
        });

        this.applications$ = this.appFacade.context.pipe(
            switchMap(({ selectedCompany }) => this.applicationService.getApplications(selectedCompany.id).pipe(map(page => page.content)))
        );

        this.groups$ = combineLatest([this.appFacade.context, this.applicationControl.valueChanges]).pipe(
            switchMap(([context, applicationId]) =>
                this.groupService.fetchAll(applicationId, context.selectedCompany.id).pipe(map(page => page.content))
            )
        );

        this.roles$ = combineLatest([this.appFacade.context, this.applicationControl.valueChanges]).pipe(
            switchMap(([context, applicationId]) =>
                this.rightsService
                    .getRightsByApplicationId(applicationId, context.selectedCompany.id, false)
                    .pipe(map(page => page.content))
            )
        );

        this.selectedRoles$ = combineLatest([this.applicationControl.valueChanges, this.appFacade.context]).pipe(
            switchMap(([applicationId, context]) =>
                this.rightsService
                    .fetchByUserId(applicationId, context.selectedCompany.id, this.data.userId)
                    .pipe(map(page => page.content))
            )
        );

        this.selectedGroups$ = combineLatest([this.appFacade.context, this.applicationControl.valueChanges]).pipe(
            switchMap(([context, applicationId]) =>
                this.groupService.fetchByUserId(applicationId, context.selectedCompany.id, this.data.userId).pipe(map(page => page.content))
            )
        );

        this.groupsAndRoles$ = combineLatest([this.groups$, this.roles$, this.selectedGroups$, this.selectedRoles$]).pipe(
            map(([groups, roles, selectedGroups, selectedRoles]) => {
                return {
                    groups: groups.map(group => ({
                        id: group.id,
                        name: group.name,
                        companyId: group.companyId,
                        applicationId: group.applicationId,
                        checked: selectedGroups.some(g => g.id === group.id),
                    })),
                    roles: roles.map(role => ({
                        id: role.id,
                        name: role.name,
                        companyId: role.companyId,
                        applicationId: role.applicationId,
                        checked: selectedRoles.some(r => r.id === role.id),
                    })),
                };
            })
        );
    }

    onGroupSelected(
        group: { id: string; name: string; checked: boolean; companyId: string; applicationId: string },
        applicationId: string,
        companyId: string
    ) {
        const { userId } = this.data;
        if (!group.checked) {
            group.checked = true;
            this.userFacade.addGroup(group.id, userId, applicationId, companyId);
        } else {
            group.checked = false;
            this.userFacade.removeGroup(group.id, userId, applicationId, companyId);
        }
    }

    onRoleSelected(
        right: { id: string; name: string; checked: boolean; companyId: string; applicationId: string },
        companyId: string,
        applicationId: string
    ) {
        const { userId, environment } = this.data;
        if (!right.checked) {
            right.checked = true;
            this.userFacade.addRole(right.id, userId, environment, companyId, applicationId);
        } else {
            right.checked = false;
            this.userFacade.removeRole(right.id, userId, environment, companyId, applicationId);
        }
    }
}
