import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[slug]',
})
export class SlugDirective {
    constructor(private control: NgControl) {}

    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·_,;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');

        const input = event.target as HTMLInputElement;

        const slug = input.value
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(' ', '') // Trim - from start of text
            .replace(' ', '')
            .replace('.', '')
            .replace('!', '')
            .replace('^', ''); // Trim - from end of text

        this.control.control.setValue(slug);
    }
}
