import { createAction, props } from '@ngrx/store';
import { Job } from '../../dto/job.dto';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '../../dto/overview/facets.dto';
import { OverviewJobDto } from '../../dto/overview/overview-job.dto';

export const createJobSuccess = createAction('[EFFECT] create new job success', props<{ id: string }>());

export const findJob = createAction('[ACTION] fetch job', props<{ id: string }>());
export const fetchJobSuccess = createAction('[EFFECT] fetch job success', props<{ job: Job }>());
export const fetchJobError = createAction('[ACTION] fetch job error', props<{ error: any }>());

export const deleteJob = createAction('[ACTION] delete job', props<{ id: string }>());
export const deleteJobSuccess = createAction('[EFFECT] delete job success', props<{ id: string }>());

export const updateJob = createAction('[ACTION] update job', props<{ job: Job }>());
export const updateJobSuccess = createAction('[EFFECT] update job success', props<{ job: Job }>());

// Editor Dialog Actions

export const clearJobDialogData = createAction('[ACTION] job editor dialog data cleared');
export const clearJobDialogFilter = createAction('[ACTION] job editor dialog filter cleared');

export const deleteJobs = createAction('[ACTION] delete jobs', props<{ ids: string[] }>());
export const deleteJobsSuccess = createAction('[EFFECT] delete jobs success', props<{ ids: string[] }>());

export const loadJobDialogData = createAction('[ACTION] load job editor dialog data');
export const loadJobDialogSuccess = createAction('[EFFECT] load job editor dialog data success', props<{ data: Page<OverviewJobDto> }>());

export const jobDialogPaginationChanged = createAction(
    '[ACTION] job editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const jobDialogSearchTermChanged = createAction(
    '[ACTION] job editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const jobDialogFacetsChanged = createAction('[ACTION] job editor dialog facets changed', props<{ facets: SelectedFacets }>());

export const jobDialogFilterPluginsChanged = createAction(
    '[ACTION] job editor dialog filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
