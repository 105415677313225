import { createAction, props } from '@ngrx/store';
import { Api } from '../../api/model/api';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '@backoffice/data-access/editor';
import { OverviewApiDto } from '../../dto/overview/overview-api.dto';

export const createApiSuccess = createAction('[EFFECT] create new api success', props<{ id: string }>());

export const findApi = createAction('[ACTION] fetch api', props<{ id: string }>());
export const fetchApiSuccess = createAction('[EFFECT] fetch api success', props<{ api: Api }>());
export const fetchApiError = createAction('[ACTION] fetch api error', props<{ error: any }>());

export const deleteApi = createAction('[ACTION] delete api', props<{ id: string }>());
export const deleteApiSuccess = createAction('[EFFECT] delete api success', props<{ id: string }>());

export const updateApi = createAction('[ACTION] update api', props<{ api: Api }>());
export const updateApiSuccess = createAction('[EFFECT] update api success', props<{ api: Api }>());

// Editor Dialog Actions

export const clearApiDialogData = createAction('[ACTION] api editor dialog data cleared');
export const clearApiDialogFilter = createAction('[ACTION] api editor dialog filter cleared');

export const deleteApis = createAction('[ACTION] delete apis', props<{ ids: string[] }>());
export const deleteApisSuccess = createAction('[EFFECT] delete apis success', props<{ ids: string[] }>());

export const loadApiDialogData = createAction('[ACTION] load api editor dialog data');
export const loadApiDialogSuccess = createAction('[EFFECT] load api editor dialog data success', props<{ data: Page<OverviewApiDto> }>());

export const apiDialogPaginationChanged = createAction(
    '[ACTION] api editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const apiDialogSearchTermChanged = createAction(
    '[ACTION] api editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const apiDialogFacetsChanged = createAction('[ACTION] api editor dialog facets changed', props<{ facets: SelectedFacets }>());

export const apiDialogFilterPluginsChanged = createAction(
    '[ACTION] api editor dialog filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
