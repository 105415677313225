import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '@shared/data-access';
import { ApplicationVersion } from '../../../../../../libs/backoffice/editor/data-access/application-version/src/lib/models/application-version.model';
import { backofficeEnvironment } from '@shared/environment';
import { Injectable } from '@angular/core';
import { LoggerService } from '@backoffice/utils';

@Injectable()
export class ApplicationVersionService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly log: LoggerService
    ) {}

    findAll(
        applicationId: string,
        companyId: string,
        options?: {
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<ApplicationVersion>> {
        let params = new HttpParams();
        if (options) {
            const { page, maxResults } = options;
            if (page) {
                params = params.append('page', page);
            }
            if (maxResults) {
                params = params.append('maxResults', maxResults);
            }
        }
        return this.httpClient.get<Page<ApplicationVersion>>(
            `${backofficeEnvironment.rest.v2.applicationVersions}company/${companyId}/application/${applicationId}`,
            { params }
        );
    }
}
