import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import {
    apiDialogFacetsChanged,
    apiDialogFilterPluginsChanged,
    apiDialogPaginationChanged,
    apiDialogSearchTermChanged,
    clearApiDialogFilter,
    createApiSuccess,
    deleteApi,
    deleteApis,
    deleteApisSuccess,
    deleteApiSuccess,
    fetchApiSuccess,
    findApi,
    loadApiDialogData,
    loadApiDialogSuccess,
    updateApi,
    updateApiSuccess,
} from '../actions/api-editor.actions';
import { apiEditorSelectors } from '../selectors/api-editor.selectors';
import { ApiService } from '../../services/api.service';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { toFilter } from '@backoffice/data-access/editor';
import { ApiEditorFacade } from '../facades/api-editor.facade';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { forkJoin } from 'rxjs';
import { Page } from '@shared/data-access';
import { OverviewApiDto } from '../../dto/overview/overview-api.dto';

@Injectable()
export class ApiEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly apiService: ApiService,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService,
        private readonly editorFacade: ApiEditorFacade
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearApiDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApis),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.apiService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteApisSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApisSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'api' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApi),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.apiService.delete(id, companyId, applicationId).pipe(map(() => deleteApiSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findApi),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(apiEditorSelectors.byId(id)),
            ]),
            filter(([_api, company, application, detail]) => !detail && !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.apiService.findById(id, companyId, applicationId).pipe(map(result => fetchApiSuccess({ api: result })))
            )
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteApisSuccess,
                loadApiDialogData,
                apiDialogPaginationChanged,
                apiDialogSearchTermChanged,
                apiDialogFacetsChanged,
                apiDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.apiService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewApiDto>) => loadApiDialogSuccess({ data }))
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateApi),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ api }, { id: companyId }, { id: applicationId }]) =>
                this.apiService.update(api, companyId, applicationId).pipe(map(response => updateApiSuccess({ api })))
            )
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteApiSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'api' }))
        )
    );

    openSnack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(updateApiSuccess),
                tap(() => {
                    this.snackBar.open(this.translate.instant('v2.api.edit.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            ),
        { dispatch: false }
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createApiSuccess),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) => this.apiService.findById(id, companyId, applicationId)),
            map(({ id, name, iconName }) => registerTab({ definition: { type: 'api', typeId: id, name: name, icon: iconName } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateApiSuccess),
            map(({ api }) => {
                const { id: typeId, name, iconName: icon } = api;
                return updateTab({ definition: { type: 'api', typeId, name, icon } });
            })
        )
    );
}
