import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabDefinition } from '../../../../../../data-access/editor/src/lib/interfaces/tab-definition.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, Observable, ReplaySubject, Subscription } from 'rxjs';
import { DataFormatEditorFacade, OverviewDataFormatDto } from '@backoffice/data-access/editor';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { DataformatCreateFormComponent } from '../../../../../../ui/editor/src/lib/dataformat/dataformat-create-form/dataformat-create-form.component';
import { data } from 'autoprefixer';

@Component({
    selector: 'app-dataformat-picker',
    templateUrl: './dataformat-picker.component.html',
    styleUrls: ['./dataformat-picker.component.scss'],
})
export class DataformatPickerComponent {
    @Input() value: string | undefined;
    @Input() required = false;
    @Input() addQuotesToValue = true;
    @Input() showAddButton = true;
    @Input() showEditButton = true;
    @Input() showRemoveButton = true;
    @Output() valueUpdated: EventEmitter<{ value: string | undefined }> = new EventEmitter<{ value: string | undefined }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    valueControl(): FormControl {
        return this.form?.get('value') as FormControl;
    }

    filterControl(): FormControl {
        return this.form?.get('valueFilter') as FormControl;
    }

    filterChanged$ = new ReplaySubject<string>();

    filteredDataFormats$!: Observable<OverviewDataFormatDto[]>;

    form: FormGroup | undefined = undefined;

    missingDataFormat = false;

    private readonly _subscriptions = new Subscription();

    constructor(
        private readonly fb: FormBuilder,
        private readonly dataFormatEditorFacade: DataFormatEditorFacade
    ) {}

    ngOnInit(): void {
        const dataFormatId = this.value ? this.removeQuotesIfPresent(this.value) : undefined;
        const required = this.required;

        this.form = this.fb.group({
            value: [dataFormatId, required ? Validators.required : null],
            valueFilter: [''],
        });

        // De subscription kan na de emit van de value komen en dan missen we die.
        this._subscriptions.add(
            this.filterControl()
                .valueChanges.pipe(distinctUntilChanged())
                .subscribe(value => {
                    this.filterChanged$.next(value);
                })
        );

        this.filteredDataFormats$ = this.filterChanged$.pipe(
            switchMap(value => {
                return this.dataFormatEditorFacade.findAll(value ?? '', [], 'score desc', 0, 10).pipe(map(({ content }) => content));
            })
        );

        if (!!dataFormatId) {
            this._subscriptions.add(
                this.dataFormatEditorFacade
                    .findById(dataFormatId)
                    .pipe(
                        take(1),
                        tap(dataFormat => {
                            if (!dataFormat.deleted) {
                                const { name, id } = dataFormat;
                                this.filterControl().setValue(name);
                                this.valueControl().setValue(id);
                                this.missingDataFormat = false;
                            } else {
                                this.filterControl().setValue('');
                                this.filterControl().setValue('Missing dataformat', { emitEvent: false });
                                this.missingDataFormat = true;
                            }
                        })
                    )
                    .subscribe()
            );
        } else {
            this.filterControl().setValue('');
        }

        this._subscriptions.add(this.valueControl().valueChanges.subscribe(value => this.onChange(value)));
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this._subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    onChange(value: any) {
        if (this.addQuotesToValue) {
            if (this.value !== `'${value}'`) {
                if (value) {
                    this.value = `'${value}'`;
                } else {
                    this.value = undefined;
                }
                this.missingDataFormat = false;
                this.valueUpdated.emit({ value: this.value });
            }
        } else {
            if (this.value !== `${value}`) {
                if (value) {
                    this.value = `${value}`;
                } else {
                    this.value = undefined;
                }
                this.missingDataFormat = false;
                this.valueUpdated.emit({ value: this.value });
            }
        }
    }

    onOpenDataFormat(dataFormatId: string | undefined) {
        if (dataFormatId) {
            this.openTab.emit({ typeId: this.removeQuotesIfPresent(dataFormatId), type: 'data-format' });
        }
    }

    onCreateDataFormat() {
        this.dataFormatEditorFacade.create(DataformatCreateFormComponent).subscribe(dataFormatCreatedIds => {
            dataFormatCreatedIds.forEach(dataFormatCreatedId => {
                this.value = `'${dataFormatCreatedId}'`;
                this.valueUpdated.emit({ value: this.value });
                this.onOpenDataFormat(dataFormatCreatedId);
            });
        });
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    deleteDataFormatLink(): void {
        this.value = undefined;
        this.valueControl().reset(null, { onlySelf: true, emitEvent: false });
        this.filterControl().reset(null, { onlySelf: true, emitEvent: false });
        this.missingDataFormat = false;

        this.valueUpdated.emit({ value: this.value });
    }
}
