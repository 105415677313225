import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FontStyleDto } from '@backoffice/data-access/editor';

interface WrapperStyle {
    answerFontStyleDto?: FontStyleDto | null;
}

@Component({
    selector: 'codex-font-answer-picker-wrapper',
    templateUrl: './font-answer-picker-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontAnswerPickerWrapperComponent {
    @Input()
    lastPanel: boolean = false;

    @Input()
    identifier: string;

    @Output()
    wrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    wrapperStyle: WrapperStyle;

    @Output()
    hoverWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    hoverWrapperStyle: WrapperStyle;

    @Output()
    focusWrapperStyleUpdated: EventEmitter<{
        wrapperStyle: WrapperStyle;
    }> = new EventEmitter<{
        wrapperStyle: WrapperStyle;
    }>();

    @Input()
    focusWrapperStyle: WrapperStyle;

    @Input()
    allowedTypes: string[] = ['normal', 'hover', 'focus'];

    type: string = 'normal';

    onChangeStyle($event: {
        font: {
            fontStyle?: FontStyleDto | null;
        };
    }) {
        this.wrapperStyle.answerFontStyleDto = $event.font.fontStyle;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    onChangeHoverStyle($event: {
        font: {
            fontStyle?: FontStyleDto | null;
        };
    }) {
        this.hoverWrapperStyle.answerFontStyleDto = $event.font.fontStyle;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    onChangeFocusStyle($event: {
        font: {
            fontStyle?: FontStyleDto | null;
        };
    }) {
        this.focusWrapperStyle.answerFontStyleDto = $event.font.fontStyle;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }

    resetStyle() {
        if (this.type === 'normal') {
            this.resetWrapperStyle();
        } else if (this.type === 'hover') {
            this.resetHoverWrapperStyle();
        } else if (this.type === 'focus') {
            this.resetFocusWrapperStyle();
        }
    }

    resetWrapperStyle() {
        this.wrapperStyle.answerFontStyleDto = null;
        this.wrapperStyleUpdated.emit({ wrapperStyle: this.wrapperStyle });
    }

    resetHoverWrapperStyle() {
        this.hoverWrapperStyle.answerFontStyleDto = null;
        this.hoverWrapperStyleUpdated.emit({ wrapperStyle: this.hoverWrapperStyle });
    }

    resetFocusWrapperStyle() {
        this.focusWrapperStyle.answerFontStyleDto = null;
        this.focusWrapperStyleUpdated.emit({ wrapperStyle: this.focusWrapperStyle });
    }
}
