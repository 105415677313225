import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationLogDto } from '../interfaces/application-log.dto';
import { backofficeEnvironment } from '@shared/environment';

@Injectable({
    providedIn: 'root',
})
export class ApplicationLogService {
    constructor(private httpClient: HttpClient) {}

    public findApplicationLogs(
        companyId: string,
        applicationId: string,
        templateId: string,
        actionId: string,
        from: Date,
        to: Date,
        level: string
    ): Observable<ApplicationLogDto[]> {
        let params = new HttpParams()
            .set('applicationId', applicationId)
            .set('companyId', companyId)
            .set('from', from.toISOString())
            .set('to', to.toISOString());

        if (!!level) {
            params = params.set('level', level);
        }
        if (!!templateId) {
            params = params.set('templateId', templateId);
        }
        if (!!actionId) {
            params = params.set('actionId', actionId);
        }
        return this.httpClient.get<ApplicationLogDto[]>(`${backofficeEnvironment.rest.v2.actionLogs}`, { params: params });
    }
}
