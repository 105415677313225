<div class="flex justify-between mb-2 gap-2 items-center">
    <label for="columns" class="small-input-label">{{ 'v2.part.style.column.amount' | translate }}</label>
    <input
        class="small-input"
        type="number"
        id="columns"
        name="columns"
        autocomplete="off"
        [(ngModel)]="_columnsStyle.columnAmount"
        (change)="onChangeColumns()" />
</div>

<codex-pixel-selector
    [label]="'v2.part.style.column.gutter' | translate"
    [value]="_columnsStyle.columnGutter"
    [minPixels]="0"
    [maxPixels]="500"
    (changePixels)="onChangeColumnGutter($event)"></codex-pixel-selector>
