import { PartDetail } from '../../../part-detail.model';

export class PieChartPartDetail extends PartDetail {
    id: string;
    code: string;
    title: string;
    slices: PieChartSliceConfiguration[] = [];

    constructor(base?: Partial<PieChartPartDetail>) {
        super();
        if (!!base) {
            this.id = base.id ?? this.id;
            this.code = base.code ?? this.code;
            this.title = base.title ?? this.title;
            this.slices = base.slices ?? this.slices;
        }
    }

    public override isValid(): boolean {
        return true;
    }

    public override hasActions(): boolean {
        return false;
    }
}

export class PieChartSliceConfiguration {
    id: string;
    name: string;
    value: string;
    code: string;

    constructor(base?: Partial<PieChartSliceConfiguration>) {
        if (!!base) {
            this.id = base.id ?? this.id;
            this.name = base.name ?? this.name;
            this.value = base.value ?? this.value;
            this.code = base.code ?? this.code;
        }
    }
}
