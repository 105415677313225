<mat-dialog-content class="scrollbar scrollbar-primary">
    <div class="p-4" [formGroup]="formGroup">
        <h1 class="!mb-2">Create Action</h1>
        <div class="choice-grid">
            <div class="choice-card" [class.choice-card-selected]="selectedType === 'BLANK'" (click)="onSelectType('BLANK')">
                <svg aria-hidden="true" focusable="false" class="icon"><use href="#actions" class="ui-element"></use></svg>
                <span class="choice-card-title">Blank</span>
            </div>
            <div
                class="choice-card"
                [class.choice-card-selected]="selectedType === 'GENERATE_API_CALL'"
                (click)="onSelectType('GENERATE_API_CALL')">
                <svg aria-hidden="true" focusable="false" class="icon"><use href="#actions" class="ui-element"></use></svg>
                <span class="choice-card-title">Generate API call</span>
            </div>
        </div>
        <ng-container *ngIf="selectedType === 'BLANK'">
            <label for="name" class="small-input-label">{{ 'v2.api.edit.name' | translate }}</label>
            <input
                class="small-input"
                type="text"
                id="name"
                name="name"
                data-name="api-name"
                required
                cdkFocusInitial
                formControlName="name"
                autocomplete="off" />
        </ng-container>
        <ng-container *ngIf="selectedType === 'GENERATE_API_CALL'">
            <label for="name" class="small-input-label">API host</label>
            <input
                class="small-input"
                type="text"
                id="apiHost"
                name="apiHost"
                data-name="api-host"
                required
                formControlName="apiHost"
                autocomplete="off" />

            <label for="name" class="small-input-label">Api documentation</label>
            <textarea
                class="small-input"
                type="text"
                id="apiDocumentation"
                name="apiDocumentation"
                data-name="api-documentation"
                required
                formControlName="apiDocumentation"
                autocomplete="off"
                rows="50">
            </textarea>

            <label class="toggle-line">
                Authentication logic in place
                <input type="checkbox" value="" class="sr-only peer" formControlName="authenticationLogicInPlace" />
                <div
                    class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
            </label>

            <ng-container *ngIf="this.formGroup.get('authenticationLogicInPlace').value">
                <app-action-single-picker></app-action-single-picker>
            </ng-container>

            <ng-container *ngIf="!this.formGroup.get('authenticationLogicInPlace').value">
                <label for="select-type" class="small-input-label">Authentication type</label>
                <select id="select-type" class="small-input" formControlName="apiAuthentication">
                    <option [value]="'NONE'">None</option>
                    <option [value]="'BASIC_AUTH'">Basic authentication</option>
                    <option [value]="'API_TOKEN'">Api token</option>
                    <option [value]="'BEARER'">Bearer token</option>
                </select>
            </ng-container>

            <ng-container *ngIf="this.formGroup.get('apiAuthentication').value === 'BEARER'">
                <label for="name" class="small-input-label">Api documentation</label>
                <textarea
                    class="small-input"
                    type="text"
                    id="apiAuthenticationDocumentation"
                    name="apiAuthenticationDocumentation"
                    data-name="api-authenticatio-documentation"
                    required
                    formControlName="apiAuthenticationDocumentation"
                    autocomplete="off"
                    rows="50">
                </textarea>
            </ng-container>
        </ng-container>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" data-name="save" type="submit" (click)="onCreate()">
                <mat-icon>add_circle</mat-icon>
                Create Action
            </button>
            <button class="secondary-button button-large" data-name="cancel" type="button" (click)="dialogRef.close({ create: false })">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-content>
