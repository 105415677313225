// data: error
export const DATA_ERROR_ADD_ERROR: string = 'DATA_ERROR_ADD_ERROR';
export const DATA_ERROR_SET_ERRORS: string = 'DATA_ERROR_SET_ERRORS';
export const DATA_ERROR_CLEAR_ERRORS: string = 'DATA_ERROR_CLEAR_ERRORS';

export const LOADING_START_LOADING: string = 'DATA_LOADING_START_LOADING';
export const LOADING_STOP_LOADING: string = 'DATA_LOADING_STOP_LOADING';

// data: authentication
export const DATA_AUTHENTICATION_SET_AUTHENTICATION: string = 'DATA_AUTHENTICATION_SET_AUTHENTICATION';
export const DATA_AUTHENTICATION_CLEAR_AUTHENTICATION: string = 'DATA_AUTHENTICATION_CLEAR_AUTHENTICATION';
export const DATA_AUTHENTICATION_SET_COMPANY: string = 'DATA_AUTHENTICATION_SET_COMPANY';
