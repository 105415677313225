import { createAction, props } from '@ngrx/store';
import { Api } from '../../../api/model/api';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '@backoffice/data-access/editor';
import { OverviewApiDto } from '../../../dto/overview/overview-api.dto';
import { GroupDto } from '../../dto/group.dto';
import { OverviewGroupDto } from '../../dto/overview-group.dto';

export const createGroupSuccess = createAction('[EFFECT] create new group success', props<{ id: string; openTab?: boolean }>());

export const findGroup = createAction('[ACTION] fetch group', props<{ id: string }>());
export const fetchGroupSuccess = createAction('[EFFECT] fetch group success', props<{ group: GroupDto }>());
export const fetchGroupError = createAction('[ACTION] fetch group error', props<{ group: GroupDto }>());

export const deleteGroup = createAction('[ACTION] delete group', props<{ id: string }>());
export const deleteGroupSuccess = createAction('[EFFECT] delete group success', props<{ id: string }>());

export const updateGroup = createAction('[ACTION] update group', props<{ group: GroupDto }>());
export const updateGroupSuccess = createAction('[EFFECT] update group success', props<{ group: GroupDto }>());

// Editor Dialog Actions

export const clearGroupDialogData = createAction('[ACTION] group editor dialog data cleared');
export const clearGroupDialogFilter = createAction('[ACTION] group editor dialog filter cleared');

export const deleteGroups = createAction('[ACTION] delete groups', props<{ ids: string[] }>());
export const deleteGroupsSuccess = createAction('[EFFECT] delete groups success', props<{ ids: string[] }>());

export const loadGroupDialogData = createAction('[ACTION] load group editor dialog data');
export const loadGroupDialogSuccess = createAction(
    '[EFFECT] load group editor dialog data success',
    props<{ data: Page<OverviewGroupDto> }>()
);

export const groupDialogPaginationChanged = createAction(
    '[ACTION] group editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const groupDialogSearchTermChanged = createAction(
    '[ACTION] group editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const groupDialogFacetsChanged = createAction('[ACTION] group editor dialog facets changed', props<{ facets: SelectedFacets }>());

export const groupDialogFilterPluginsChanged = createAction(
    '[ACTION] group editor dialog filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
