import { NgModule } from '@angular/core';
import { MarketplaceOverviewPageComponent } from './pages/marketplace-overview/marketplace-overview-page.component';
import { ProductOverviewRowComponent } from './components/product-overview-row/product-overview-row.component';
import { AddToMarketplacePageComponent } from './pages/add-to-marketplace/add-to-marketplace-page.component';
import { ProductDetailPageComponent } from './pages/product-detail-page/product-detail-page.component';
import { AddCommentComponent } from './components/add-comment/add-comment.component';
import { AddVersionComponent } from './components/add-version/add-version.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { ProductAdminGuard } from './guards/product-admin.guard';
import { RoleGuard } from './guards/role.guard';
import { MarketplaceFacade } from './facade/marketplace.facade';
import { BackofficeUiSearchModule } from '@backoffice/ui/search';
import { HttpLoaderInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-loader.interceptor';
import { HttpErrorInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-error.interceptor';
import { HttpAuthenticationInterceptorProvider } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/http/interceptor/http-authentication.interceptor';
import { CommonsModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { V2TagModule } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-tag/v2-tag.module';
import { MarketplaceWrapperComponent } from './pages/marketplace-wrapper/marketplace-wrapper.component';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
    declarations: [
        MarketplaceOverviewPageComponent,
        ProductOverviewRowComponent,
        AddToMarketplacePageComponent,
        ProductDetailPageComponent,
        AddCommentComponent,
        AddVersionComponent,
        EditProductComponent,
        MarketplaceWrapperComponent,
    ],
    exports: [MarketplaceOverviewPageComponent, MarketplaceWrapperComponent],
    imports: [CommonsModule, V2TagModule, BackofficeUiSearchModule, MarkdownModule],
    providers: [
        HttpLoaderInterceptorProvider,
        HttpErrorInterceptorProvider,
        HttpAuthenticationInterceptorProvider,
        ProductAdminGuard,
        RoleGuard,
        MarketplaceFacade,
    ],
})
export class BackofficeFeatureMarketplaceModule {}
