import { PartDetail } from '../../../part-detail.model';

export class IconPartDetail extends PartDetail {
    code: string;
    icon: string;
    tooltip: string;

    isValid(): boolean {
        return this.isCodeValid();
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.icon.error.code');
        }
        return validationErrors;
    }
}
