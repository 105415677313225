import { Type } from 'class-transformer';
import { PartStyle } from '../../../part-style.model';
import { ContainerStyleDto } from '../../../../../interfaces/style/container.style.dto';
import { ListStyleDto } from '../../../../../interfaces/style/list.style.dto';

export class HorizontalListPartStyle extends PartStyle {
    normalStyle: ContainerStyleDto;

    hoverStyle: ContainerStyleDto;

    @Type(() => ListStyleDto)
    listStyle: ListStyleDto;
}
