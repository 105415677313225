import { PartStyle } from '../../../part-style.model';
import { BackgroundStyleDto } from '../../../../../interfaces/style/background.style.dto';
import { BorderStyleDto } from '../../../../../interfaces/style/border.style.dto';
import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';
import { PaddingStyleDto } from '../../../../../interfaces/style/padding.style.dto';

export class ButtonGroupPartStyle extends PartStyle {
    backgroundStyle: BackgroundStyleDto;

    borderStyle: BorderStyleDto;
    borderTopStyle: BorderStyleDto;
    borderLeftStyle: BorderStyleDto;
    borderRightStyle: BorderStyleDto;
    borderBottomStyle: BorderStyleDto;

    paddingStyle: PaddingStyleDto;
    paddingTopStyle: PaddingStyleDto;
    paddingLeftStyle: PaddingStyleDto;
    paddingRightStyle: PaddingStyleDto;
    paddingBottomStyle: PaddingStyleDto;

    labelFontStyleDto: FontStyleDto;
    answerFontStyleDto: FontStyleDto;
}
