import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Page } from '@shared/data-access';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { OverviewApiDto } from '../dto/overview/overview-api.dto';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { Api } from '../api/model/api';
import { CreateApiDto } from '../api/interfaces/create-api.dto';
import { ApiCreatedDto } from '../api/interfaces/api-created.dto';

@Injectable()
export class ApiService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public create(dto: CreateApiDto): Observable<ApiCreatedDto> {
        this.log.debug('Creating api', [dto]);
        return this.httpClient.post<ApiCreatedDto>(
            `${backofficeEnvironment.rest.v2.api}company/${dto.companyId}/application/${dto.applicationId}`,
            dto
        );
    }

    public delete(id: string, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Deleting api <${id}>`);
        return this.httpClient.delete<void>(`${backofficeEnvironment.rest.v2.api}company/${companyId}/application/${applicationId}/${id}`);
    }

    public findAll(
        companyId: string,
        applicationId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<OverviewApiDto>> {
        this.log.debug('Getting api overview');
        let params = new HttpParams().set('companyId', companyId).set('applicationId', applicationId);

        if (extras) {
            const { filters, keyword, orderBy, page, maxResults } = extras;
            if (orderBy) {
                params = params.set('orderBy', orderBy);
            }

            if (page !== undefined) {
                params = params.set('page', page);
            }

            if (maxResults !== undefined) {
                params = params.set('maxResults', maxResults);
            }

            if (keyword) {
                params = params.append('keyword', keyword);
            }

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.append('filters', value)));
            }
        }

        return this.httpClient.get<Page<OverviewApiDto>>(`${backofficeEnvironment.rest.v2.api}`, { params });
    }

    public findById(id: string, companyId: string, applicationId: string): Observable<Api> {
        this.log.debug(`Find api by id <${id}>`);
        return this.httpClient
            .get<Api>(`${backofficeEnvironment.rest.v2.api}company/${companyId}/application/${applicationId}/${id}`, {
                headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
            })
            .pipe(map(api => plainToClass(Api, api)));
    }

    public update(api: Api, companyId: string, applicationId: string): Observable<void> {
        this.log.debug(`Updating api`, [api]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.api}company/${companyId}/application/${applicationId}/${api.id}`,
            api
        );
    }
}
