<ng-container [formGroup]="form">
    <label for="dataformats" class="small-input-label">{{ 'v2.action.argument.dataformat.select' | translate }}</label>
    <div class="flex flex-row gap-1 flex-auto items-center">
        <mat-form-field
            class="flex-grow !text-xxs"
            subscriptSizing="dynamic"
            [attr.class]="this.missingDataFormat ? 'select-error' : ''"
            appearance="outline">
            <mat-select id="dataformats" formControlName="value" panelClass="toolpaneSelect scrollbar scrollbar-primary">
                <mat-option>
                    <ngx-mat-select-search
                        [placeholderLabel]="'v2.action.argument.dataformat.search' | translate"
                        [clearSearchInput]="false"
                        formControlName="valueFilter">
                        <span ngxMatSelectNoEntriesFound>
                            {{ 'v2.action.argument.dataformat.none.found' | translate }}
                        </span>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let dataformat of filteredDataFormats$ | async" [value]="dataformat.id">
                    <mat-icon>{{ dataformat.iconName }}</mat-icon>
                    <span>{{ dataformat.name }}</span> <br />
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button
            (click)="deleteDataFormatLink(); $event.stopPropagation()"
            *ngIf="showRemoveButton && !!valueControl().value"
            [matTooltip]="'v2.action.argument.scope.clear' | translate"
            class="destructive-button button-extra-small !mb-2">
            <mat-icon>clear</mat-icon>
        </button>
        <button
            (click)="onOpenDataFormat(this.value); $event.stopPropagation()"
            *ngIf="showEditButton && !!valueControl().value && !this.missingDataFormat"
            [matTooltip]="'v2.action.argument.dataformat.edit' | translate"
            class="primary-button button-extra-small !mb-2">
            <mat-icon>edit</mat-icon>
        </button>
        <button
            (click)="onCreateDataFormat(); $event.stopPropagation()"
            *ngIf="showAddButton && (!valueControl().value || this.missingDataFormat)"
            [matTooltip]="'v2.action.argument.dataformat.create' | translate"
            class="primary-button button-extra-small !mb-2">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <mat-error *ngIf="missingDataFormat" class="!text-xxs">{{ 'v2.action.argument.dataformat.select.not.found' | translate }}</mat-error>
</ng-container>
