import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyService } from '@core/services/company.service';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { selectUser } from '../../../app/store/data/authenticated.selector';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../app/store/application.state';
import { Router } from '@angular/router';
import { AppFacade } from '@core/facades/app.facade';
import { LoggerService } from '../../../../../../libs/backoffice/utils/src/lib/services/logger.service';
import { plainToClass } from 'class-transformer';
import { map, switchMap } from 'rxjs/operators';
import { setCompanies } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/statemanagement/actioncreator/company.actioncreator';

@Injectable()
export class CompanyFacade {
    user$: Observable<any> = this.store.select(selectUser);

    constructor(
        private readonly companyService: CompanyService,
        private readonly store: Store<ApplicationState>,
        private readonly appFacade: AppFacade,
        private readonly router: Router,
        private readonly logger: LoggerService
    ) {}

    inviteUser(mail: string, companyId: string): Observable<void> {
        this.logger.info(`Inviting user ${mail} to company ${companyId}`);
        return this.companyService.inviteUser(mail, companyId);
    }

    getUserCompanies(): Observable<void> {
        return this.user$.pipe(
            switchMap(user => {
                if (user) {
                    return this.companyService.getUserCompanies(user.id).pipe(
                        map((userCompanies: CompanyDto[]) => {
                            const userCompanyDtos: CompanyDto[] = plainToClass(CompanyDto, userCompanies);
                            this.store.dispatch(setCompanies(userCompanyDtos));
                        })
                    );
                } else {
                    return of(null);
                }
            })
        );
    }
}
