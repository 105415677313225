import { createAction, props } from '@ngrx/store';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '@backoffice/data-access/editor';
import { RightDto } from '../../dto/right.dto';
import { OverviewRightDto } from '../../dto/overview-right.dto';

export const createRightSuccess = createAction(
    '[EFFECT] create new right success',
    props<{
        id: string;
        openTab?: boolean;
    }>()
);

export const findRight = createAction('[ACTION] fetch right', props<{ id: string }>());
export const fetchRightSuccess = createAction('[EFFECT] fetch right success', props<{ right: RightDto }>());
export const fetchRightError = createAction('[ACTION] fetch right error', props<{ right: RightDto }>());

export const deleteRight = createAction('[ACTION] delete right', props<{ id: string }>());
export const deleteRightSuccess = createAction('[EFFECT] delete right success', props<{ id: string }>());

export const updateRight = createAction('[ACTION] update right', props<{ right: RightDto }>());
export const updateRightSuccess = createAction('[EFFECT] update right success', props<{ right: RightDto }>());

// Editor Dialog Actions

export const clearRightDialogData = createAction('[ACTION] right editor dialog data cleared');
export const clearRightDialogFilter = createAction('[ACTION] right editor dialog filter cleared');

export const deleteRights = createAction('[ACTION] delete rights', props<{ ids: string[] }>());
export const deleteRightsSuccess = createAction('[EFFECT] delete rights success', props<{ ids: string[] }>());

export const loadRightDialogData = createAction('[ACTION] load right editor dialog data');
export const loadRightDialogSuccess = createAction(
    '[EFFECT] load right editor dialog data success',
    props<{ data: Page<OverviewRightDto> }>()
);

export const rightDialogPaginationChanged = createAction(
    '[ACTION] right editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const rightDialogSearchTermChanged = createAction(
    '[ACTION] right editor dialog search term changed',
    props<{ searchTerm: string | undefined }>()
);
export const rightDialogFacetsChanged = createAction(
    '[ACTION] right editor dialog facets changed',
    props<{
        facets: SelectedFacets;
    }>()
);

export const rightDialogFilterPluginsChanged = createAction(
    '[ACTION] right editor dialog filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
