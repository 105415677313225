<div class="flex gap-1 mt-3 mb-3 justify-end h-9">
    <button
        class="primary-button button-medium"
        data-name="create-new-role"
        type="button"
        [disabled]="billing.userUsage.flag === 'RED'"
        (click)="openInviteUserDialog()">
        <mat-icon>add_circle</mat-icon>
        {{ 'v2.usermanagement.developers.invite.button' | translate }}
    </button>
</div>
<div class="mat-elevation-z2">
    <table
        class="w-[100%]"
        mat-table
        [dataSource]="developers.content"
        matSort
        matSortActive="email"
        matSortDisableClear
        matSortDirection="desc">
        <ng-container matColumnDef="email">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'v2.company.users.email' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user.email }}</td>
        </ng-container>
        <ng-container matColumnDef="firstname" class="mat-elevation-z8">
            <th mat-header-cell *matHeaderCellDef>{{ 'v2.company.users.firstname' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef>{{ 'v2.company.users.lastname' | translate }}</th>
            <td mat-cell *matCellDef="let user">{{ user.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="validatedByUser">
            <th mat-header-cell *matHeaderCellDef width="200">{{ 'v2.company.users.validated.by.user' | translate }}</th>
            <td mat-cell *matCellDef="let element" width="200" class="validation">
                <mat-icon *ngIf="element.validatedByUser">check_circle</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="validatedByCompany">
            <th mat-header-cell *matHeaderCellDef width="200">{{ 'v2.company.users.validated.by.company' | translate }}</th>
            <td mat-cell *matCellDef="let element" width="200" class="validation">
                <mat-icon *ngIf="element.validatedByCompany">check_circle</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell class="w-4" *matHeaderCellDef>{{ 'v2.company.users.actions' | translate }}</th>
            <td mat-cell class="w-4" *matCellDef="let user">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    class="icon icon-button mx-auto !h-5 !w-5 !text-xl !leading-5 z-10 cursor-pointer"
                    data-name="controls"
                    (click)="openRolesAndPermissionsDialog(user.userId)"
                    [matTooltip]="'v2.company.users.permissions' | translate">
                    <use href="#authentication" class="ui-element"></use>
                </svg>
            </td>
        </ng-container>

        <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" aria-label="Select page"> </mat-paginator>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
