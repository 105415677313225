import { GenericStyleDto } from '../../../../../interfaces/style/generic-style.dto';
import { MarginStyleDto } from '../../../../../interfaces/style/margin.style.dto';

export interface PagingButtonPartStyle extends GenericStyleDto {
    buttonFontSize: string;
    marginStyle: MarginStyleDto;
    marginTopStyle: MarginStyleDto;
    marginLeftStyle: MarginStyleDto;
    marginRightStyle: MarginStyleDto;
    marginBottomStyle: MarginStyleDto;
}
