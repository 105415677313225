import { ModuleWithProviders, NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { ObserversModule } from '@angular/cdk/observers';
import { PlatformModule } from '@angular/cdk/platform';
import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatAutocompleteModule as MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule as MatCardModule } from '@angular/material/card';
import { MatChipsModule as MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule as MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule as MatInputModule } from '@angular/material/input';
import { MatListModule as MatListModule } from '@angular/material/list';
import { MatMenuModule as MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule as MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule as MatRadioModule } from '@angular/material/radio';
import { MatSelectModule as MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule as MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule as MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
    MAT_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatSnackBarModule as MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule as MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatPaginatorIntl as MatPaginatorIntl, MatPaginatorModule as MatPaginatorModule } from '@angular/material/paginator';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CodexPaginator } from './codex.paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSortModule } from '@angular/material/sort';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkTreeModule } from '@angular/cdk/tree';

const MATERIAL_MODULES = [
    ClipboardModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatStepperModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    OverlayModule,
    PortalModule,
    A11yModule,
    PlatformModule,
    ObserversModule,
    MatBadgeModule,
    MatTableModule,
    MatTreeModule,
    MatPaginatorModule,
    DragDropModule,
    NgxMatSelectSearchModule,
    MatSortModule,
    CdkTreeModule,
];

@NgModule({
    imports: [
        ClipboardModule,
        BrowserAnimationsModule,
        BrowserModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCardModule,
        MatChipsModule,
        MatCheckboxModule,
        MatGridListModule,
        MatInputModule,
        MatListModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatTabsModule,
        MatToolbarModule,
        PortalModule,
        ObserversModule,
        DragDropModule,

        // These modules include providers.
        A11yModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatRadioModule,
        MatSliderModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTooltipModule,
        PlatformModule,
        OverlayModule,
        MatBadgeModule,
        MatTableModule,
        MatTreeModule,
        MatPaginatorModule,
        MatSortModule,
        CdkTreeModule,
    ],
    exports: MATERIAL_MODULES,
})
export class CodexMaterialRootModule {}

@NgModule({
    imports: MATERIAL_MODULES,
    exports: MATERIAL_MODULES,
    providers: [
        { provide: MatPaginatorIntl, useClass: CodexPaginator },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    ],
})
export class CodexMaterialModule {
    /** @deprecated */
    static forRoot(): ModuleWithProviders<CodexMaterialRootModule> {
        return { ngModule: CodexMaterialRootModule };
    }
}
