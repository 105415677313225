import { FontFamily } from './font/font-family.model';
import { FontSize } from './font/font-size.model';
import { FontWeight } from './font/font-weight.model';
import { FontStyle } from './font/font-style.model';
import { LetterSpacing } from './font/letter-spacing.model';
import { Variable } from './util/variable.model';
import { ColorV2 } from './color/colorv2.model';

export class Typography {
    color: ColorV2;
    fontFamily: FontFamily;
    fontSize: FontSize;
    fontWeight: FontWeight;
    fontStyle: FontStyle;
    letterSpacing: LetterSpacing;
    variableNames: {
        fontColor: Variable;
        fontFamily: Variable;
        fontSize: Variable;
        fontWeight: Variable;
        letterSpacing: Variable;
        fontStyle: Variable;
    };

    constructor(base: Typography) {
        Object.assign(this, base);
        this.variableNames = {
            fontColor: base.variableNames.fontColor,
            fontFamily: base.variableNames.fontFamily,
            fontSize: base.variableNames.fontSize,
            fontWeight: base.variableNames.fontWeight,
            letterSpacing: base.variableNames.letterSpacing,
            fontStyle: base.variableNames.fontStyle,
        };
    }
}
