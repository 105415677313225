import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { Page } from '@shared/data-access';
import { filter, switchMap, take } from 'rxjs/operators';
import { OverviewActionDto } from '@backoffice/data-access/editor';
import { ActionCreatedDto } from '../../dto/action-created.dto';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application.dto';
import { selectCurrentContext } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ActionService } from '@backoffice/editor/data-access/action';
import { selectSelectedCompanyId } from '../../../../../../../feature/company/edit-company/src/lib/statemanagement/selectors/company.selector';

@Injectable({
    providedIn: 'root',
})
export class ActionOverviewFacade {
    selectedCompanyId$: Observable<string> = this.store.select(selectSelectedCompanyId);

    currentContext$: Observable<{ userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> =
        this.store.select(selectCurrentContext);

    constructor(
        private actionService: ActionService,
        private store: Store<ApplicationState>
    ) {}

    public deleteActions(actions: { id: string }[]): Observable<void[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return forkJoin(
                    actions.map(action =>
                        this.actionService.delete(action.id, currentContext.selectedCompany.id, currentContext.selectedApplication.id)
                    )
                );
            })
        );
    }

    public getActions(
        keyword: string,
        filters: string[],
        orderBy: string,
        page: number,
        maxResults: number
    ): Observable<Page<OverviewActionDto>> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionService.findAll(currentContext.selectedCompany.id, currentContext.selectedApplication.id, {
                    keyword,
                    orderBy,
                    filters,
                    page,
                    maxResults,
                });
            })
        );
    }

    public createAction(): Observable<ActionCreatedDto> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.actionService.create({
                    companyId: currentContext.selectedCompany.id,
                    applicationId: currentContext.selectedApplication.id,
                });
            })
        );
    }
}
