<div class="overview-hub-item">
    <div class="overview-hub-item-main-info">
        <img *ngIf="!data.iconUrl" src="/images/theme/image_placeholder.svg" class="thumbnail" />
        <img
            *ngIf="!!data.iconUrl"
            [src]="
                'https://' +
                environment.backEndHost +
                '/cdn-cgi/image/width=80,height=80,fit=scale-down,quality=100,format=auto' +
                '/marketplace/products/' +
                data.id +
                '/icon?v=' +
                data.iconUrl
            "
            class="thumbnail" />
        <div class="name-data">
            <div class="name">
                <span>{{ data.name }}</span>
            </div>
            <div class="audit-data">
                <span>Created by {{ data.createdBy }}</span>
                <span>|</span>
                <span>{{ data.createdOn | date: 'medium' }}</span>
                <span>|</span>
                <span>{{ data.installs }} installs</span>
                <span>|</span>
                <span>{{ data.views }} views</span>
            </div>
            <div class="product-reference">{{ data.description | truncate: 100 }}</div>
        </div>
        <div class="actions">
            <button
                *ngIf="!data.installation"
                class="primary-button button-medium"
                data-name="install"
                type="button"
                (click)="handleInstallLatestVersion(data); $event.stopPropagation()">
                <mat-icon>download</mat-icon>
                {{ 'v2.hub.detail.tabs.versions.actions.install' | translate }}
            </button>
            <button
                *ngIf="data.installation"
                class="destructive-button button-medium"
                data-name="uninstall"
                type="button"
                (click)="handleUninstallLatestVersion(data.id); $event.stopPropagation()">
                <mat-icon>delete</mat-icon>
                {{ 'v2.hub.detail.menu.uninstall' | translate }}
            </button>
        </div>
    </div>
    <hr class="seperator" />
    <div class="product-card__tags">
        <mat-chip-listbox>
            <mat-chip *ngFor="let tag of data?.tags">{{ tag.tag | titlecase }}</mat-chip>
        </mat-chip-listbox>
    </div>
</div>
