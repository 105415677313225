import { NgModule } from '@angular/core';
import { CommonsModule } from '../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { TypographyPickerV2Component } from './components/pickers/typography-picker-v2/typography-picker-v2.component';
import { HexCharactersDirective } from '../../../feature/editor/pickers/src/lib/color-picker-v2/hex-characters-directive';
import { PickerDataAccessModule } from '@backoffice/editor/data-access/picker';
import { BackofficeFeatureEditorPickersModule } from 'backoffice/feature/editor/pickers';

@NgModule({
    imports: [CommonsModule, ColorPickerModule, PickerDataAccessModule, BackofficeFeatureEditorPickersModule],
    declarations: [TypographyPickerV2Component],
    exports: [TypographyPickerV2Component],
    providers: [HexCharactersDirective],
})
export class BackofficeUiArgumentsModule {}
