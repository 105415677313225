import { BackgroundStyleDto, BorderStyleDto, PaddingStyleDto, ShadowStyleDto } from '@backoffice/data-access/editor';
import { GenericStyleDto } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/interfaces/style/generic-style.dto';

export abstract class GenericStyleComponent {
    abstract getPartStyle(): GenericStyleDto;

    abstract onStyleUpdated(): void;

    onChangeBorder($event: {
        border: {
            borderStyle: BorderStyleDto;
            borderTopStyle: BorderStyleDto;
            borderLeftStyle: BorderStyleDto;
            borderBottomStyle: BorderStyleDto;
            borderRightStyle: BorderStyleDto;
        };
    }) {
        this.getPartStyle().borderStyle = $event.border.borderStyle;
        this.getPartStyle().borderTopStyle = $event.border.borderTopStyle;
        this.getPartStyle().borderLeftStyle = $event.border.borderLeftStyle;
        this.getPartStyle().borderBottomStyle = $event.border.borderBottomStyle;
        this.getPartStyle().borderRightStyle = $event.border.borderRightStyle;
        this.onStyleUpdated();
    }

    onChangePadding($event: {
        padding: {
            paddingStyle: PaddingStyleDto;
            paddingTopStyle: PaddingStyleDto;
            paddingLeftStyle: PaddingStyleDto;
            paddingBottomStyle: PaddingStyleDto;
            paddingRightStyle: PaddingStyleDto;
        };
    }) {
        this.getPartStyle().paddingStyle = $event.padding.paddingStyle;
        this.getPartStyle().paddingTopStyle = $event.padding.paddingTopStyle;
        this.getPartStyle().paddingLeftStyle = $event.padding.paddingLeftStyle;
        this.getPartStyle().paddingBottomStyle = $event.padding.paddingBottomStyle;
        this.getPartStyle().paddingRightStyle = $event.padding.paddingRightStyle;
        this.onStyleUpdated();
    }

    onChangeBackground($event: {
        background: {
            backgroundStyle: BackgroundStyleDto;
        };
    }) {
        this.getPartStyle().backgroundStyle = $event.background.backgroundStyle;
        this.onStyleUpdated();
    }

    onChangeShadow($event: {
        shadow: {
            shadowStyle: ShadowStyleDto;
        };
    }) {
        this.getPartStyle().shadowStyle = $event.shadow.shadowStyle;
        this.onStyleUpdated();
    }

    onChangeCursor($event: { cursor: string }) {
        if (!this.getPartStyle().cursorStyle) {
            this.getPartStyle().cursorStyle = { type: $event.cursor };
        } else {
            this.getPartStyle().cursorStyle.type = $event.cursor;
        }
        this.onStyleUpdated();
    }

    resetShadowStyle() {
        this.getPartStyle().shadowStyle = null;
        this.onStyleUpdated();
    }

    resetCursorStyle() {
        this.getPartStyle().cursorStyle = null;
        this.onStyleUpdated();
    }

    resetBorderStyle() {
        this.getPartStyle().borderStyle = null;
        this.getPartStyle().borderTopStyle = null;
        this.getPartStyle().borderLeftStyle = null;
        this.getPartStyle().borderRightStyle = null;
        this.getPartStyle().borderBottomStyle = null;
        this.onStyleUpdated();
    }

    resetPaddingStyle() {
        this.getPartStyle().paddingStyle = null;
        this.getPartStyle().paddingTopStyle = null;
        this.getPartStyle().paddingLeftStyle = null;
        this.getPartStyle().paddingRightStyle = null;
        this.getPartStyle().paddingBottomStyle = null;
        this.onStyleUpdated();
    }

    resetBackgroundStyle() {
        this.getPartStyle().backgroundStyle = null;
        this.onStyleUpdated();
    }

    resetOverflowStyle() {
        this.getPartStyle().innerSize.overflowX = null;
        this.getPartStyle().innerSize.overflowY = null;
        this.onStyleUpdated();
    }

    initializeStyle() {
        if (!this.getPartStyle().innerSize) {
            this.getPartStyle().innerSize = {};
        }
    }
    defaultStyle(): GenericStyleDto {
        return {
            innerSize: {},
        } as GenericStyleDto;
    }
}
