import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { Store } from '@ngrx/store';
import { filter, switchMap, take } from 'rxjs/operators';
import { ApplicationDto } from '../../../../../data-access/editor/src/lib/interfaces/application.dto';
import { selectCurrentContext } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationState } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { AuditLogDto, AuditLogService } from '@backoffice/data-access/audit-logs';

@Injectable()
export class AuditLogsFacade {
    currentContext$: Observable<{ userLanguage: string; selectedCompany: CompanyDto; selectedApplication: ApplicationDto }> =
        this.store.select(selectCurrentContext);

    constructor(
        private auditLogService: AuditLogService,
        private store: Store<ApplicationState>
    ) {}

    public getAuditLogs(
        templateId: string | undefined,
        actionId: string | undefined,
        from: Date,
        to: Date,
        type: string
    ): Observable<AuditLogDto[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.auditLogService.findAuditLogs(
                    currentContext.selectedCompany.id,
                    currentContext.selectedApplication.id,
                    templateId,
                    actionId,
                    from,
                    to,
                    type
                );
            })
        );
    }
}
