import { PartDetail } from '../../../part-detail.model';
import { TabsPartTab } from './tabs-part.tab';
import { Part } from '../../../part.model';
import { classToPlain, plainToClass, Type } from 'class-transformer';

export class TabsPartDetail extends PartDetail {
    code: string;

    @Type(() => TabsPartTab)
    tabs: TabsPartTab[];

    //transient
    openedTab: number;

    override isValid(): boolean {
        return this.isCodeValid() && this.areTabsValid();
    }

    private areTabsValid() {
        if (!!this.tabs && this.tabs.length > 0) {
            let valid = true;
            for (const tab of this.tabs) {
                valid = valid && this.isTabValid(tab);
            }
            return valid;
        } else {
            return true;
        }
    }

    private isTabValid(tab: TabsPartTab) {
        return !!tab.code && tab.code !== '' && !!tab.name && tab.name !== '';
    }

    private isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    override getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.tabs.error.code');
        }
        if (!this.areTabsValid()) {
            validationErrors.push('v2.part.tabs.error.tab');
        }
        return validationErrors;
    }

    override addContainerPart(part: Part) {
        if (this.tabs[this.openedTab]) {
            if (!this.tabs[this.openedTab].parts) {
                this.tabs[this.openedTab].parts = [];
            }
            this.tabs[this.openedTab].parts?.push(part);
        }
    }

    override updateContainerPart(partToUpdate: Part) {
        const currentPart = this.getChildParts().find(part => part.selectorId === partToUpdate.selectorId);
        if (currentPart) {
            this.removeContainerPart(currentPart.selectorId);
        }
        const plainPartToUpdate = classToPlain(partToUpdate);
        const objectUpdatedPart: Part = plainToClass(Part, plainPartToUpdate);
        const targetTab = this.tabs.find(tab => tab.code === objectUpdatedPart.secondaryContainerId);
        if (targetTab) {
            if (!targetTab.parts) {
                targetTab.parts = [];
            }
            targetTab.parts.push(objectUpdatedPart);
        }
        return objectUpdatedPart;
    }

    override removeContainerPart(partSelectorId: string) {
        this.tabs.forEach(tab => {
            if (tab.parts) {
                const foundPartIndex = tab.parts.findIndex(part => part.selectorId === partSelectorId);
                if (foundPartIndex > -1) {
                    tab.parts.splice(foundPartIndex, 1);
                    return;
                }
            }
        });
    }

    override getChildParts(): Part[] {
        const childParts: Part[] = [];
        if (this.tabs) {
            this.tabs.forEach(tab => {
                if (tab.parts) {
                    tab.parts.forEach(part => childParts.push(part));
                }
            });
        }
        return childParts;
    }

    public shouldInitializeChildParts(): boolean {
        return !this.initialized && this.tabs && this.tabs.length > 0;
    }

    public override hasActions(): boolean {
        return false;
    }

    public override getSubContainers(): any[] {
        if (this.tabs && this.tabs.length > 0) {
            return this.tabs.map(tab => {
                return {
                    id: tab.id,
                    code: tab.code,
                    name: tab.name,
                    icon: tab.icon ? tab.icon : 'tabs',
                    type: 'tab',
                    parts: tab.parts,
                };
            });
        } else {
            return [];
        }
    }
}
