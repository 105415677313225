import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { CompanyIdentityProvider } from '../../../modules/shared/interfaces/company-identity-provider.dto';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../app/common/statemanagement/state/applicationstate';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CompanyIdentityproviderService {
    constructor(
        private store: Store<ApplicationState>,
        private http: HttpClient
    ) {}

    getCompanyIdentityProviders(companyId: string): Observable<CompanyIdentityProvider[]> {
        return this.http.get<CompanyIdentityProvider[]>(`${backofficeEnvironment.rest.company}${companyId}/identityproviders`);
    }

    getCompanyIdentityProvider(companyId: string, identityProviderId: string): Observable<CompanyIdentityProvider> {
        return this.http.get<CompanyIdentityProvider>(
            `${backofficeEnvironment.rest.company}${companyId}/identityproviders/${identityProviderId}`
        );
    }

    updateCompanyIdentityProvider(
        companyId: string,
        identityProviderId: string,
        companyIdentityProvider: CompanyIdentityProvider
    ): Observable<void> {
        return this.http.put<void>(
            `${backofficeEnvironment.rest.company}${companyId}/identityproviders/${identityProviderId}`,
            companyIdentityProvider
        );
    }

    createCompanyIdentityProvider(companyId: string, companyIdentityProvider: CompanyIdentityProvider): Observable<void> {
        return this.http.post<void>(`${backofficeEnvironment.rest.company}${companyId}/identityproviders`, companyIdentityProvider);
    }

    deleteCompanyIdentityProvider(companyId: string, identityProviderId: string): Observable<void> {
        return this.http.delete<void>(`${backofficeEnvironment.rest.company}${companyId}/identityproviders/${identityProviderId}`);
    }
}
