import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyDto } from '../../../modules/shared/interfaces/company.dto';
import { ApplicationDto } from '../../v2-application/dto/application.dto';
import { selectCurrentContext } from '../../store/data/authenticated.selector';
import { OverviewTemplateDto, Template, TemplateService } from '@backoffice/data-access/editor';
import { Page } from '@shared/data-access';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../store/application.state';
import { Group } from '../../../features/user-management/models/group.model';
import { Role } from '../../../features/user-management/models/role.model';
import { GroupsService } from '../../../../../../libs/backoffice/data-access/editor/src/lib/group/services/groups.service';
import { RightsService } from '../../../../../../libs/backoffice/data-access/editor/src/lib/right/services/roles.service';
import { OverviewRightDto } from '../../../../../../libs/backoffice/data-access/editor/src/lib/right/dto/overview-right.dto';
import { RightDto } from '../../../../../../libs/backoffice/data-access/editor/src/lib/right/dto/right.dto';
import { GroupDto } from '../../../../../../libs/backoffice/data-access/editor/src/lib/group/dto/group.dto';

@Injectable({
    providedIn: 'root',
})
export class SharedFacade {
    currentContext$: Observable<{
        userLanguage: string;
        selectedCompany: CompanyDto;
        selectedApplication: ApplicationDto;
    }> = this.store.select(selectCurrentContext);

    constructor(
        private templateService: TemplateService,
        private groupsService: GroupsService,
        private rightsService: RightsService,
        private store: Store<ApplicationState>
    ) {}

    public getTemplates(
        applicationId: string,
        keyword: string,
        filters: string[],
        orderBy: string,
        page: number,
        maxResults: number
    ): Observable<Page<OverviewTemplateDto>> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.templateService.find(
                    currentContext.selectedCompany.id,
                    applicationId ? applicationId : currentContext.selectedApplication.id,
                    {
                        keyword,
                        orderBy,
                        filters,
                        page,
                        maxResults,
                    }
                );
            })
        );
    }

    public getTemplateOfApplication(templateId: string, applicationId: string): Observable<Template> {
        if (!!templateId) {
            return this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                switchMap(currentContext => {
                    return this.templateService.getTemplate(templateId, currentContext.selectedCompany.id, applicationId);
                })
            );
        }
    }

    public getTemplate(templateId: string, applicationId: string): Observable<Template> {
        if (!!templateId) {
            return this.currentContext$.pipe(
                filter(currentContext => !!currentContext),
                take(1),
                switchMap(currentContext => {
                    return this.templateService.getTemplate(
                        templateId,
                        currentContext.selectedCompany.id,
                        applicationId ? applicationId : currentContext.selectedApplication.id
                    );
                })
            );
        }
    }

    public getAllGroups(): Observable<GroupDto[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.groupsService
                    .fetchAll(currentContext.selectedApplication.id, currentContext.selectedCompany.id)
                    .pipe(map(groupPage => groupPage.content));
            })
        );
    }

    public getAllRoles(): Observable<RightDto[]> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.rightsService
                    .getRightsByApplicationId(currentContext.selectedApplication.id, currentContext.selectedCompany.id, false)
                    .pipe(map(rolePage => rolePage.content));
            })
        );
    }
}
