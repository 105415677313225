import { PayloadAction } from '../../common/statemanagement/action/payload.action';
import { Template } from '@backoffice/data-access/editor';

export const ACTION_ADD_TEMPLATE = '<Action> add template';
export const ACTION_CLEAR_STATE = '<Action> clear state';

export function addTemplate(template: Template): PayloadAction {
    return {
        type: ACTION_ADD_TEMPLATE,
        payload: template,
    };
}

export function clearState(): PayloadAction {
    return {
        type: ACTION_CLEAR_STATE,
    };
}
