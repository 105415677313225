import { createAction, props } from '@ngrx/store';
import { TabDefinition } from '../../interfaces/tab-definition.interface';

export const registerTab = createAction('[ACTION] register tab', props<{ definition: TabDefinition }>());
export const updateTab = createAction('[ACTION] update tab', props<{ definition: TabDefinition }>());
export const selectTab = createAction('[ACTION] select tab', props<{ typeId: string }>());
export const closeTab = createAction(
    '[ACTION] close tab',
    props<{
        tabType: 'action' | 'api' | 'data' | 'data-format' | 'template' | 'media' | 'job' | 'designsystem' | 'right' | 'group';
        typeId: string;
    }>()
);
export const closeTabs = createAction(
    '[ACTION] close tabs',
    props<{
        tabType: 'action' | 'api' | 'data' | 'data-format' | 'template' | 'media' | 'job' | 'designsystem' | 'right' | 'group';
        typeIds: string[];
    }>()
);
