import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Api } from '../../api/model/api';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const apiAdapter = createEntityAdapter<Api>();

const { selectIds, selectAll, selectTotal, selectEntities } = apiAdapter.getSelectors();

export const apiEditorSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.api)),
    dialogData: createSelector(selectState, state => state.apiDialog.result),
    dialogFilter: createSelector(selectState, state => {
        const { page, maxResults, searchTerm, facets, filterPlugins } = state.apiDialog;
        return {
            page,
            maxResults,
            searchTerm,
            facets,
            filterPlugins,
        };
    }),
    searchTerm: createSelector(selectState, state => state.apiDialog.searchTerm),
    pagination: createSelector(selectState, state => {
        const { page, maxResults } = state.apiDialog;
        return { page, maxResults };
    }),
    facets: createSelector(selectState, state => state.apiDialog.facets),
    filterPlugins: createSelector(selectState, state => state.apiDialog.filterPlugins),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<Api> = selectEntities(state.api);
            if (id in dictionary) {
                return dictionary[id];
            }
            return undefined;
        }),
};
