<div
    class="block min-w-0 col-span-4 text-nowrap overflow-hidden text-ellipsis justify-center"
    [style.color]="getFontColor()"
    [style.font-family]="form.value.fontFamily"
    [style.font-size]="form.value.fontSize.value + (form.value.fontSize.unit !== 'pixels' ? form.value.fontSize.unit : 'px')"
    [style.line-height]="form.value.fontSize.value + (form.value.fontSize.unit !== 'pixels' ? form.value.fontSize.unit : 'px')"
    [style.font-weight]="form.value.fontWeight"
    [style.letter-spacing]="form.value.letterSpacing + 'px'"
    [style.font-style]="form.value.italic ? 'italic' : ''">
    {{ title }}
</div>

<div class="col-span-1">
    <codex-viewport-selector
        [formGoup]="form.controls.fontSize"
        [allowedUnits]="['pixels', 'rem', 'em']"
        class="w-full"></codex-viewport-selector>
</div>
<div class="col-span-1">
    <input type="number" class="small-input" [formControl]="form.controls.letterSpacing" />
</div>
<div class="col-span-1 flex items-center justify-center">
    <label class="toggle-line">
        <input type="checkbox" class="sr-only peer" [formControl]="form.controls.italic" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>
</div>

<label class="block col-span-1 dark:text-white">
    <select class="small-input" [formControl]="form.controls.fontWeight">
        <option value="100">Thin</option>
        <option value="200">Extra Light</option>
        <option value="300">Light</option>
        <option value="400">Normal</option>
        <option value="500">Medium</option>
        <option value="600">Semi Bold</option>
        <option value="700">Bold</option>
        <option value="800">Extra Bold</option>
        <option value="900">Black</option>
    </select>
</label>
<label class="w-full block col-span-2 dark:text-white">
    <nocodex-color-picker-v2 [formControl]="form.controls.color" [designsystemId]="designSystem.id"></nocodex-color-picker-v2>
</label>
<label class="w-full block col-span-2 dark:text-white">
    <nocodex-font-family-v2 [formControl]="form.controls.fontFamily"></nocodex-font-family-v2>
</label>
