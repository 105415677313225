export class TemplateVersion {
    public readonly id: string;
    public name: string;
    public versionNumber: string;
    public currentVersion: boolean;
    public testVersion: boolean;
    public acceptanceVersion: boolean;
    public releaseNotes: string;

    public setCurrentVersion(): void {
        if (!this.currentVersion) {
            this.currentVersion = true;
        }
    }

    public setTestVersion(): void {
        if (!this.testVersion) {
            this.testVersion = true;
        }
    }

    public setAcceptanceVersion(): void {
        if (!this.acceptanceVersion) {
            this.acceptanceVersion = true;
        }
    }
}
