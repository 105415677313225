import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationDto } from '../../../../app/v2-application/dto/application.dto';
import { OverviewTemplateDto } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/dto/overview/overview-template.dto';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'codex-application-edit-error',
    templateUrl: './application-edit-error.component.html',
})
export class ApplicationEditErrorComponent implements OnInit {
    @Input()
    application: ApplicationDto;

    @Output()
    updateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();

    @Output()
    cancelUpdateApplication: EventEmitter<{ application: ApplicationDto }> = new EventEmitter<{ application: ApplicationDto }>();

    formGroup: FormGroup;

    constructor(private readonly fb: FormBuilder) {}

    ngOnInit(): void {
        this.formGroup = this.fb.group({});
    }

    onErrorTemplateChanged($event: { template: OverviewTemplateDto }) {
        if ($event.template?.id) {
            this.application.errorTemplateId = $event.template.id;
        } else {
            this.application.errorTemplateId = null;
        }
    }

    onNotFoundTemplateChanged($event: { template: OverviewTemplateDto }) {
        if ($event.template?.id) {
            this.application.notFoundTemplateId = $event.template.id;
        } else {
            this.application.notFoundTemplateId = null;
        }
    }

    onUnAuthorizedTemplateChanged($event: { template: OverviewTemplateDto }) {
        if ($event.template?.id) {
            this.application.unAuthorizedTemplateId = $event.template.id;
        } else {
            this.application.unAuthorizedTemplateId = null;
        }
    }

    onUpdateApplication() {
        this.updateApplication.emit({ application: this.application });
    }

    onCancel() {
        this.cancelUpdateApplication.emit({ application: this.application });
    }
}
