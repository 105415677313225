import { createSelector } from '@ngrx/store';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { CompanyLanguageDto } from '../../dto/deprecated/company.language.dto';
import { ApplicationState } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';

export const selectSelectedCompany = (state: ApplicationState) => state.context.selectedCompany;
export const selectSelectedCompanyId = createSelector(selectSelectedCompany, (selectedCompanyId: CompanyDto): string => {
    if (selectedCompanyId) {
        return selectedCompanyId.id;
    }
});

export const selectCompanies = (state: ApplicationState) => state.context.companies;
export const selectRecentCompanies = (state: ApplicationState) => state.context.recentCompanies;

export const selectRecentApplications = (state: ApplicationState) => state.context.recentApplications;

export const selectSelectedCompanyLanguages = (state: ApplicationState) => state.data.selectedCompanyLanguages;
export const selectSelectedCompanyLanguagesMap = createSelector(
    selectSelectedCompanyLanguages,
    (companyLanguages: CompanyLanguageDto[]): Map<string, CompanyLanguageDto> =>
        new Map(companyLanguages ? companyLanguages.map(companyLanguage => [companyLanguage.languageCode, companyLanguage]) : [])
);

export const selectSelectedCompanyBilling = (state: ApplicationState) => state.data.selectedCompanyBilling;
