import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { LoggerService } from '@backoffice/utils';
import { backofficeEnvironment } from '@shared/environment';
import { Page } from '@shared/data-access';
import '@shared/utils';
import { CreateTemplateDto } from '../../interfaces/template/create-template.dto';
import { TemplateCreatedDto } from '../../interfaces/template/template-created.dto';
import { OverviewTemplateDto } from '../../dto/overview/overview-template.dto';
import { Template } from '../../models/template/template.model';

@Injectable()
export class TemplateService {
    constructor(
        private httpClient: HttpClient,
        private _zone: NgZone,
        private readonly logger: LoggerService
    ) {}

    public create(templateCreateDto: CreateTemplateDto, companyId: string, applicationId: string): Observable<TemplateCreatedDto> {
        this.logger.debug('Creating template', [templateCreateDto]);
        return this.httpClient.post<TemplateCreatedDto>(
            `${backofficeEnvironment.rest.v2.templates}company/${companyId}/application/${applicationId}`,
            templateCreateDto
        );
    }

    public update(template: Template, companyId: string, applicationId: string): Observable<void> {
        this.logger.debug('Updating template', [template]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.templates}company/${companyId}/application/${applicationId}/${template.id}`,
            template
        );
    }

    public delete(id: string, companyId: string, applicationId: string): Observable<void> {
        this.logger.debug('Deleting template', [id]);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.templates}company/${companyId}/application/${applicationId}/${id}`
        );
    }

    public removeInheritance(id: string, companyId: string, applicationId: string): Observable<void> {
        this.logger.debug('Deleting template', [id]);
        return this.httpClient.delete<void>(
            `${backofficeEnvironment.rest.v2.templates}company/${companyId}/application/${applicationId}/${id}/parent`
        );
    }

    public findById(id: string, companyId: string, applicationId: string, languageCode?: string | null): Observable<Template> {
        this.logger.debug('Find template by id', [id]);
        let params = new HttpParams();
        if (!!languageCode) {
            params = params.set('languageCode', languageCode);
        }
        return this.httpClient
            .get<Template>(`${backofficeEnvironment.rest.v2.templates}company/${companyId}/application/${applicationId}/${id}`, {
                headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
                params,
            })
            .pipe(map(template => plainToClass(Template, template)));
    }

    public getTemplate(templateId: string, companyId: string, applicationId: string): Observable<Template> {
        this.logger.debug('Getting template', [templateId]);
        return this.httpClient
            .get<Template>(`${backofficeEnvironment.rest.v2.templates}company/${companyId}/application/${applicationId}/${templateId}`, {
                headers: new HttpHeaders().set('X-Skip-Error-Interceptor', 'true'),
            })
            .pipe(map(template => plainToClass(Template, template)));
    }

    public translateTemplate(
        translateTemplate: {
            fromTemplateId: string;
            templateId: string;
            companyId: string;
            applicationId: string;
        },
        companyId: string,
        applicationId: string
    ) {
        this.logger.debug('Translating template', [translateTemplate]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.templates}company/${companyId}/application/${applicationId}/${translateTemplate.templateId}/translate`,
            translateTemplate
        );
    }

    public find(
        companyId: string,
        applicationId: string,
        extras?: {
            keyword?: string;
            orderBy?: string;
            filters?: string[];
            page?: number;
            maxResults?: number;
        }
    ): Observable<Page<OverviewTemplateDto>> {
        this.logger.debug('Getting templates overview');
        let params = new HttpParams().set('companyId', companyId).set('applicationId', applicationId);

        if (extras) {
            const { keyword, orderBy, filters, page, maxResults } = extras;
            params = params
                .appendNonNull('orderBy', orderBy)
                .appendNonNull('page', page)
                .appendNonNull('maxResults', maxResults)
                .appendNonNull('keyword', keyword);

            if (filters && filters.length > 0) {
                filters.forEach(value => (params = params.appendNonNull('filters', value)));
            }
        }

        return this.httpClient.get<Page<OverviewTemplateDto>>(`${backofficeEnvironment.rest.v2.templates}`, { params });
    }

    streamTemplateUpdates(templateId: string, otp: string, companyId: string, applicationId: string): Observable<any> {
        return new Observable(observer => {
            const eventSource = new EventSource(
                `${backofficeEnvironment.rest.v2.templates}stream/company/${companyId}/application/${applicationId}/${templateId}?otp=${otp}`
            );
            eventSource.onmessage = event => {
                this._zone.run(() => {
                    this.logger.info('running');
                    observer.next(event);
                });
            };
            eventSource.onerror = error => {
                this._zone.run(() => {
                    this.logger.info('error');
                    this.logger.info(error);
                });
            };
        });
    }

    initStreamTemplateUpdates(templateId: string, companyId: string, applicationId: string): Observable<{ value: string }> {
        return this.httpClient.get<{ value: string }>(
            `${backofficeEnvironment.rest.v2.templates}stream-init/company/${companyId}/application/${applicationId}/${templateId}`
        );
    }
}
