<div
    [attr.id]="'accordion-open-body-' + this.attribute.id"
    [attr.data-name]="'content-' + this.attribute.id"
    [attr.aria-labelledby]="'accordion-open-heading-' + this.attribute.id"
    [class.accordion-panel-first]="index === 0"
    [class.accordion-panel-last]="index === attributeAmount - 1"
    [class.accordion-panel]="true"
    class="hidden">
    <label for="attribute-name" class="small-input-label">Name</label>
    <input
        class="small-input"
        type="text"
        id="attribute-name"
        name="attribute-name"
        class="small-input"
        autocomplete="off"
        (input)="this.propertyChanged.emit({ attribute: this.attribute })"
        [(ngModel)]="attribute.name" />

    <label for="attribute-description" class="small-input-label">Description</label>
    <textarea
        class="small-input"
        type="text"
        id="attribute-description"
        name="attribute-description"
        class="small-input"
        placeholder="Describe your data-format attribute in detail..."
        autocomplete="off"
        (input)="this.propertyChanged.emit({ attribute: this.attribute })"
        [(ngModel)]="attribute.description"></textarea>

    <hr class="seperator" />

    <label class="toggle-line">
        Nullable
        <input
            type="checkbox"
            value=""
            class="sr-only peer"
            [(ngModel)]="attribute.nullable"
            (ngModelChange)="this.propertyChanged.emit({ attribute: this.attribute })" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>
    <label class="toggle-line">
        Required
        <input
            type="checkbox"
            value=""
            class="sr-only peer"
            [(ngModel)]="attribute.required"
            (ngModelChange)="this.propertyChanged.emit({ attribute: this.attribute })" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>

    <hr class="seperator" />

    <codex-array-type-settings
        *ngIf="this.attribute.types.indexOf('array') > -1"
        [attribute]="attribute"
        (change)="this.propertyChanged.emit({ attribute: this.attribute })"></codex-array-type-settings>
    <codex-boolean-type-settings
        *ngIf="this.attribute.types.indexOf('boolean') > -1"
        [attribute]="attribute"
        (change)="this.propertyChanged.emit({ attribute: this.attribute })"></codex-boolean-type-settings>
    <codex-integer-type-settings
        *ngIf="this.attribute.types.indexOf('integer') > -1"
        [attribute]="attribute"
        (change)="this.propertyChanged.emit({ attribute: this.attribute })"></codex-integer-type-settings>
    <codex-number-type-settings
        *ngIf="this.attribute.types.indexOf('number') > -1"
        [attribute]="attribute"
        (change)="this.propertyChanged.emit({ attribute: this.attribute })"></codex-number-type-settings>
    <codex-object-type-settings
        *ngIf="this.attribute.types.indexOf('object') > -1"
        [attribute]="attribute"
        (change)="this.propertyChanged.emit({ attribute: this.attribute })"></codex-object-type-settings>
    <codex-string-type-settings
        *ngIf="this.attribute.types.indexOf('string') > -1"
        [attribute]="attribute"
        (change)="this.propertyChanged.emit({ attribute: this.attribute })"></codex-string-type-settings>
</div>
