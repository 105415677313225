<mat-dialog-content cdkTrapFocus cdkTrapFocusAutoCapture class="dataformat-search">
    <app-search-header
        [title]="'Design-systems'"
        (create)="handleCreateClicked()"
        [createTooltip]="'Create new designsystem (alt + n)'"
        [createButtonTitle]="'Create'"
        [showCopy]="false"
        [enableCopy]="this.selectedRows.length > 0"
        (delete)="handleDeleteClicked()"
        [deleteTooltip]="'Delete selected designsystems (alt + r)'"
        [deleteButtonTitle]="'Remove'"
        [showDelete]="true"
        [enableDelete]="this.selectedRows.length > 0">
    </app-search-header>
    <div class="ml-3 mb-3 mr-3 mt-1 flex-col flex">
        <search-form
            (search)="handleSearchTermChanged($event)"
            [searchString]="searchTerm$ | async"
            [searchPlaceHolder]="'v2.designsystem.overview.search.designsystem'"
            class="w-full mb-2"
            data-name="dataformat-search">
        </search-form>
        <ng-container *ngIf="page$ | async as page">
            <div class="flex flex-col">
                <div class="search-results-wrapper">
                    <div class="w-1/5 pt-4 flex flex-col gap-1">
                        <app-filter-plugins
                            label="Include plugin templates"
                            [filterPlugins]="filterPlugins$ | async"
                            (selectionChanged)="handleFilterPluginsChanged($event)"
                            [facets]="page.facetFields"
                            [prefix]="'template_search'"
                            [selectedValues]="facets$ | async"
                            (facetSelectionChanged)="handleFacetsChanged($event)">
                        </app-filter-plugins>
                    </div>
                    <div *ngIf="page.content && page.content.length > 0; else noResult" class="overview-items">
                        <codex-designsystem-overview-v2
                            *ngFor="let designsystem of page.content"
                            (boxSelectionChanged)="handleRowSelectionChanged($event, designsystem.id)"
                            (clicked)="handleItemClicked(designsystem)"
                            [designsystem]="designsystem">
                        </codex-designsystem-overview-v2>
                    </div>
                </div>
                <ng-template #noResult>
                    <div
                        class="pt-2 flex-auto w-[70%] mb-5 gap-1 flex flex-col scrollbar scrollbar-primary !overflow-x-visible self-center">
                        <div class="flex-col flex justify-center items-center">
                            <img class="w-[350px] h-[150px] object-cover" src="/src/images/theme/no_results_found.gif" />
                            <h1 class="text-center !mb-2">We couldn't find any design systems for your search.</h1>
                            <p class="!mb-8 text-white">
                                Start designing the look & feel of your application by adding a new design system.
                            </p>
                            <button type="button" class="primary-button button-extra-large" (click)="handleCreateClicked()">
                                <svg
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24">
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M5 12h14m-7 7V5" />
                                </svg>
                                Create new design system
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>
            <ng-container *ngIf="page.content && page.content.length > 0">
                <mat-paginator
                    *ngIf="pagination$ | async as pagination"
                    (page)="handlePaginationChanged($event)"
                    [length]="page.count"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                    [pageSize]="pagination.maxResults"
                    [pageIndex]="pagination.page"
                    aria-label="Select page">
                </mat-paginator>
            </ng-container>
        </ng-container>
    </div>
</mat-dialog-content>
