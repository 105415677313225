import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState, editorStateKey } from '../editor.state';
import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { Action } from '../../action/model/action';

const selectState = createFeatureSelector<EditorState>(editorStateKey);

const actionAdapter = createEntityAdapter<Action>();

const { selectIds, selectAll, selectTotal, selectEntities } = actionAdapter.getSelectors();

export const actionEditorSelectors = {
    ids: createSelector(selectState, () => selectIds),
    all: createSelector(selectState, () => selectAll),
    total: createSelector(selectState, () => selectTotal),
    entities: createSelector(selectState, state => selectEntities(state.actions)),
    dialogData: createSelector(selectState, state => state.actionDialog.result),
    dialogFilter: createSelector(selectState, state => {
        const { page, maxResults, searchTerm, facets, filterPlugins } = state.actionDialog;
        return {
            page,
            maxResults,
            searchTerm,
            facets,
            filterPlugins,
        };
    }),
    searchTerm: createSelector(selectState, state => state.actionDialog.searchTerm),
    pagination: createSelector(selectState, state => {
        const { page, maxResults } = state.actionDialog;
        return { page, maxResults };
    }),
    facets: createSelector(selectState, state => state.actionDialog.facets),
    filterPlugins: createSelector(selectState, state => state.actionDialog.filterPlugins),
    byId: (id: string) =>
        createSelector(selectState, state => {
            const dictionary: Dictionary<Action> = selectEntities(state.actions);
            return dictionary[id];
        }),
};
