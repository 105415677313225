import { PartDetail } from '../../../part-detail.model';
import { Part } from '../../../part.model';
import { classToPlain, plainToClass, Type } from 'class-transformer';
import { AccordionPartItem } from './accordion-part.item';

export class AccordionPartDetail extends PartDetail {
    code: string;

    @Type(() => AccordionPartItem)
    accordionItems: AccordionPartItem[];

    //transient
    openedItem: number;

    override isValid(): boolean {
        return this.isCodeValid() && this.areItemsValid();
    }

    private areItemsValid() {
        if (!!this.accordionItems && this.accordionItems.length > 0) {
            let valid = true;
            for (const item of this.accordionItems) {
                valid = valid && this.isItemValid(item);
            }
            return valid;
        } else {
            return true;
        }
    }

    private isItemValid(item: AccordionPartItem) {
        return !!item.code && item.code !== '' && !!item.name && item.name !== '';
    }

    private isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    override getValidationErrors(): string[] {
        const validationErrors = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.accordion.error.code');
        }
        if (!this.areItemsValid()) {
            validationErrors.push('v2.part.accordion.error.item');
        }
        return validationErrors;
    }

    override addContainerPart(part: Part) {
        if (this.accordionItems[this.openedItem]) {
            if (!this.accordionItems[this.openedItem].parts) {
                this.accordionItems[this.openedItem].parts = [];
            }
            this.accordionItems[this.openedItem].parts?.push(part);
        }
    }

    override updateContainerPart(partToUpdate: Part) {
        const currentPart = this.getChildParts().find(part => part.selectorId === partToUpdate.selectorId);
        if (currentPart) {
            this.removeContainerPart(currentPart.selectorId);
        }
        const plainPartToUpdate = classToPlain(partToUpdate);
        const objectUpdatedPart: Part = plainToClass(Part, plainPartToUpdate);
        const targetTab = this.accordionItems.find(item => item.code === objectUpdatedPart.secondaryContainerId);
        if (targetTab) {
            if (!targetTab.parts) {
                targetTab.parts = [];
            }
            targetTab.parts.push(objectUpdatedPart);
        }
        return objectUpdatedPart;
    }

    override removeContainerPart(partSelectorId: string) {
        this.accordionItems.forEach(item => {
            if (item.parts) {
                const foundPartIndex = item.parts.findIndex(part => part.selectorId === partSelectorId);
                if (foundPartIndex > -1) {
                    item.parts.splice(foundPartIndex, 1);
                    return;
                }
            }
        });
    }

    override getChildParts(): Part[] {
        const childParts: Part[] = [];
        if (this.accordionItems) {
            this.accordionItems.forEach(item => {
                if (item.parts) {
                    item.parts.forEach(part => childParts.push(part));
                }
            });
        }
        return childParts;
    }

    public shouldInitializeChildParts(): boolean {
        return !this.initialized && this.accordionItems && this.accordionItems.length > 0;
    }

    public override getSubContainers(): any[] {
        if (this.accordionItems && this.accordionItems.length > 0) {
            return this.accordionItems.map(item => {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    icon: item.icon ? item.icon : 'tabs',
                    type: 'accordionitem',
                    parts: item.parts,
                };
            });
        } else {
            return [];
        }
    }
}
