import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyDto } from '@shared/interfaces/company.dto';

@Component({
    selector: 'company-edit-general-info',
    templateUrl: 'company-edit-general-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyEditGeneralInfoComponent implements OnInit {
    @Input() company: CompanyDto;

    @Output() updateCompany: EventEmitter<{ company: CompanyDto }> = new EventEmitter<{ company: CompanyDto }>();

    form: FormGroup;

    constructor(private readonly fb: FormBuilder) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [this.company.companyName, Validators.required],
        });
    }

    onChangeCompanyName() {
        if (this.form.get('name').value !== '') {
            this.company.companyName = this.form.get('name').value;
        }
    }

    onUpdateCompany() {
        this.updateCompany.emit({
            company: this.company,
        });
    }
}
