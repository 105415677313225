import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorState } from '../../../store/editor.state';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CurrentContext, LoggerService } from '@backoffice/utils';
import { DataFormat } from '../../models/data-format';
import { dataFormatEditorSelectors } from '../selectors/dataformat-editor.selectors';
import {
    clearDataFormatDialogData,
    createDataFormatSuccess,
    dataFormatDialogFacetsChanged,
    dataFormatDialogFilterPluginsChanged,
    dataFormatDialogPaginationChanged,
    dataFormatDialogSearchTermChanged,
    deleteDataFormats,
    findDataFormat,
    loadDataFormatDialogData,
    updateDataFormat,
} from '../actions/dataformat-editor.actions';
import { plainToClass } from 'class-transformer';
import { AppFacade } from '@core/facades/app.facade';
import { Page } from '@shared/data-access';
import { selectCurrentContext } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { ApplicationState } from '../../../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { DataFormatCreatedDto, DataformatService, EditorFacade, SelectedFacets } from '../../../../index';
import { OverviewDataFormatDto } from '../../interfaces/overview-data-format.dto';
import { ComponentType } from '@angular/cdk/overlay';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialog } from '@angular/material/dialog';
import { GeneratedIdentifierFlags } from '@schematics/angular/third_party/github.com/Microsoft/TypeScript/lib/typescript';
import { AssistantGenerateDataformatValidateComponent } from '../../../../../../../ui/assistant/src/lib/components/assistant-generate-dataformat-validate/assistant-generate-dataformat-validate.component';
import { UploadFile } from 'ngx-uploader';

@Injectable()
export class DataFormatEditorFacade {
    currentContext$: Observable<CurrentContext | undefined> = this.applicationStateStore.select(selectCurrentContext);

    get dialogData(): Observable<Page<OverviewDataFormatDto> | undefined> {
        return this.store.select(dataFormatEditorSelectors.dialogData);
    }

    get searchTerm(): Observable<string | undefined> {
        return this.store.select(dataFormatEditorSelectors.searchTerm);
    }

    get pagination(): Observable<{ page: number; maxResults: number }> {
        return this.store.select(dataFormatEditorSelectors.pagination);
    }

    get facets(): Observable<SelectedFacets> {
        return this.store.select(dataFormatEditorSelectors.facets);
    }

    get filterPlugins(): Observable<boolean> {
        return this.store.select(dataFormatEditorSelectors.filterPlugins);
    }

    get filter(): Observable<{
        searchTerm: string | undefined;
        page: number;
        maxResults: number;
        facets: SelectedFacets;
        filterPlugins: boolean;
    }> {
        return this.store.select(dataFormatEditorSelectors.dialogFilter);
    }

    constructor(
        private readonly store: Store<EditorState>,
        private readonly applicationStateStore: Store<ApplicationState>,
        private readonly dataFormatService: DataformatService,
        private readonly appFacade: AppFacade,
        private readonly log: LoggerService,
        private readonly dialog: MatDialog
    ) {}

    clearDialogData(): void {
        this.store.dispatch(clearDataFormatDialogData());
    }

    changePagination(page: number, maxResults: number): void {
        this.store.dispatch(dataFormatDialogPaginationChanged({ page, maxResults }));
    }

    changeSearchTerm(searchTerm: string | undefined): void {
        this.store.dispatch(dataFormatDialogSearchTermChanged({ searchTerm }));
    }

    changeFacets(facets: SelectedFacets): void {
        this.store.dispatch(dataFormatDialogFacetsChanged({ facets }));
    }

    changeFilterPlugins(filterPlugins: boolean): void {
        this.store.dispatch(dataFormatDialogFilterPluginsChanged({ filterPlugins }));
    }

    createWithoutDialog(name: string, description: string): Observable<DataFormatCreatedDto> {
        return combineLatest([this.appFacade.selectedCompany, this.appFacade.selectedApplication]).pipe(
            take(1),
            filter(([company, application]) => !!company && !!application),
            switchMap(([{ id: companyId }, { id: applicationId }]) =>
                this.dataFormatService.create(
                    {
                        companyId,
                        applicationId,
                        type: 'BLANK',
                        name,
                    },
                    companyId,
                    applicationId
                )
            )
        );
    }
    create(componentType: ComponentType<any>, openTab: boolean = true): Observable<string[]> {
        this.log.info('Create new dataformat');
        return combineLatest([
            this.appFacade.selectedCompany,
            this.appFacade.selectedApplication,
            this.dialog
                .open(componentType, Object.assign({ disableClose: true }, backofficeEnvironment.dialogConfig.extrasmall))
                .afterClosed(),
        ]).pipe(
            take(1),
            filter(([company, application, { create }]) => !!create && !!company && !!application),
            switchMap(([{ id: companyId }, { id: applicationId }, { type, name, dataformatdescription, file }]) =>
                this.createDataFormat(companyId, applicationId, type, name, dataformatdescription, file, openTab)
            )
        );
    }

    createDataFormat(
        companyId: string,
        applicationId: string,
        type: string,
        name: string | null,
        dataformatdescription: string,
        file: UploadFile | null,
        openTab: boolean
    ): Observable<string[]> {
        return this.dataFormatService
            .create(
                {
                    companyId,
                    applicationId,
                    type,
                    name,
                    dataformatdescription,
                    file,
                },
                companyId,
                applicationId
            )
            .pipe(
                switchMap(result => {
                    if (!result.id && !!result.generationResponse) {
                        return this.dialog
                            .open(
                                AssistantGenerateDataformatValidateComponent,
                                Object.assign(
                                    {
                                        disableClose: true,
                                        data: {
                                            message: { promptMessage: dataformatdescription, response: result.generationResponse },
                                        },
                                    },
                                    backofficeEnvironment.dialogConfig.big
                                )
                            )
                            .afterClosed();
                    } else {
                        return of({ result });
                    }
                }),
                map(({ result, updated }) => {
                    if (result) {
                        this.store.dispatch(createDataFormatSuccess({ id: result.id, openTab }));
                        return [result.id];
                    } else if (updated && updated.length > 0) {
                        return updated.map(updatedDataFormat => updatedDataFormat.id);
                    }
                })
            );
    }

    delete(ids: string[]): void {
        this.log.info(`Deleting data format <${ids}>`);
        if (ids && ids.length > 0) {
            this.store.dispatch(deleteDataFormats({ ids }));
        }
    }

    findAll(
        keyword: string | undefined,
        filters: string[],
        orderBy: string,
        page: number,
        maxResults: number
    ): Observable<Page<OverviewDataFormatDto>> {
        return this.currentContext$.pipe(
            filter((currentContext): currentContext is CurrentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.dataFormatService.findAll(currentContext.selectedCompany.id, currentContext.selectedApplication.id, {
                    keyword,
                    orderBy,
                    filters,
                    page,
                    maxResults,
                });
            })
        );
    }

    findById(dataFormatId: string): Observable<DataFormat> {
        this.log.info(`Find data format by id ${dataFormatId}`);
        this.store.dispatch(findDataFormat({ id: dataFormatId }));
        return this.store.select(dataFormatEditorSelectors.byId(dataFormatId)).pipe(
            filter(dataFormat => !!dataFormat),
            map(dataFormat => plainToClass(DataFormat, dataFormat))
        );
    }

    initialiseDialogData(): void {
        this.store.dispatch(loadDataFormatDialogData());
    }

    update(dataFormat: DataFormat, openTab: boolean = false): void {
        this.log.info(`Update dataformat`, [dataFormat]);
        // @ts-ignore
        dataFormat.jsonSchema = JSON.stringify(dataFormat.jsonSchema);
        this.store.dispatch(updateDataFormat({ dataFormat, openTab }));
    }
}
