import { Injectable } from '@angular/core';
import { UserService } from '@core/services/user.service';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../../app/v2-application/dto/application.dto';
import { selectCurrentContext } from '../../../app/store/data/authenticated.selector';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../app/store/application.state';
import { LoggerService } from '../../../../../../libs/backoffice/utils/src/lib/services/logger.service';
import { Page } from '../../../../../../libs/shared/data-access/src/lib/model/page.model';
import { UserDto } from '../../../../../../libs/backoffice/feature/company/edit-company/src/lib/dto/deprecated/user.dto';

@Injectable()
export class UserFacade {
    currentContext$: Observable<{
        userLanguage: string;
        selectedCompany: CompanyDto;
        selectedApplication: ApplicationDto;
    }> = this.store.select(selectCurrentContext);

    constructor(
        private readonly userService: UserService,
        private readonly store: Store<ApplicationState>,
        private readonly logger: LoggerService
    ) {}

    createApplicationUser(createApplicationUser: {
        email: string | null;
        password: string | null;
        temporaryPassword: boolean | null;
        firstName: string | null;
        lastName: string | null;
        emailVerified: boolean | null;
        forceOtp: boolean | null;
        passwordLess: boolean | null;
        forceWebAuthn: boolean | null;
        triggerEmailValidationImmediatly: boolean | null;
        environment: string | null;
    }) {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.userService.createApplicationUser(
                    Object.assign({ companyId: currentContext.selectedCompany.id }, createApplicationUser)
                );
            })
        );
    }

    findDevelopers(orderBy: string, page: number, maxResults: number, keyword?: string): Observable<Page<UserDto>> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.userService.findByCompany(
                    currentContext.selectedCompany.id,
                    'DEVELOPER',
                    'PRODUCTION',
                    orderBy,
                    page,
                    maxResults,
                    keyword
                );
            })
        );
    }

    findUsers(orderBy: string, page: number, maxResults: number, keyword?: string, environment?: string): Observable<Page<UserDto>> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.userService.findByCompany(
                    currentContext.selectedCompany.id,
                    'USER',
                    environment,
                    orderBy,
                    page,
                    maxResults,
                    keyword
                );
            })
        );
    }

    addGroup(groupId: string, userId: string, applicationId: string, companyId: string) {
        this.userService
            .addGroup(groupId, userId, applicationId, companyId)
            .pipe(
                tap(() => this.logger.info(`Adding group ${groupId} to user ${userId}`, [UserFacade.name])),
                take(1)
            )
            .subscribe();
    }

    addRole(roleId: string, userId: string, environment: string, companyId: string, applicationId: string) {
        this.userService
            .addRole(roleId, userId, environment, companyId, applicationId)
            .pipe(
                tap(() => this.logger.info(`Adding role ${roleId} to user ${userId}`, [UserFacade.name])),
                take(1)
            )
            .subscribe();
    }

    removeGroup(groupId: string, userId: string, applicationId: string, companyId: string) {
        this.userService
            .removeGroup(groupId, userId, applicationId, companyId)
            .pipe(
                tap(() => this.logger.info(`Removing group ${groupId} from user ${userId}`, [UserFacade.name])),
                take(1)
            )
            .subscribe();
    }

    removeRole(roleId: string, userId: string, environment: string, companyId: string, applicationId: string) {
        this.userService
            .removeRole(roleId, userId, environment, companyId, applicationId)
            .pipe(
                tap(() => this.logger.info(`Removing role ${roleId} from user ${userId}`, [UserFacade.name])),
                take(1)
            )
            .subscribe();
    }

    inviteUser(mail: string, companyId: string): Observable<void> {
        console.info(`Inviting user ${mail} to company ${companyId}`);
        return this.userService.inviteUser(mail, companyId);
    }

    deleteUser(companyUserId: string, environment: string): Observable<void> {
        return this.currentContext$.pipe(
            filter(currentContext => !!currentContext),
            take(1),
            switchMap(currentContext => {
                return this.userService.deleteUser(companyUserId, currentContext.selectedCompany.id, environment);
            })
        );
    }
}
