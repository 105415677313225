import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProgressService } from './common/components/progress';
import { AppFacade } from '../modules/core/facades/app.facade';
import { FlowbiteNow } from '@backoffice/utils';

@Component({
    selector: 'app-root',
    template: ` <codex-progress *ngIf="(loadingBusys$ | async) === true"></codex-progress>
        <router-outlet></router-outlet>`,
})
export class AppComponent implements AfterViewChecked, OnInit {
    loadingBusys$: Observable<boolean> = this.progressService.isLoading;

    constructor(
        private readonly progressService: ProgressService,
        private readonly appFacade: AppFacade,
        private cdRef: ChangeDetectorRef
    ) {}

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        this.appFacade.loadUser();
        // This works, as soon as we have no need for calls during scope loading we will be able to make it run using a webworker.
        //const worker = new Worker(new URL('./workers/scope.worker', import.meta.url));
        //worker.postMessage(this.appFacade)
    }
}
