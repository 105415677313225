import { createAction, props } from '@ngrx/store';
import { Media } from '../../models/media';
import { Page } from '@shared/data-access';
import { SelectedFacets } from '../../dto/overview/facets.dto';

export const createMediaSuccess = createAction('[EFFECT] create new media success', props<{ id: string; name: string }>());

export const findMedia = createAction('[ACTION] fetch media', props<{ id: string }>());
export const fetchMediaSuccess = createAction('[EFFECT] fetch media success', props<{ media: Media }>());
export const fetchMediaError = createAction('[ACTION] fetch media error', props<{ error: any }>());

export const deleteMedia = createAction('[ACTION] delete media', props<{ id: string }>());
export const deleteMediaSuccess = createAction('[EFFECT] delete media success', props<{ id: string }>());

export const updateMedia = createAction('[ACTION] update media', props<{ media: Media }>());
export const updateMediaSuccess = createAction('[EFFECT] update media success', props<{ media: Media }>());

// Editor Dialog Actions

export const clearMediaDialogData = createAction('[ACTION] media editor dialog data cleared');
export const clearMediaDialogFilter = createAction('[ACTION] media editor dialog filter cleared');

export const deleteMultipleMedia = createAction('[ACTION] delete multiple media', props<{ ids: string[] }>());
export const deleteMultipleMediaSuccess = createAction('[ACTION] delete multiple media success', props<{ ids: string[] }>());

export const loadMediaDialogData = createAction('[ACTION] load media editor dialog data');
export const loadMediaDialogDataSuccess = createAction('[EFFECT] load media editor dialog data success', props<{ data: Page<Media> }>());

export const mediaDialogFacetsChanged = createAction('[ACTION] media editor dialog facets changed', props<{ facets: SelectedFacets }>());
export const mediaDialogPaginationChanged = createAction(
    '[ACTION] media editor dialog pagination changed',
    props<{ page: number; maxResults: number }>()
);
export const mediaDialogSearchTermChanged = createAction(
    '[ACTION] media editor dialog search term  changed',
    props<{ searchTerm: string | undefined }>()
);

export const mediaDialogFilterPluginsChanged = createAction(
    '[ACTION] media editor dialog filter plugins changed',
    props<{ filterPlugins: boolean }>()
);
