import { Component, OnInit } from '@angular/core';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { AppFacade } from '@core/facades/app.facade';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'codex-company-onboarding',
    templateUrl: './company-onboarding.component.html',
    styleUrls: ['./company-onboarding.component.scss'],
})
export class CompanyOnboardingComponent implements OnInit {
    user$ = this.appFacade.user;

    createCompanyForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<CompanyOnboardingComponent>,
        private readonly appFacade: AppFacade,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        this.initForm();
    }

    initForm() {
        this.createCompanyForm = this.fb.group({
            companyName: ['', Validators.required],
        });
    }

    onAddCompany() {
        if (this.createCompanyForm.valid) {
            this.dialogRef.close({ companyName: this.createCompanyForm.get('companyName').value });
        }
    }
}
