import { JsonProperty } from './json-property';

const type = ['array', 'boolean', 'integer', 'null', 'number', 'object', 'string'];
type Type = (typeof type)[number];

const noCodeXType = ['array', 'boolean', 'integer', 'number', 'object', 'string', 'longstring', 'email', 'url'];
type NoCodeXType = (typeof type)[number];

export class JsonArrayItem {
    noCodeXType: NoCodeXType;
    type: Type[];
    properties?: {
        [key: string]: JsonProperty;
    };
    required?: string[] | undefined;

    constructor(base: Partial<JsonArrayItem> = {}) {
        this.type = base.type;
        this.required = base.required;

        if (base.properties) {
            this.properties = {};
            const propertyNames = base.properties ? Object.keys(base.properties) : [];
            propertyNames.forEach(name => {
                this.properties[name] = new JsonProperty(base.properties[name]);
            });
        }
    }
}
