<mat-dialog-content class="!p-4 scrollbar-primary scrollbar" style="max-height: unset !important">
    <h1 class="package-title">Scale to infinity with our simple pricing package!</h1>
    <div class="flex justify-center items-center gap-4 m-4">
        <div class="package h-[500px] w-[350px]" *ngIf="showFree">
            <div class="package-card h-full relative">
                <h1 class="!leading-8">Perfect for explorers!</h1>
                <span class="price"><span class="currency">€</span> 0.0</span>
                <span class="frequency">per month</span>
                <ul class="mt-2 detail text-center">
                    <li><strong>Storage:</strong> 512 MB</li>
                    <li><strong>Bandwidth:</strong> 1 GB / month</li>
                    <li><strong>Developers:</strong> 1</li>
                    <li><strong>CPU:</strong> 20 mins / month</li>
                </ul>
                <div class="bottom-[15px] absolute" style="width: calc(100% - 30px)">
                    <button class="primary-button button-large flex justify-center gap-2" (click)="dialogRef.close()">
                        <mat-icon>send</mat-icon>
                        {{ 'v2.company.package.free.button' | translate }}
                    </button>
                    <a class="more-info" target="_blank" href="https://www.nocode-x.com/pricing/">>> More info</a>
                </div>
            </div>
        </div>
        <div class="package h-[500px] w-[350px]">
            <div class="package-card relative h-full">
                <h1 class="!mb-2 !leading-8">The right move for builders!</h1>
                <div class="button-group" role="group">
                    <button
                        type="button"
                        class="button-group-button-left"
                        autofocus
                        (click)="yearly = true"
                        [ngClass]="yearly ? 'button-group-button-selected' : ''">
                        Pay annually
                    </button>
                    <button
                        type="button"
                        class="button-group-button-middle"
                        autofocus
                        (click)="yearly = false"
                        [ngClass]="!yearly ? 'button-group-button-selected' : ''">
                        Pay monthly
                    </button>
                </div>
                <span *ngIf="yearly" class="price"><span class="currency">€</span> {{ amountOfCores * 20 }}</span>
                <span *ngIf="!yearly" class="price"><span class="currency">€</span> {{ amountOfCores * 24 }}</span>
                <span class="frequency">per month</span>
                <ul class="mt-2 detail text-center">
                    <li><strong>Storage:</strong> {{ amountOfCores * 10 }} GB</li>
                    <li><strong>Bandwidth:</strong> {{ amountOfCores * 5 }} GB / month</li>
                    <li><strong>Developers:</strong> {{ amountOfCores * 1 }}</li>
                    <li><strong>CPU:</strong> {{ amountOfCores * 1000 }} mins / month</li>
                </ul>
                <div class="bottom-[15px] absolute" style="width: calc(100% - 30px)">
                    <input
                        placeholder="Amount of cores"
                        class="small-input"
                        type="number"
                        id="amount-of-cores"
                        name="amount-of-cores"
                        autocomplete="off"
                        [min]="1"
                        [(ngModel)]="amountOfCores" />
                    <input
                        placeholder="Coupon code"
                        class="small-input"
                        type="text"
                        id="coupon-code"
                        name="coupon-code"
                        autocomplete="off"
                        [(ngModel)]="couponCode" />
                    <button class="primary-button button-large flex justify-center gap-2" (click)="initiateBillingPackage('PERSONAL')">
                        <mat-icon>send</mat-icon>
                        {{ 'v2.company.package.personal.button' | translate }}
                    </button>
                    <a class="more-info" target="_blank" href="https://www.nocode-x.com/pricing/">>> More info</a>
                </div>
            </div>
        </div>
    </div>
    <div class="text-white text-center">
        <a class="text-accenttext font-bold" href="mailto:info@co-dex.eu">Contact us</a> for a custom offer or an on-prem setup.
    </div>
</mat-dialog-content>
