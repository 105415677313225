import { BackgroundStyleDto } from '../../../../../interfaces/style/background.style.dto';
import { BorderStyleDto } from '../../../../../interfaces/style/border.style.dto';
import { FontStyleDto } from '../../../../../interfaces/style/font.style.dto';
import { GenericStyleDto } from '../../../../../interfaces/style/generic-style.dto';
import { PaddingStyleDto } from '../../../../../interfaces/style/padding.style.dto';
import { ShadowStyleDto } from '../../../../../interfaces/style/shadow.style.dto';

export interface PagingPartGenericStyleDto extends GenericStyleDto {
    textFont: FontStyleDto;
    buttonFontSize: string;
    buttonBackgroundStyle: BackgroundStyleDto;

    buttonBorderStyle: BorderStyleDto;
    buttonBorderTopStyle: BorderStyleDto;
    buttonBorderLeftStyle: BorderStyleDto;
    buttonBorderRightStyle: BorderStyleDto;
    buttonBorderBottomStyle: BorderStyleDto;

    buttonPaddingStyle: PaddingStyleDto;
    buttonPaddingTopStyle: PaddingStyleDto;
    buttonPaddingLeftStyle: PaddingStyleDto;
    buttonPaddingRightStyle: PaddingStyleDto;
    buttonPaddingBottomStyle: PaddingStyleDto;

    buttonShadowStyle: ShadowStyleDto;
}
