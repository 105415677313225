import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverviewTemplateDto } from '@backoffice/data-access/editor';
import { ApplicationDto } from '../../../../../../data-access/editor/src/lib/interfaces/application.dto';

@Component({
    selector: 'codex-template-overview-row-v2',
    templateUrl: './template-overview-row.component.html',
    styleUrls: ['./template-overview-row.component.scss'],
})
export class TemplateOverviewRowComponent {
    @Input() template: OverviewTemplateDto;
    @Input() application: ApplicationDto;

    @Output() clicked = new EventEmitter<void>();
    @Output() selectionChanged = new EventEmitter<boolean>();

    checkboxSelected = false;

    onClicked(): void {
        this.clicked.emit();
    }

    onCheckboxSelected(): void {
        this.checkboxSelected = !this.checkboxSelected;
        this.selectionChanged.emit(this.checkboxSelected);
    }
}
